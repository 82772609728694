var React = require('react');
var {connect} = require('react-redux');
var { withTranslation } = require('react-i18next');
var {isMobile, isAndroid} = require('Utils');

class DashboardBannerForMobile extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { t } = this.props;
        if (!isMobile()) {
            return null;
        }
        let linkToStore = "https://play.google.com/store/apps/details?id=com.sciroot&ah=ebzr7LDRrCHZgDfryg8FJfC-lj4";

        if (!isAndroid()){
            linkToStore = "https://apps.apple.com/il/app/n-drip-connect/id1573150032";
        }

        return (
            <div className="bg-bermuda-gray container-fluid position-fixed d-flex bd-highlight justify-content-between align-items-center p-2" style={{zIndex: 300, right:0}}>
                <div className='row flex-nowrap align-items-center'>
                    <span className="p-2 bd-highlight text-white mr-2 ml-2 font-weight-semi-bold">{t('new')}</span>
                    <a className='p-2 bd-highlight d-flex' href={linkToStore}>
                        <span className='text-white border border-white rounded-pill  border-5  p-1 pr-2 pl-2 font-weight-semi-bold'>{t('download_the_app')}</span>

                    </a>
                </div>

                <img src={ require('images/icons/close_white.svg') } className='p-2 bd-highlight' onClick={this.props.closeBanner}/>

            </div>
        )

    }
}
class DashboardBannerForDesktop extends React.Component {
    render() {
        if (isMobile()) {
            return null;
        }
        return (
            <div className="pb-2 ml-4 align-self-center d-none d-md-block">
                <LinksToStores height={35}/>
            </div>
        )
    }
}

class LogInBanner extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="text-center">
                <span className="text-grey45">{t('take_the_app_to_the_field')}</span>
                <div style={{height: 10}}/>
                <LinksToStores height={40}/>
            </div>
        )
    }
}

class LinksToStores extends React.Component {
    render() {
        return (
            <div>

                <a href="https://play.google.com/store/apps/details?id=com.sciroot&ah=ebzr7LDRrCHZgDfryg8FJfC-lj4">
                    <img src={require('images/general/Downdload_Google_Play_Store.png')} className="h-30 d-inline-block"
                         style={{height: this.props.height, width: "auto"}}/>
                </a>

                <a href="https://apps.apple.com/il/app/n-drip-connect/id1573150032">
                    <img src={require('images/general/Download_App_Store.png')} className="h-30 d-inline-block ml-3"
                         style={{height: this.props.height, width: "auto"}}/>
                </a>

            </div>
        )
    }
}

LogInBanner = connect()(withTranslation()(LogInBanner));
DashboardBannerForMobile = connect()(withTranslation()(DashboardBannerForMobile));
DashboardBannerForDesktop = connect()(withTranslation()(DashboardBannerForDesktop));

module.exports = {LogInBanner, DashboardBannerForMobile, DashboardBannerForDesktop};
