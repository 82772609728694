const React = require("react");
const {connect} = require("react-redux");
const {withTranslation, useTranslation} = require("react-i18next");

const SelectionMenu = ({menuOptions, selectedItem, onItemClick}) => {
    const {t} = useTranslation();

    return (
        <div style={{marginTop: "16px", marginLeft: "24px", display: "flex", flexDirection: "row"}}>
            <div style={{fontWeight: "400", marginRight: "8px"}} className="text-size-16 text-grey71">{t("Insights_group_by")}</div>
            <div style={{cursor: "pointer", display: "flex", flexDirection: "row"}}>
                <div className="dropdown text-grey71 font-weight-normal text-underline">
                    <div className="dropdown-toggle d-flex align-items-center" id="dropdownMenuButton"
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div className="overflow-hidden text-truncate">
                            <div className="font-weight-normal text-grey45">{selectedItem.title}</div>
                        </div>
                    </div>
                    <div className="dropdown-menu scrollable-menu" style={{padding: "8px 4px 8px 4px"}}
                         aria-labelledby="dropdownMenuButton">
                        {menuOptions?.map((option, index) => {

                            return (<div className="dropdown-item" style={{
                                display: "flex",
                                flexDirection: "row",
                                borderRadius: "3px",
                                paddingLeft: "8px"
                            }} onClick={() => {
                                onItemClick(index)
                            }}>
                                {option.isSelected ?
                                    <img style={{marginRight: "8px"}} src={require('images/icons/v_icon.svg')}/> :
                                    <div style={{width: "18px"}}/>}
                                <div className="text-grey45 text-size-16" key={option.title}>{option.title}</div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

module.exports = connect()(withTranslation()(SelectionMenu));