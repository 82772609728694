
//Take mixpanel's reference according to context
var mixpanelInstance = null;

//In used only by Mobile-App
export var initMixpanel = (mixpanel) => {
    mixpanelInstance = mixpanel;
    mixpanelInstance.register = mixpanel.registerSuperProperties;
}

export var getMixpanelInstance = () => {
    return mixpanelInstance || mixpanel
}