const React = require("react");
const InsightsTableContent = require("./InsightsTableContent");
const {connect} = require("react-redux");
const {withTranslation} = require("react-i18next");


const InsightsTable = ({headers, insightsData, userData, openFieldsToggle, setOpenFieldsToggle, getHeadersStyle}) => {

    let mappedHeadersStyle = getHeadersStyle(headers) || [];
    return (
        <div className="bg-grey91" style={{height: "730px", overflowY: "scroll", marginLeft: "24px", marginTop:"16px", marginRight:"24px", marginBottom:"40px", borderRadius: "8px", borderBottom:"1px solid #DCE0E8"}}>
            <table className="bg-grey91" id="insightsTable" style={{width: "100%", borderRadius: "8px"}}>

                <thead style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "white",
                    boxShadow: " 0px 1px 4px rgba(146, 158, 181, 0.1), 0px 4px 8px rgba(145, 157, 180, 0.18)"
                }}>
                <tr style={{height: "56px"}}>
                    {mappedHeadersStyle.map((header, i) => (
                        <th className="text-grey45 text-size-14" style={{
                            width: header[1],
                            backgroundColor: "#FFFFFF",
                            borderTop: "1px solid rgb(220, 224, 232)",
                            borderRight: i === mappedHeadersStyle.length - 1 ? "1px solid rgb(220, 224, 232)" : "0px",
                            borderLeft: i === 0 ? "1px solid rgb(220, 224, 232)" : "0px",
                            fontWeight: "400",
                            padding: i === 0 ? "0px" : "8px 16px"
                        }} key={i}>
                            <span>{header[0]}</span>
                        </th>
                    ))}
                </tr>
                </thead>
                <InsightsTableContent insightsTableMap={insightsData} userData={userData}
                                      openFieldsToggle={openFieldsToggle} setOpenFieldsToggle={setOpenFieldsToggle}/>
            </table>
        </div>
    )
}

module.exports = connect()(withTranslation()(InsightsTable));