var React = require('react');
var {connect} = require('react-redux');
var fieldManager = require('fieldManager');



class IrrigationMeter extends React.Component {


  constructor(props) {
      super(props);

      this.gauge = null;

      var staticZonesNormal = Object.keys(fieldManager.stress_levels).map(function(key, index) {

        return {strokeStyle: fieldManager.stress_levels[key]['color'],
                min: fieldManager.stress_levels[key]['min_value'] * 100,
                max: fieldManager.stress_levels[key]['max_value'] * 100,
                height:1};

      });

      var staticZoneNoData = Object.keys(fieldManager.stress_levels).map(function(key, index) {

        return {strokeStyle: '#dfe7f0',
                min: 0,
                max: 100,
                height:1};

      });

      this.normalOpts = {
        angle: 0.08, // The span of the gauge arc
        lineWidth: 0.5, // The line thickness

        radiusScale: 1.1, // Relative radius
        pointer: {
          length: 0.52, // // Relative to gauge radius
          strokeWidth: 0.045, // The thickness
          color: '#4d5674' // Fill color
        },
        limitMax: false,     // If false, max value increases automatically if value > maxValue
        limitMin: false,     // If true, the min value of the gauge will be fixed
        colorStart: '#6FADCF',   // Colors
        colorStop: '#8FC0DA',    // just experiment with them
        strokeColor: '#E0E0E0',  // to see which ones work best for you
        generateGradient: true,
        highDpiSupport: true,     // High resolution support
        staticZones: staticZonesNormal,
      };

      this.optsNoData = {
        angle: 0.08, // The span of the gauge arc
        lineWidth: 0.5, // The line thickness

        radiusScale: 1.1, // Relative radius
        pointer: {
          length: 0.0, // // Relative to gauge radius
          strokeWidth: 0.045, // The thickness
          color: '#dfe7f0' // Fill color
        },
        limitMax: false,     // If false, max value increases automatically if value > maxValue
        limitMin: false,     // If true, the min value of the gauge will be fixed
        colorStart: '#6FADCF',   // Colors
        colorStop: '#8FC0DA',    // just experiment with them
        strokeColor: '#E0E0E0',  // to see which ones work best for you
        generateGradient: true,
        highDpiSupport: true,     // High resolution support
        staticZones: staticZoneNoData,
      };

      Gauge.prototype.setOptions = function (options) {
        var gauge, j, len, phi, ref;

        if (options == null) {
          options = null;
        }

        Gauge.__super__.setOptions.call(this, options);

        this.configPercentColors();
        this.extraPadding = 0;

        if (this.options.angle < 0) {
          phi = Math.PI * (1 + this.options.angle);
          this.extraPadding = Math.sin(phi);
        }

        this.availableHeight = this.canvas.height * (1 - this.paddingTop - this.paddingBottom);
        this.lineWidth = this.availableHeight * this.options.lineWidth;
        this.radius = (this.availableHeight - this.lineWidth / 2) / (1.0 + this.extraPadding);
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        ref = this.gp;

        for (j = 0, len = ref.length; j < len; j++) {
          gauge = ref[j];
          gauge.setOptions(this.options.pointer);
          // gauge.render(); Line deleted which will fix your problem !!!!
        }

        this.render();
        return this;
      };
  }

  componentDidMount() {

    this.props.onRef(this);

    var that = this;

    var target = document.getElementById('da-gauge'); // your canvas element
    this.gauge = new Gauge(target); // create sexy gauge!
    this.gauge.maxValue = 100; // set max gauge value
    this.gauge.setMinValue(0);  // Prefer setter over gauge.minValue = 0
    this.gauge.animationSpeed = 66; // set animation speed (32 is default value)
    // this.gauge.setTextField(document.getElementById("preview-textfield"));

    this.updateMeterValue(-1);
  }

  componentWillUnmount() {
      this.props.onRef(undefined);
    }

  updateMeterValue(stress_value){

    if (this.gauge) {

      if (stress_value != -1) {
        this.gauge.setOptions(this.normalOpts);
        this.gauge.set(parseFloat(stress_value) * 100);
      } else {
        this.gauge.setOptions(this.optsNoData);
        this.gauge.set(0.0);
      }


    }
  }

  render() {
    let gaugeSize = this.props.shouldDisplayInFieldDetails ? {width: "80px", height: "80px"} : {}
    return (

        <div className="text-center d-flex" style={gaugeSize}>
          <canvas id='da-gauge'/>
        </div>
    );
  }
};

const mapStateToProps = function(state){
  return {

  }
}

const mapDispatchToProps = {

}

module.exports = connect(mapStateToProps,mapDispatchToProps)(IrrigationMeter);
