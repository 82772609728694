var React = require('react');
var {connect} = require('react-redux');
var fieldManager = require('fieldManager');
var {upper, timeSinceNowString} = require('helpers');
var moment = require('moment');
var distributionActions = require('distributionActions');
var IrrigationMeter = require('IrrigationMeter');
var { withTranslation } = require('react-i18next');
var {calculateVolume, unitDisplayName, convert} = require('unitsManager');
var fieldManager = require('fieldManager');


class IrrigationMeterContainer extends React.Component {

  constructor(props) {
    super(props);

    this.onMouseover = this.onMouseover.bind(this);
    this.onMouseout = this.onMouseout.bind(this);

  }

  componentDidMount() {
    this.setState({'mainMessage':'', 'fieldArea':0, 'commandMessage':'', 'shouldDisplayVolume':false, 'noNeedToIrrigateMessage':undefined,'irrigationAmountMessage':'', 'irrigationVolumeMessage':'', 'stressLevel':0,'recommendation_date':''});

    if (this.props.selectedField) {
      this.updateGauge(this.props.selectedField);
    }

  }

  componentWillUnmount() {
  }

  componentDidUpdate(nextProps, nextState) {

    if ((nextProps.loadHistoryStatus.status == "success") || (this.props.selectedField && !nextProps.selectedField) || (this.props.selectedField && nextProps.selectedField && JSON.stringify(this.props.selectedField) != JSON.stringify(nextProps.selectedField)))  {
      this.updateGauge(this.props.selectedField);
    }
  }

  updateGauge(field) {

    var recommendation_amount = -1;
    var stress_level = -1;
    var accumulatedETc = -1;
    var recommendation_date = moment();
    var recommendationAmountVolume = -1;
    var accumulatedETcVolume = -1;
    var area = undefined;
    let fieldAreaConverted = undefined;

    if (field) {
      let fieldItems = fieldManager.getFieldItems(field);
      stress_level = fieldItems.stressDataItem.value;
      recommendation_amount = fieldItems.irrigationDataItem.value;
      recommendation_date = fieldItems.irrigationDataItem.date;
      accumulatedETc = fieldItems.accumulatedETc.value;

      area = fieldManager.getFieldArea(field.geo_data.areas);
      fieldAreaConverted = convert(area, "dunam", this.props.userData.area, "area");
      recommendationAmountVolume = calculateVolume(fieldAreaConverted, this.props.userData['area'], recommendation_amount, this.props.userData['length'], this.props.userData['volume']);
      accumulatedETcVolume = calculateVolume(fieldAreaConverted, this.props.userData['area'], accumulatedETc, this.props.userData['length'], this.props.userData['volume']);
    }

    this.gauge.updateMeterValue(stress_level);

    var  volumeMessageObject = fieldManager.get_stress_message(stress_level, recommendationAmountVolume, accumulatedETcVolume, recommendation_date, unitDisplayName(this.props.userData['volume']));
    var  messageObject = fieldManager.get_stress_message(stress_level, recommendation_amount, accumulatedETc, recommendation_date, this.props.userData['length']);

    let mainMessage = this.props.t(messageObject['mainMessage']);
    let commandMessage = this.props.t(messageObject['commandMessage']);

    let irrigationMessage = undefined;
    let irrigationVolumeMessage = undefined;
    let noNeedToIrrigateMessage = undefined;
    if (messageObject['irrigationAmountMessage'] == "good") {

        noNeedToIrrigateMessage =  <img src={ require('images/icons/ir_ok_icon.svg') } className="img-fluid" alt="irrigation ok"/>;

    } else if (messageObject['irrigationAmountMessage']) {
      irrigationMessage = messageObject['irrigationAmountMessage'];
      irrigationVolumeMessage = volumeMessageObject['irrigationAmountMessage']
    }

    let timeAgo = timeSinceNowString(moment.unix(recommendation_date))

    this.setState({'stressLevel':stress_level,
      'mainMessage':mainMessage,
      'commandMessage':commandMessage,
      'noNeedToIrrigateMessage':noNeedToIrrigateMessage,
      'irrigationAmountMessage':irrigationMessage,
      'irrigationVolumeMessage':irrigationVolumeMessage,
      'fieldArea':fieldAreaConverted,
      'recommendation_date': timeAgo});
  }

   onMouseover (e) {
     this.setState({'shouldDisplayVolume':true});
   }

   onMouseout (e) {
     this.setState({'shouldDisplayVolume':false});
   }

  render() {

    const { t } = this.props;

    let messageElement = <div></div>;
    let recommendationElement = <div></div>;

    let recommendationMessageSize = this.props.shouldStrech ? "text-size-42" : "text-size-52 pt-2";
    let commandMessageSize = this.props.shouldStrech ? "text-size-14" : "text-size-20 text-grey45 text-truncate";
    let seperator = this.props.shouldStrech ? <div></div> : <div className="bg-grey96 mb-2 mt-3 mr-4 ml-4 " style={{"height": "2px"}}></div>;

    if (this.state){
      if (this.state.noNeedToIrrigateMessage) {
        recommendationElement = <div>
          <div className="w-100 text-center pt-1" >

            <div className="pt-0 pb-2">{this.state.noNeedToIrrigateMessage}</div>
            {seperator}
            <div className={"text-grey45 text-truncate " + commandMessageSize}>{this.state && this.state.commandMessage}</div>
          </div>


        </div>;

      } else if (this.state.irrigationAmountMessage) {
        recommendationElement =
            <div className="align-self-center">
              <div className="w-100 text-center ">
                {this.state.shouldDisplayVolume ?

                    <div>
                      <div className={"text-grey45 font-weight-bold " + recommendationMessageSize}
                           onMouseEnter={this.onMouseover.bind(this)} onMouseLeave={this.onMouseout.bind(this)}
                           style={{"lineHeight": "1"}}>
                        {this.state.irrigationVolumeMessage} </div>
                      {/*<div*/}
                      {/*    className="text-size-16">{t("based_on")} {this.state.fieldArea} {unitDisplayName(this.props.userData['area'])}</div>*/}
                    </div>
                    :
                    <div className={"text-grey45 font-weight-bold " + recommendationMessageSize} onMouseEnter={this.onMouseover.bind(this)}
                         onMouseLeave={this.onMouseout.bind(this)} style={{"lineHeight": "1"}}>
                      {this.state.irrigationAmountMessage}</div>}
                {seperator}
                <div className={"text-grey45 text-truncate pt-1 " + commandMessageSize}>{this.state && this.state.commandMessage}
                </div>
              </div>

        </div>;

      } else {
        recommendationElement = <div className="pr-2 pl-2 pt-2 text-size-26 text-center text-grey71 font-weight-bold" style={{"opacity":"0.6", 'lineHeight':1.2}}>{t("no_recommendation_available")}</div>;
      }
    }

    return (

          <div className=" d-flex flex-column h-100 w-100 align-items-center justify-content-between bg-white" style={{"borderRadius": "5px",border:"1px solid #DCE0E8"}}>
            <div className="" style={{}}></div>
            <div className="d-flex flex-column align-items-center" style={{"maxWidth":"75%"}}>
              <div className="" style={{ "width":'100%'}}><IrrigationMeter onRef={ref => (this.gauge = ref)}/></div>

            {recommendationElement}
            </div>

            <div className="text-grey71 text-size-12 w-100 text-center pb-1 " style={{}}><small> {this.state && this.state.recommendation_date && this.state.stressLevel != -1 && t("last_update") +  t(this.state.recommendation_date[1]).format(this.state.recommendation_date[0])}</small></div>
          </div>
    );
  }
};
//<IrrigationMeter onRef={ref => (this.gauge = ref)}/>
const mapStateToProps = function(state){
  return {
    selectedField: state.distribution_data.selected_entities.field,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    userData: state.authentication_data.user_data,
    loadHistoryStatus: state.distribution_data.farming_unit_load_history,
  }
}

const mapDispatchToProps = {
  setSelectedEntities: distributionActions.selected_entities
}

module.exports = connect(mapStateToProps,mapDispatchToProps)(withTranslation()(IrrigationMeterContainer));


/*
<div className="d-flex flex-column align-items-center">
  <div style={{"width":'100%', 'height':'100%'}} ><IrrigationMeter onRef={ref => (this.gauge = ref)}/></div>
  {messageElement}
  <div className="text-size-20 text-grey45 text-truncate font-weight-semi-bold" style={{"lineHeight":"24px"}}>{this.state && this.state.commandMessage}</div>
  {recommendationElement}

</div>
*/
