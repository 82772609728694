const React = require('react');
const {SelectMenu} = require('SelectMenu');
const {useTranslation, withTranslation} = require("react-i18next");
const {useState, useEffect} = require("react");
const ArrowIcon = require('images/icons/edit_field/ArrowIcon.svg');
const moment = require('moment');
const {connect} = require("react-redux");
const {unitDisplayName} = require('unitsManager');
const TimeFrame = require("TimeFrame");

const weekDays = moment.weekdays();
let daysMenuOptions=[];
Object.assign(daysMenuOptions,weekDays.map((day)=>{return {title:day,value:day.toLowerCase()}}));
daysMenuOptions.push({title: "Every Day", value: "everyDay"}, {
    title: "Every Two Days",
    value: "every2Day"
}, {title: "Every Three Days", value: "every3Day"}, {
    title: "Every Four Days",
    value: "every4Day"
}, {title: "Every Five Days", value: "every5Day"}, {
    title: "Every Six Days",
    value: "every6Day"
}, {title: "Every Seven Days", value: "every7Day"}, {
    title: "Every Eight Days",
    value: "every8Day"
}, {title: "Every Nine Days", value: "every9Day"}, {title: "Every Ten Days", value: "every10Day"},
    {title: "Every Two Days (Minimum)", value: "minEvery2Day"},
    {title: "Every Three Days (Minimum)", value: "minEvery3Day"},
    {title: "Every Four Days (Minimum)", value: "minEvery4Day"},
    {title: "Every Five Days (Minimum)", value: "minEvery5Day"},
    {title: "Every Six Days (Minimum)", value: "minEvery6Day"},
    {title: "Every Seven Days (Minimum)", value: "minEvery7Day"},
    {title: "Every Eight Days (Minimum)", value: "minEvery8Day"},
    {title: "Every Nine Days (Minimum)", value: "minEvery9Day"},
    {title: "Every Ten Days (Minimum)", value: "minEvery10Day"})


var startHoursMenuOptions = [];
const setStartHoursMenuOptions = () => {
    var hours = [];
    for (var i = 0; i <= 47; i++) {
        var n = i % 2 == 0 ? i / 2 + ':00' : (i + 1) / 2 - 1 + ':30';
        if (i < 20) {
            n = '0' + n;
        }
        hours.push({title: n, value: n});
    }
    return hours;
}
startHoursMenuOptions = setStartHoursMenuOptions();


const EditTimeFrame = ({onDone, onCancel,userData, timeFrameSerialNumber, irrigationSystem, soilType, minSource, maxSource, minPlaceHolder, maxPlaceHolder, maxTooltipContent , minTooltipContent, irrigationStartDate}) => {
    const [chosenStartDay, setChosenStartDay] = useState(daysMenuOptions[0].value);
    const [chosenStartHour, setChosenStartHour] = useState(startHoursMenuOptions[0].value);
    const [chosenEndHour, setChosenEndHour] = useState();
    const [chosenStartDate, setChosenStartDate] = useState();
    const [chosenName, setChosenName] = useState("Timeframe " + timeFrameSerialNumber);
    const [chosenEndDate, setChosenEndDate] = useState();
    const [chosenMin, setChosenMin] = useState();
    const [chosenMax, setChosenMax] = useState();
    const [endHourMenuOptions, setEndHourMenuOptions] = useState(startHoursMenuOptions);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    let userUnits = (userData && ("length" in userData)) ? unitDisplayName(userData.length) : "";
    const {t} = useTranslation();

    useEffect(() => {
        setFilteredEndHourMenuOptions('','00:00');
    }, [])

    const setFilteredEndHourMenuOptions = (id,hour) => {
        setChosenStartHour(hour);
        var hours = [];
        var chosenStartHourIndex = startHoursMenuOptions.findIndex(obj => obj.value === hour);
        for (var i = chosenStartHourIndex + 1; i < 48; i++) {
            var n = i % 2 == 0 ? i / 2 + ':00' : (i + 1) / 2 - 1 + ':30';
            if (i < 20) {
                n = '0' + n;
            }
            hours.push({title: n, value: n});
        }
        hours.push({title: "00:00", value: "00:00"});
        hours.push({title: "-" +t("next_day") +"-", value: ""});
        for (var i = 0 + 1; i < 48; i++) {
            var n = i % 2 === 0 ? i / 2 + ':00' : (i + 1) / 2 - 1 + ':30';
            if (i < 20) {
                n = '0' + n ;
            }
            hours.push({title: n + " ⁺¹", value: n + "+1"});
        }
        setEndHourMenuOptions(hours);
        setChosenEndHour(hours[0].value);
    }

    const handleDonePressed = (day, start, end, min, max, startDate, endDate, name) => {

        if (max !== undefined ){
            max = parseInt(max);
        }

        if (min !== undefined ){
            min = parseInt(min);
        }

        if (max === 0 || max <= min) {
            setError(true);
            setErrorMessage(t("max_bigger_than_min"));
            return;
        }
        let isError;
        isError = onDone(day, start, end, min, max, startDate, endDate, name);
        if (isError === "") {
            setError(false);
        } else {
            setErrorMessage(isError);
            setError(true);
        }
    }

    const handleChangeDay = (id, newDay) => {
        setChosenStartDay(newDay);
        return true;
    }

    const handleStartHour = (id, startHour) => {
        setFilteredEndHourMenuOptions('', startHour);
        return false;
    }

    const handleChangeStartDate = (id, startDate) => {
        setChosenStartDate(moment(startDate).format('YYYY-MM-DD'));
    }

    const handleChangeEndDate = (id, endDate) => {
        setChosenEndDate(moment(endDate).format('YYYY-MM-DD'));
    }

    const handleChangeTimeFrameName = (id, name) => {
        setChosenName(name);
    }

    const handleChangeMin = (id, min) => {
        if (((chosenMax !== undefined && parseFloat(min) > parseFloat(chosenMax)) || parseFloat(min) > parseFloat(maxPlaceHolder))){
            setError(true);
            setErrorMessage(t("min_smaller_than_max"));
        } else {
            setError(false);
        }
        setChosenMin(min);
    }

    const handleChangeMax = (id, max) => {
        if ((chosenMin !== undefined && parseFloat(max) < parseFloat(chosenMin) || parseFloat(max) < parseFloat(minPlaceHolder))){
            setError(true);
            setErrorMessage(t("max_bigger_than_min"));
        } else {
            setError(false);
        }
        setChosenMax(max);
    }

    const handleChangeEndHour = (id, endHour) => {
        setChosenEndHour(endHour);
        return false;
    }

    return (
        <div className="d-flex mb-4 card p-0" style={{backgroundColor:"#f6f8fa",border:0,borderBottom:"1px solid #DCE0E8", borderRadius:0}}>
            <TimeFrame displayExpended={true} displayDeleteMenu={false} id={""} day={chosenStartDay}
                       start_hour={chosenStartHour} end_hour={chosenEndHour} min={chosenMin} max={chosenMax} name={chosenName}
                       start_date={chosenStartDate} end_date={chosenEndDate} onChangeDay={handleChangeDay}
                       onChangeStartHour={handleStartHour} onChangeStartDate={handleChangeStartDate} onChangeEndDate={handleChangeEndDate}
                       onChangeEndHour={handleChangeEndHour} onChangeTimeFrameName={handleChangeTimeFrameName} onChangeMax={handleChangeMax}
                       onChangeMin={handleChangeMin} timeFrameSerialNumber={timeFrameSerialNumber} minTooltipContent={minTooltipContent} maxTooltipContent={maxTooltipContent} irrigationStartDate={irrigationStartDate}
                       minPlaceHolder={minPlaceHolder} maxPlaceHolder={maxPlaceHolder} minSource={minSource} maxSource={maxSource}/>
            <div className='d-flex flex-row align-items-center w-100 my-2' style={{paddingRight:"35px"}}>
                <label hidden={!error} className='mb-0 ml-3 text-size-14 lh-1 text-red'>{errorMessage}</label>
                <button style={{outline: 'none', width: 80, height: 24, fontWeight:"600"}} type='button'
                        className='btn btn-outline-secondary d-flex mr-2 ml-auto justify-content-center align-items-center text-size-12'
                        onClick={onCancel}>
                    Cancel
                </button>
                <button style={{outline: 'none', width: 80, height: 24, fontWeight:"600"}} type='button'
                        className={'btn d-flex mr-2 justify-content-center align-items-center bg-blue-primary text-white text-size-12'}
                        onClick={() => handleDonePressed(chosenStartDay, chosenStartHour, chosenEndHour, chosenMin, chosenMax, chosenStartDate, chosenEndDate, chosenName)}>
                    Done
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        userData: state.authentication_data.user_data
    }
}
const mapDispatchToProps = {}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditTimeFrame));