var React = require('react');
var {connect} = require('react-redux');
var { Link } = require ('react-router-dom');
var ApprovalModal = require('ApprovalModal');
var AlertModal = require('AlertModal');
var ManageDistributionUsersModal = require('ManageDistributionUsersModal');
var UpdateDistributionUnitModal = require('UpdateDistributionUnitModal');
var distributionActions = require('distributionActions');
var $ = require('jquery');
var { withTranslation } = require('react-i18next');
var {sort_dictioanry_by_value_and_property} = require('helpers');

class DistributionUnitsTable extends React.Component {

  constructor(props) {
      super(props);

      this.navigateToFarmingUnitManagementPage = this.navigateToFarmingUnitManagementPage.bind(this);
      this.onDeleteApprovalClick = this.onDeleteApprovalClick.bind(this);
  }

  onDeleteApprovalClick(objects) {
    this.props.deleteDistribution(objects[0]);
  }

  navigateToFarmingUnitManagementPage(distribution_id) {

    this.props.selectedEntitiesById(distribution_id, null);
    this.props.history.push("FarmingUnitsManagement");
  }

  render() {
    const { t } = this.props;

    var deletionApprovalModalId = "deletionApprovalModalId";
    var deleteAlertModalId = "deleteAlertModalId";
    var manageDistributionUsersModalId = "manageDistributionUsersModalId";

    if ((this.props.distribution_actions.status == "fail") && (this.props.distribution_actions.type == "DELETE")) {
      $('#' + deleteAlertModalId).modal('show');
    }

    let allDistributionsSorted = undefined;
    if (this.props.all_distributions) {
      allDistributionsSorted = sort_dictioanry_by_value_and_property(this.props.all_distributions.distributions, "name");
    }

    return (
      <div>
          <ApprovalModal modalId={deletionApprovalModalId} message={t('management_delete_distribution_message')} onApprove={this.onDeleteApprovalClick}/>
          <AlertModal modalId={deleteAlertModalId} message={this.props.distribution_actions.error} onDismiss={this.props.clearDistributionActions}/>
          <ManageDistributionUsersModal modalId={manageDistributionUsersModalId}/>

          <table className="table shadow-lg rounded" >
            <thead>
              <tr className="bg-green text-white">
                <th scope="col"></th>
                <th scope="col">{t('distribution_unit')}</th>
                <th scope="col">{t('actions')}</th>
              </tr>
            </thead>
            <tbody>

              {!allDistributionsSorted  &&
                <tr className="bg-smoked">
                  <td colSpan="4" className="align-middle text-center">
                    <span className="spinner-grow " role="status" aria-hidden="true"></span>
                  </td>
                </tr>
              }

              {allDistributionsSorted && allDistributionsSorted.map((item) => {

                var current_distribution = item[1];

                var links_column = (<td className="align-middle"></td>);
                if (current_distribution.role  == "admin") {
                  links_column = (<td className="align-middle">
                      <a className="btn btn-link" data-toggle="modal" data-target={"#" + this.props.updateDistributionModalId}
                        data-name={current_distribution.name} data-region={current_distribution.region} data-id={current_distribution.id}>
                        <img src={ require('images/icons/edit_icon.png') } className="img-fluid" alt="edit distribution"/></a>

                      <a className="btn btn-link" data-toggle="modal" data-target={"#" + deletionApprovalModalId} data-values={[current_distribution.id]}>
                        <img src={ require('images/icons/delete_icon.png') } className="img-fluid" alt="delete distribution"/></a>

                      <a className="btn btn-link" data-toggle="modal" data-target={"#" + manageDistributionUsersModalId} data-id={current_distribution.id}>
                        <img src={ require('images/icons/account_icon.png') } className="img-fluid" alt="manage distribution users"/></a>
                  </td>);
                }

                return <tr key={current_distribution.id}>
                        <td className="align-middle" scope="row"></td>
                        <td className="align-middle">
                          <a className="text-black" href="#" onClick={() => { this.navigateToFarmingUnitManagementPage(current_distribution.id) }}>{current_distribution.name}</a>
                        </td>
                        {links_column}
                      </tr>;
              })}
            </tbody>
          </table>
      </div>
    );
  }
};

const mapStateToProps = function(state){

  return {
    all_distributions: state.distribution_data.distributions,
    distribution_actions: state.distribution_data.distribution_actions,
  }
}

const mapDispatchToProps = {
  deleteDistribution: distributionActions.delete_distribution,
  clearDistributionActions: distributionActions.clear_distribution_action,
  selectedEntitiesById: distributionActions.selected_entities_by_id
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DistributionUnitsTable));
