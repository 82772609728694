var axios = require('axios');
var GeneralClasses = require('@model/GeneralClasses');
var fieldManager = require('@managers/fieldManager');
var sensorsManager = require('@managers/sensorsManager');

const SCIROOT_FARMING_UNIT_PATH = 'farming_unit'

export var create_farming_unit = (distribution_id, farming_unit_name, salesforce_id, country, region) => {

  return new Promise(function (resolve, reject) {

    axios.post(SCIROOT_FARMING_UNIT_PATH + "/" + distribution_id, {
      farming_unit_name: farming_unit_name,
      salesforce_id: salesforce_id,
      country: country,
      region: region
    }).then(function (res) {

      resolve("");

    }).catch(function(e) {
      console.log(e.message);
      reject(e);
    });
  });
};

export var update_farming_unit = (distribution_id, farming_unit_id, farming_unit_name, salesforce_id, country, region) => {

  return new Promise(function (resolve, reject) {

    axios.put(SCIROOT_FARMING_UNIT_PATH + "/" + distribution_id + "/" + farming_unit_id, {
      farming_unit_name: farming_unit_name,
      salesforce_id: salesforce_id,
      country: country,
      region: region
    }).then(function (res) {

      resolve("");

    }).catch(function(e) {
      console.log(e.message);
      reject(e);
    });

  });
};

export var delete_farming_unit = (distribution_id, farming_unit_id) => {

  return new Promise(function (resolve, reject) {

    axios.delete(SCIROOT_FARMING_UNIT_PATH + "/" + distribution_id + "/" + farming_unit_id, {
    }).then(function (res) {

      resolve("");

    }).catch(function(e) {
      console.log(e.message);
      reject(e);
    });
  });
};

export var loadFarmingUnitHistory = (distribution_id, farming_unit_id, field_id) => {

  return new Promise(function (resolve, reject) {

    axios.get(SCIROOT_FARMING_UNIT_PATH + "/" + distribution_id + "/" + farming_unit_id, {
      farming_unit_id: farming_unit_id,
      distribution_id: distribution_id,
    }).then(function (res) {

      resolve({"historicalData": res.data,
               "farming_unit_id": farming_unit_id});

    }).catch(function(e) {
      console.log(e.message);
      reject(e);
    });
  });
};

export var all_farming_unit_areas = (farming_unit, selected_field_id, fieldsState = 'active') => {

  if (!farming_unit) {
    return {};
  }

  var all_areas_dictionary = {};

  for (var field_id in farming_unit.fields) {

    let currentField = farming_unit.fields[field_id];

    if (currentField.settings.field_state != fieldsState) {
      continue;
    }

    let fieldItems = fieldManager.getFieldItems(currentField);

    var field_stress_level = fieldItems.stressDataItem.value;
    var field_stress_color = fieldManager.get_stress_color(field_stress_level);

    all_areas_dictionary[field_id] = {areas:farming_unit.fields[field_id].geo_data.areas,
                                      title:farming_unit.fields[field_id].name,
                                      selected: field_id == selected_field_id,
                                      fill_color:field_stress_color};
  }

  return all_areas_dictionary;

}

export var all_farming_unit_markers = (farming_unit, selected_field_id, fieldsState = 'active') => {

  if (!farming_unit) {
    return {};
  }

  var all_markers_dictionary = {};

  for (var field_id in farming_unit.fields) {

    let currentField = farming_unit.fields[field_id];

    if (currentField.settings.field_state != fieldsState) {
      continue;
    }

    var markers = {};

    for (var sensor_id in currentField.sensors) {

      var current_sensor_coordinates = currentField.sensors[sensor_id].coordinates;

      let sensorIndex = Object.keys(sensorsManager.getWPSensors(currentField.sensors)).indexOf(sensor_id);

      let current_sensor_color;
      let displayName = "";
      let type = "";
      if (currentField.sensors[sensor_id] instanceof GeneralClasses.WFRSensor) {
        current_sensor_color = "#FFFFFF";
        displayName = "Flow Meter Sensor";
        type = currentField.sensors[sensor_id].manufacturer + " " + currentField.sensors[sensor_id].sub_type;
      }
      if (currentField.sensors[sensor_id] instanceof GeneralClasses.WPSensor) {
        current_sensor_color = sensorsManager.sensorColorByIndex(sensorIndex);
        displayName = "Sensor " + currentField.sensors[sensor_id].name;
        type=currentField.sensors[sensor_id].type;
      }
      if (currentField.sensors[sensor_id] instanceof GeneralClasses.NDripPiezometer) {
        current_sensor_color = "#FFFFFF";
        displayName = "Piezometer Sensor";
        type=currentField.sensors[sensor_id].type;
      }

      if ((current_sensor_coordinates.latitude != null && current_sensor_coordinates.longitude != null) &&
        (current_sensor_coordinates.latitude != '' && current_sensor_coordinates.longitude != ''))
        markers[sensor_id] = {coordinates: [currentField.sensors[sensor_id].coordinates.latitude,
                                            currentField.sensors[sensor_id].coordinates.longitude],
                              info:         displayName,
                              color:        current_sensor_color,
                              transmitter_id: currentField.sensors[sensor_id].transmitter_id,
                              type: type
                            };
    }

    if (markers && Object.keys(markers).length > 0)
      all_markers_dictionary[field_id] = {markers:markers,
                                          selected: field_id == selected_field_id};

  }

  return all_markers_dictionary;

};

export var shouldDisplayAllSensors = (farmingUnit) => {

  if (farmingUnit) {

    if (farmingUnit.settings.sensor_display == "selected_only") {
      return false;
    }
  }

  return true;
};

export var shouldDisplayPlanner = (farmingUnit) => {

  if (farmingUnit) {
    for (const field_id in farmingUnit.fields) {
      if (farmingUnit.fields[field_id].settings.is_rolling_fit_active)
        return true
    }
  }
  return false;
};

export var is_moderator = (farmingUnit) => {
  if (farmingUnit) {
    return farmingUnit.role === "moderator";
  }
  return false
}
