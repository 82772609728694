var React = require('react');


/**
 * This function takes in a string containing a bounding box (bbox) and returns an array of coordinates in the format required for a map.
 * @param {string} bbox_string - The string representation of the bbox in the format "xmin,ymin,xmax,ymax".
 * @returns {Array} The array of coordinates in the format [[xmin, ymin], [xmax, ymax]].
 */
export const createCoordsBoundsFromBbox = (bbox_string, os = "ios") => {

  // Convert the bbox string to an array of numbers
  const bbox_array = bbox_string.slice(1, -1).split(',').map(Number);

  // Rearrange the bbox coordinates into the format required for a map
  const boundsFormatForMap = bbox_array.reduce((acc, val, index) => {
    if (index % 2 === 0) { // Even-indexed elements are x coordinates
      acc.push([val]); // Create a new coordinate pair and add the x coordinate
    } else { // Odd-indexed elements are y coordinates
      acc[acc.length - 1].push(val); // Add the y coordinate to the most recent coordinate pair
    }
    return acc;
  }, []);

  // Return the formatted coordinates array
  return boundsFormatForMap;
};