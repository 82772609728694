var React = require('react');
var {connect} = require('react-redux');
var distributionActions = require('distributionActions');
var soilDataActions = require('soilDataActions')
var cropDataActions = require('cropDataActions')
var fertilizerDataActions = require('fertilizerDataActions')
var userActions = require('userActions');


class BaseComponent extends React.Component {

  constructor(props) {
      super(props);

      // load all distributions
      this.props.dispatch(distributionActions.load_distributions(false, true));
      this.props.dispatch(soilDataActions.load_soil_data());
      this.props.dispatch(cropDataActions.load_crop_data());
      this.props.dispatch(userActions.get_user_data());
      this.props.dispatch(fertilizerDataActions.load_fertilizer_data());

      // this.onSubmitClick = this.onSubmitClick.bind(this);
  }

  render() {


    return (
      <div>base component</div>
    );
  }
};

const mapStateToProps = function(state){

  return {
    all_distributions: state.distribution_data.distributions,
  }
}

module.exports = (BaseComponent);
