var React = require('react');
var {connect} = require('react-redux');
var authenticateActions = require('authenticateActions');
var { withTranslation } = require('react-i18next');

class RegisterForm extends React.Component {

  constructor(props) {
      super(props);

      this.state = {login_form_additional_classes: ''};
      this.onRegisterButtonClick = this.onRegisterButtonClick.bind(this);
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handleConfirmationCodeChange = this.handleConfirmationCodeChange.bind(this);
      this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);

      this.state = {
        user_id:'',
        confirmation_code: '',
        new_password: '',
      };
  }

  onRegisterButtonClick(e) {

    e.preventDefault();
    e.stopPropagation();

    var form = document.getElementById("login-form");

    // Local check (given by bootstrap)
    if (form.checkValidity() == true) {

      if (this.props.forgot_password_flow.phase == 'confirm') {
        this.props.forgot_password_confirm(this.state.user_id, this.state.confirmation_code, this.state.new_password);
      } else {

        this.props.forgot_password(this.state.user_id);

      }
    }

    if (this.props.forgot_password_flow.phase == 'confirm') {
      this.setState({confirm_form_additional_classes: 'was-validated'});
    } else {

      this.setState({forgot_form_additional_classes: 'was-validated'});

    }
  }


  handleEmailChange(event) {
      this.setState({user_id: event.target.value.toLowerCase()});
  }
  handleConfirmationCodeChange(event) {
      this.setState({confirmation_code: event.target.value});
  }
  handleNewPasswordChange(event) {
      this.setState({new_password: event.target.value});
  }


  render() {
    const { t } = this.props;

    var {forgot_password_error} = this.props.forgot_password_flow;
    var forgot_form_additional_classes = this.state.forgot_form_additional_classes;
    var confirm_form_additional_classes = this.state.confirm_form_additional_classes;

    if (this.props.forgot_password_flow.phase == 'confirm') {
      return (


        <form className={`needs-validation ${this.state.confirm_form_additional_classes}`} noValidate onSubmit={this.onRegisterButtonClick} id="login-form">


          <div className="form-group ml-2 mr-2 mt-3 text-light-blue">
            <label htmlFor="emailInput">{t('email')}</label>
            <input type="email" className="form-control" value={this.state.user_id} readOnly placeholder={t('login_enter_your_email')}/>
              <div className="invalid-feedback">{t('login_enter_a_valid_email')}</div>
          </div>

          <div className="form-group ml-2 mr-2 mt-3 text-light-blue">
            <label htmlFor="emailInput">{t('confirmation_code')}</label>
            <input type="number" className="form-control" value={this.state.confirmation_code} onChange={this.handleConfirmationCodeChange} placeholder={t('confirmation_code')}/>
              <small className="form-text text-muted">
                {t('login_enter_verification_code_message')}
              </small>
              <div className="invalid-feedback">{t('login_enter_a_valid_confirmation_code')}</div>
          </div>

          <div className="form-group ml-2 mr-2 mt-3 text-light-blue">
            <label htmlFor="emailInput">{t('login_new_password')}</label>
            <input type="password" className="form-control" value={this.state.new_password} onChange={this.handleNewPasswordChange} placeholder={t('login_new_password')}/>
              <div className="invalid-feedback">{t('login_enter_a_valid_password')}</div>
          </div>

          <div className="text-center text-red">{forgot_password_error}</div>

          <div className="text-center">

            <button type="submit" style={{"opacity":"0.9"}} className="btn btn-grey45 btn-lg general-button mt-3">{t('change')}</button>

          </div>

          <div className="text-center mt-4 mb-5">
            <br/><br/><br/><br/><br/><br/><br/><br/>
          </div>
        </form>
      );
    } else {
      return (


        <form className={`needs-validation ${this.state.forgot_form_additional_classes}`} noValidate onSubmit={this.onRegisterButtonClick} id="login-form">


          <div className="form-group ml-2 mr-2 mt-3 text-light-blue">
            <label htmlFor="emailInput">{t('email')}</label>
            <input type="email" ref="email" className="form-control" value={this.state.user_id} onChange={this.handleEmailChange} placeholder={t('login_enter_your_email')}/>
              <div className="invalid-feedback">{t('login_enter_a_valid_email')}</div>
          </div>

          <div className="text-center text-red">{forgot_password_error}</div>

          <div className="text-center">

            <button type="submit" style={{"opacity":"0.9"}} className="btn btn-grey45 btn-lg general-button mt-3">{t('forgot')}</button>

          </div>

          <div className="text-center mt-4 mb-5">
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          </div>
        </form>
      );
    }
  }
};

const mapStateToProps = function(state){

  return {
    id_token: state.authentication_data.tokens.id_token,
    forgot_password_flow: state.authentication_data.forgot_password_flow,
    authentication_data: state.authentication_data,
  }
}

const mapDispatchToProps = {
  forgot_password: authenticateActions.forgot_password,
  forgot_password_confirm: authenticateActions.forgot_password_confirm,
}


module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegisterForm));
