
var asyncStorage = null;

//Used only by Mobile-App
export var initStorage = (storage) => {
    asyncStorage = storage;
}

async function AsyncStorage_getItem(itemKey) {
    try {
        let item = await asyncStorage.getItem(itemKey);

        return item;
    } catch (e) {
        // Ignore
    }
}

export const getItem = (itemKey, defaultValue = undefined) => {

    if (typeof localStorage === 'object') {
        return localStorage.getItem(itemKey);
    }
    if(asyncStorage && itemKey != 'SELECTED_SENSOR_TRANSMITTER_IDS') {
        return AsyncStorage_getItem(itemKey);
    }

    return defaultValue;

};


async function AsyncStorage_removeItem(itemKey) {
    try {
        await asyncStorage.removeItem(itemKey);
        // return;
    } catch (e) {
        // Ignore
    }
}

export var removeItem = (itemKey) => {

    if (typeof localStorage === 'object') {
        localStorage.removeItem(itemKey);
        return;
    }
    if(asyncStorage && itemKey != 'SELECTED_SENSOR_TRANSMITTER_IDS') {
        AsyncStorage_removeItem(itemKey);
    }
};


async function AsyncStorage_setItem(itemKey, itemValue) {
    try {
        if (!itemValue){
            itemValue = '';
        }
        await asyncStorage.setItem(itemKey, itemValue);
        // return;
    } catch (e) {
        // Ignore
    }
}

export var setItem = (itemKey, itemValue) => {

    if (typeof localStorage === 'object') {
        try {
            localStorage.setItem(itemKey, itemValue);
        } catch (e) {
            console.log("Cache is full, Please empty data");
        }
        return;
    }
    if(asyncStorage && itemKey != 'SELECTED_SENSOR_TRANSMITTER_IDS') {
        AsyncStorage_setItem(itemKey, itemValue);
    }
};
