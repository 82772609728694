const React = require("react");
import {Filter} from "./Filter";
const {useTranslation} = require("react-i18next");

 export const NavigationBar = ({setEvent, event, choices, typesDictionary, setTypesList, reportedTypes, comingUpTypes}) => {
    let reportedStyle = event === "reported" ? 1 : 0;
    let comingUpStyle = event === "coming up"? 1 : 0;
    const {t} = useTranslation();
  return(
                <div style={{width: "100%", justifyContent: "center", display: "flex", borderBottom: "1px solid #DCE0E8", position: "sticky", top: 0, backgroundColor: "white", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", zIndex:"1000"}}>
            <ul className="nav" role="tablist">
                <li className="nav-item">
                    <a className={"nav-link font-weight-semi-bold text-size-13 " + (event === choices[0] ? "text-blue-primary underline " : "text-grey71 ")} onClick={()=>{
                        setEvent(choices[0])
                    }}
                       id={choices[0]} style={{letterSpacing: "0.07em"}} data-toggle="tab" href="#" role="tab"
                       >{t(choices[0]).toUpperCase()}</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link font-weight-semi-bold text-size-13 " + (event === choices[1] ? "text-blue-primary underline " : "text-grey71 ")} onClick={()=>{
                        setEvent(choices[1])
                    }}
                       id={choices[1]} style={{letterSpacing: "0.07em"}}  data-toggle="tab" href="#" role="tab"
                       >{t(choices[1]).toUpperCase()}</a>
                </li>
            </ul>

                    <div style={{opacity:reportedStyle, zIndex:reportedStyle}}><Filter typesList={reportedTypes} typesDictionary={typesDictionary} event={event} setTypesList={setTypesList}/></div>
                    <div style={{opacity:comingUpStyle, zIndex:comingUpStyle}}><Filter typesList={comingUpTypes} typesDictionary={typesDictionary} event={event} setTypesList={setTypesList}/></div>
            </div>
  )

}