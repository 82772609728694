const React = require('react');
const {SelectMenu} = require('SelectMenu');
const {useTranslation, withTranslation} = require('react-i18next');
const {connect} = require("react-redux");
const {unitDisplayName} = require('unitsManager');
const {NDCheckBox} = require("../../../../stories/NDCheckBox");


const irrigationTypeMenuOptions = [
    {title: 'Choose Type', value: ''},
    {title: 'Center-Pivot', value: 'centerPivot'},
    {title: 'Flood', value: 'flood'},
    {title: 'Furrow', value: 'furrow'},
    {title: 'N-Drip',value: 'ndrip'},
    {title: 'No Irrigation System', value: 'noIrrigationSystem'},
    {title: 'Pressurized Drip', value: 'pressurizedDrip'},
    {title: 'Sprinklers', value: 'sprinklers'},
]

const tankTypeMenuOptions = [
    {title: 'Choose Type', value: ''},
    {title: '500 Liter', value: '500Liter'},
    {title: '800 Liter', value: '800Liter'},
]

const IrrigationSystem = ({initialRate, initialType, initialHin, initialTankType, initialMeasuredRate, initialMeasuredRateStatus, onMeasuredRateStatusChange, onTankTypeChange, onIrrigationSystemTypeChange, onIrrigationSystemRateChange, onNDripHinChange, userData}) => {
    const {t} = useTranslation();
    let userUnits = (userData && ("length" in userData)) ? unitDisplayName(userData.length) : "";

    const handleChangeInIrrigationSystemType = (type) => {
        onIrrigationSystemTypeChange(type);
    }

    const handleChangeInTankType = (newTankType) => {
        onTankTypeChange(newTankType);
    }

    const handleChangeInMeasuredRateStatus = (newStatus) => {
        let status = newStatus === 1 ? true : false;
        onMeasuredRateStatusChange(status);
    }

    const handleChangeInIrrigationSystemRate = (rate) => {
        if(rate === ""){
            onIrrigationSystemRateChange(0);
        } else {
            onIrrigationSystemRateChange(parseFloat(rate));
        }
    }

    const handleChangeInHin = (hin) => {
        onNDripHinChange(hin)
    }

    let ndripIrrigationDisplay = initialType === "ndrip" ? true : false;

    return (
        <div className="ml-2 mt-3 mb-3" style={{marginBottom: ndripIrrigationDisplay ? "0px" : "24px"}}>
            <div className="d-flex align-items-center justify-content-between text-grey45" style={{marginBottom:"16px"}}>
                <div><label className="font-weight-bold text-size-20">{t("irrigation_system")}</label></div>
            </div>
            <div className="row" style={{marginRight: "4px"}}>
                <div className="col-3" style={{paddingRight: "0px", paddingLeft: "16px"}}>
                    <div style={{height: "32px", display: "flex", alignItems: "end"}}><label
                        className="text-grey45 mb-0 text-size-14 font-weight-semi-bold">{t("irrigation_type")}</label></div>
                    <div style={{lineHeight: 1, paddingTop: "4px"}}>
                        <SelectMenu
                            onChange={(newType) => handleChangeInIrrigationSystemType(newType)} options={irrigationTypeMenuOptions}
                            curValue={initialType} required={true} sortBox={""}/>
                    </div>
                </div>
                { ndripIrrigationDisplay && <div className="col-3" style={{paddingRight: "8px", paddingLeft: "8px"}}>
                    <div style={{height: "32px", display: "flex", alignItems: "end"}}><label
                        className="text-grey45 mb-0 text-size-14 font-weight-semi-bold">{t("edit_field_tank_type")}</label></div>
                    <div style={{lineHeight: 1, paddingTop: "4px"}}>
                        <SelectMenu
                            onChange={(newTankType) => handleChangeInTankType(newTankType)} options={tankTypeMenuOptions}
                            curValue={initialTankType} required={true} sortBox={""}/>
                    </div>
                </div>}
                {ndripIrrigationDisplay &&  <div className="col-2" style={{paddingRight: "8px", paddingLeft: "0px"}}>
                    <div style={{height: "32px", display: "flex", alignItems: "end"}}><label
                        className="text-grey45 mb-0 text-size-14 font-weight-semi-bold">{`${t("designed_pressure")} (${userUnits})`}</label>
                    </div>
                    <div style={{lineHeight: 1, paddingTop: "4px"}}>
                        <input
                            className='form-control float-left d-inline placeholder' type="number"
                            value={initialHin ? initialHin : ''} placeholder={"0.0"}
                            onChange={(e) => handleChangeInHin(e.target.value)}/>
                    </div>
                </div>}

                <div className="col-2" style={{paddingRight: "8px", paddingLeft: ndripIrrigationDisplay ? "0px" : "8px"}}>
                    <div style={{height: "32px", display: "flex", alignItems: "end"}}><label
                        className="text-grey45 mb-0 text-size-14 font-weight-semi-bold">{`${t("edit_field_custom_irrigation_rate")} (${userUnits}/h)`}</label>
                    </div>
                    <div style={{lineHeight: 1, paddingTop: "4px"}}>
                        <input
                            className='form-control float-left d-inline placeholder' type="number" value={initialRate}
                            onChange={(e) => handleChangeInIrrigationSystemRate(e.target.value)}/>
                    </div>
                </div>
                {ndripIrrigationDisplay && <div className="col-2" style={{paddingRight: "8px", paddingLeft: "0px"}}>
                    <div style={{height: "32px", display: "flex", alignItems: "end"}}><label
                        className="text-grey45 mb-0 text-size-14 font-weight-semi-bold">{`${t("edit_field_measured_irrigation_rate")} (${userUnits}/h)`}</label>
                    </div>
                    <div style={{lineHeight: 1, paddingTop: "4px"}}>
                        <input style={{color: "#999CA3"}}
                               className='form-control float-left d-inline' type="number" value={initialMeasuredRate}
                               disabled={true}/>
                    </div>
                </div>}
            </div>
            {ndripIrrigationDisplay && <div className="row" style={{marginRight: "4px", marginTop: "24px", marginBottom:"24px"}}>
                <div className="col-12" style={{paddingLeft: "16px"}}>
                    <div className={"d-flex align-items-center"} style={{gap: "8px"}}>
                        <NDCheckBox mode={initialMeasuredRateStatus ? 1 : 0} size={'small'} onClick={(event)=>{handleChangeInMeasuredRateStatus(event)}}></NDCheckBox>
                        <label style={{marginBottom:"0px"}} className="text-grey45 text-style-titles-16-regular">{t("edit_field_representative_input_checkbox")}</label>
                    </div>
                </div>

            </div>}
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        userData: state.authentication_data.user_data
    }
}
const mapDispatchToProps = {}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IrrigationSystem));
