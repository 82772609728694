var axios = require('axios');
var GeneralClasses = require('@model/GeneralClasses');
var {error_message} = require('@managers/helpers');

const SCIROOT_SOIL_DATA_PATH = 'soil'

export var soil_data = () => {

  return new Promise(function (resolve, reject) {

    axios.get(SCIROOT_SOIL_DATA_PATH, {
    }).then(function (res) {
      var soil_data = new GeneralClasses.SoilData(res.data);
      resolve(soil_data);

    }).catch(function(err) {
      console.log(error_message(err));
      reject(err);
    });
  });
};
