var React = require('react');
var {useMemo, useState} = require('react');
require("./SelectMenu.scss");
const chevronIcon = require('images/icons/header/down_arrow.svg');

export const SelectMenu = ({options , onChange, curValue, existingFieldDisableType, dayError, startHourError, endHourError,required, sortBox, menuStyle, placeholder, selectClassName = ""}) => {
    const [value, setValue] = useState(curValue || options[0])
    const [isRequired , setIsRequired] =useState(required || false);
    const optionsElements = useMemo(() => options.map(({title, value}) => {
        if (value === "") {
            return <option key={value} value={value}>{title}</option>
        }
        return <option className="bg-white" key={value} value={value}>{title}</option>
    }), [options])
    
    const handleChange = e => {
        setValue(e.target.value)
        onChange && onChange(e.target.value)
    }

    if (placeholder)
        optionsElements.unshift(<option default hidden key='placeholder' style={{color:'grey71'}}>{placeholder}</option>)

    return (
        <div className={"selectMenuWrapper"}>
            <select disabled={existingFieldDisableType}  className={`form-control w-100 selectMenu ${existingFieldDisableType ? "text-disabled64" : "text-grey45"} ` + ((dayError || startHourError || endHourError) ? 'warning' :  '') + sortBox + selectClassName}
                    onChange={handleChange} value={curValue || placeholder || options[0]} required={isRequired} style={menuStyle}>
                {optionsElements}
            </select>
            <img src={chevronIcon} className={'chevronIcon' + sortBox} />
        </div>
    );
};

export const ComplexSelectMenu = ({options , onChange, curValue, existingFieldDisableType, dayError, startHourError, endHourError}) => {
    const [value, setValue] = useState(curValue?.type || options[0])
    const optionsElements = useMemo(() => options.map(({title, value}) => {
        return <option key={JSON.stringify(value)} value={value?.type}>{title}</option>
    }), [options])

    const handleChange = e => {
        let newVal = options[e.target.selectedIndex]?.value
        setValue(newVal?.type)
        onChange && onChange(newVal)
    }

    return (
        <div className={"selectMenuWrapper"}>
            <select disabled={existingFieldDisableType} className={`form-control w-100 selectMenu ${existingFieldDisableType ? "text-disabled64" : "text-grey45"} ` + ((dayError || startHourError || endHourError) ? 'warning' : '')} onChange={handleChange} value={value}>
                {optionsElements}
            </select>
            <img src={chevronIcon} className={'chevronIcon'} />
        </div>
    );
};