var React = require('react');


export class PlannerCell {
    constructor(isPast) {
        this.isPast = isPast;
    }
}

export class DateCellObj extends PlannerCell{
    constructor(name, date, isSelected, isPast) {
        super()
        this.name = name;
        this.date = date;
        this.isSelected = isSelected;
        this.isPast = isPast;
    }
}

export class DayCellObj extends DateCellObj{
    constructor(name, date, isSelected, isPast) {
        super(name, date, isSelected, isPast);
    }
}

export class WeekCellObj extends DateCellObj{
    constructor(name, date, isSelected, isNewMonth, isPast) {
        super(name, date, isSelected, isPast);
        this.isNewMonth = isNewMonth;
    }
}

export class TableCell extends PlannerCell{
    constructor(seasonBoundary, date, fieldId){
        super()
        this.seasonBoundary = seasonBoundary;
        this.startDate = date;
        this.fieldId = fieldId;
    }
}

export class ValueCellObj extends TableCell{
    constructor(type, data, total, date, subType, fieldId) {
        super(fieldId, date)
        this.type = type;
        this.data = data;
        this.total = total;
        this.subType = subType;
    }
}

export class Phase extends TableCell{
    constructor(name, seasonBoundary) {
        super(seasonBoundary)
        this.name = name;
    }
}

export class Note extends TableCell{
    constructor(text, data) {
        super()
        this.text = text;
        this.data = data;
    }
}