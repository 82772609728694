var React = require('react');
const {useTranslation, withTranslation} = require("react-i18next");
const {connect} = require("react-redux");
var {upper} = require('@managers/helpers');



const NotificationsChannels = ({notificationsChannelChange, notificationsChannelsData, allowAlertsPermissions}) => {
    const {t} = useTranslation();

    const onNotificationChannelChange = (event) => {
        let checkBox = event.target;
        let channelString = checkBox.id;
        let selectedChannel = channelString.split("_")[0];
        let selectedNotificationsType = channelString.split("_")[1];
        let copyOfNotificationsChannelsData = {};
        Object.assign(copyOfNotificationsChannelsData, notificationsChannelsData);
        copyOfNotificationsChannelsData = {
            ...copyOfNotificationsChannelsData,
            [selectedChannel]: {
                ...copyOfNotificationsChannelsData[selectedChannel],
                [selectedNotificationsType]: checkBox.checked
            }
        }
        mixpanel.track("Notification Channel has been Changed", {
            'Channel': channelString,
            'Value': checkBox.checked
        });
        notificationsChannelChange(copyOfNotificationsChannelsData);
    }
    return (
        <div style={{display: "flex", flexDirection: "column", marginTop: "32px"}}>
            <div className="text-grey45"
                 style={{fontSize: "14px", fontWeight: "600", marginBottom: "8px"}}>{t("notifications_channels")}</div>
            <div style={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                border: "1px solid rgba(220, 224, 232, 1)",
                borderRadius: "5px"
            }}>
                <table style={{display: "grid"}}>
                    <thead
                        style={{boxShadow: "0px 1px 4px rgba(146, 158, 181, 0.1), 0px 4px 8px rgba(145, 157, 180, 0.18)"}}>
                    <tr style={{
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        paddingLeft: "16px",
                        paddingRight: "36px",
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "bold",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        alignItems: "flex-start"
                    }}>

                        <th className="text-grey71 mr-auto"
                            style={{borderTopLeftRadius: "5px", letterSpacing: "0.25px"}}>{t("event_type").toUpperCase()}</th>
                        <th className="text-grey71 d-flex justify-content-center"
                            style={{width: "120px", letterSpacing: "0.25px"}}>{t("email").toUpperCase()}</th>
                        <th className="text-grey71 d-flex justify-content-center"
                            style={{width: "120px", letterSpacing: "0.25px"}}>{t("push").toUpperCase()}</th>
                    </tr>
                    </thead>
                    <tbody>
                        {allowAlertsPermissions &&
                        <tr style={{
                        paddingLeft: "16px",
                        paddingRight: "36px",
                        display: "flex",
                        fontSize: "14px",
                        fontWeight: "bold",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        alignItems: "flex-start",
                        borderBottom: "1px solid rgba(220, 224, 232, 1)",
                    }}>
                        <td className="text-grey45 font-weight-bold mr-auto">{upper(t("alerts"))}</td>
                        <td className="d-flex justify-content-center" style={{width: "120px", padding: "4px 2px"}}>
                            <input style={{width: "16px", height: "16px"}} onClick={(event) => {
                                onNotificationChannelChange(event)
                            }} id="alerts_email" type="checkbox"
                                   checked={notificationsChannelsData?.alerts?.email}/></td>
                        <td className="d-flex justify-content-center" style={{width: "120px", padding: "4px 2px"}}>
                            <input style={{width: "16px", height: "16px"}} onClick={(event) => {
                                onNotificationChannelChange(event)
                            }} id="alerts_push" type="checkbox"
                                   checked={notificationsChannelsData?.alerts?.push}/></td>
                    </tr>}
                    <tr style={{
                        paddingLeft: "16px",
                        paddingRight: "36px",
                        display: "flex",
                        fontSize: "14px",
                        fontWeight: "bold",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        alignItems: "flex-start"
                    }}>
                        <td className="text-grey45 font-weight-bold mr-auto">{t("recommendations")}</td>
                        <td className="d-flex justify-content-center" style={{width: "120px", padding: "4px 2px"}}>
                            <input style={{width: "16px", height: "16px"}} onClick={(event) => {
                                onNotificationChannelChange(event)
                            }} id="recommendations_email" type="checkbox"
                                   checked={notificationsChannelsData?.recommendations?.email}/></td>
                        <td className="d-flex justify-content-center" style={{width: "120px", padding: "4px 2px"}}>
                            <input style={{width: "16px", height: "16px"}} onClick={(event) => {
                                onNotificationChannelChange(event)
                            }} id="recommendations_push" type="checkbox"
                                   checked={notificationsChannelsData?.recommendations?.push}/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

module.exports = connect()(withTranslation()(NotificationsChannels));