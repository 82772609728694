var React = require('react');
var {connect} = require('react-redux');
var { Link } = require ('react-router-dom');
var ApprovalModal = require('ApprovalModal');
var AlertModal = require('AlertModal');
var ManageFarmingUnitUsersModal = require('ManageFarmingUnitUsersModal');
var UpdateFarmingUnitModal = require('UpdateFarmingUnitModal');
var distributionActions = require('distributionActions');
var $ = require('jquery');
var { withTranslation } = require('react-i18next');
var {sort_dictioanry_by_value_and_property} = require('helpers');

class FarmingUnitsTable extends React.Component {

  constructor(props) {
      super(props);
  }

  onDeleteApprovalClick(objects) {
    this.dispatch(distributionActions.delete_farming_unit(objects[0], objects[1]));
  }

  clearFarmingUnitAction(e) {
    this.dispatch(distributionActions.clear_farming_unit_action());
  }

  render() {
    const { t } = this.props;

    var deletionApprovalModalId = "deletionApprovalModalId";
    var deleteAlertModalId = "deleteAlertModalId";
    var editModalId = this.props.modalId;
    var manageFarmingUnitUsersModalId = "manageFarmingUnitUsersModalId";

    if ((this.props.farming_unit_actions.status == "fail") && (this.props.farming_unit_actions.type == "DELETE")) {
      $('#' + deleteAlertModalId).modal('show');
    }

    let allFarmingUnitsSorted = undefined;
    if (this.props.selected_distribution && this.props.selected_distribution.farming_units) {
      allFarmingUnitsSorted = sort_dictioanry_by_value_and_property(this.props.selected_distribution.farming_units, "name");
    }

    return (
      <div>
          <ApprovalModal modalId={deletionApprovalModalId} message="Are you sure you want to delete the farming unit?" onApprove={this.onDeleteApprovalClick}/>
          <AlertModal modalId={deleteAlertModalId} message={this.props.farming_unit_actions.error} onDismiss={this.clearFarmingUnitAction}/>
          <UpdateFarmingUnitModal modalId={editModalId}/>
          <ManageFarmingUnitUsersModal modalId={manageFarmingUnitUsersModalId}/>


          <table className="table shadow-lg rounded" >
            <thead>
              <tr className="bg-green text-white">
                <th scope="col"></th>
                <th scope="col">{t('farming_unit')}</th>
                <th scope="col">{t('Fields Ammount')}</th>
                <th scope="col">{t('actions')}</th>
              </tr>
            </thead>
            <tbody>

              {!allFarmingUnitsSorted  &&
                <tr className="bg-smoked">
                  <td colSpan="4" className="align-middle text-center">
                    <span className="spinner-grow " role="status" aria-hidden="true"></span>
                  </td>
                </tr>
              }

              {allFarmingUnitsSorted && allFarmingUnitsSorted.map((item) => {
                var current_farming_unit = item[1];
                var current_distribution_id = this.props.selected_distribution.id;

                var links_column = (<td className="align-middle"></td>);
                
                if ((current_farming_unit.role  == "admin") || (this.props.selected_distribution.role == "admin")) {
                  links_column = (<td className="align-middle">
                    <a className="btn btn-link" data-toggle="modal" data-target={"#" + editModalId}
                          data-name={current_farming_unit.name} data-salesforceid={current_farming_unit.salesforceID} data-id={current_farming_unit.id} data-distributionid={current_distribution_id}
                          data-country={current_farming_unit?.country || undefined} data-region={current_farming_unit?.region || undefined}>
                          <img src={ require('images/icons/edit_icon.png') } className="img-fluid" alt="edit farming unit"/>
                    </a>
                    <a className="btn btn-link" data-toggle="modal" data-target={"#" + deletionApprovalModalId} data-values={[current_distribution_id, current_farming_unit.id]}>
                      <img src={ require('images/icons/delete_icon.png') } className="img-fluid" alt="delete distribution"/>
                    </a>
                    <a className="btn btn-link" data-toggle="modal" data-target={"#" + manageFarmingUnitUsersModalId} data-id={current_distribution_id} data-farming_unit_id={current_farming_unit.id}>
                      <img src={ require('images/icons/account_icon.png') } className="img-fluid" alt="manage distribution users"/></a>
                  </td>);
                }

                return <tr key={current_farming_unit.id}>
                        <td className="align-middle" scope="row"></td>
                        <td className="align-middle">{current_farming_unit.name}</td>
                        <td className="align-middle">{Object.keys(current_farming_unit.fields).length}</td>
                        {links_column}
                      </tr>;
              })}
            </tbody>
          </table>
      </div>
    );
  }
};

const mapStateToProps = function(state){

  return {
    selected_distribution: state.distribution_data.selected_entities.distribution,
    farming_unit_actions: state.distribution_data.farming_unit_actions,
  }
}

module.exports = connect(mapStateToProps)(withTranslation()(FarmingUnitsTable));





//
    // <a className="btn btn-link" data-toggle="modal" data-target={"#" + deletionApprovalModalId} data-id={current_distribution.id}>
    //   <img src={ require('images/icons/delete_icon.png') } className="img-fluid ml-3" alt="delete distribution"/></a>
//
//     <a className="btn btn-link" data-toggle="modal" data-target={"#" + manageDistributionUsersModalId} data-id={current_distribution.id}>
//       <img src={ require('images/icons/account_icon.png') } className="img-fluid ml-3" alt="manage distribution users"/></a>
