import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import $ from "jquery";
import {useTranslation} from "react-i18next";
import {getEventDurationLine} from "@managers/fieldManager";
import {waterTreatmentDisplayName} from "@managers/planningManager";
import {differenceInHours, monthNames, colorMap, titlesMap, typeToDataType} from "./PlannerUtils";
import {AddEventButton} from "./PlannerCommonUI";
const {parseShortFloat} = require('@managers/helpers');
var {get_farming_units_history, get_field_history} = require('distributionActions');
var {getDensity} = require('nitrogenManager');

export const EventsListPopup = (props) => {
    const [data, setData] = useState({});

    const selectedFarmingUnit = useSelector((state) => state.distribution_data.selected_entities.farming_unit);
    const selectedDistribution = useSelector((state) => state.distribution_data.selected_entities.distribution);
    const dispatch = useDispatch();

    useEffect(() => {

        $("#" + props.modalId).on('show.bs.modal', function (event) {
            var fieldId = "";
            var type = "";
            var content = "";
            var startDate = "";
            var editable = "";
            if (event.relatedTarget) {
                var button = $(event.relatedTarget) // Button that triggered the modal
                fieldId = button[0].dataset.id; // Extract info from data-* attributes
                type = button[0].dataset.type;
                startDate = button[0].dataset.start;
                content = JSON.parse(button[0].dataset.content);
                editable = button[0].dataset.editable
            }

            setData({
                content: content,
                fieldId: fieldId,
                type: type,
                startDate: startDate,
                editable:editable
            })

        });
    }, [])


    const updateTable = () => {
        props.updateTable();
        dispatch(get_farming_units_history(selectedDistribution.id, selectedFarmingUnit.id, data.fieldId));
        dispatch(get_field_history(selectedDistribution.id, selectedFarmingUnit.id, data.fieldId));
    }

    const closeModal = () => {
        $('#' + props.modalId).modal('hide');
    }

    return (
        <ModalContainer id={'eventsListModal'}>
            <EventsListModalContent data={data} closeModal={closeModal} updateTable={updateTable}/>
        </ModalContainer>
    )
}

const ModalContainer = (props) => {
    const  defaultStyle = {
        height: "100%",
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        margin:'auto'
    }
  return(
    <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby={props.id+"Label"} aria-hidden="true">
        <div className="modal-dialog" role="document" style={{...defaultStyle}}>
          {props.children}
        </div>
      </div>
  )
}

function tsToFulDate(t, ts) {
    let date = new Date(parseInt(ts)*1000);
    if (!date)
        return "";
    return dateToDisplay(t, date) + ", " + hourToDisplay(t, date)
}

function dateToDisplay(t, date) {
    return t(monthNames[date.getMonth()]) + " " + date.getDate();
}

function hourToDisplay(t, date) {
    var hours = date.getHours();
    // var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    // minutes = minutes < 10 ? '0' + minutes : minutes;
    // var strTime = hours + ':' + minutes + ' ' + ampm;
    var strTime = hours + ampm;
    return strTime;
}

function EventsListHeader({data, closeModal}) {
    const {t} = useTranslation();

    let title = titlesMap[data.type]
    let date = new Date(parseInt(data.startDate*1000));
    let subTitle = dateToDisplay(t, date);

    if ('content' in data && !data.content.isWeekViewMode) {
        let endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 6);
        subTitle += " - " + dateToDisplay(t, endDate);
    }

    return (
        <div className='d-flex row justify-content-between' style={{paddingBottom:16, flex:1, marginLeft:16, marginRight:16}}>
            <div>
                <div className='text-style-titles-20-bold text-grey45'>{t(title)}</div>
                <div className='text-style-body-16-semi-bold text-grey45'>{subTitle}</div>
            </div>
            <img src={require('images/icons/planner/close.svg')} style={{width: 24}} onClick={closeModal}/>
        </div>
    );
}

function EventRow({type, item, ts}) {
    const userData = useSelector((state) => state.authentication_data.user_data);

    let value = '';
    if (type === 'irr') {
        let amount = item?.value?.toFixed(3) + userData?.length;
        let duration = differenceInHours(ts, item?.endDate);
        duration = getEventDurationLine({duration: duration});
        let durationToDisplay = duration[0]+duration[1].trim();
        if(duration.length>2){
            durationToDisplay += duration[2]+duration[3].trim();
        }
        value = amount + ' / ' + durationToDisplay;
    }
    else if (type === 'fert') {
        let fertAmount = parseShortFloat(item?.value) + userData?.weight;
        let nitroAmount = item?.nitroAmount + userData?.weight +'N/\n'+userData?.area;
        value = '{0}({1})'.format(fertAmount, nitroAmount);
    }
    else if(type == 'note'){
        value = item;
    }
    else if(type == 'wtr'){
        value = '{0}, {1}{2}'.format(waterTreatmentDisplayName[item.type], item.value, item.units);
    }

    let textColor = 'text-'+colorMap[type];
    return <div className={['text-style-titles-16-bold', textColor].join(' ')}
                style={{whiteSpace: 'pre-line', textAlign: 'end', width: 150}}>{value}</div>

}


function EventsList({data}) {
    const {t} = useTranslation();
    const events = data?.content?.data;

    let rightTitleMap = {'note': t('note'), 'irr': t('amount') +' / '+ t('length'), 'fert': t('amount')};
    let leftTitle = data.type ? t('planner_start_time') : t('date');
    let rightTitle = rightTitleMap[data.type];

    let itemClass = '';
    let modalProps = {};
    if (data?.editable == 'true'){
        itemClass = 'list-item';
        modalProps = {type:"button", 'data-dismiss':"modal", 'data-toggle':"modal",
                             'data-target':"#eventModalId" }
    }

    return (
        <div className='d-flex' style={{paddingTop:8, paddingBottom:8, borderWidth:1, borderColor:'grey91', flexDirection: 'column', marginLeft:24, marginRight:24, width: '-webkit-fill-available'}}>
            <div className='row d-flex justify-content-between border border-grey91'
                 style={{paddingTop:8, paddingBottom:8, paddingRight:16, paddingLeft:16, borderRadius:3}}>
                <div className='text-style-caps-12-bold text-grey71'>{leftTitle}</div>
                <div className='text-style-caps-12-bold text-grey71'>{rightTitle}</div>
            </div>
            <div className='d-flex row border-bottom border-grey91' style={{maxHeight: 308, overflow: 'scroll'}}>
                {events && Object.keys(events).map((ts, i, row) => {
                    const borderBottom = i + 1 === row.length ? 'border-bottom-0' : '';
                    const item = events[ts];
                    const dataDuration = differenceInHours(ts, item.endDate);
                    const fertAmount = item.value / getDensity(item.fertType)
                    return (
                        <div className={['d-flex col justify-content-between border border-top-0 border-grey91', itemClass, borderBottom].join(' ')}
                            style={{padding: 16}} key={ts} {...modalProps} data-duration={dataDuration} data-id={data.fieldId} data-action='update' data-start={ts}
                             data-ftype={item.fertType} data-famount={fertAmount} data-value={item.nitroAmount || item.value} data-type={typeToDataType[data.type]}>
                            <div className='text-style-body-16-regular text-grey45'>{tsToFulDate(t, ts)}</div>
                            <EventRow type={data.type} item={item} ts={ts}/>
                        </div>
                    )
                })}
            </div>

        </div>
    );
}

const EventsListModalContent = ({data, closeModal}) => {
let showAddEventButton = data.type != 'wtr';

    return (
        <div className='modal-content bg-white' style={{width: 340, padding: 24}}>
            <div className="d-flex row border-grey91 align-items-center">

                <EventsListHeader data={data} closeModal={closeModal}/>
                <EventsList data={data}/>
                {showAddEventButton &&
                    <AddEventButton fieldId={data.fieldId}
                                 style={{justifyContent: 'center', flex: 1, marginLeft: 8, marginRight: 8}}
                                 propsForModal={{
                                     'data-id': data.fieldId,
                                     'data-start': data.startDate,
                                     'data-type': titlesMap[data.type],
                                     'data-ftype': data?.content?.subType,
                                     'data-dismiss': "modal",
                                     'data-disableTypeChange': true
                                 }}/>
                }
            </div>
        </div>
    )
}
