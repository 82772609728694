var React = require('react');
const {connect} = require("react-redux");
const {withTranslation} = require("react-i18next");
const {useEffect, useState} = require("react");
var cropDataManager = require('cropDataManager');
var CSS = require("../crop/CropManagement.scss");
const AddNewCropSection = require("AddNewCropSection");
const EditCropSection = require("EditCropSection");

const CropManagement = (props) => {

    const [crops, setCrops] = useState({})
    const [dataUpdated, setDataUpdated] = useState(true)


    useEffect(() => {

        cropDataManager.crop_data().then(function (result) {
            setCrops(result.crop_data)

        }, function (err) {
            console.log(err);
            ;
        });
    }, [dataUpdated])


    const handleDataUpdated = () => {
        setDataUpdated(!dataUpdated)}

    
    return (
        <div>
            <AddNewCropSection crops={crops} onUpdate={() => handleDataUpdated()}/>
            <hr/>
            <EditCropSection crops={crops} onUpdate={() => handleDataUpdated()}/>


        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        crop_data: state.crop_data.crop_data,
    }
}

module.exports = connect(mapStateToProps)(withTranslation()(CropManagement));