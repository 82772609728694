import {useTranslation} from "react-i18next";

var React = require('react');
var moment = require('moment-timezone');
var {upper} = require('@managers/helpers');
require('./planner.css');


export function AddEventButton({fieldId, propsForModal={}, style={}}) {
    const {t} = useTranslation();
    const today = moment();

    let defaultProps = {
        'data-toggle': "modal", 'data-target': "#eventModalId", 'data-start': today.unix(),
        'data-id': fieldId, 'data-action': 'create', 'data-type': 'irrigation'
    };
    propsForModal = Object.assign(defaultProps, propsForModal);

    return(
        <div className='add-button'
             style={{height:24, borderRadius:3, paddingLeft:8, paddingRight:12, gap:2, ...style}}
             type="button" {...propsForModal}>
            <img src={require('images/icons/planner/plus.svg')} style={{width: 16}}/>

            <span className='text-style-titles-14-semi-bold text-grey45'>{upper(t('planner_add_event_button'))}</span>
        </div>
    )
}

export function Shadow({direction, style, shouldHide, rowHeight}) {
    let degrees = '';
    let dimension = '100%';

    if (direction == 'left') {
        degrees = '90deg';
        style.width = 8;
        style.height = rowHeight;
    } else if (direction == 'right') {
        degrees = '-90deg';
        style.width = 8;
        style.height = rowHeight + 1;
    } else if (direction == 'top') {
        degrees = '180deg';
        style.height = 8;
    }
    if (shouldHide) {
        dimension = '0%'
    }

    return <div style={{...style, background: `linear-gradient(${degrees}, rgba(145, 157, 180, 0.19) 0%, rgba(145, 157, 180, 0.00) ${dimension})`}}/>
}

