var soilDataManager = require('@managers/soilDataManager');
var GeneralClasses = require('@model/GeneralClasses');
var {error_message} = require('@managers/helpers');


// Middleware
export var load_soil_data = () => {

  return (dispatch, getState) => {

    var state = getState();

    // Load only once
    if (!state.soil_data.soil_data) {

      dispatch(get_soil_data_init());

      soilDataManager.soil_data().then(function(result) {

          dispatch(get_soil_data_success(result));

        }, function(err) {
          console.log(err);
          dispatch(get_soil_data_fail(err));
        });
      }
    };
};

export var get_soil_data_init = () => {
  return {
    type: "GET_SOIL_DATA_INIT"
  }
};

export var get_soil_data_success = (soil_data) => {
  return {
    type: "GET_SOIL_DATA_SUCCESS",
    soil_data: soil_data,
  }
};

export var get_soil_data_fail = (err) => {
  return {
    type: "GET_SOIL_DATA_FAIL",
    error_message: error_message(err)
  }
};

export var clear_soil_data_actions = () => {

  return {
    type: "CLEAR_SOIL_DATA_ACTIONS"
  }
};
