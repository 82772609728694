var React = require('react');

/**
 * This function takes in a minimum value and a maximum value and returns an array of five equally spaced values between them.
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {Array} The array of equally spaced values.
 */
export const getEqualDistanceValues = (min, max) => {

  // Assign 0 to the minimum value if it is less than 0
  if (min < 0) {
    min = 0;
  }

  // Calculate the interval between each value
  const interval = (max - min) / 4;

  // Create an array to hold the values, starting with the minimum value
  const values = [min.toFixed(2)];

  // Calculate the remaining three values and add them to the array
  for (let i = 1; i <= 3; i++) {

    // Calculate the value as the minimum value plus the interval times the index
    const value = min + (interval * i);

    // Add the value to the array, rounded to one decimal places
    values.push(value.toFixed(2));
  }

  // Add the maximum value to the array and return it
  values.push(max.toFixed(2));
  return values;
};

