var React = require('react');
var {ComplexSelectMenu} = require('SelectMenu');
var {upper} = require('helpers');
const {useTranslation, withTranslation} = require("react-i18next");
var ValvesInput = require('ValvesInput');
const {useState} = require("react");
const {connect} = require("react-redux");

// select menu options for generic Select Menu
const menuOptions = [
    {title: 'Aquastride', value: {type: 'aquastride'}},
    {title: 'Galcon', value: {type: 'galcon'}},
    {title: 'Motorola', value: {type: 'motorola'}},
    {title: 'Tal Gil', value: {type: 'tal-gil'}},
    {title: 'N-Drip Piezometer', value: {type: 'piezometer'}},
    {title: 'N-Drip Pulse', value: {type: 'pulse'}},
    {title: 'N-Drip Tensiometer', value: {type: 'wp'}},
    {title: 'N-Drip Flow Meter T4 1', value: {type: 'ndrip wm', subType: 'T4 1'}},
    {title: 'N-Drip Flow Meter T4 2', value: {type: 'ndrip wm', subType: 'T4 2'}},
    {title: 'N-Drip Flow Meter T6 1', value: {type: 'ndrip wm', subType: 'T6 1'}},
    {title: 'N-Drip Flow Meter T6 2', value: {type: 'ndrip wm', subType: 'T6 2'}},
    {title: 'N-Drip Flow Meter T8', value: {type: 'ndrip wm', subType: 'T8'}}
]

const EditSensor = ({type, sub_type, valves, transmitter_id, coordinates, m3_per_pulse , manufacturer, onCancel , onDone ,existingFieldDisableType,name,userData, offset}) => {
    const { t } = useTranslation();
    let initialType = type === "pulse" || type === "piezometer" || type === 'wp' ? {type: type} : !!sub_type ? {type: 'ndrip wm', subType: sub_type} : {type: manufacturer || 'aquastride'};
    const [chosenType,setChosenType] = useState(initialType ||  {type: 'aquastride'})
    const [chosenTransmitterID,setChosenTransmitterID] = useState(transmitter_id || '');
    const [chosenLatitude,setChosenLatitude] = useState(coordinates?.latitude || '');
    const [chosenLongitude,setChosenLongitude] = useState(coordinates?.longitude || '')
    const [chosenValves,setChosenValves] = useState(valves)
    const [chosenM3PerPulse,setChosenM3PerPulse] = useState(m3_per_pulse);
    const [chosenName , setChosenName]  = useState(name || '');
    const [chosenOffset,setChosenOffset] = useState(offset || 0);


    const changeTypeHandler = (newType) => {
        setChosenType(newType);
    }

    const valvesHandler = (newValves) => {
        setChosenValves(newValves)
    }

    const middleColTitle = ['motorola', 'tal-gil', 'galcon', 'aquastride'].includes(chosenType.type ) ? t("valves") : chosenType.type === 'pulse' ? t("water_meter_units") : chosenType.type === 'piezometer' ? `${t("offset")} (${userData?.length})` : '';
    const middleColContent = middleColTitle === t("valves") ? <ValvesInput onAdd={valvesHandler} valves={chosenValves}/> : middleColTitle === t("water_meter_units") ? <div style={{lineHeight: 1,}}><input className='form-control w-100' value={chosenM3PerPulse} onInput={e => setChosenM3PerPulse(e.target.value)}/></div>  : middleColTitle === `${t("offset")} (${userData?.length})` ? <div style={{lineHeight: 1}}><input type="number" className='form-control w-100' value={chosenOffset} onInput={e => setChosenOffset(e.target.value)}/></div> : null ;

    return (<div className="d-flex mb-4 card bg-grey96">
        <div className=" d-flex justify-content-between flex-grow-1 card-body  flex-row bg-grey96" style={{padding: '12px 16px'}}>
            <div className='text-grey45' style={{width: '136px', marginRight: '16px'}}>

                <label style={{lineHeight: 1}} className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'>{upper(t("type"))}</label>
                <div style={{lineHeight: 1}}><ComplexSelectMenu existingFieldDisableType={existingFieldDisableType} onChange={(newType) => changeTypeHandler(newType)} options={menuOptions} curValue={chosenType || manufacturer || type}/></div>
            </div>
            <div className='text-grey45' style={{width: '136px', marginRight: '16px'}}>
                <label style={{lineHeight: 1}} className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'>{t("transmitter_id")}</label>
                <div style={{lineHeight: 1}}><input className='form-control w-100' type="text" value={chosenTransmitterID} onChange={e => setChosenTransmitterID(e.target.value.toLowerCase())}/></div>
            </div>
            <div className='text-grey45' style={{width: '120px', marginRight: '16px'}}>
                 <label style={{lineHeight: 1}} className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'>{t("name")}</label>
                <div style={{lineHeight: 1}}><input className='form-control w-100' type="text" value={chosenName} onChange={e => setChosenName(e.target.value)}/></div>
            </div>
            <div className='text-grey45 w-136' style={{width: '136px', marginRight: '16px'}}>
                <label style={{lineHeight: 1}} className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'>{middleColTitle}</label>
                <div style={{lineHeight: 1}}>{middleColContent}</div>
            </div>
            <div className='text-grey45' style={{width: '136px'}}>
                <label style={{lineHeight: 1}} className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'>{t('latitude')}</label>
                <div className='w-100'  style={{lineHeight: 1}}><input className='form-control w-100' type="number" value={chosenLatitude} onChange={e => setChosenLatitude(e.target.value)}/></div>
                <label style={{lineHeight: 1}} className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'>{t('longitude')}</label>
                <div className='w-100' style={{lineHeight: 1,width:136}}><input className='form-control w-100' type="number" value={chosenLongitude} onChange={e => setChosenLongitude(e.target.value)}/></div>
            </div>
        </div>
        <div className='d-flex flex-row align-items-center w-100 my-2'>
            <button style={{outline: 'none', width:80, height:24, fontWeight:"600"}} type='button' className='btn btn-outline-secondary d-flex mr-2 ml-auto justify-content-center align-items-center text-size-12' onClick={onCancel}>
                Cancel
            </button>
            <button style={{outline: 'none', width:80, height:24, fontWeight:"600"}} type='button' className={'btn d-flex mr-3 justify-content-center align-items-center bg-blue-primary text-white text-size-12'} onClick={() => onDone(chosenTransmitterID, chosenLatitude, chosenLongitude, chosenM3PerPulse, chosenValves, chosenType, chosenName, chosenOffset)}>
                Done
            </button>
        </div>
    </div>);
};

const mapStateToProps = function (state) {
    return {
        userData: state.authentication_data.user_data,
    }
}

module.exports = connect(mapStateToProps)(withTranslation()(EditSensor));