var React = require('react');
var {connect} = require('react-redux');
const {useTranslation, withTranslation} = require("react-i18next");
var SensorGraph = require('SensorGraph');
const moment = require("moment");
const {useEffect, useState} = require("react");
var GeneralClasses = require('GeneralClasses');
var {unitDisplayName, convert} = require('unitsManager');
var {loadSamplesData} = require('sensorsManager')

const DischargeGraphContainer = (props) => {

    const [sensor_graph, setSensor_graph] = useState(null);
    const [isDischargeGraph, setIsDischargeGraph] = useState(false);
    const [waterDataState, setWaterDataState] = useState({});
    const {t} = useTranslation();
    let userLengthUnits = (props.userData && ("length" in props.userData)) ? unitDisplayName(props.userData.length) : "";
    let userVolumeUnits = (props.userData && ("volume" in props.userData)) ? unitDisplayName(props.userData.volume) + "/h" : "";


    useEffect(() => {

        if (sensor_graph) {
            setIsDischargeGraph(false);
            let waterMeterData = loadSamplesData(props.samples , moment(props.lastSeen), props.pressure_samples, props.discharge_samples , props.userData, setIsDischargeGraph);
            setWaterDataState(waterMeterData);
            scaleGraph(undefined, undefined, waterMeterData);
        }
    }, [props.samples, sensor_graph]);

    const changeXAxes = (startDate, endDate) => {

        let firstDateInData = moment(props.samples[props.samples.length - 1]?.end_date).unix();
        let lastDateInData = moment(props.lastSeen).unix()

        if (startDate >= firstDateInData && endDate <= lastDateInData) return true
        else return false;
    }


    const scaleGraph = (startDate = moment(props.lastSeen).subtract(17, "d").unix(), endDate = moment(props.lastSeen).unix(), waterData = waterDataState) => {

        let dischargeData = waterData?.discharge?.filter((sample) => {
            if (moment(sample.date).unix() >= startDate && moment(sample.date).unix() <= endDate) return sample;
        });
        let pressureData = waterData?.pressure?.filter((sample) => {
            if (moment(sample.date).unix() >= startDate && moment(sample.date).unix() <= endDate) return sample;
        });
        let framedData = {};
        framedData["pressure"] = pressureData;
        framedData["discharge"] = dischargeData;
        reloadGraph(framedData);
    }

    const reloadGraph = (waterMeterData) => {
        let dataToDisplay = {};
        sensor_graph.loadData(null, null, undefined, undefined)
        if (waterMeterData["pressure"]) {
            dataToDisplay['pressure'] = new GeneralClasses.Sensor(
                {
                    name: 'pressureValues',
                    displayName: 'Pressure',
                    data: waterMeterData["pressure"],
                    color: "#662F6F",
                    coordinates: {latitude: '23.2222', longitude: '23.2222'},
                    settings: {is_active: true}
                }, 'pressureValues');
        }
        if (waterMeterData["discharge"]) {
            dataToDisplay['discharge'] = new GeneralClasses.Sensor(
                {
                    name: 'dischargeValues',
                    displayName: 'Discharge',
                    data: waterMeterData["discharge"],
                    color: "#3A8500",
                    coordinates: {latitude: '23.2222', longitude: '23.2222'},
                    settings: {is_active: true}
                }, 'dischargeValues');
        }
        sensor_graph.loadData(dataToDisplay, {}, 0, props.time_zone, {}, {});
    }

    return (
        <div className="d-flex flex-column w-100 h-100">
            <div className="d-flex flex-wrap ">
                <div
                    className="d-flex pl-lg-0 text-size-16 font-weight-bold align-items-center ml-3 text-grey45">{isDischargeGraph ? t("discharge over time") : t("Pressure over time")}</div>
            </div>
            <div className="flex-grow-1">
                <div className="d-flex h-100">
                    <div className="flex-fill" style={{'width': '40%'}}>
                        <SensorGraph onRef={ref => (setSensor_graph(ref))} onGraphUpdate={this.onGraphUpdate}
                                     units={isDischargeGraph ? userVolumeUnits : userLengthUnits} isLoading={false}
                                     shouldDrawTodayMark={false}
                                     shouldDrawBottom={false} shouldDrawInputs={false} shouldDiffMode={true}
                                     shouldSetMinMaxXAxes={true} toolTipMode={'nearest'} distributionMode={"linear"}
                                     shouldDisplayZoom={false}
                                     shouldSpaceData={false} irrigation_hin={props?.irrigation_hin}
                                     shouldDrawResetButton={false} shouldDiplayUTC={true} shouldScaleGraph={true}
                                     scaleGraph={scaleGraph} changeXAxes={changeXAxes}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        userData: state.authentication_data.user_data
    }
}
const mapDispatchToProps = {}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DischargeGraphContainer));