import {convert} from "./unitsManager";
var moment = require('moment');


const daysArray = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
const minutesInDay = 60 * 24
const halfAnHoursInWeek = 24 * 2 * 7

const toMinutes = time => {
    const [hours, minutes] = time.split(":").map(val => parseInt(val, 10));
    return hours * 60 + minutes;
};

const getDayIndex = (day) => {
    let dayIndex = 0;
    switch (day) {
        case "sunday":
            dayIndex = 0;
            break;
        case "monday":
            dayIndex = 24 * 2;
            break;
        case "tuesday":
            dayIndex = 24 * 2 * 2;
            break;
        case "wednesday":
            dayIndex = 24 * 2 * 3;
            break;
        case "thursday":
            dayIndex = 24 * 2 * 4;
            break;
        case "friday":
            dayIndex = 24 * 2 * 5;
            break;
        case "saturday":
            dayIndex = 24 * 2 * 6;
            break;
        default:
            break;
    }
    return dayIndex
}

function getDayIndices(timeFrame) {

    let dayIndices = []
    // If the day property of the current timeFrameKey includes the string "every"
    if (timeFrame.day.includes("every")) {

        // Map the daysArray and call getDayIndex on each element to get the day indices
        // we will go through all days of the week
        dayIndices = daysArray.map(day => getDayIndex(day))
    } else {
        dayIndices = [getDayIndex(timeFrame.day)]
    }
    return dayIndices
}

const fillBoolArray = (timeFrames, timeFramesBoolArray, newTimeFrame) => {

    // Loop through the timeFrames object
    for (const timeFrameKey in timeFrames) {

        let dayIndices = getDayIndices(timeFrames[timeFrameKey])

        for (let dayIndex of dayIndices) {
            const startTimeInMinutes = toMinutes(timeFrames[timeFrameKey].start_hour);
            const endTimeInMinutes = timeFrames[timeFrameKey].end_hour.includes("+1")
                ? toMinutes(timeFrames[timeFrameKey].end_hour.slice(0, -2)) + minutesInDay
                : toMinutes(timeFrames[timeFrameKey].end_hour);

            // Calculate the difference between the start and end times in half-hours
            const difference = (endTimeInMinutes - startTimeInMinutes) / 30;
            let startIndex = (startTimeInMinutes / 30) + dayIndex;

            for (let i = startIndex; i < startIndex + difference; i++) {
                timeFramesBoolArray[i] = true;
            }

            // If the day index is 0, fill the same range of indices in the boolArray with true values, starting from the start time plus halfAnHoursInWeek ( for saturdays )
            if (dayIndex === 0) {
                for (let i = startTimeInMinutes + halfAnHoursInWeek; i < startTimeInMinutes + halfAnHoursInWeek + difference; i++) {
                    timeFramesBoolArray[i] = true;
                }
            }
        }
    }
}

 const haveOverlappingPeriods = (timeFrame1, timeFrame2) => {
    // Check if the objects have the required start_date and end_date properties
    const hasProperties1 = timeFrame1.hasOwnProperty('active_period_start_date') && timeFrame1.hasOwnProperty('active_period_end_date');
    const hasProperties2 = timeFrame2.hasOwnProperty('active_period_start_date') && timeFrame2.hasOwnProperty('active_period_end_date');

    if (!hasProperties1 || !hasProperties2) {
        return false; // If either object is missing the required properties, return false
    }

    const start1 = moment(timeFrame1.active_period_start_date);
    const end1 = moment(timeFrame1.active_period_end_date);
    const start2 = moment(timeFrame2.active_period_start_date);
    const end2 = moment(timeFrame2.active_period_end_date);

    // Check if the periods overlap
    const overlap = (start1.isBefore(end2) || start1.isSame(end2)) && (start2.isBefore(end1) || start2.isSame(end1));

    return overlap;
 }

export const validateChangedTimeFrame = (timeFrames, timeFrame) => {

    if (timeFrame.hasOwnProperty("active_period_start_date") && timeFrame.hasOwnProperty("active_period_end_date")) {
        for (let i = 0; i < timeFrames.length; i++) {
                let Overlap = haveOverlappingPeriods(timeFrame, timeFrames[i]);
                if (Overlap) {
                    return false;
                }
        }
        return true;
    }

    // The purpose of the function is to validate the given time frame by checking if the time slot it defines is already taken or not
    const nextSixWeeks = 6 * 7 * 24 * 2; // number of half hours in 6 weeks
    const timeFramesBoolArray = Array(nextSixWeeks).fill(false);
    fillBoolArray(timeFrames, timeFramesBoolArray, timeFrame)

    let dayIndices = getDayIndices(timeFrame)
    for (let dayIndex of dayIndices) {

        let difference = 0;
        let startTimeMinutes = toMinutes(timeFrame.start_hour)
        if (timeFrame.end_hour.includes("+1")) {
            let endTimeMinutes = toMinutes(timeFrame.end_hour.slice(0, -2)) + minutesInDay
            difference = (endTimeMinutes - startTimeMinutes) / 60 * 2;
        } else {
            if (toMinutes(timeFrame.end_hour) - startTimeMinutes < 0) {
                return false
            }
            difference = (toMinutes(timeFrame.end_hour) - startTimeMinutes) / 60 * 2;
        }
        let start = startTimeMinutes / 30 + dayIndex;

        for (let i = start; i < start + difference; i++) {
            if (timeFramesBoolArray[i]) {
                return false;
            }
        }

        if (dayIndex === 0 || dayIndex === 288) {
            let startCircular = dayIndex === 0 ? start + halfAnHoursInWeek : start
            for (let i = startCircular; i < startCircular + difference; i++) {
                if (timeFramesBoolArray[i]) {
                    return false;
                }
            }
        }
    }
    return true;
};

export const calculateMinMax = (timeFrame, fieldIrrigationSystemObj, fieldSoilType, soilData, userUnits) => {
    let minPlaceHolder = "";
    let maxPlaceHolder = "";
    let maxTooltipSource = "";
    let minSource = "";
    let maxSource = "";
    let irrigationType = fieldIrrigationSystemObj?.irrigation_type.toLowerCase();

    let soilDataObj = soilData?.soil_data[fieldSoilType.toLowerCase()];
    // calculate min placeholder -based on soil type limitations
    if (irrigationType?.includes("sprinkler")) {
        maxPlaceHolder = convert(soilDataObj?.irrigation_boundaries["sprinkler"].max, "mm", userUnits, "length");
        minPlaceHolder = convert(soilDataObj?.irrigation_boundaries["sprinkler"].min, "mm", userUnits, "length");

    } else if (fieldIrrigationSystemObj?.irrigation_type.toLowerCase().includes("pivot")) {
        maxPlaceHolder = convert(soilDataObj?.irrigation_boundaries["pivot"].max, "mm", userUnits, "length");
        minPlaceHolder = convert(soilDataObj?.irrigation_boundaries["pivot"].min, "mm", userUnits, "length");
    } else {
        maxPlaceHolder = convert(soilDataObj?.irrigation_boundaries["drip"].max, "mm", userUnits, "length");
        minPlaceHolder = convert(soilDataObj?.irrigation_boundaries["drip"].min, "mm", userUnits, "length");
    }

    if (timeFrame.hasOwnProperty("min") && parseFloat(timeFrame.min) >= 0) {
        minSource = "user"
    } else {
        minSource = "soil";
    }
    let minTooltipSource = "soil";
    maxSource = "soil";
    maxTooltipSource = "soil";

    if (Object.keys(timeFrame).length !== 0) {
        // calculate max placeholder -based on the min between soil type limitations and irrigation rate
        let timeFrameDuration = calculateHourDifference(timeFrame.start_hour, timeFrame.end_hour);
        let fieldIrrigationRate = fieldIrrigationSystemObj?.irrigation_rate;
        let irrigationRateValue = fieldIrrigationRate * timeFrameDuration;

        if (maxPlaceHolder < irrigationRateValue) {
            maxSource = "soil";
        } else {
            maxPlaceHolder = irrigationRateValue;
            maxSource = "rate";
            maxTooltipSource = "rate";
        }

        if (timeFrame.hasOwnProperty("max") && parseFloat(timeFrame.max) >= 0) {
            maxSource = "user";
        }
    }
    return {minPlaceHolder, maxPlaceHolder, minSource, maxSource, minTooltipSource, maxTooltipSource};
}

    const calculateHourDifference = (startTime, endTime) => {
        const [startHour, startMinute] = startTime.split(":").map(Number);

        // Split endTime into hours, minutes, and offset
        const endTimeParts = endTime.split(/[+:]/);
        let endHour = parseInt(endTimeParts[0]);
        const endMinute = parseInt(endTimeParts[1]);
        const endDayOffset = endTime.includes("+") ? parseInt(endTimeParts[endTimeParts.length - 1]) : 0;

        // If endTime is on the next day, add 24 hours
        endHour += endDayOffset * 24;

        // Convert times to hours
        const startTotalHours = startHour + startMinute / 60;
        const endTotalHours = endHour + endMinute / 60;

        // Calculate the difference
        let hourDifference = endTotalHours - startTotalHours;

        // If end time is before start time, assume it's on the next day
        if (hourDifference < 0) {
            hourDifference += 24;
        }
        return hourDifference;
    }