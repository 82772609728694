var React = require('react');
var {connect} = require('react-redux');
var moment = require('moment');
var { Link } = require ('react-router-dom');
var $ = require('jquery');
var distributionActions = require('distributionActions');
var GeneralClasses = require('GeneralClasses');
var cropDataManager = require('cropDataManager');
var DayPickerInput = require('react-day-picker/DayPickerInput').default;
var {upper} = require('helpers');
var { withTranslation } = require('react-i18next');
var {NDCheckBox} = require("@stories/NDCheckBox");
var ContextMenu = require('ContextMenu');


class KCBox extends React.Component {

  constructor(props) {
    super(props);
    this.handlePhaseChange = this.handlePhaseChange.bind(this);
    this.handleNewPhase = this.handleNewPhase.bind(this);
    this.handlePhaseRemoval = this.handlePhaseRemoval.bind(this);

    this.handleMonthValueChange = this.handleMonthValueChange.bind(this);

    this.handleCropFeatureChange = this.handleCropFeatureChange.bind(this);
    this.handleCropVarietyChange = this.handleCropVarietyChange.bind(this);
    this.handleSowDateChange = this.handleSowDateChange.bind(this);
    this.handleIrrigationStartDateChange = this.handleIrrigationStartDateChange.bind(this);
    this.handleHarvestDateChange = this.handleHarvestDateChange.bind(this);
    this.handleCropTypeChange = this.handleCropTypeChange.bind(this);
    this.initCropData = this.initCropData.bind(this);
    this.updateAutoGenerateLink = this.updateAutoGenerateLink.bind(this);
    this.handleNewDevPhase = this.handleNewDevPhase.bind(this);
    this.handleMenuAction = this.handleMenuAction.bind(this);
    this.handleChangeIsAppliedStressFactor = this.handleChangeIsAppliedStressFactor.bind(this);
    this.handleChangeIsAppliedStressActive = this.handleChangeIsAppliedStressActive.bind(this);

    this.state = {showMenu:[], pressedOnNewPhase:false, newPhaseAppliedStressActive:false, enableGenerate:true};

  }

  componentDidUpdate(nextProps, nextState) {

    this.initCropData();
    this.updateAutoGenerateLink();
  }

  componentDidMount() {

    var that = this;

    $('#kcTab').on('click', function (e) {

      if (e.target.id !== "kcTab") {
        that.props.selectedField.crop_data.kc_table.type = e.target.id;
        that.props.onKCTableChange(that.props.selectedField.crop_data.kc_table);
      }
    })
        $(function () {
            $('[data-toggle="tooltip"]').tooltip({offset: '0,6'})
        })

    this.setState({
      'shouldDisplayAutoGenerateLink': false,
      'isAutoGenerateLinkLoading': false,
      showMenu: new Array(this.props.selectedField.crop_data.kc_table.data[this.props.selectedField.crop_data.kc_table.type].length).fill(false)
    });
  }

  componentWillUnmount() {

  }

  handleMonthValueChange(event) {
    const selectedMonth = event.target.getAttribute("month");
    const newValue = event.target.value;

    if (typeof (this.props.selectedField.crop_data.kc_table.data['byMonth'][selectedMonth]) === "object") {
      this.props.selectedField.crop_data.kc_table.data['byMonth'][selectedMonth].kc = newValue;
    } else {
      this.props.selectedField.crop_data.kc_table.data['byMonth'][selectedMonth] = newValue;
    }

    this.props.onKCTableChange(this.props.selectedField.crop_data.kc_table);
  }

  handlePhaseChange(event) {

    const selectedIndex = event.target.getAttribute("selectedindex");
    const phaseKey = event.target.getAttribute("phasekey");
    const newValue = event.target.value;

      if (this.props.selectedField.crop_data.kc_table.data[this.props.selectedField.crop_data.kc_table.type][selectedIndex] === undefined) {
         this.props.selectedField.crop_data.kc_table.data[this.props.selectedField.crop_data.kc_table.type][selectedIndex] = {
           "name":"",
           "is_dev": false,
           "duration": "",
        "kc": "",
        "stress": {
          "is_stress_active": false,
          "factor": 0
        }
      }
    }
    this.props.selectedField.crop_data.kc_table.data[this.props.selectedField.crop_data.kc_table.type][selectedIndex][phaseKey] = newValue;
    this.props.onKCTableChange(this.props.selectedField.crop_data.kc_table);
  }

  handleNewPhase(type) {
    if (this.props.selectedField.crop_data.kc_table.data[type][this.props.selectedField.crop_data.kc_table.data[type].length - 1]?.name !== "") {
      const newPhaseRecord = {
        kc: "",
        name: "",
        is_dev: false,
        duration: "",
        stress: {
          is_stress_active: false,
          factor: ""
        }
      };
      this.props.selectedField.crop_data.kc_table.data[type].push(newPhaseRecord);
      this.props.onKCTableChange(this.props.selectedField.crop_data.kc_table);
    }
  }

  handleNewDevPhase(index, type) {

    const newDevPhaseRecord = new GeneralClasses.PhaseRecord({
      kc:"",
      name: this.props.t('kc_developments'),
      is_dev: true,
      duration: "",
    }, type);

    this.props.selectedField.crop_data.kc_table.data[type].splice(parseInt(index + 1),0, newDevPhaseRecord);
    this.props.onKCTableChange(this.props.selectedField.crop_data.kc_table);
  }

  handlePhaseRemoval(index, type){

    const kc_data = this.props.selectedField.crop_data.kc_table.data[type]
    let amountToDelete = 1;
    let startingIndex = index;

    if (!kc_data[index].is_dev) {
      if ((typeof kc_data[index + 1] != 'undefined') && (kc_data[index + 1].is_dev)){
        amountToDelete += 1;
      }

      if ((typeof kc_data[index - 1] != 'undefined') && (kc_data[index - 1].is_dev)){
        amountToDelete += 1;
        startingIndex = index - 1;
      }
    }

    this.props.selectedField.crop_data.kc_table.data[type].splice(parseInt(startingIndex),amountToDelete);
    this.handleMenuAction(index);
    this.props.onKCTableChange(this.props.selectedField.crop_data.kc_table);
  }

  initCropData(){
    if (this.props.crop_data && this.props.crop_data.crop_data &&
        ((this.props.selectedField.crop_data.crop_type == "") || (this.props.selectedField.crop_data.crop_feature == ""))) {

      // console.log(Object.keys(this.props.crop_data.crop_data).sort());
      let crop_type = "";
      let selectedCropFeature = "";
      let selectedCropVariety = ""

      if (this.props.selectedField.crop_data.crop_type == "") {
        crop_type = Object.keys(this.props.crop_data.crop_data).sort()[0];
      }
      else {
        crop_type = this.props.selectedField.crop_data.crop_type;
      }

      crop_type = crop_type.trim();

           if (this.props.selectedField.crop_data.crop_variety == "") {
        selectedCropVariety = Object.keys(this.props.crop_data.crop_data[crop_type])[0];
      }
      else {
        selectedCropVariety = this.props.selectedField.crop_data.crop_variety;
      }

      if (this.props.selectedField.crop_data.crop_feature == "") {
        selectedCropFeature = Object.keys(this.props.crop_data.crop_data[crop_type]["general"])[0];
      }
      else {
        selectedCropFeature = this.props.selectedField.crop_data.crop_feature;
      }

      selectedCropFeature = selectedCropFeature.trim();

      this.props.onCropDetailsChange(crop_type, "", selectedCropFeature);
    }
  }

  handleCropTypeChange(event) {
    let selectedCropType = event.target.value
    let selectedCropVariety = Object.keys(this.props.crop_data.crop_data[selectedCropType])[0];
    let selectedCropFeature = Object.keys(this.props.crop_data.crop_data[selectedCropType][selectedCropVariety])[0]
    this.props.onCropDetailsChange(selectedCropType, selectedCropVariety, selectedCropFeature);
  }

  handleCropVarietyChange(event) {
    const selectedVariety = event.target.value;
    const selectedCropFeature = Object.keys(this.props.crop_data.crop_data[this.props.selectedField.crop_data.crop_type][selectedVariety])[0]
    this.props.onCropDetailsChange(this.props.selectedField.crop_data.crop_type, selectedVariety, selectedCropFeature);
  }

  handleCropFeatureChange(event) {
    const selectedFeature = event.target.value;
    this.props.onCropDetailsChange(this.props.selectedField.crop_data.crop_type, this.props.selectedField.crop_data.crop_variety, selectedFeature);
  }

  handleAutoGenerateKCTable() {

    let that = this;
    let currentKcDataKey = this.props.selectedField.crop_data.crop_type + this.props.selectedField.crop_data.crop_variety + this.props.selectedField.crop_data.crop_feature;

    if (this.props.selectedField && this.props.selectedField.geo_data.areas &&
        this.props.selectedField.geo_data.areas.length &&
        (currentKcDataKey === this.state.kcDataKey) && this.state.kcData && this.state.enableGenerate) {
      that.props.onKCTableChange(this.state.kcData);
      this.setState({enableGenerate: false});
    }
  }

  handleChangeIsAppliedStressActive(status, index, type) {
    if (this.props.selectedField.crop_data.kc_table.data[type][index] === undefined) {
         this.props.selectedField.crop_data.kc_table.data[type][index] = {
           "name":"",
           "is_dev": false,
           "duration": "",
        "kc": "",
        "stress": {
          "is_stress_active": status === 1 ? true : false,
          "factor": 10
        }
      }
    }
    else if (typeof (this.props.selectedField.crop_data.kc_table.data[type][index]) === "string") {
      let kcValue = this.props.selectedField.crop_data.kc_table.data[type][index];
      this.props.selectedField.crop_data.kc_table.data[type][index] = {
        "kc": kcValue,
        "stress": {
          "is_stress_active": status === 1 ? true : false,
          "factor": 10
        }
      }
    } else if (this.props.selectedField.crop_data.kc_table.data[type][index].hasOwnProperty("stress")) {
      this.props.selectedField.crop_data.kc_table.data[type][index].stress["is_stress_active"] = status === 1 ? true : false;
        this.props.selectedField.crop_data.kc_table.data[type][index].stress["factor"] = this.props.selectedField.crop_data.kc_table.data[type][index].stress["factor"] === "" ? 10 : this.props.selectedField.crop_data.kc_table.data[type][index].stress["factor"]
    } else {
      this.props.selectedField.crop_data.kc_table.data[type][index]["stress"] = {};
      this.props.selectedField.crop_data.kc_table.data[type][index]["stress"]["is_stress_active"] = status === 1 ? true : false;
      this.props.selectedField.crop_data.kc_table.data[type][index]["stress"]["factor"] = 10;
    }
    this.props.onKCTableChange(this.props.selectedField.crop_data.kc_table);
  }

  handleChangeIsAppliedStressFactor(factor, index, type) {
    this.props.selectedField.crop_data.kc_table.data[type][index]["stress"]["factor"] = factor;
    this.props.onKCTableChange(this.props.selectedField.crop_data.kc_table);
  }

  handleChangeNewPhaseAppliedStress(status) {
    this.setState({newPhaseAppliedStressActive: status === 1 ? true : false})
  }

  handleChangeNewPhaseAppliedStressFactor(factor) {
    this.setState({newPhaseAppliedStressFactor: factor})
  }

  updateAutoGenerateLink() {
    let that = this;

    let currentKcDataKey = this.props.selectedField.crop_data.crop_type + this.props.selectedField.crop_data.crop_variety + this.props.selectedField.crop_data.crop_feature;

    if (this.props.selectedField && this.props.selectedField.geo_data.areas && this.props.selectedField.geo_data.areas.length && (currentKcDataKey != this.state.kcDataKey)) {

      this.setState({
        'isAutoGenerateLinkLoading': true,
        'kcDataKey': currentKcDataKey
      });
      let crop_variety = this.props.crop_data.crop_data[this.props.selectedField.crop_data.crop_type.toLowerCase()][this.props.selectedField.crop_data.crop_variety.toLowerCase()] ? this.props.selectedField.crop_data.crop_variety : "general"
      cropDataManager.generateKCTable(this.props.crop_data.crop_data,
          this.props.selectedField.crop_data.crop_type,
          this.props.selectedField.crop_data.crop_feature,
          crop_variety,).then(function (res) {
        that.setState({
          'shouldDisplayAutoGenerateLink': true,
          'isAutoGenerateLinkLoading': false,
          'kcDataKey': currentKcDataKey,
          'kcData':res
        });
      }).catch(function(err) {
        that.setState({
          'shouldDisplayAutoGenerateLink': false,
          'isAutoGenerateLinkLoading': false,
          'kcDataKey': currentKcDataKey,
          'kcData': undefined
        });
      });
    }
  }

  handleSowDateChange(day) {
    var formattedDate = moment(day).format('YYYY-MM-DD');

    this.props.onSowDateChange(formattedDate);
  }
  handleIrrigationStartDateChange(day) {
    var formattedDate = moment(day).format('YYYY-MM-DD');

    this.props.onIrrigationDateChange(formattedDate);
  }

  handleMenuAction(index) {
    this.setState((prevState) => {
      return {
        ...prevState,
        showMenu: {
          ...prevState.showMenu,
          [index]: !prevState.showMenu[index]
        }
      }
    })
  }

  appliedStressComponent(index, type) {
    let stressFactor = this.props.selectedField.crop_data.kc_table.data[type][index].stress?.factor;
    let stressActive = this.props.selectedField.crop_data.kc_table.data[type][index].stress?.is_stress_active;
    if (!this.props.selectedField.crop_data.kc_table.data[type][index].hasOwnProperty("stress")) {
      stressFactor = this.props.selectedField.crop_data.kc_table.data[type][index]?.factor
      stressActive = this.props.selectedField.crop_data.kc_table.data[type][index]?.is_stress_active
    }
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
          <div style={{marginBottom: "4px", display: "flex", flexDirection: "row"}}>
            <div style={{display: "flex", flexDirection: "row", gap: "12px"}}>
              <div className="d-flex justify-content-center align-items-center"><NDCheckBox onClick={(status) => {
                this.handleChangeIsAppliedStressActive(status, index, type)
              }} mode={stressActive ? 1 : 0}
                                                                                            size={'small'}/></div>
              <input style={{
                height: "40px",
                color: !stressActive ? "#999CA3" : null
              }} type={"number"} placeholder={"0"}
                     value={stressFactor}
                     className={"form-control placeholder"} onChange={(event) => {
                this.handleChangeIsAppliedStressFactor(event.target.value, index, type)
              }} disabled={!stressActive}/>
            </div>
          </div>
        </div>
    )
  }

  menuComponent(index, menuOptions) {
    return (
        <td style={{width: "5%", 'paddingTop': 0, paddingBottom: "30px", paddingLeft: "4px"}}
            className="align-middle text-center">
          {menuOptions.length > 0 &&
              <div className='d-flex align-items-center justify-content-between position-relative'>
                {this.state.showMenu[index] && <ContextMenu options={menuOptions} onToggle={() => {
                  this.handleMenuAction(index)
                }}/>}
                <button onClick={() => {
                  this.handleMenuAction(index)
                }} type='button'
                        className={`btn d-flex justify-content-center align-items-center sensorMenuBtn ${this.state.showMenu[index] ? 'active' : ''}`}>
                  <img src={require('images/icons/three-dots-horizontal.svg')}/>
                </button>
              </div>}
        </td>
    )
  }

   kcMonthComponent (monthToDisplay, monthToSave, tooltipID, tooltipContainerID, t, is_required, shouldDisplayTitle) {
    return (
        <>
          <td className={"text-style-titles-16-bold text-grey45"}
              style={{padding: "6px", marginTop: shouldDisplayTitle ? "26px" : "5px", marginRight: "22px", width:"26px"}}
              scope="col">{monthToDisplay}</td>
          <div style={{display: "flex", flexDirection: "column"}}>
            {shouldDisplayTitle &&
                <div style={{marginBottom: "4px", display: "flex", flexDirection: "row"}} id={tooltipContainerID}>
                  <div
                      className="text-style-titles-14-semi-bold text-grey45"> {t("kc_phases_stress_checkbox")} (%)
                  </div>
                  <img data-toggle="tooltip" id={tooltipID} data-container={`#${tooltipContainerID}`}
                       onMouseOver={() => {
                         $(`#${tooltipID}`).tooltip('show');
                       }}
                       data-placement="top"
                       title={t("kc_phases_stress_tooltip")}
                       style={{width: "14px", height: "14px", marginLeft: "4px"}}
                       src={require('../../../resources/images/icons/info_icon.svg')}/>
                </div>}
            <div style={{display: "flex", flexDirection: "row", gap: "12px"}}>
              <div className="d-flex justify-content-center align-items-center"><NDCheckBox onClick={(status) => {
                this.handleChangeIsAppliedStressActive(status, monthToSave.toLowerCase(), "byMonth")
              }}
                                                                                            mode={this.props.selectedField.crop_data.kc_table.data["byMonth"][monthToSave.toLowerCase()]?.stress?.is_stress_active ? 1 : 0}
                                                                                            size={'small'}/></div>
              <input style={{height: "40px"}} type={"number"} placeholder={"0"}
                     value={this.props.selectedField.crop_data.kc_table.data["byMonth"][monthToSave.toLowerCase()]?.stress?.factor || ""}
                     className={"form-control placeholder"}
                     onChange={(event) => {
                       this.handleChangeIsAppliedStressFactor(event.target.value, monthToSave.toLowerCase(), "byMonth")
                     }}
                     disabled={!this.props.selectedField.crop_data.kc_table.data["byMonth"][monthToSave.toLowerCase()]?.stress?.is_stress_active}/>
            </div>

          </div>
          <div style={{display: "flex", flexDirection: "column"}}>
            {shouldDisplayTitle && <div className="text-style-titles-14-semi-bold text-grey45"
                               style={{marginBottom: "4px"}}>{t("kc").toUpperCase()}</div>}
            <td style={{padding: "0px"}}><input style={{height: "40px"}} type="number" min="0" step="0.01"
                                                month={monthToSave.toLowerCase()} className="form-control placeholder"
                                                placeholder="0.0" required={is_required}
                                                value={parseFloat(this.props.selectedField.crop_data.kc_table.data["byMonth"][monthToSave.toLowerCase()]["kc"]) || parseFloat(this.props.selectedField.crop_data.kc_table.data["byMonth"][monthToSave.toLowerCase()])}
                                                onChange={this.handleMonthValueChange}/></td>
          </div>
        </>
    )
  }

  handleHarvestDateChange(day) {
    var formattedDate = moment(day).format('YYYY-MM-DD');

    this.props.onHarvestDateChange(formattedDate);
  }

  kc_growth_component(sow_date_object, type, daysTitle, is_required) {
    const { t } = this.props;
    if (this.props.selectedField.crop_data.kc_table.data[type].length === 0) {
      this.handleNewPhase(type);
    }
    let durationInByType = type === "byGrowthPhase" ? "duration_days" : "byGrowthPhaseGDD" ? "duration_gdd" : "duration";
    let tooltipID = type === "byGrowthPhase" ? "GFTooltip" : "GDDTooltip";
    let tooltipContainerID = type === "byGrowthPhase" ? "ContainerTooltipGF" : "ContainerTooltipGDD";
    return <div className="row">
      <div className="col-12">
        <table className="table table-borderless">
          <thead>
          <tr>
            <td className="text-style-titles-14-semi-bold text-grey45" style={{width:"17%", verticalAlign:"middle", paddingBottom:"4px"}} scope="col" id={tooltipContainerID}>
              {t("kc_phases_stress_checkbox")} (%)
            <img data-toggle="tooltip" id={tooltipID} data-container={`#${tooltipContainerID}`}
                                                                                              onMouseOver={() => {$(`#tooltip`).tooltip('show');}}
                                                                                              data-placement="top"
                                                                                              title={t("kc_phases_stress_tooltip")}
                                                                                              style={{ width: "14px", height: "14px", marginLeft:"4px"}}
                                                                                              src={require('../../../resources/images/icons/info_icon.svg')}/>
            </td>
            <td className="text-style-titles-14-semi-bold text-grey45" style={{width:"42%", verticalAlign:"middle", paddingBottom:"4px"}} scope="col">{t('phase')}</td>
            <td className="text-style-titles-14-semi-bold text-grey45" style={{width:"18%", verticalAlign:"middle", paddingBottom:"4px"}} scope="col">{t('kc')}</td>
            <td className="text-style-titles-14-semi-bold text-grey45" style={{width:"18%", verticalAlign:"middle", paddingBottom:"4px"}} scope="col">{t('duration')} ({daysTitle})</td>
            <td style={{width:"5%"}} scope="col"></td>
          </tr>
          </thead>
          <tbody>

          {this.props.selectedField && this.props.selectedField.crop_data.kc_table.data[type].map((current_phase, index) => {
            const menuOptions = [{
              title: t("delete"),
              icon: require('images/icons/trash.svg'),
              func: () => this.handlePhaseRemoval(index, type),
              textColor: "#F66B7A"
            }];

            // support existing kc tables in field
            let value = current_phase[durationInByType] ? current_phase[durationInByType] : current_phase["duration"];

            if (!current_phase.is_dev && this.props.selectedField.crop_data.kc_table.data[type].length > (parseInt(index) + 1) && !this.props.selectedField.crop_data.kc_table.data[type][parseInt(index) + 1].is_dev) {
              menuOptions.unshift(
                  {
                    title: t("kc_add_development_phase"),
                    icon: require('images/icons/plus_icon_grey_45.svg'),
                    func: () => this.handleNewDevPhase(index, type),
                    textColor: "#4D5674"
                  },
                  {
                    type: "divider"
                  }
              );
            }
            if (!current_phase.is_dev) {
              const row = (<tr key={index + type}>
                <td style={{width:"16%", paddingTop:"0px", paddingBottom:"24px"}}>
                  {this.appliedStressComponent(index, type)}
                </td>
                <td style={{width:"43%",'paddingTop': 0, 'paddingBottom': 0, paddingRight:"4px"}}><input type="text" selectedindex={index} phasekey='name' className="form-control placeholder" placeholder="Phase Name" value={current_phase.name} style={{height:"40px"}} onChange={this.handlePhaseChange} required={is_required}/></td>
                <td style={{width:"18%",'paddingTop': 0, 'paddingBottom': 0, paddingRight:"4px", paddingLeft:"4px"}}><input type="number" selectedindex={index} phasekey='kc'  min="0" step="0.0001" className="form-control placeholder" placeholder="0.0" style={{height:"40px"}} value={current_phase.kc} onChange={this.handlePhaseChange} required={is_required}/></td>
                <td style={{width:"18%",'paddingTop': 0, 'paddingBottom': 0, paddingRight:"4px", paddingLeft:"4px"}}> <input type="number" selectedindex={index} phasekey={'duration'}  min="0" step="0.01" className="form-control placeholder" style={{height:"40px"}} placeholder="0.0" value={value} onChange={this.handlePhaseChange} required={is_required}/></td>
                {this.menuComponent(index, menuOptions)}
              </tr>);
              return (row);
            } else {
              return (<tr key={index + type}>
                <td style={{width:"16%", paddingTop:"0px", paddingBottom:"24px"}}>
                  {this.appliedStressComponent(index, type)}
                </td>
                <td style={{width:"61%",'paddingTop': 0, 'paddingBottom': 0, paddingRight:"4px"}} colSpan={2}><input type="text" selectedindex={index} phasekey='name' className="form-control placeholder" style={{color:"#999CA3"}} placeholder="Phase Name" value={t('kc_developments')} disabled={true}/></td>
                <td style={{width:"18%",'paddingTop': 0, 'paddingBottom': 0, paddingRight:"4px", paddingLeft:"4px"}}> <input type="number" selectedindex={index} phasekey={'duration'} min="0" className="form-control placeholder" placeholder="0.0" value={value} onChange={this.handlePhaseChange} required={is_required}/></td>
                {this.menuComponent(index, menuOptions)}
              </tr>);
            }
          })}
          <tr>
            <td></td>
            <td colSpan={3} style={{paddingRight:"4px"}}><div style={{backgroundColor:"#EEF1F6", borderRadius:"4px", border:"1px solid #DCE0E8",color:"#4D5674"}} className="button-big"><div style={{textDecoration:"none", cursor:"pointer"}} className="d-flex justify-content-center align-items-center text-grey45 font-weight-semi-bold text-size-16 noHoverEvents" onClick={() => {this.handleNewPhase(type)}}>
                <img style={{marginRight:"4px"}} src={ require('images/icons/plus_icon_grey_45.svg') } alt="New Phase"/> Add phase
              </div></div></td></tr>

          </tbody>
        </table>
      </div>
    </div>
  }


  render() {
    const { t } = this.props;

    var is_kc_by_month = this.props.selectedField.crop_data ? this.props.selectedField.crop_data.kc_table.type == "byMonth" : false;
    var is_kc_by_growth_phase = this.props.selectedField.crop_data ? this.props.selectedField.crop_data.kc_table.type == "byGrowthPhase" : false;
    var is_kc_by_growth_phase_gdd = this.props.selectedField.crop_data ? this.props.selectedField.crop_data.kc_table.type == "byGrowthPhaseGDD" : false;

    var sow_date_object = this.props.selectedField && this.props.selectedField.crop_data.sow_date ? moment(this.props.selectedField.crop_data.sow_date,"YYYY-MM-DD").format("DD-MMM-YYYY") : undefined;
    var irrigation_start_date_object = this.props.selectedField && this.props.selectedField.crop_data.irrigation_start_date ? moment(this.props.selectedField.crop_data.irrigation_start_date,"YYYY-MM-DD").format("YYYY-MM-DD") : sow_date_object;
    var harvest_date_object = this.props.selectedField && this.props.selectedField.crop_data.harvest_date ? moment(this.props.selectedField.crop_data.harvest_date,"YYYY-MM-DD").format("YYYY-MM-DD") : undefined;

    let is_coup_model_active = this.props.selectedField && this.props.selectedField.settings.is_coupmodel_active;

    let auto_generate_html = "";

    if (this.props.selectedField && this.props.selectedField.geo_data.areas && this.props.selectedField.geo_data.areas.length > 0 && this.state.shouldDisplayAutoGenerateLink) {

      if (this.state.isAutoGenerateLinkLoading) {
        auto_generate_html = <div className="spinner-border spinner-border-sm text-secondary ml-2" role="status"></div>
      } else {
        auto_generate_html =
            <span><a className="btn btn-link" href="#" onClick={() => this.handleAutoGenerateKCTable()}><small
                className={`font-weight-semi-bold text-size-14 button-primary`} style={{
              backgroundColor: this.state.enableGenerate  ?  "#4563C7" : "rgba(208, 211, 217, 1)",
              borderRadius: "4px",
              padding: "4px 12px",
              color: "white"
            }}>{t('kc_auto_generate')}</small></a></span>;
      }
    }
    let crop_variety = "general"
    if (this.props.crop_data && this.props.crop_data.crop_data[this.props.selectedField.crop_data.crop_type]) {
      crop_variety = this.props.crop_data.crop_data[this.props.selectedField.crop_data.crop_type][this.props.selectedField.crop_data.crop_variety] ? this.props.selectedField.crop_data.crop_variety : "general"
    }
    let byMonthDisplayTitles = [t('months_january').substring(0,3), t('months_july').substring(0,3), t('months_february').substring(0,3), t('months_august').substring(0,3), t('months_march').substring(0,3), t('months_september').substring(0,3), t('months_april').substring(0,3), t('months_october').substring(0,3), t('months_may').substring(0,3), t('months_november').substring(0,3), t('months_june').substring(0,3), t('months_december').substring(0,3)]
    let byMonthSaveTitles = [('january').substring(0,3), ('july').substring(0,3), ('february').substring(0,3), ('august').substring(0,3), ('march').substring(0,3), ('september').substring(0,3), ('april').substring(0,3), ('october').substring(0,3), ('may').substring(0,3), ('november').substring(0,3), ('june').substring(0,3), ('december').substring(0,3)]

    return (
        <div className="text-grey45">
          <div className="form-row justify-content-center">
            <div className="col-12 col-lg-4">
              <div className="form-group ml-2 mr-2 mt-3 font-weight-semi-bold text-size-14">
                <label htmlFor="cropType" style={{marginBottom: "4px"}}>{t('crop_type')}</label>
                <select className="form-control custom-select" ref="crop_type_field" id="crop_type_id"
                        value={this.props.selectedField.crop_data.crop_type} onChange={this.handleCropTypeChange}
                        disabled={this.props.disableCropType}>
                  {this.props.crop_data && this.props.crop_data.crop_data && Object.keys(this.props.crop_data.crop_data).sort().map((item, index) => {
                    return <option key={index} value={item}>{t(upper(item))}</option>;
                  })}

                </select>

                <div className="invalid-feedback">{t('kc_crop_type_invalid_data_message')}</div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className="form-group ml-2 mr-2 mt-3 font-weight-semi-bold text-size-14">
                <label htmlFor="kcVariety" style={{marginBottom: "4px"}}>{t('kc_variety')}</label>
                <select className="form-control custom-select" ref="crop_variety_field" id="crop_variety_id"
                        value={crop_variety} onChange={this.handleCropVarietyChange}
                        disabled={this.props.disableCropType}>
                  {this.props.crop_data && this.props.crop_data.crop_data && (this.props.selectedField.crop_data.crop_type.toLowerCase() in this.props.crop_data.crop_data) && Object.keys(this.props.crop_data.crop_data[this.props.selectedField.crop_data.crop_type.toLowerCase()]).sort().map((item, index) => {
                    return <option key={index} value={item}>{t(upper(item))}</option>;
                  })}

                </select>
                <div className="invalid-feedback">{t('kc_variety_invalid_data_message')}</div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="form-group ml-2 mr-2 mt-3 font-weight-semi-bold text-size-14">
                <label htmlFor="feature" style={{marginBottom: "4px"}}>{t('kc_feature')}</label>
                <br/>
                <select className="form-control custom-select" ref="crop_feature_field" id="crop_feature_id"
                        value={this.props.selectedField.crop_data.crop_feature.toLowerCase()}
                        onChange={this.handleCropFeatureChange} disabled={this.props.disableCropType}>
                  {this.props.crop_data && this.props.crop_data.crop_data &&
                      (this.props.selectedField.crop_data.crop_type.toLowerCase() in this.props.crop_data.crop_data) && crop_variety in this.props.crop_data.crop_data[this.props.selectedField.crop_data.crop_type.toLowerCase()] &&
                      Object.keys(this.props.crop_data.crop_data[this.props.selectedField.crop_data.crop_type.toLowerCase()][crop_variety]).sort().map((item, index) => {
                        return <option value={item} key={index}>{upper(item)}</option>;
                      })}

                </select>
                <div className="invalid-feedback">{t('kc_feature_invalid_data_message')}</div>
              </div>
            </div>


          </div>

          <div className="form-row justify-content-left">
            <div className="col-12 col-lg-4">
              <div className="form-group ml-2 mr-2 mt-3 font-weight-semi-bold text-size-14">
                <label htmlFor="sowDate" style={{marginBottom: "4px"}}>{t('kc_sow_date')}</label>
                <br/>
                <DayPickerInput classNames={{overlayWrapper: "DayPickerInput-OverlayWrapperRight"}}
                                value={sow_date_object} onDayChange={this.handleSowDateChange} inputProps={{
                  className: 'form-control',
                  type: "text",
                  ref: "sow_date_field",
                  id: "sow_date_input",
                  placeholder: t('choose_date'),
                  required: true,
                  style: {width: '100%'},
                  disabled: this.props.isModerator
                }}/>
                <div className="invalid-feedback">{t('kc_sow_date_invalid_data_message')}</div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="form-group ml-2 mr-2 mt-3 font-weight-semi-bold text-size-14">
                <label htmlFor="IrrigationStartDate" style={{marginBottom: "4px"}}>{t('edit_field_pre_season_irrigation_start_date')}</label>
                <br/>
                <DayPickerInput classNames={{overlayWrapper: "DayPickerInput-OverlayWrapperRight"}}
                                value={irrigation_start_date_object} onDayChange={this.handleIrrigationStartDateChange} inputProps={{
                  className: 'form-control',
                  type: "text",
                  ref: "irrigation_start_date",
                  id: "irrigation_start_date_input",
                  placeholder: t('choose_date'),
                  required: true,
                  style: {width: '100%'},
                  disabled: this.props.isModerator
                }}/>
                <div className="invalid-feedback">{t('kc_sow_date_invalid_data_message')}</div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="form-group ml-2 mr-2 mt-3 font-weight-semi-bold text-size-14">
                <label htmlFor="harvestDate" style={{marginBottom: "4px"}}>{t('kc_harvest_date')}</label>
                <br/>
                <DayPickerInput classNames={{overlayWrapper: "DayPickerInput-OverlayWrapperRight"}}
                                value={harvest_date_object} onDayChange={this.handleHarvestDateChange} inputProps={{ className: 'form-control', type:"text", ref:"harvest_date_field", id:"harvest_date_input",  placeholder:t('choose_date'), required:is_coup_model_active, style:{width: '100%'}, disabled:this.props.isModerator}}/>
                <div className="invalid-feedback">{t('kc_harvest_date_invalid_data_message')}</div>
              </div>
            </div>

          </div>

          <div className="form-row justify-content-left">

            <div className="col-12">
              <div className="form-group ml-2 mr-2 mt-3 font-weight-bold text-size-20">
                <label className="text-style-titles-20-bold text-grey45" style={{marginLeft:"6px", marginBottom:"0px"}} htmlFor="kcTable">{t('kc_kc_table')}</label>
                <div style={{position:"absolute", right:0, display:"inline"}}>{auto_generate_html}</div>
                {sow_date_object && <div className="text-grey71 text-size-14 font-weight-normal" style={{
                  marginLeft: "6px",
                  marginTop: "8px",
                  marginBottom: "16px"
                }}>{t('kc_first_phase_starts')} {sow_date_object}</div>
                }
              </div>
            </div>
          </div>

          <ul className="nav" id="kcTab" role="tablist" style={{"marginTop": "0px","borderBottom":"1px solid #DCE0E8" ,"paddingRight": "15px", marginBottom:"16px"}}>
             <li className="nav-item">
              <a className={"nav-link font-weight-semi-bold text-size-13 " + (is_kc_by_month ? "text-blue-primary underline " : "text-grey71 ")}
                 id="byMonth" data-toggle="tab" href="#home" role="tab"
                 aria-controls="profile">{t('kc_by_month').toUpperCase()}</a>
            </li>
            <li className="nav-item">
              <a className={"nav-link font-weight-semi-bold text-size-13 " + (is_kc_by_growth_phase ? "text-blue-primary underline " : "text-grey71 ")}
                 id="byGrowthPhase" data-toggle="tab" href="#growth" role="tab"
                 aria-controls="home">{t('kc_by_growth_phase').toUpperCase()}</a>
            </li>
              <li className="nav-item">
              <a className={"nav-link font-weight-semi-bold text-size-13 " + (is_kc_by_growth_phase_gdd ? "text-blue-primary underline " : "text-grey71 ")}
                 id="byGrowthPhaseGDD" data-toggle="tab" href="#growthgdd" role="tab"
                 aria-controls="profile">{t('kc_by_growth_phase').toUpperCase() + " GDD"}</a>
            </li>
          </ul>

          <div className="tab-content" id="kcTabContent">
            <div className={"tab-pane fade show " + (is_kc_by_month ? "active " : "")} id="home" role="tabpanel" aria-labelledby="home-tab">

              <table className="table table-borderless">
                <thead>
                {byMonthDisplayTitles.map((monthToDisplay, index)=>{
                  let monthToSaveInDB = byMonthSaveTitles[index];
                  let shouldDisplayTitle = index < 2 ;
                  let tooltipContainerID = "tooltip_container_" + index;
                  let tooltipSecondContainerID = "tooltip_container_" + (index+1);
                  let tooltipID = "tooltip_" + index;
                  let tooltipSecondID = "tooltip_" + (index+1);
                  if (index % 2 !== 0) return null;
                  else return (
                      <tr style={{display: "flex", flexDirection: "row", marginBottom: "24px"}}>
                        <div style={{paddingLeft: "0px"}} className={"col-1"}/>
                        <div className="d-flex justify-content-center col-5" style={{gap: "8px"}}>{this.kcMonthComponent(monthToDisplay, monthToSaveInDB, tooltipID, tooltipContainerID, t, is_kc_by_month, shouldDisplayTitle)}</div>
                        <div className="d-flex justify-content-center col-5" style={{gap: "8px", paddingLeft: "30px"}}>{this.kcMonthComponent(byMonthDisplayTitles[index + 1], byMonthSaveTitles[index + 1], tooltipSecondID, tooltipSecondContainerID, t, is_kc_by_month, shouldDisplayTitle)}</div>
                        <div className={"col-1"}/>
                      </tr>
                      )
                })
                }
                </thead>
              </table>
            </div>
            <div className={"tab-pane fade show " + (is_kc_by_growth_phase ? "active" : "")} id="growth" role="tabpanel" aria-labelledby="growth-tab">
              {this.kc_growth_component(sow_date_object, "byGrowthPhase", t('days').toLowerCase(), is_kc_by_growth_phase)}
            </div>
            <div className={"tab-pane fade show " + (is_kc_by_growth_phase_gdd ? "active" : "")} id="growthgdd" role="tabpanel" aria-labelledby="growth-tab">
              {this.kc_growth_component(sow_date_object, "byGrowthPhaseGDD", "GDD", is_kc_by_growth_phase_gdd)}
            </div>
          </div>
        </div>
    );
  }
};

const mapStateToProps = function(state){
  return {
    crop_data: state.crop_data.crop_data,
  }
}

const mapDispatchToProps = {
}

module.exports = connect(mapStateToProps,mapDispatchToProps)(withTranslation()(KCBox));
