var React = require('react');
const { useEffect, useCallback} = require("react");
const {generateDivider, generateCheckBoxMenuItem, generateDefaultMenuItem, generateNavigatorMenuItem} = require("./ContextMenu.util");


const menuItemGenerator = {
    divider: (props) => generateDivider(props),
    checkBox: (props) => generateCheckBoxMenuItem(props),
    default: (props) => generateDefaultMenuItem(props),
    link: (props) => generateNavigatorMenuItem(props)
}

const ContextMenu = ({options, onToggle, isDeleteMenu= false, isLinkMenu= false}) => {

    const optionsElements =  options.map((props) => {
        return (<li className='dropDownOption' style={{width:isDeleteMenu ? isLinkMenu ? "auto" : "136px" : '280px'}} key={props.title || Math.random()}>{menuItemGenerator[props.type || 'default'](props)}</li>)
    });


    const toggleMenu = useCallback((e) => {
        const isClickInsideMenu = e.target.classList.contains('dropdown-menu') || e.target.classList.contains('menu-checkbox')
        if (!isClickInsideMenu) {
            onToggle(false)
        }
    }, [onToggle])

    useEffect(() => {
        window.addEventListener('click', toggleMenu)
        return () => {
            window.removeEventListener('click', toggleMenu)
        }
    }, [options])

    return (<ul className="dropdown-menu d-table-row h-237 w-297" style={{top: isLinkMenu ? "100%" : "auto", right: isLinkMenu ? "0%" : '100%', zIndex: 1, left: 'initial', boxShadow:"0px 1px 4px rgba(146, 158, 181, 0.1), 0px 4px 8px rgba(145, 157, 180, 0.18)", borderRadius: "8px", padding:isLinkMenu ? "8px 4px" : "8px"}}>
            {optionsElements}
        </ul>);
};

module.exports = ContextMenu;