import {stress_levels, STRESSED_LIMIT} from "./fieldManager";
import {error_message, upper} from "./helpers";
import {unitDisplayName} from "./unitsManager";

var GeneralClasses = require('../model/GeneralClasses');
var axios = require('axios');
var React = require('react');

const INSIGHTS_PATH = 'insights'

export const classification_dictionary = {
    "bad": {
        min_value: 0.0,
        max_value: 0.15,
        color: "rgb(246, 107, 122)",
    },
    "fair": {
        min_value: 0.15,
        max_value: STRESSED_LIMIT,
        color: "rgb(255, 179, 2)",
    },
    "good": {
        min_value: 0.50,
        max_value: 1.01,
        color: "rgb(10, 203, 188)",
    },
};

export var get_classification_color = (value) => {

    for (var key in classification_dictionary) {
        var current_classification_level = classification_dictionary[key];

        if ((value >= current_classification_level['min_value']) &&
            (value < current_classification_level['max_value'])) {
            return current_classification_level["color"];
        }
    }

    return "#4D5674";
}

export var get_distribution_insight_data = (distribution_id, farming_unit_id) => {

    return new Promise(function (resolve, reject) {

        axios.get(INSIGHTS_PATH + "/" + distribution_id + "/" + farming_unit_id, {}).then(function (res) {

            resolve(res.data);

        }).catch(function (e) {

            console.log(error_message(e));
            reject(e);
        });
    });
};


export var get_all_farming_units_from_distribution = (active_distribution, setInsightsData, setOpenFieldsToggle, openFieldsToggle, setDistributionData, setFieldsData) => {

    if (active_distribution.hasOwnProperty("farming_units") && Object.keys(active_distribution.farming_units).length > 0) {
        let distributionId = active_distribution.id;
        let farmingUnitsIds = Object.keys(active_distribution.farming_units);
        let farmingUnitsData = [];
        let fieldsData = [];
        let farmingUnitsListLength = 0;
        let distributionData = {
            distribution_id: "",
            distribution_name: "",
            distribution_field_count: 0,
            distribution_total_area: 0,
            distribution_total_water_usage: 0,
            distribution_crop_types: [],
            distribution_total_fertilizers_usage: 0,
            distribution_total_nitrogen_leached: 0
        }

        farmingUnitsIds.forEach((farmingUnitId) => {
            return new Promise(function (resolve, reject) {

                get_distribution_insight_data(active_distribution.id, farmingUnitId).then(function (res) {

                    farmingUnitsData.push(res.farmingUnitsArray[0]);
                    farmingUnitsListLength += res.farmingUnitsArray.length;
                    setOpenFieldsToggle(new Array(farmingUnitsListLength).fill(false))
                    
                    if (res.farmingUnitsArray[0].farming_unit_crop_types.length > 0) {
                        let updated_crop_types = distributionData.distribution_crop_types.concat(res.farmingUnitsArray[0].farming_unit_crop_types);
                        distributionData.distribution_crop_types = updated_crop_types;
                    }

                    if (Object.keys(res.fields_details).length > 0){
                        Object.keys(res.fields_details).forEach((obj)=>{
                            fieldsData.push(res.fields_details[obj]);
                        })
                    }
                    distributionData = {
                        distribution_id: res.distributionId,
                        distribution_name: res.distributionName,
                        distribution_crop_types: [...new Set(distributionData.distribution_crop_types)],
                        distribution_field_count: distributionData.distribution_field_count + res.farmingUnitsArray[0].farming_unit_num_of_fields,
                        distribution_total_area: distributionData.distribution_total_area + res.farmingUnitsArray[0].farming_unit_total_area,
                        distribution_total_fertilizers_usage: distributionData.distribution_total_fertilizers_usage + res.farmingUnitsArray[0].total_used_fert,
                        distribution_total_nitrogen_leached: distributionData.distribution_total_nitrogen_leached + res.farmingUnitsArray[0].farming_unit_total_nitrogen_leached,
                        distribution_total_water_usage: distributionData.distribution_total_water_usage + res.farmingUnitsArray[0].total_used_irrigation
                    }
                    setDistributionData(distributionData);
                    setInsightsData(farmingUnitsData);
                    setFieldsData(fieldsData);
                }).catch(function (e) {

                    console.log(error_message(e));
                    reject(e);
                });
            });
        })

    }
};
var getOuterRowStructureByGroupName = (groupName) => {

    if (groupName === "farming_unit") {
        return ["", "", "", 0, 0, 0, 0, 0];

    } else if (groupName === "crop") {
        return ["", "", "", 0, 0, 0, 0, 0];

    } else if (groupName === "county") {
        return ["", "", "", 0, 0, 0, 0, 0];

    } else if (groupName === "county_crop") {
        return ["", "", 0, 0, 0, 0, 0, 0, 0];
    }
}
var getDictionaryKeyByGroupName = (groupName , fieldObj) => {

    if (groupName === "farming_unit") {
        return fieldObj.farming_unit_id;

    } else if (groupName === "crop") {
        return fieldObj.crop_type;

    } else if (groupName === "county") {
        return fieldObj.region;

    } else if (groupName === "county_crop") {
        return fieldObj.region + " / " + fieldObj.crop_type;
    }
}


export var buildStructureByGroup = (fieldsData, groupName, userData) => {

    let nitrogenUserUnits = (userData && ("weight" in userData)) ? unitDisplayName(userData.weight + "N/" + userData.area) : "";
    let areaUserUnits = (userData && ("area" in userData)) ? unitDisplayName(userData.area) : "";
    let appliedIrrigationUnits = (userData && ("volume" in userData) && userData.hasOwnProperty("unit_system") && !userData.unit_system.includes("metric")) ? unitDisplayName(userData.volume + "/" + userData.area) : userData && ("length" in userData) && userData.hasOwnProperty("unit_system") && userData.unit_system.includes("metric") ? unitDisplayName(userData.length) : "";
    let volumeUnits = (userData && ("volume" in userData)) ? unitDisplayName(userData.volume) : "";

    const headersDictionary = {
        "farming_unit": [
            ["", " ", "Farming unit / Field", "Crop", "County", `Total area (${areaUserUnits})`, `Total Irrigation (${volumeUnits})`, `Total Irrigation (${appliedIrrigationUnits})`, `Total applied Nitrogen (${nitrogenUserUnits})`, "Nitrogen use efficiency", ""],
        ],
        "crop": [
            ["", " ", "Crop / Field", "Farming unit", "County", `Total area (${areaUserUnits})`, `Total Irrigation (${volumeUnits})`, `Total Irrigation (${appliedIrrigationUnits})`, `Total applied Nitrogen (${nitrogenUserUnits})`, "Nitrogen use efficiency", ""],
        ], "county": [
            ["", " ", "County / Field", "Farming unit", "Crop", `Total area (${areaUserUnits})`, `Total Irrigation (${volumeUnits})`, `Total Irrigation (${appliedIrrigationUnits})`, `Total applied Nitrogen (${nitrogenUserUnits})`, "Nitrogen use efficiency", ""],
        ],
        "county_crop": [
            ["", " ", "County / Crop / Field", "Farming unit", `Total area (${areaUserUnits})`, `Total Irrigation (${volumeUnits})`, `Total Irrigation (${appliedIrrigationUnits})`, `Benchmark: Total Irrigation (${appliedIrrigationUnits})`, `Current water saving (${appliedIrrigationUnits})`, `Total applied Nitrogen (${nitrogenUserUnits})`, "Nitrogen use efficiency", ""],
        ]
    };
    let loopOrderOuterRow = {
        "farming_unit": ["FARMING_UNIT", "CROP", "COUNTY", "AREA", "IRRIGATION", "APPLIED_IRRIGATION", "APPLIED_NITROGEN", "NITROGEN_USE_EFFICIENCY"],
        "crop": ["CROP", "FARMING_UNIT", "COUNTY", "AREA", "IRRIGATION", "APPLIED_IRRIGATION", "APPLIED_NITROGEN", "NITROGEN_USE_EFFICIENCY"],
        "county": ["COUNTY", "FARMING_UNIT", "CROP", "AREA", "IRRIGATION", "APPLIED_IRRIGATION", "APPLIED_NITROGEN", "NITROGEN_USE_EFFICIENCY"],
        "county_crop": ["COUNTY/CROP", "FARMING_UNIT", "AREA", "IRRIGATION", "APPLIED_IRRIGATION", "BENCHMARK_IRRIGATION", "WATER_SAVING", "APPLIED_NITROGEN", "NITROGEN_USE_EFFICIENCY"]
    }

    if (Object.keys(fieldsData).length > 0 || fieldsData.length > 0) {
        let groupedData = fieldsData?.reduce(function (newObj, obj) {
            let key = getDictionaryKeyByGroupName(groupName, obj);

            if (!newObj[key]) {
                newObj[key] = [];
                newObj[key]["fields_details"] = [];
                newObj[key]["outerRowInformation"] = getOuterRowStructureByGroupName(groupName);
                newObj[key]["outerRowInformation"][0] = key.toUpperCase();
                newObj[key]["innerRowsInformation"] = [];
                newObj[key]["innerRowsIDList"] = [];
                newObj[key]["extraData"] = {
                    "totalArea": obj.field_size,
                    "activeNitrogenFields": obj.nitrogen_efficiency !== "N/A" ? 1 : 0
                };
            } else {
                newObj[key]["extraData"]["totalArea"] += obj.field_size;
                newObj[key]["extraData"]["activeNitrogenFields"] += obj.nitrogen_efficiency !== "N/A" ? 1 : 0;
            }
            newObj[key]["fields_details"].push(obj);
            return newObj;
        }, {});
        Object.keys(groupedData).map((groupKey) => {

            let activeNitrogenFields = 0;
            groupedData[groupKey]["fields_details"].map((field) => {
                let innerRowInfo = [field.name];
                loopOrderOuterRow[groupName].map((key, index) => {

                    if (key === "FARMING_UNIT") {

                        if (groupName !== "farming_unit") {
                            innerRowInfo[index + 1] = field.farming_unit_name;
                        } else {
                            groupedData[groupKey]["outerRowInformation"][index] = field.farming_unit_name;
                        }

                    } else if (key === "CROP") {
                        let cropType = upper(field.crop_type);

                        if (groupName !== "crop") {
                            innerRowInfo[index + 1] = cropType;
                        } else {
                            groupedData[groupKey]["outerRowInformation"][index] = cropType;
                        }

                    } else if (key === "COUNTY") {
                        let county = upper(field.region);

                        if (groupName !== "county") {
                            innerRowInfo[index + 1] = county;
                        } else {
                            groupedData[groupKey]["outerRowInformation"][index] = county;
                        }

                    } else if (key === "AREA") {
                        let fieldSize = field.field_size?.toFixed(2);
                        innerRowInfo[index + 1] = fieldSize;
                        groupedData[groupKey]["outerRowInformation"][index] += parseFloat(fieldSize);

                    } else if (key === "IRRIGATION") {
                        let usedIrr = field.used_irr?.toFixed(2);
                        innerRowInfo[index + 1] = usedIrr;
                        groupedData[groupKey]["outerRowInformation"][index] += parseFloat(usedIrr);

                    } else if (key === "APPLIED_IRRIGATION") {
                        let totalIrr = field.total_irrigation * field.field_size;
                        innerRowInfo[index + 1] = field.total_irrigation?.toFixed(2);
                        groupedData[groupKey]["outerRowInformation"][index] += parseFloat(totalIrr) / parseFloat(groupedData[groupKey]["extraData"]["totalArea"]);

                    } else if (key === "BENCHMARK_IRRIGATION") {
                        let benchmarkIrr = field.benchmark * field.field_size;
                        innerRowInfo[index + 1] = field.benchmark?.toFixed(2);
                        groupedData[groupKey]["outerRowInformation"][index] += parseFloat(benchmarkIrr) / parseFloat(groupedData[groupKey]["extraData"]["totalArea"]);

                    } else if (key === "WATER_SAVING") {
                        let waterSaving = (field.benchmark - field.total_irrigation) * field.field_size;
                        innerRowInfo[index + 1] = (field.benchmark - field.total_irrigation)?.toFixed(2);
                        groupedData[groupKey]["outerRowInformation"][index] += parseFloat(waterSaving) / parseFloat(groupedData[groupKey]["extraData"]["totalArea"]);

                    } else if (key === "APPLIED_NITROGEN") {
                        let appliedNitrogen = field.used_fert * field.field_size;
                        innerRowInfo[index + 1] = field.used_fert?.toFixed(2);
                        groupedData[groupKey]["outerRowInformation"][index] += parseFloat(appliedNitrogen) / parseFloat(groupedData[groupKey]["extraData"]["totalArea"]);

                    } else if (key === "NITROGEN_USE_EFFICIENCY") {

                        if (field.nitrogen_efficiency !== "N/A") {
                            activeNitrogenFields += 1;
                            innerRowInfo[index + 1] = "~" + (field.nitrogen_efficiency * 100) + "%";
                            groupedData[groupKey]["outerRowInformation"][index] += parseFloat(field.nitrogen_efficiency);
                        } else {
                            innerRowInfo[index + 1] = field.nitrogen_efficiency;
                        }
                    }
                });
                groupedData[groupKey]["innerRowsInformation"].push(innerRowInfo);
            });
            loopOrderOuterRow[groupName].map((key, index) => {

                if (key === "AREA") {
                    groupedData[groupKey]["outerRowInformation"][index] = groupedData[groupKey]["outerRowInformation"][index].toFixed(2);

                } else if (key === "IRRIGATION") {
                    groupedData[groupKey]["outerRowInformation"][index] = groupedData[groupKey]["outerRowInformation"][index].toFixed(2);

                }
                else if (key === "APPLIED_IRRIGATION") {
                    groupedData[groupKey]["outerRowInformation"][index] = (groupedData[groupKey]["outerRowInformation"][index]).toFixed(2);

                }
                else if (key === "BENCHMARK_IRRIGATION") {
                    groupedData[groupKey]["outerRowInformation"][index] = (groupedData[groupKey]["outerRowInformation"][index]).toFixed(2);

                } else if (key === "WATER_SAVING") {
                    groupedData[groupKey]["outerRowInformation"][index] = (groupedData[groupKey]["outerRowInformation"][index]).toFixed(2);

                } else if (key === "APPLIED_NITROGEN") {
                    groupedData[groupKey]["outerRowInformation"][index] = (groupedData[groupKey]["outerRowInformation"][index]).toFixed(2);

                } else if (key === "NITROGEN_USE_EFFICIENCY") {

                    if (groupedData[groupKey]["extraData"]["activeNitrogenFields"] > 0) {
                        groupedData[groupKey]["outerRowInformation"][index] = "~" + ((groupedData[groupKey]["outerRowInformation"][index] / groupedData[groupKey]["extraData"]["activeNitrogenFields"]) * 100).toFixed(0) + "%" + " (avg)";
                    } else {
                        groupedData[groupKey]["outerRowInformation"][index] = "N/A";
                    }
                }
            });
        })
        return {data: groupedData, headers: headersDictionary[groupName]};
    }
}


