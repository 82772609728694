import React, {memo, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NDCheckBox} from "../../../stories/NDCheckBox";


const FilterList = memo(({localTypesList,onFilterList,event,t,typesDictionary}) => {
    return (
        <div>
            {localTypesList.map((type, index) => {
                type.isActive = type.isActive ? 1 : 0;
                return (
                    <div className="d-flex align-items-center" style={{height: "32px"}} key={index}>
                        <div className="ml-2"><NDCheckBox mode={type.isActive} size={'small'} onClick={()=>{onFilterList(event, type.type, index)}}></NDCheckBox></div>
                        <span
                            className="text-size-16 text-grey45 text-truncate pl-2 pr-2 mr-2">{t(typesDictionary[type.type])}</span>
                    </div>
                )
            })}
        </div>
    )
})


export const Filter = ({typesList, setTypesList,  style = {}, event, typesDictionary}) => {

    const [currentEvent, setCurrentEvent] = useState(event)
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [localTypesList, setLocalTypesList] = useState([]);

    useEffect(() => {
        if (currentEvent !== event) {
            setIsFilterOpen(false)
            setCurrentEvent(event)
        }
    },[event])

    let defaultIconStyle = {
        backgroundColor: isFilterOpen ? "#DCE0E8" : "transparent",
                cursor: "pointer",
                borderRadius: "3px",
                width: "32px",
                alignSelf: "flex-end",
    }
    let defaultListStyle = {
                     padding: "8px",
                     border: "none",
                     margin: "0px",
                     boxShadow: "0px 1px 4px rgba(146, 158, 181, 0.1), 0px 4px 8px rgba(145, 157, 180, 0.18)"
    }

    let navigationLineListStyle = {
        left: "auto",
        right: "8px",
    }
    let navigationLineIconStyle = {
        position:"absolute",
        bottom: "2px",
        height: "32px",
        right: "10px"
    }
    if (Object.keys(style).length === 0){
        defaultIconStyle = {...defaultIconStyle, ...navigationLineIconStyle};
        defaultListStyle = {...defaultListStyle, ...navigationLineListStyle};
    } else {
        defaultIconStyle = {...defaultIconStyle, ...style.icon};
        defaultListStyle = {...defaultListStyle, ...style.list};
    }

    useEffect(() => {
        setLocalTypesList(typesList);
    }, [typesList])


    const {t} = useTranslation();

            const onFilterList = useCallback((listType, filterType, index) => {
            setLocalTypesList(prevState => {
                const updatedState = [...prevState];
                updatedState[index] = {
                    ...updatedState[index],
                    isActive: updatedState[index].isActive === 1 ? 0 : 1 ,
                };
                setTypesList(updatedState);
                return updatedState;
            });
        mixpanel.track("List Filtering", {'Event Type': filterType, "Filter Source": listType});
    },[setTypesList])

    if (localTypesList.length === 0) return null 

    return (
        <div>
            <div aria-haspopup="true"
                 aria-expanded="false"
                 className="d-flex align-items-center justify-content-center"
                 onClick={() => {
                     setIsFilterOpen(!isFilterOpen)}}
                 style={{...defaultIconStyle}}>
                <img src={require('images/icons/filter_icon.svg')}/>
            </div>
            <div className={`dropdown-menu${isFilterOpen ? ' show' : ''}`} aria-labelledby={`dropdownMenuButton`}
                 style={{...defaultListStyle}}>
                <FilterList localTypesList={localTypesList} onFilterList={onFilterList} t={t} event={event} typesDictionary={typesDictionary}/>
            </div>
        </div>
    )
}