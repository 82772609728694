import {update_field} from "./fieldManager";
import moment from "moment";
import {reduceSampleDensity} from "./helpers";
import {convert} from "./unitsManager";

var axios = require('axios');
var GeneralClasses = require('@model/GeneralClasses');
var {error_message} = require('@managers/helpers');

const SCIROOT_SENSORS_PATH = 'sensors';
const SCIROOT_SENSORS_RECORDS_PATH = 'sensor_records';
const SCIROOT_TRANSMITTER_PATH = 'transmitter';
export const PIEZOMETER_SENSOR_TYPE = 'piezometer'


export var sensor_samples = (fieldId, sensorId, days_back) => {

  return new Promise(function (resolve, reject) {

    // var arrStr = encodeURIComponent(JSON.stringify(serial_numbers));

    axios.get(SCIROOT_SENSORS_PATH + "/" + fieldId + "/" + sensorId, {
      params: {
        days_back: days_back
      }
    }).then(function (res) {

      resolve(res.data);

    }).catch(function(err) {
      console.log(error_message(err));
      reject(err);
    });
  });
};

export var addSensorRecord = (fieldId, sensorId, startDate, recordType, value, extra={}) => {

  return new Promise(function (resolve, reject) {

    axios.post(SCIROOT_SENSORS_RECORDS_PATH + "/" + fieldId + "/" + sensorId, {
        value: value,
        startDate: startDate,
        recordType: recordType,
        extra:extra
    }).then(function (res) {

      resolve(res.data);

    }).catch(function(err) {
      console.log(error_message(err));
      reject(err);
    });
  });
};

export var deleteSensorRecord = (fieldId, sensorId, startDate, recordType) => {

  return new Promise(function (resolve, reject) {

    axios.delete(SCIROOT_SENSORS_RECORDS_PATH + "/" + fieldId + "/" + sensorId + "?startDate=" + startDate + "&recordType=" + recordType , {
    }).then(function (res) {

      resolve(res.data);

    }).catch(function(err) {
      console.log(error_message(err));
      reject(err);
    });
  });
};

export var deleteSensorRecordProperty = (fieldId, sensorId, startDate, recordType, propertyToDelete) => {

  return new Promise(function (resolve, reject) {

    axios.put(SCIROOT_SENSORS_RECORDS_PATH + "/" + fieldId + "/" + sensorId + "?startDate=" + startDate + "&recordType=" + recordType + "&property=" + propertyToDelete , {
    }).then(function (res) {

      resolve(res.data);

    }).catch(function(err) {
      console.log(error_message(err));
      reject(err);
    });
  });
};

export var getTransmitterStatus = (transmitterId , extended = true) => {

  return new Promise(function (resolve, reject) {
    const queryParams = { extended: extended };
    axios.get(SCIROOT_TRANSMITTER_PATH + "/" + transmitterId, {
        params : queryParams
    }).then(function (res) {

      let resJson = JSON.parse(res.data);

      if (resJson.statusCode != 200) {
        throw(resJson.body);
      }

      resolve(resJson);

    }).catch(function(err) {
      console.log(error_message(err));
      reject(err);
    });
  });
};


export var sensorDisplayName = (sensorObject) => {

  let name = sensorObject.transmitter_id;

  if (sensorObject.name != "") {
    name = sensorObject.name + " - " + sensorObject.transmitter_id;
  }

  return name;
};

export var sensorShortDisplayName = (sensorObject) => {

  let name = sensorObject.transmitter_id;

  if (sensorObject.name != "") {
    name = sensorObject.name;
  }

  return name;
};

export var getWPSensors = (fieldSensors)  => {

  if (fieldSensors) {
    let wpSensors = Object.entries(fieldSensors).filter(a => a[1] instanceof GeneralClasses.WPSensor)
    let wpSensorsDict = wpSensors.reduce(function(prev,curr){prev[curr[0]]=curr[1];return prev;},{});
    return wpSensorsDict;
  } else {
    return {};
  }
}


export var get_transmitter_ids = (sensors) => {
  if (sensors) {
    let allTransmitters = Object.keys(sensors).map(a => sensors[a].transmitter_id);
    return allTransmitters
  } else {
    return [];
  }

}

export var replace_sensor_in_field = async (distributions, old_transmitter_id, new_transmitter_id) => {

    let sensorIsExist = false;
    let newField;
    let distribution_id, farmingUnit_id, field_id;
    const distributionsArr = Object.values(Object.values(distributions)[0]);
    distributionsArr.forEach((distribution, distIndex) => {
        const farmingUnitsArr = Object.values(distribution.farming_units);
        farmingUnitsArr.forEach((farmingUnit, farmingUnitIndex) => {
            const fieldsArr = Object.values(farmingUnit.fields);
            fieldsArr.forEach((field, fieldIndex) => {
                const sensorsArr = Object.values(field.sensors);
                sensorsArr.forEach((sensor, sensorIndex) => {
                    if (sensor.transmitter_id.localeCompare(old_transmitter_id) === 0) {
                        sensorIsExist = true;
                        distribution_id = distribution.id;
                        farmingUnit_id = farmingUnit.id;
                        field_id = field.id;
                        let newSensor = sensor;
                        newSensor.transmitter_id = new_transmitter_id;
                        let newSensorsArr = [...sensorsArr];
                        newSensorsArr.splice(sensorIndex, 1, newSensor);
                        let sensorsObj = {};
                        newSensorsArr.forEach((sensor) => {
                            sensorsObj[sensor.id] = sensor;
                        })
                        newField = Object.assign(field);
                        newField.sensors = sensorsObj;
                    }
                })
            })
        })
    });

    let res = await replace_sensor_helper(distribution_id, farmingUnit_id, newField);
    if (sensorIsExist && res == "") {
        return "Sensor replaced successfully";
    } else if (res !== "") {
        return res;
    } else {
        return "No permissions to replace sensor";
    }
}

var replace_sensor_helper = async (distribution_id, farmingUnit_id, newField) => {
    try {
        let res = await update_field(distribution_id, farmingUnit_id, newField);
        return res;
    } catch (error) {
        return error;
    }
}

// Function to modify sensors based on keys starting with 'SC_'
export const serialNumberCheck = (sensors) => {
    // Create an empty object to store filtered sensors
    const filteredSensors = {};

    // Iterate over the keys of the sensors object
    Object.keys(sensors).forEach(key => {

        // Check if the key starts with 'SC_'
        if (key.startsWith('SC_')) {

            // // Get the index based on the number of keys in the filteredSensors object
            // const index = Object.keys(filteredSensors).length + 1;

            // Create a new key using the index
            const newKey = `tmp_${sensors[key].transmitter_id}`;

            // Assign the corresponding value to the new key in the filteredSensors object
            filteredSensors[newKey] = sensors[key];
        } else {
            filteredSensors[key] = sensors[key]
        }
    });

    // Return the filteredSensors object
    return filteredSensors;
}

export var samplesByTimeFrames = (samples, pressure_samples, discharge_samples) => {

    let framedPressureSamples = [];
    let framedDischargeSamples = [];

    for (let i = 0; i < samples.length; i++) {
        let startDate = moment(samples[i]["start_date"],"MM/DD/YYYY h:mm:ss A Z").unix();
        let endDate = moment(samples[i]["end_date"],"MM/DD/YYYY h:mm:ss A Z").unix();

        let pressureSamp = pressure_samples.filter((sample) => {
            if (moment(sample.date,"MM/DD/YYYY h:mm:ss A Z").unix() > startDate && moment(sample.date,"MM/DD/YYYY h:mm:ss A Z").unix() < endDate) return sample;
        });
        framedPressureSamples.push({pressureSamp});

        let dischargeSamp = discharge_samples.filter((sample) => {
            if (moment(sample.date).unix() > startDate && moment(sample.date).unix() < endDate) return sample;
        });
        framedDischargeSamples.push({dischargeSamp});
    }

    return {framedPressureSamples, framedDischargeSamples};

}
export var loadSamplesData = (samples, lastSeen, pressure_samples, discharge_samples, userData, setIsDischargeGraph = () => {
}) => {
    let waterData = {}
    let pressureSamplesForGraph = [];
    let dischargeSamplesForGraph = [];
    let lastDate = moment(lastSeen,'MM/DD/YYYY hh:mm:ss A Z');
    let {
        framedPressureSamples,
        framedDischargeSamples
    } = samplesByTimeFrames(samples, pressure_samples, discharge_samples);
    if (pressure_samples.length > 0) {
        pressureSamplesForGraph.push({
            value: "0",
            date: lastDate,
            index: pressureSamplesForGraph.length,
            dataToDisplay: []
        })
    }
    if (discharge_samples.length > 0) {
        dischargeSamplesForGraph.push({
            value: "0",
            date: lastDate,
            index: dischargeSamplesForGraph.length,
            dataToDisplay: []
        })
    }

    for (let i = 0; i < samples.length; i++) {
        let startDate = moment(samples[i]["start_date"],'MM/DD/YYYY hh:mm:ss A Z')
        let endDate = moment(samples[i]["end_date"],'MM/DD/YYYY hh:mm:ss A Z');

        if (framedPressureSamples[i].pressureSamp.length > 0) {
            pressureSamplesForGraph.push({
                value: "0",
                date: endDate,
                index: pressureSamplesForGraph.length,
                dataToDisplay: [],
                frameStartDate: startDate,
                frameEndDate: endDate
            })
            for (let j = framedPressureSamples[i].pressureSamp.length - 1; j >= 0; j--) {
                pressureSamplesForGraph.push({
                    value: convert((framedPressureSamples[i].pressureSamp[j].value) * 10, "mm", userData.length, "length"),
                    date: moment(framedPressureSamples[i].pressureSamp[j].date, 'MM/DD/YYYY hh:mm:ss A Z'),
                    index: pressureSamplesForGraph.length,
                    dataToDisplay: [],
                    frameStartDate: startDate,
                    frameEndDate: endDate
                })
            }
            pressureSamplesForGraph.push({
                value: "0",
                date: startDate,
                index: pressureSamplesForGraph.length,
                dataToDisplay: [],
                frameStartDate: startDate,
                frameEndDate: endDate
            })
        }
        if (framedDischargeSamples[i].dischargeSamp.length > 0) {
            setIsDischargeGraph(true);
            dischargeSamplesForGraph.push({
                value: "0",
                date: endDate,
                index: dischargeSamplesForGraph.length,
                dataToDisplay: [],
                frameStartDate: startDate,
                frameEndDate: endDate
            })
            for (let k = framedDischargeSamples[i].dischargeSamp.length - 1; k >= 0; k--) {
                dischargeSamplesForGraph.push({
                    value: convert((framedDischargeSamples[i].dischargeSamp[k].value), "cubic metre", userData.volume, "volume"),
                    date: moment(framedDischargeSamples[i].dischargeSamp[k].date,'MM/DD/YYYY hh:mm:ss A Z'),
                    index: dischargeSamplesForGraph.length,
                    dataToDisplay: [],
                    frameStartDate: startDate,
                    frameEndDate: endDate
                })
            }
            dischargeSamplesForGraph.push({
                value: "0",
                date: startDate,
                index: dischargeSamplesForGraph.length,
                dataToDisplay: [],
                frameStartDate: startDate,
                frameEndDate: endDate
            })
        }

        if (pressure_samples.length > 0) {
            pressureSamplesForGraph.push({
                value: "0",
                date: startDate,
                index: pressureSamplesForGraph.length,
                dataToDisplay: [],
                frameStartDate: startDate,
                frameEndDate: endDate
            })
        }
        if (discharge_samples.length > 0) {
            dischargeSamplesForGraph.push({
                value: "0",
                date: startDate,
                index: dischargeSamplesForGraph.length,
                dataToDisplay: [],
                frameStartDate: startDate,
                frameEndDate: endDate
            })
        }
        lastDate = startDate
    }
    waterData["pressure"] = reduceSampleDensity(pressureSamplesForGraph, false);
    waterData["discharge"] = reduceSampleDensity(dischargeSamplesForGraph, false);

    return waterData;
}

export const checkTransmitterType = (transmitterId, field) => {
    let transmitterType = "";

    if (field) {
        Object.keys(field.sensors).forEach((key) => {
            const activeElement = field.sensors[key];
            if (activeElement.transmitter_id === transmitterId) {
                transmitterType = activeElement.type
            }
        });
    }

    return transmitterType;
}

export var sensorColorByIndex = (index, activeField) => {

    if (activeField === "archive") {
        return ["#88AAE3", "#D4B696", "#9ADBD2", "#DB9AB8", "#BB8BA5", "#A1A7D4", "#DFD9B9", "#BCD9E3", "#AD9E9C", "#B8C7B4",
            "#D8D1DE", "#AAACB8", "#BDCEE0", "#E7DBD3", "#E8C5C2", "#D0B4DF", "#EDD5ED", "#DCDCD5", "#F2E4AB", "#E9D5DA"][index];
    }
    else {
        return ["#3F83F7", "#EF9331", "#00C1A7", "#C30860", "#85094D", "#4251B9", "#FFDF2C", "#20A2CF", "#DE4836", "#80D06A",
            "#9A31EF", "#5E70D1", "#7188A1", "#EEB48D", "#DD776F", "#B361DE", "#CD91CD", "#B8B99B", "#FFEA94", "#EE5279"][index]
    }

};

 export const sensorsMap = new Map([
    ['aquastride','Aquastride'],
    ['wfr_aquastride','Aquastride'],
    ['galcon','Galcon'],
    ['wfr_galcon','Galcon'],
    ['wfr_motorola','Motorola'],
    ['motorola','Motorola'],
    ['tal-gil','Tal Gil'],
    ['wfr_tal-gil','Tal Gil'],
    ['piezometer','N-Drip Piezometer'],
    ['piezometer_ndrip','N-Drip Piezometer'],
    ['pulse','N-Drip Pulse'],
    ['wp','N-Drip Sensor'],
    ['wfr_ndrip','N-Drip Flow Rate'],
    ['ndrip','N-Drip Flow Rate'],
    ['wfr','Flow Rate'],
    ['ndrip T4 1','N-Drip Flow Rate T4 1'],
    ['ndrip T4 2','N-Drip Flow Rate T4 2'],
    ['ndrip T6 1','N-Drip Flow Rate T6 1'],
    ['ndrip T6 2','N-Drip Flow Rate T6 2'],
    ['ndrip T8','N-Drip Flow Rate T8'],
    ['manual','Manual']
]);
