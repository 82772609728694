var React = require('react');
var { Link } = require ('react-router-dom');
var {connect} = require('react-redux');
var distributionActions = require('distributionActions');

class FarmingUnitsDropdown extends React.Component {

  constructor(props) {
      super(props);
  }

  onDropdownSelection(key) {

    if (key) {
        this.props.dispatch(distributionActions.selected_entities(this.props.active_distribution,
                            this.props.active_distribution.farming_units[key], null, null));
    }
  }

  render() {

    let sortedFramingUnits = undefined;
    if (this.props.active_distribution) {

      sortedFramingUnits = Object.values(this.props.active_distribution.farming_units);
      sortedFramingUnits.sort((a, b) => a.name.localeCompare(b.name));
    }

    return (
      <div>
        <div className="dropdown text-grey71">
          <div className="dropdown-toggle d-flex align-items-center" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div  className="overflow-hidden text-truncate">
              <Link to="" className="h6 font-weight-normal text-grey71">{this.props.active_farming_unit && this.props.active_farming_unit.name}</Link>
            </div>

          </div>
          <div className="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuButton">
            {sortedFramingUnits && sortedFramingUnits.map((current_farming_unit) => {

              return <a className="dropdown-item text-grey71" href="#" key={current_farming_unit.id} onClick={() => { this.onDropdownSelection(current_farming_unit.id) }}>{current_farming_unit.name}</a>
            })}
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = function(state){

  return {
    active_distribution: state.distribution_data.selected_entities.distribution,
    active_farming_unit: state.distribution_data.selected_entities.farming_unit,
  }
}

module.exports = connect(mapStateToProps)(FarmingUnitsDropdown);
