var React = require('react');
var {connect} = require('react-redux');
var fieldManager = require('fieldManager');
var {upper} = require('helpers');
var moment = require('moment');
var distributionActions = require('distributionActions');
var IrrigationMeter = require('IrrigationMeter');
var { withTranslation } = require('react-i18next');
var {unitDisplayName} = require('unitsManager');


class FertilizationStatusContainer extends React.Component {

  constructor(props) {
    super(props);

    // this.handleFieldSelection = this.handleFieldSelection.bind(this);

  }

  componentDidMount() {
    // this.setState({'mainMessage':'', 'commandMessage':'', 'irrigationAmountMessage':'', 'stressLevel':0});

    if (this.props.selectedField) {
      // this.updateGauge(this.props.selectedField);
    }

  }

  componentWillUnmount() {
  }

  componentDidUpdate(nextProps, nextState) {

    if ((nextProps.loadHistoryStatus.status == "success") || (this.props.selectedField && !nextProps.selectedField) || (this.props.selectedField && nextProps.selectedField && JSON.stringify(this.props.selectedField) != JSON.stringify(nextProps.selectedField)))  {
      // this.updateGauge(this.props.selectedField);
    }
  }


  render() {

    const { t } = this.props;

    let NInSoil = 0.0;
    let NInPlant = 0.0;
    let NLeached = 0.0;
    let NPpm = 0.0;

    if (this.props.selectedField && this.props.selectedField.historical_data) {

      if ("n_in_soil" in this.props.selectedField.historical_data) {
        let NInSoilMaxKey = Math.max.apply(null,Object.keys(this.props.selectedField.historical_data.n_in_soil.values));
        NInSoil = this.props.selectedField.historical_data.n_in_soil.values[NInSoilMaxKey];
      }

      if ("n_in_plant" in this.props.selectedField.historical_data) {
        let NInPlantMaxKey = Math.max.apply(null,Object.keys(this.props.selectedField.historical_data.n_in_plant.values));
        NInPlant = this.props.selectedField.historical_data.n_in_plant.values[NInPlantMaxKey];
      }

      if ("n_leached" in this.props.selectedField.historical_data) {
        let NLeachesMaxKey = Math.max.apply(null,Object.keys(this.props.selectedField.historical_data.n_leached.values));
        NLeached = this.props.selectedField.historical_data.n_leached.values[NLeachesMaxKey];
      }

      if ("n_in_soil_mgNkgSoil" in this.props.selectedField.historical_data) {
        let NPPMMaxKey = Math.max.apply(null,Object.keys(this.props.selectedField.historical_data.n_in_soil_mgNkgSoil.values));
        NPpm = this.props.selectedField.historical_data.n_in_soil_mgNkgSoil.values[NPPMMaxKey];
      }
    }

    let userUnits = (this.props.userData && ("weight" in this.props.userData))? unitDisplayName(this.props.userData.weight + "N/" + this.props.userData.area) : "";

    return (
        <div className="bg-white w-100 h-100" style={{"borderRadius": "5px",border:"1px solid #DCE0E8"}}>
          <div  className="pt-4 d-flex " >


          {/* In Plant */}
          <div className="d-flex flex-fill flex-column pt-4 pb-4 align-items-center justify-content-center">
            <div className=""><img src={ require('images/icons/fertilization/in_plant.svg') } /></div>
            <div className="text-grey45 pt-3 pb-3 text-size-fertilizer-title font-weight-bold">{t('plant')}</div>
            <div className="text-grey45  text-size-nitrogen font-weight-bold m-0 p-0" style={{'lineHeight':'1.1'}}>{NInPlant && parseFloat(NInPlant).toFixed(2)}</div>
            <div className="text-size-12 text-grey71 m-0 p-0 font-weight-semi-bold">{userUnits}</div>
            <div className="text-size-12">&nbsp;</div>
          </div>

          {/* Separator */}
          <div className="flex-column d-flex" style={{width:"1px"}}>
            <div style={{flex:1}}></div>
            <div className="bg-grey91" style={{flex:8}}></div>
            <div style={{flex:1}}></div>
          </div>

          {/* In Soil */}
          <div className="d-flex flex-fill flex-column pt-4 pb-4 align-items-center justify-content-center">
            <div className=""><img src={ require('images/icons/fertilization/in_soil.svg') } /></div>
            <div className="text-grey45 pt-3 pb-3 text-size-fertilizer-title font-weight-bold">{t('soil')}</div>
            <div className="text-grey45  text-size-nitrogen font-weight-bold m-0 p-0" style={{'lineHeight':'1.1'}}>{NInSoil && parseFloat(NInSoil).toFixed(2)}</div>
            <div className="text-size-12 text-grey71 m-0 p-0 font-weight-semi-bold">{userUnits}</div>
            <div className="text-size-12 text-grey71  font-weight-regular">* {NPpm && parseFloat(NPpm).toFixed(2)} ppm</div>
          </div>

          {/* Separator */}
          <div className="flex-column d-flex" style={{width:"1px"}}>
            <div style={{flex:1}}></div>
            <div className="bg-grey91" style={{flex:8}}></div>
            <div style={{flex:1}}></div>
          </div>

          {/* Leached */}
          <div className="d-flex flex-fill flex-column pt-4 pb-4 align-items-center justify-content-center">
            <div className=""><img src={ require('images/icons/fertilization/drained.svg') } /></div>
            <div className="text-grey45 pt-3 pb-3 text-size-fertilizer-title font-weight-bold">{t('leached')}</div>
            <div className="text-grey45  text-size-nitrogen font-weight-bold m-0 p-0 font-weight-semi-bold" style={{'lineHeight':'1.1'}}>{NLeached && (parseFloat(NLeached)).toFixed(2)}</div>
            <div className="text-size-12 text-grey71 m-0 p-0 font-weight-semi-bold">{userUnits}</div>
            <div className="text-size-12">&nbsp;</div>
          </div>
        </div>
          <div className="pb-1 d-flex justify-content-center">
            <span className="text-grey71 text-size-12" style={{'lineHeight': '15px'}}>{"*" + t('estimated_nitrogen_values')}</span>
          </div>
        </div>
    );
  }
};

const mapStateToProps = function(state){
  return {
    selectedField: state.distribution_data.selected_entities.field,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    userData: state.authentication_data.user_data,
    loadHistoryStatus: state.distribution_data.field_load_history,
  }
}

const mapDispatchToProps = {
  setSelectedEntities: distributionActions.selected_entities
}

module.exports = connect(mapStateToProps,mapDispatchToProps)(withTranslation()(FertilizationStatusContainer));

