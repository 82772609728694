var React = require('react');
const {useTranslation, withTranslation} = require("react-i18next");
const {connect} = require("react-redux");
const $ = require("jquery");
const {useEffect} = require("react");
const NotificationsSelectorPerFieldSkeleton = require("./NotificationsSelectorPerFieldSkeleton")

const NotificationsSelectorPerField = ({handleRadioButtonClicked, userFieldsPermissions, allowAlertsPermissions, loadingData}) => {
    const {t} = useTranslation();

    useEffect(() => {

        $(function () {
            $('[data-toggle="tooltip"]').tooltip({offset: '0,6'})
        })
    }, [])


    const onSelectNotificationType = (radioButtonClicked, fieldID = "") => {

        const radioButton = radioButtonClicked.target;
        let changedData = {"notifications": {"notification_type": radioButton.id}};

        if (fieldID === "") {
            handleRadioButtonClicked(changedData, Object.keys(userFieldsPermissions));
            mixpanel.track("Notification All Field Selection", {
                'Type': radioButton.id,
        });

        } else {
            handleRadioButtonClicked(changedData, [fieldID]);
            mixpanel.track("Notification Field Change", {
                'Type': radioButton.id,
                'Field Name': userFieldsPermissions[fieldID].title
            });
        }

    }

    const checkAllFieldsPermissions = (buttonID) => {

        if (userFieldsPermissions === {}) return false;

        else if (Object.keys(userFieldsPermissions).length == 0) return false;

        else {
            for (const field in userFieldsPermissions) {

                if (!userFieldsPermissions[field].permissions.hasOwnProperty("notification_type") && ((buttonID === "recommendations" && !allowAlertsPermissions) ||buttonID === "all" && allowAlertsPermissions )) continue;

                else if ((!userFieldsPermissions[field].permissions.hasOwnProperty("notification_type") || userFieldsPermissions[field].permissions.notification_type !== buttonID)) return false;

            }
            return true;
        }
    };

    const checkFieldPermissions = (buttonID, field_id) => {

        if (userFieldsPermissions === {}) return false;

        if (!userFieldsPermissions[field_id].permissions.hasOwnProperty("notification_type") && ((buttonID === "recommendations" && !allowAlertsPermissions) ||buttonID === "all" && allowAlertsPermissions )) return true;

        else if (userFieldsPermissions[field_id].permissions.hasOwnProperty("notification_type") && userFieldsPermissions[field_id].permissions.notification_type === buttonID) {
            return true
        } else return false;
    };

    return (
        <div style={{display: "flex", flexDirection: "column", marginTop: "32px"}}>
            <div className="text-grey45"
                 style={{fontSize: "14px", fontWeight: "600", marginBottom: "8px"}}>{t("field_notifications")}</div>
            <div style={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                border: "1px solid rgba(220, 224, 232, 1)",
                borderRadius: "5px",
                height: "624px"
            }}>
                <table style={{display: "grid"}}>
                    <thead style={{
                        boxShadow: " 0px 1px 4px rgba(146, 158, 181, 0.1), 0px 4px 8px rgba(145, 157, 180, 0.18)",
                        position: "sticky",
                        top: "0px",
                        backgroundColor: "white",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px"
                    }}>
                    <tr style={{
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        paddingLeft: "16px",
                        paddingRight: "36px",
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "bold",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        alignItems: "flex-start",
                        borderBottom: "1px solid rgba(220, 224, 232, 1)"
                    }}>

                        <th className="text-grey71 mr-auto "
                            style={{borderTopLeftRadius: "5px", letterSpacing: "0.25px"}}>{t('field').toUpperCase()}</th>
                        <th className="text-grey71 d-flex justify-content-center"
                            style={{width: "120px", letterSpacing: "0.25px"}}>{t("none").toUpperCase()}</th>
                        {allowAlertsPermissions &&
                            <th className="text-grey71 d-flex justify-content-center"
                            style={{width: "120px", letterSpacing: "0.25px", alignItems: "center", gap: "4px"}}>{t("alerts").toUpperCase()}<img data-toggle="tooltip"
                                                                                              data-placement="top"
                                                                                              title={t("alerts_tooltip")}
                                                                                              style={{ width: "14px", height: "14px"}}
                                                                                              src={require('../../resources/images/icons/info_icon.svg')}/></th>}
                        <th className="text-grey71 d-flex justify-content-center"
                            style={{width: "120px", letterSpacing: "0.25px", alignItems: "center", gap: "4px"}}>{t("recommendations").toUpperCase()}<img data-toggle="tooltip"
                                                                                              data-placement="top"
                                                                                              title={t("recommendations_tooltip")}
                                                                                              style={{ width: "14px", height: "14px"}}
                                                                                              src={require('../../resources/images/icons/info_icon.svg')}/>
                        </th>
                        {allowAlertsPermissions &&
                            <th className="text-grey71 d-flex justify-content-center"
                            style={{width: "120px", letterSpacing: "0.25px"}}>{t("all").toUpperCase()}</th>}
                    </tr>
                    {!loadingData && <tr style={{
                        paddingLeft: "16px",
                        paddingRight: "36px",
                        display: "flex",
                        fontSize: "14px",
                        fontWeight: "bold",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        alignItems: "flex-start"
                    }}>
                        <td className="text-grey45 font-weight-bold mr-auto">{t("select_all")}</td>
                        <td className="d-flex justify-content-center" style={{width: "120px", padding: "4px 2px"}}>
                            <input style={{width: "16px", height: "16px"}} checked={checkAllFieldsPermissions("none")}
                                   onChange={(radioButtonClicked) => onSelectNotificationType(radioButtonClicked)}
                                   name="select_all" id="none" type="radio"/></td>

                        {allowAlertsPermissions &&
                            <td className="d-flex justify-content-center" style={{width: "120px", padding: "4px 2px"}}>
                            <input style={{width: "16px", height: "16px"}} checked={checkAllFieldsPermissions("alerts")}
                                   onChange={(radioButtonClicked) => onSelectNotificationType(radioButtonClicked)}
                                   name="select_all" id="alerts" type="radio"/></td>}

                           <td className="d-flex justify-content-center" style={{width: "120px", padding: "4px 2px"}}>
                            <input style={{width: "16px", height: "16px"}} checked={checkAllFieldsPermissions("recommendations")}
                                   onChange={(radioButtonClicked) => onSelectNotificationType(radioButtonClicked)}
                                   name="select_all" id="recommendations" type="radio"/></td>

                        {allowAlertsPermissions &&
                            <td className="d-flex justify-content-center" style={{width: "120px", padding: "4px 2px"}}>
                            <input style={{width: "16px", height: "16px"}} checked={checkAllFieldsPermissions("all")}
                                   onChange={(radioButtonClicked) => onSelectNotificationType(radioButtonClicked)}
                                   name="select_all" id="all" type="radio"/></td>}
                    </tr>}
                    </thead>
                    <tbody style={{overflowY: "scroll", height: "544px"}}>

                       {!loadingData ? Object.keys(userFieldsPermissions).map((fieldID, index) => {
                        return (
                            <tr style={{
                                paddingLeft: "16px",
                                paddingRight: "36px",
                                display: "flex",
                                fontSize: "14px",
                                fontWeight: "bold",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                alignItems: "flex-start",
                                borderBottom: "1px solid rgba(220, 224, 232, 1)"
                            }}>
                                <td className="text-grey45 font-weight-bold mr-auto">{userFieldsPermissions[fieldID].title}</td>
                                <td className="d-flex justify-content-center"
                                    style={{width: "120px", padding: "4px 2px"}}><input style={{width: "16px", height: "16px"}} name={fieldID}
                                                                                        onChange={(radioButtonClicked) => onSelectNotificationType(radioButtonClicked, fieldID)}
                                                                                        checked={checkFieldPermissions("none", fieldID)}
                                                                                        id="none" type="radio"/></td>
                                {allowAlertsPermissions &&
                                    <td className="d-flex justify-content-center"
                                    style={{width: "120px", padding: "4px 2px"}}><input style={{width: "16px", height: "16px"}} name={fieldID}
                                                                                        onChange={(radioButtonClicked) => onSelectNotificationType(radioButtonClicked, fieldID)}
                                                                                        checked={checkFieldPermissions("alerts", fieldID)}
                                                                                        id="alerts" type="radio"/></td>}
                                   <td className="d-flex justify-content-center"
                                    style={{width: "120px", padding: "4px 2px"}}><input style={{width: "16px", height: "16px"}} name={fieldID}
                                                                                        onChange={(radioButtonClicked) => onSelectNotificationType(radioButtonClicked, fieldID)}
                                                                                        checked={checkFieldPermissions("recommendations", fieldID)}
                                                                                        id="recommendations" type="radio"/></td>
                                   {allowAlertsPermissions &&
                                       <td className="d-flex justify-content-center"
                                       style={{width: "120px", padding: "4px 2px"}}><input style={{width: "16px", height: "16px"}} name={fieldID}
                                                                                        onChange={(radioButtonClicked) => onSelectNotificationType(radioButtonClicked, fieldID)}
                                                                                        checked={checkFieldPermissions("all", fieldID)}
                                                                                        id="all" type="radio"/></td>}
                            </tr>
                        )
                    }) : <NotificationsSelectorPerFieldSkeleton displayAlerts={allowAlertsPermissions}/>}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

module.exports = connect()(withTranslation()(NotificationsSelectorPerField));