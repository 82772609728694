var React = require('react');
var {useState, useEffect, memo, useCallback, useMemo} = require('react');
var {connect, useSelector} = require('react-redux');
var moment = require('moment');
var { withTranslation } = require('react-i18next');
var { useTranslation } = require('react-i18next');
var planningManager = require('@managers/planningManager');
var fieldManager = require('@managers/fieldManager');
var {sensorsMap} = require('sensorsManager');
var {NavigationBar} = require('../general/NavigationBar');
var {fertilizerNameToDisplay} = require('nitrogenManager');
const {ReportEventModal} = require("ReportEventModal");
var {DeleteModal} = require('../general/Modal/DeleteModal');
var {upper, calculateDischargeDeviation, DISCHARGE_ALERT_THRESHOLD} = require('helpers');
var {Filter} = require("Filter");
const $ = require("jquery");




const choices = ["coming up","reported"]

const ComingUp = memo(({plans, timezone}) => {
    const {t} = useTranslation();
    let noPlannerEvents = <div
        className="p-3 text-center w-100 h-100 text-size-18 font-weight-semi-bold d-flex align-items-center justify-content-center"
        style={{whiteSpace: "pre-line", color: "#A8ABB7"}}>
        {t('no_planner_events')}
    </div>
    return (
        <div>
            {
                (plans.length === 0 || !timezone) ? noPlannerEvents : plans[plans.length - 1].startDate < moment().unix() ? noPlannerEvents :
                    plans.map(item => {
                        return <EventRow key={item.planType + item.startDate}
                                         type={item.planType}
                                         startDate={item.startDate}
                                         value={item.value}
                                         extraData={{'endDate': item.endDate, 'duration': item.duration, 'fertType': item.fertType, 'wtrType': item.wtrType}}
                                         units={item.units}
                                         timezone={timezone}
                                         event={"coming_up"}
                                         shouldDisplayHover={false}
                        />
                    })}
        </div>
    )

})

const ReportedEvents = memo(({filteredReportedEvents, timezone}) => {

        const { t } = useTranslation();
        const [dischargeExtraData, setDischargeExtraData] = useState([]);
        const selectedField = useSelector((state) => state.distribution_data.selected_entities.field);

        useEffect(() => {
            if (selectedField !== null && selectedField?.historical_data?.hasOwnProperty("discharge")) {
                let testsExtraData = calculateDischargeDeviation({}, selectedField?.historical_data?.discharge);
                setDischargeExtraData(testsExtraData);
            }
        }, [filteredReportedEvents])


        let noReportedEvents = <div
            className="p-5 text-center w-100 h-100 text-size-18 font-weight-semi-bold d-flex align-items-center justify-content-center"
            style={{whiteSpace: "pre-line", color: "#A8ABB7"}}>
            {t("no_reported")}
        </div>

        return (
            <div className='align-items-start w-100 h-100'>
                {(filteredReportedEvents.length === 0 || !timezone) ? noReportedEvents :
                    filteredReportedEvents.sort((ev1, ev2) => {
                        return ev2.startDate - ev1.startDate
                    }).map(item => {
                        let dischargeItemIndex = dischargeExtraData?.findIndex((dischargeItem) => {
                            return dischargeItem.date === item.startDate
                        });
                        let displayAlert = false;
                        if (item.type === 'discharge' && dischargeExtraData[dischargeItemIndex]?.relationDiff < DISCHARGE_ALERT_THRESHOLD) {
                            displayAlert = true;
                        }
                        return (<EventRow key={item.type + item.startDate}
                                         type={item.type}
                                         startDate={item.startDate}
                                         value={item.value}
                                         extraData={{'endDate': item?.endDate, 'duration': item?.duration, 'fertType': item?.fertType, 'wfrValue': item?.wfrValue, 'wfrEndDate': item?.wfrEndDate, 'sensorId': item?.sensorId, 'wtrType': item?.wtrType}}
                                         units={item.unit}
                                         timezone={timezone}
                                         event={"reported"}
                                         source={item.source}
                                         manufacturer={item?.manufacturer}
                                         sensorID={item?.sensor_id}
                                         shouldDisplayHover={true}
                                         displayAlert={displayAlert}
                        />)
                    })}
            </div>
    )
})

const EventRow = memo(({type, startDate, value, extraData, units, timezone, event, source, manufacturer, sensorID, shouldDisplayHover, displayAlert}) => {

    const [backgroundColor, setBackgroundColor] = useState("#FFFFFF")
    const [isEventHovered, setIsEventHovered] = useState(false);
    let sourceID = "";

    if (source === "manual") {
        sourceID = source;
    } else {
        sourceID = sensorID;
    }
    const item = [type, source, startDate, extraData?.wfrValue, extraData?.wfrEndDate, extraData?.sensorId, sourceID]
    let dischargeEvent = ['discharge'].includes(type);
    let dischargeLessThan12Hours = moment.unix(startDate).add(12, 'hours').isBefore(moment());

        useEffect(() => {
        $(function () {$('[data-toggle="tooltip"]').tooltip();})
    }, [])

    IMAGES_MAP = {
        "irr": require('images/icons/planner/irrigation_icon.svg'),
        "fert": require('images/icons/planner/fert_icon.svg'),
        "precipitation": require('images/icons/planner/rain.svg'),
        "plan_hcl": require('images/icons/planner/hcl_icon.svg'),
        "plan_hpo": require('images/icons/planner/hpo_icon.svg'),
        "plan_note": require('images/icons/planner/note_icon.svg'),
        "discharge": require('images/icons/discharge_icon_.svg'),
        "note": require('images/icons/planner/note_icon.svg'),
        "wtr": require('images/icons/planner/water_treatment.svg'),
    }
    type = type.replace("_plan", "");
    if (type === "fertilization" || type === "plan_fert") {
        type = "fert"
        source = fertilizerNameToDisplay(extraData.fertType) || source;
    } else if (type === "wtr" || type === "plan_wtr") {
        type = "wtr"
        source = planningManager.waterTreatmentDisplayName[extraData.wtrType] || source;
    } else if (type === "irrigation" || type === "plan_irr") {
        type = "irr";
        let irrSource = source + manufacturer;
        source = sensorsMap.get(irrSource);
    } else if (type === "precipitation_daily"){
        type = "precipitation"
        source = null
    } else if (type === "discharge"){
        source = source === "manual" ? "Discharge Test" : sensorsMap.get(source);
        value = "Discharge " + parseFloat(value)?.toFixed(2);
        units = `(${units})`
    }

    const { t } = useTranslation();

    const onStartHover = () => {

        if (shouldDisplayHover && type !== "precipitation" && (!dischargeEvent || (dischargeEvent && !dischargeLessThan12Hours))) {
            setBackgroundColor("#F6F8FA");
            setIsEventHovered(true);
        }
    }

    const onStopHover = () => {

        if (shouldDisplayHover && type !== "precipitation" && (!dischargeEvent || (dischargeEvent && !dischargeLessThan12Hours))) {
            setBackgroundColor("#FFFFFF");
            setIsEventHovered(false);
        }
    }

    dateTitle = fieldManager.getEventDateTitle(startDate, extraData?.endDate, timezone, event);
    dateFormat =  moment.unix(parseInt(startDate)).tz(timezone).format("MMM D, hh:mma");
    hourFormat = moment.unix(parseInt(startDate)).tz(timezone).format("hh:mma")
    duration = fieldManager.getEventDurationLine(extraData);
    source = source ? source : undefined;

    let valueClassName = type === 'plan_note' ? "text-size-14" : "font-weight-bold text-size-16";

    let dayTitle = dateTitle !== ' ' ?
                        <div className="d-flex align-items-start font-weight-semi-bold text-size-14 text-grey45 bg-grey96 d-inline" style={{padding:"1px 6px", gap:"8px", borderRadius:"3px", height:"22px"}}>
                            {dateTitle + ' ' + hourFormat}
                        </div>
                     :
                        <div className="font-weight-normal text-size-14 text-grey71">
                            {dateFormat}
                        </div>

    if (dateTitle == "") {
        return <div></div>
    } else {
        return (
            <div onMouseEnter={onStartHover} onMouseLeave={onStopHover} className="d-flex align-self-stretch flex-grow-0" style={{
                height: "64px",
                borderBottom: "1px solid #EEF1F6",
                boxSizing: "border-box",
                padding: "16px",
                gap: "10px",
                backgroundColor: backgroundColor
            }}>
                <div className="d-flex flex-grow-1 align-items-center" style={{gap:"16px", width:"228px"}}>
                    <div className="d-flex align-items-center">
                        <img src={IMAGES_MAP[type]}/>
                    </div>
                    <div className="d-flex justify-content-center align-items-start "
                         style={{gap:"2px"}}>
                        {duration.length === 0 ?
                            <div className="text-grey45 d-flex justify-content-start"
                                 style={{"borderRadius": "5px", "height": "42px"}}>
                                <div className="overflow-hidden align-self-center" style={{maxHeight:"42px", lineHeight:"20px", width:"188px"}}>
                                    <span className={valueClassName}>{value+" "}</span>
                                    <span className="font-weight-normal text-size-16">{units}</span>
                                    {displayAlert && <img data-toggle="tooltip" style={{marginLeft: "4px"}}
                                          data-placement="top" title={t("discharge_result_exceeding_hint")}
                                          src={require('images/icons/red_alert_icon.svg')}/>}
                                    {source && <div style={{color:"#919DB4", fontSize:14, fontWeight:400, lineHeight:1.3}}>{source}</div>}
                                </div>
                            </div>
                            :
                            <div className="d-flex w-100" style={{flexDirection:"column"}}>
                                    <div style={{flexDirection:"row"}} className="text-grey45 justify-content-center" >
                                        <span className="font-weight-bold text-size-16">{value+" "}</span>
                                        <span className="font-weight-normal text-size-16">{units + " / "}</span>
                                        <span className="font-weight-bold text-size-16">{duration[0]}</span>
                                        <span className="font-weight-normal text-size-16">{duration[1]}</span>
                                        {duration.length > 2 ?
                                            <span className="font-weight-bold text-size-16">{duration[2]}</span> : null}
                                        {duration.length > 2 ? <span
                                            className="font-weight-normal text-size-16">{duration[3]}</span> : null}
                                    </div>
                                    {source && <div style={{ color:"#919DB4", fontSize:14, fontWeight:400, lineHeight:1}}>{source}</div>}

                            </div>
                        }
                    </div>

                </div>

                <div className="d-flex justify-content-end align-items-center p-0"
                     style={{width: "132px", gap: "8px", overflow: "hidden"}}>{dayTitle}</div>

                {/*display the delete icon only for the hovered item*/}
                {(isEventHovered && type !== "precipitation" && (!dischargeEvent || (dischargeEvent && !dischargeLessThan12Hours)) && shouldDisplayHover) ?
                    <div className={"position-absolute d-flex flex-row-reverse align-items-center"} style={{
                        width: "117px",
                        right: "12px",
                        background: "linear-gradient(270deg, #F6F8FA 50%, #F6F8FA 50%, rgba(246, 248, 250, 0) 100%)",
                        cursor: "pointer"
                    }}>
                        <div>
                            <div className="mr-3" data-toggle="modal" data-target="#deleteModal" data-values={item}>
                                <img src={require('images/icons/trash_icon.svg')}/>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        );
    }
})

function NextActionsContainer({selectedField, timezone, parentHeight, heigtAuto, fieldHasPlan, displayNitrogen}) {
  const [plans, setPlans] = useState([]);
  const [filteredComingUpEvents, setFilteredComingUpEvents] = useState([]);
  const [reportedEvents, setReportedEvents] = useState([]);
  const [filteredReportedEvents, setFilteredReportedEvents] = useState([]);
  const [selectedEventsType, setSelectedEventsType] = useState(fieldHasPlan ? "coming up" : "reported");
  const [filterModalPosition, setFilterModalPosition] = useState("244px");
  const userData = useSelector((state) => state.authentication_data.user_data);
  const { t, i18n } = useTranslation();
  let isArchive = selectedField?.settings?.field_state === "archived";
  let titleColor = isArchive ? " text-grey71" : " text-grey45";

  const EVENT_TYPES_DICTIONARY = useMemo(() => {
    return {"discharge": t("discharge_test"),
    "precipitation_daily": "Rain",
    "plan_irr": t("irrigation"),
    "plan_fert": t("fertilization"),
    "fertilization": t("fertilization"),
    "irrigation": t("irrigation"),
    "wtr": t("planner_water_treatment")}
},[t])

    useEffect(() => {
          if (selectedField?.historical_data && Object.keys(selectedField.historical_data).find(property => ['wfr_length', 'fert', 'precipitation_daily', 'discharge'].includes(property))) {
              let res = fieldManager.historical_data_to_array(selectedField.historical_data)
              const filtered = res.filter(x => x.type != "precipitation")
              setReportedEvents(filtered);
              setFilteredReportedEvents(filtered)
          } else {
              setReportedEvents([]);
          }
      }, [selectedField?.historical_data])

  const initNextActionEvents = () => {
      let daysAhead = 45;
      planningManager.getPlannerItems(selectedField?.id, userData, daysAhead, handlePlansChange);
  }

  const getComingUpTypes = useCallback((eventsList) => {
     const comingUpUniqueTypes = new Set();
            eventsList.forEach(obj => {
                if (obj.startDate < moment().unix()) return;
                const objType = obj.planType;
                comingUpUniqueTypes.add(objType);
            });
            return (Array.from(comingUpUniqueTypes).filter((event)=>{return !["plan_hcl","plan_hpo","plan_note"].includes(event)}).map((event) => {
                return {type: event, isActive: true}
            }));
  },[])

  const getReportedTypes = useCallback((eventsList) => {
        const reportedUniqueTypes = new Set();
        eventsList.forEach(obj => {
            const objType = obj.type;
            reportedUniqueTypes.add(objType);
        });
        return(Array.from(reportedUniqueTypes).map((event) => {
            return {type: event, isActive: true}
        }));
  },[])

  const setActiveTypesList = (updatedTypes) => {
      if (updatedTypes.length > 0) {
            const active = updatedTypes.map((obj) => {
                if (obj.isActive) {
                    return obj.type
                }
            })
            filterEventsList(active);
        }
  }

  const filterEventsList = (activeTypes) => {

        if (selectedEventsType === "reported"){
            const filteredReportedValues = reportedEvents.filter((value) => activeTypes.includes(value.type))
            setFilteredReportedEvents(filteredReportedValues)

        } else if (selectedEventsType === "coming up") {
            const filteredComingValues = plans.filter((plan) => activeTypes.includes(plan.planType))
             setFilteredComingUpEvents(filteredComingValues)
        }
  }

    const handlePlansChange = (plans) => {
        setPlans(plans);
        setFilteredComingUpEvents(plans);
    }

    useEffect(() => {

        if (selectedField && fieldHasPlan && userData) {
            initNextActionEvents();
        }
        setSelectedEventsType(fieldHasPlan ? "coming up" : "reported")
    }, [selectedField, fieldHasPlan, userData]);

    useEffect(() => {

        const updateWindowDimensions = () => {
            const newWidth = window.innerWidth;
            if (newWidth < 1536) {
                setFilterModalPosition("285px");
            } else {
                setFilterModalPosition("244px");
            }
        };

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)

    }, [])

    let nextActionsStyle = heigtAuto ? "overflow-auto d-flex flex-column align-items-start w-100 nextActionsContainer list-group-item p-0" : "overflow-auto d-flex flex-column align-items-start w-100 list-group-item p-0"
    let comingUpListStyle = filteredComingUpEvents.length === 0 ? "d-flex w-100 h-100 justify-content-center align-items-center" : filteredComingUpEvents[filteredComingUpEvents.length - 1].startDate < moment().unix() ? "d-flex w-100 h-100 justify-content-center align-items-center" : "d-block w-100";
    let reportedListStyle = "d-flex w-100 h-100 justify-content-center align-items-center" ;

    const reportedTypes = useMemo(() => getReportedTypes(reportedEvents),[reportedEvents])

    const comingUpTypes = useMemo(() =>getComingUpTypes(plans),[plans])


    const selectedTypesList = useMemo(() => {
        switch (selectedEventsType) {
            case "reported":
                return reportedTypes
            
            case "coming up":
                return comingUpTypes
            default:
                return [];
        }
    },[selectedEventsType,reportedTypes,comingUpTypes])

    return (
        <div>
             <div className='flex row justify-content-between ml-1 mr-1'>
                 <div className={"text-size-16 font-weight-bold d-flex " + titleColor} style={{
                     paddingBottom: "8px",
                     flexDirection: "row"
                 }}>{upper(t(fieldHasPlan ? 'events_list' : 'reported'))}
                     <div className={"font-weight-normal"} style={{marginTop:"-5px"}}>
                         {!fieldHasPlan &&
                             <Filter typesList={selectedTypesList} 
                                     setTypesList={setActiveTypesList}
                                     typesDictionary={EVENT_TYPES_DICTIONARY}
                                     event={"reported"} style={{
                                 "icon": {
                                     "height": "32px", "marginLeft": "4px", top: "18px"
                                 }, list: {left: displayNitrogen ? "470px" : "89px", top: displayNitrogen ? filterModalPosition : "52px"}
                             }}/>}
                     </div>
                 </div>

                 <div type="button" className="d-flex mb-2 bg-blue-primary align-items-center"
                      style={{borderRadius: 3, height: 24}} data-toggle="modal" data-target="#reportEventModal">
                     <span
                         className="text-size-14 font-weight-semi-bold text-white pl-4 pr-4">{upper(t('report_event'))}</span>
                 </div>
             </div>
        <div className="d-flex bg-white" style={{"borderRadius": "5px", border:"1px solid #DCE0E8", overflowX:"hidden"}}>
            <ReportEventModal t={t} i18n={i18n}/>
            <DeleteModal t={t}/>
            <div className={nextActionsStyle} style={{height: parentHeight, border:"none", zIndex:1}}>
                {fieldHasPlan && <NavigationBar typesDictionary={EVENT_TYPES_DICTIONARY} setTypesList={setActiveTypesList} setEvent={setSelectedEventsType} event={selectedEventsType} choices={choices} reportedTypes={reportedTypes} comingUpTypes={comingUpTypes}/>}
                {fieldHasPlan && <div style={{overflowY:"scroll"}} className={selectedEventsType === "coming up" ? comingUpListStyle : "d-none"}><ComingUp plans={filteredComingUpEvents} timezone={timezone}/></div>}
                <div className={selectedEventsType === "reported" ? reportedListStyle : "d-none"}><ReportedEvents filteredReportedEvents={filteredReportedEvents} timezone={timezone}/></div>
            </div>
        </div>
            </div>
    );
}

module.exports = withTranslation()(NextActionsContainer);

