var React = require('react');
var {connect} = require('react-redux');
var { Link, BrowserRouter, Switch, Route, Redirect, withRouter } = require ('react-router-dom');

class LeftSideBar extends React.Component {

  constructor(props) {
      super(props);
  }

  render() {
    if (this.props.shouldDisplayNavigation == false) {
        return (<div></div>)
    }

    const pathname = this.props.history.location.pathname;

    const dashboard_bg = pathname == "/" ? "bg-grey96" : "";
    const account_bg = pathname.startsWith("/Account") ? "bg-grey96" : "";
    const settings_bg = pathname.startsWith("/Settings") ? "bg-grey96" : "";

    return (

      <div className="bg-pale-grey-two card-shadow d-none d-sm-none d-md-block" style={{'position':'fixed', 'width': '50px', 'top':(this.props.headerHeight + this.props.seperatorHeight).toString() + "px", 'height':'100%', 'left':'0px'}}>

          <Link to="" className="p-0" style={{'borderRadius': '0px'}} >
                <div className={"d-flex justify-content-center align-items-center " + dashboard_bg} style={{'width':'50px', 'height':'50px'}}>
                  <img src={ require('images/icons/left_sidebar/dashboard_icon.svg') } className="img-fluid" alt="edit field"/>
                </div>
          </Link>

          <Link to="Account#" className="p-0" style={{'borderRadius': '0px'}}>
                <div className={"d-flex justify-content-center align-items-center " + account_bg} style={{'width':'50px', 'height':'50px'}}>
                  <img src={ require('images/icons/left_sidebar/account_icon.svg') } className="img-fluid" alt="edit field"/>
                </div>
          </Link>

          <Link to="Settings#" className="p-0" style={{'borderRadius': '0px'}}>
                <div className={"d-flex justify-content-center align-items-center " + settings_bg} style={{'width':'50px', 'height':'50px'}}>
                  <img src={ require('images/icons/left_sidebar/settings_icon.svg') } className="img-fluid" alt="edit field"/>
                </div>
          </Link>

      </div>
    );
  }
};

const mapStateToProps = function(state){

  return {
    // user_details: state.authentication_data.user_details,
  }
}

module.exports = withRouter(connect(mapStateToProps)(LeftSideBar));
