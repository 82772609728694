var React = require('react');
var {connect} = require('react-redux');
var distributionActions = require('distributionActions');
var timezoneManager = require('timezoneManager');
var DayPickerInput = require('react-day-picker/DayPickerInput').default;
var GeneralClasses = require('GeneralClasses');
var moment = require('moment');
var {upper, sort_dictioanry_by_value} = require('helpers');
require('react-day-picker/lib/style.css');
var env = require('env');
var {unitDisplayName, convert} = require('unitsManager');
var sensorsManager = require('sensorsManager');
var fieldManager = require('fieldManager');
var KCBox = require('KCBox');
var CoupModelBox = require('CoupModelBox');
var CustomMap = require('CustomMap');
var { withTranslation } = require('react-i18next')
var $ = require('jquery');
var SensorsWrapper = require('SensorsWrapper');
var IrrigationSystem = require('IrrigationSystem');
var IrrigationTimeFrame = require('IrrigationTimeFrame');
var Fertigation = require('Fertigation');

var FARMING_TYPES_DICTIONARY = {
    "open_field": "Open field",
    "tunnel": "Tunnel",
    "greenhouse": "Greenhouse",
    "net_house": "Net house",
    "lab": "Lab",
    "net_house_tali_grapes": "Net house Tali grapes",
    "net_house_meshek_malka": "Net house Meshek Malka",
    "thermal_blanket": "Thermal Blanket",
}

class UpdateFieldModal extends React.Component {

  constructor(props) {
      super(props);

      this.onSubmitClick = this.onSubmitClick.bind(this);

      this.calculateArea = this.calculateArea.bind(this);
      this.onLayerSelection = this.onLayerSelection.bind(this);
      this.onAreaChanged = this.onAreaChanged.bind(this);
      this.handleSowDateChange = this.handleSowDateChange.bind(this);
      this.handleIrrigationDateChange = this.handleIrrigationDateChange.bind(this);
      this.handleHarvestDateChange = this.handleHarvestDateChange.bind(this);
      this.handleCoupModelSelection = this.handleCoupModelSelection.bind(this);
      this.handleNewExpirationDay = this.handleNewExpirationDay.bind(this);
      this.handleSoilConcentrationChange = this.handleSoilConcentrationChange.bind(this);
      this.handleFertigationConcentrationChange = this.handleFertigationConcentrationChange.bind(this);
      this.handleAutomateIrrigation = this.handleAutomateIrrigation.bind(this);
      this.handleFertigationPlanChange = this.handleFertigationPlanChange.bind(this);

      this.shouldDisableCropType=this.shouldDisableCropType.bind(this);

      this.handleIrrigationSystemTypeChange = this.handleIrrigationSystemTypeChange.bind(this);
      this.handleIrrigationSystemRateChange = this.handleIrrigationSystemRateChange.bind(this);
      this.handleIrrigationTimeFrameChange = this.handleIrrigationTimeFrameChange.bind(this);
      this.handleTankTypeChange = this.handleTankTypeChange.bind(this);
      this.handleMeasuredRateStatusChange = this.handleMeasuredRateStatusChange.bind(this);
      this.onFieldSizeChange = this.onFieldSizeChange.bind(this);
      this.autoFill = this.autoFill.bind(this);
      this.handleSoilTypeChange = this.handleSoilTypeChange.bind(this);
      this.handleFarmingTypeChange = this.handleFarmingTypeChange.bind(this);

      this.state = {title:'',
                    action:'',
                    invalid_map_coords_message:'',
                    current_field:new GeneralClasses.Field(),
                    areaSize:'',
                    soil_type:""};

      this.timezones = timezoneManager.get_timezones();
      this.timezone_array_sorted = sort_dictioanry_by_value(this.timezones);

      this.handleKCTableChange = this.handleKCTableChange.bind(this);
      this.handleCropDetailsChange = this.handleCropDetailsChange.bind(this);

      this.handleSensorsChange = this.handleSensorsChange.bind(this);
      this.handleWFRSensorsChange = this.handleWFRSensorsChange.bind(this);
      this.checkValuesChangesInField = this.checkValuesChangesInField.bind(this);
  }



  componentDidMount() {
    var that = this;

    let soil_data=[];

    $("#" + this.props.modalId).on('shown.bs.modal', function (event) {
        let action_type = "";
        if (event.relatedTarget) {
            let button = $(event.relatedTarget)
            action_type = button[0].dataset?.data;
        }
        if (action_type === "duplicate" || that.state.action === "Update"|| that.state.action === that.props.t('update')|| action_type === "activate") {
            that.custom_map.invalidate({
                [that.state.current_field.id]: {
                    areas: that.state.current_field.geo_data.areas,
                    title: that.refs.field_name.value,
                    fill_color: '#FFFFFF'
                }
            }, {}, {}, true);
        } else {
            that.custom_map.invalidate({}, {}, {}, true);
        }
    });

    $("#" + this.props.modalId).on('show.bs.modal', function (event) {
      var form = document.getElementById("distribution-form");
      form.classList.remove('was-validated');

      var field_id = "";
      var action_type = "";
      var selected_field = null;
      if (event.relatedTarget) {
        var button = $(event.relatedTarget) // Button that triggered the modal
        field_id = button[0].dataset.id; // Extract info from data-* attributes
        action_type = button[0].dataset?.data; // check if duplicate/activate is pressed?
      }

      if (field_id == "") {
        selected_field = new GeneralClasses.Field();
        that.setState({
          title: that.props.t('create'),
          action:that.props.t('create'),
          invalid_map_coords_message:'',
          current_field:selected_field,
          soil_type:selected_field.soil_type});

      } else {
        // copy object
        selected_field = Object.assign({}, that.props.selected_farming_unit.fields[field_id]);
        let areaSize = that.calculateArea(selected_field.geo_data.areas);

        if (selected_field.geo_data.area_size >0 && selected_field.geo_data.area_size !== areaSize ) {
            areaSize = selected_field.geo_data.area_size;
        }

        that.setState({
          areaSize: areaSize,
          title:action_type === "duplicate" || action_type === "activate" ? that.props.t('create') : that.props.t('update'),
          action:action_type === "duplicate" || action_type === "activate" ? that.props.t('create') :that.props.t('update') ,
          invalid_map_coords_message:'',
          current_field:selected_field,
          soil_type:selected_field.soil_type});
      }

        // set all fields with data
        if (action_type === "duplicate") {
            that.refs.field_name.value = "Copy of " + that.state.current_field.name;
            that.setState(prevState => ({
                areaSize: prevState.areaSize,
                title: prevState.title,
                action: prevState.action,
                invalid_map_coords_message: prevState.invalid_map_coords_message,
                soil_type:prevState.soil_type,
                current_field: {
                    ...prevState.current_field,
                    initial_concentrations:{},
                    irrigation_system:{
                        ...prevState.current_field.irrigation_system,
                        fertigation_concentrations: [],
                    },
                    contract_data: {
                        ...prevState.current_field.contract_data,
                        installation_date: moment().unix(),
                        expiration_date: moment().add(1, 'years').unix(),
                    }
                }
            }))
        } else if (action_type === "activate") {
            that.refs.field_name.value = that.state.current_field.name;
            that.setState(prevState => ({
                areaSize: prevState.areaSize,
                title: prevState.title,
                action: prevState.action,
                invalid_map_coords_message: prevState.invalid_map_coords_message,
                soil_type:prevState.soil_type,
                current_field: {
                    ...prevState.current_field,
                    settings: {
                        ...prevState.current_field.settings,
                        field_state: "active"
                    },
                    contract_data: {
                        ...prevState.current_field.contract_data,
                    }
                }
            }))
        } else {
            that.refs.field_name.value = that.state.current_field.name;
        }

    });

    $("#" + this.props.modalId).on('hidden.bs.modal', function (event) {

      that.custom_map.resetMap();
      that.props.clear_field_action();
    });


  }

  autoFill() {
    this.refs.field_name.value = "Auto generated field " + moment().unix();
    this.refs.soil_type_field.value = "Sand";

    this.setState(prevState => ({
        soil_type:prevState.soil_type,
        current_field: {
            ...prevState.current_field,
            crop_data: {
              ...prevState.current_field.crop_data,
              kc_table: {type:"byGrowthPhase", data:
                      {"byGrowthPhaseGDD":[{kc:1.15,name: "dummy phase", is_dev: false,duration: 30},
                              {is_dev: true,duration: 10},
                              {kc:0.9,name: "late", is_dev: false,duration: 70}],
                          "byGrowthPhase":[{kc:1.15,name: "dummy phase", is_dev: false,duration: 30},
              {is_dev: true,duration: 10},
              {kc:0.9,name: "late", is_dev: false,duration: 70}],
              "byMonth":
              {'jan':"1.01", 'feb':"1.02", 'mar':"1.15", 'apr':"1.15",
                'may':"1.16", 'jun':"1.20", 'jul':"1.03", 'aug':"1.03",
                'sep':"1.00", 'oct':"0.77", 'nov':"0.76", 'dec':"0.56"}
              }},
              sow_date: moment(1552214813333).format('YYYY-MM-DD'),
            },
            contract_data: {
              ...prevState.current_field.contract_data,
              installation_date:moment().unix(),
              expiration_date: moment().add(3, 'years').unix(),
            }
        }
    }));
  }

  calculateArea(areas) {
    // calculate area
    fieldArea = fieldManager.getFieldArea(areas);
    fieldAreaConverted = convert(fieldArea, "dunam", this.props.userData.area, "area");

    return fieldAreaConverted;
  }

  onAreaChanged(areas) {

      var message = this.state.invalid_map_coords_message;
      let area_size = this.calculateArea(areas);

      if (areas && areas.length > 0) {
        message = '';
      }

      this.setState(prevState => ({
          areaSize: area_size,
          invalid_map_coords_message:message,
          current_field: {
              ...prevState.current_field,
              geo_data: {
                ...prevState.current_field.geo_data,
                areas:areas,
                area_size:area_size,
                agriapi_polygon_ids:[]
              }
          }
      }));
  };

    onFieldSizeChange(newFieldSize) {
        this.setState(prevState => ({
            areaSize: newFieldSize,
            current_field: {
                ...prevState.current_field,
                geo_data: {
                    ...prevState.current_field.geo_data,
                    area_size: newFieldSize
                }
            }
        }));
    }

  handleSensorsChange(newSensorsObject) {
    this.setState(prevState => {
      return {
        current_field: {
            ...prevState.current_field,
            sensors: newSensorsObject
        }
    }
    });
  }

  handleWFRSensorsChange(newSensorsObject) {
    this.setState(prevState => ({
        current_field: {
            ...prevState.current_field,
            wfrsensors: newSensorsObject
        }
    }));
  }

  handleKCTableChange(newKCTable) {
    this.setState(prevState => ({
        current_field: {
            ...prevState.current_field,
            crop_data: {
              ...prevState.current_field.crop_data,
              kc_table: newKCTable,
            }
        }
    }));
  }

    handleSoilTypeChange(newSoilType) {
        this.setState(prevState => ({
            ...prevState,
            soil_type:newSoilType,
            current_field: {
                ...prevState.current_field,
                soil_type: newSoilType
            }
        }));
    }

        handleFarmingTypeChange(newFarmingType) {
            this.setState(prevState => ({
                ...prevState,
                current_field: {
                    ...prevState.current_field,
                    farming_type: newFarmingType
                }
            }));
    }

    handleSoilConcentrationChange(concentrations) {
        this.setState(prevState => ({
            current_field: {
                ...prevState.current_field,
                initial_concentrations: concentrations
            }
        }));
    }

    handleFertigationConcentrationChange(fcs) {
      let fcsPlain = fcs.map(currentItem => {
          return {
              start_date: currentItem.startDate.format("YYYY-MM-DD"),
              fertType: currentItem.fertType,
              concentration: parseFloat(currentItem.concentration),
          };
      });

        this.setState(prevState=>({
            current_field:{
                ...prevState.current_field,
                irrigation_system:{
                    ...prevState.current_field.irrigation_system,
                    fertigation_concentrations:fcsPlain
                }
            }
        }))
    }

    handleFertigationPlanChange(fertigationPlan) {
      console.log(fertigationPlan);
    }

    handleAutomateIrrigation(shouldAutomateState) {

        this.setState(prevState => ({
            current_field: {
                ...prevState.current_field,
                settings: {
                    ...prevState.current_field.settings,
                    coup_settings: {
                        should_automate_irrigations: shouldAutomateState
                    },
                }
            }
        }));
    }

    handleCoupModelSelection(coupModelState) {

        this.setState(prevState => ({
            current_field: {
                ...prevState.current_field,
                settings: {
                    ...prevState.current_field.settings,
                    is_coupmodel_active: coupModelState,
                }
            }
        }));
    }

  handleCropDetailsChange(crop_type, crop_variety, crop_feature) {
    this.setState(prevState => ({
        current_field: {
            ...prevState.current_field,
            crop_data: {
              ...prevState.current_field.crop_data,
              crop_type: crop_type,
              crop_variety: crop_variety,
              crop_feature: crop_feature,
            }
        }
    }));
  }

  handleSowDateChange(formattedDate) {

    this.setState(prevState => ({
        current_field: {
            ...prevState.current_field,
            crop_data: {
              ...prevState.current_field.crop_data,
              sow_date: formattedDate,
            }
        }
    }));
  }
  handleIrrigationDateChange(formattedDate) {
    this.setState(prevState => ({
        current_field: {
            ...prevState.current_field,
            crop_data: {
              ...prevState.current_field.crop_data,
              irrigation_start_date: formattedDate,
            }
        }
    }));
  }

    handleHarvestDateChange(formattedDate) {

        this.setState(prevState => ({
            current_field: {
                ...prevState.current_field,
                crop_data: {
                    ...prevState.current_field.crop_data,
                    harvest_date: formattedDate,
                }
            }
        }));
    }

  handleNewExpirationDay(day) {

    let newExpirationData = moment(day).unix();

    this.setState(prevState => ({
        current_field: {
            ...prevState.current_field,
            contract_data: {
              ...prevState.current_field.contract_data,
              expiration_date: newExpirationData,
            }
        }
    }));
  }

    handleRollingFitChange(event) {
        const rollingFitSupported = event.target.checked;

        this.setState(prevState => ({
            current_field: {
                ...prevState.current_field,
                settings: {
                    ...prevState.current_field.settings,
                    is_rolling_fit_active: rollingFitSupported,
                }
            }
        }));
    }

  handleIrrigationSystemTypeChange(type){
      this.setState(prevState=>({
          current_field:{
              ...prevState.current_field,
              irrigation_system:{
                  ...prevState.current_field.irrigation_system,
                  irrigation_type:type
              }
          }
      }))
  }
  handleTankTypeChange(tankType){
      this.setState(prevState=>({
          current_field:{
              ...prevState.current_field,
              irrigation_system:{
                  ...prevState.current_field.irrigation_system,
                  tank_type: tankType
              }
          }
      }))
  }
  handleMeasuredRateStatusChange(status){
      this.setState(prevState=>({
          current_field:{
              ...prevState.current_field,
              settings: {
                    ...prevState.current_field.settings,
                    is_measured_rate_active: status
                }
          }
      }))
  }
    handleIrrigationSystemRateChange(rate){
      this.setState(prevState=>({
          current_field:{
              ...prevState.current_field,
              irrigation_system:{
                  ...prevState.current_field.irrigation_system,
                  irrigation_rate:rate
              }
          }
      }))
  }

    handleIrrigationSystemHinChange(hin) {
        this.setState(prevState => ({
            current_field: {
                ...prevState.current_field,
                irrigation_system: {
                    ...prevState.current_field.irrigation_system,
                    irrigation_hin: hin
                }
            }
        }))
    }

  handleIrrigationTimeFrameChange(timeFrames){
      this.setState(prevState=>({
          current_field:{
              ...prevState.current_field,
              irrigation_system:{
                  ...prevState.current_field.irrigation_system,
                  time_frames:timeFrames
              }
          }
      }))
  }

  onSubmitClick(e) {

    e.preventDefault();
    e.stopPropagation();

    var form = document.getElementById("distribution-form");
    var should_submit = form.checkValidity();
    var current_field = this.state.current_field;

    // self checks for map values
    if (!current_field.geo_data.areas || (current_field.geo_data.areas && current_field.geo_data.areas.length == 0)) {
      this.setState({invalid_map_coords_message:this.props.t('edit_field_choose_area_message')});
      should_submit = false;
    } else {
      this.setState({invalid_map_coords_message:""});
    }
    // Local check (given by bootstrap)
    if (should_submit) {
      current_field.name = this.refs.field_name.value;
      current_field.cluster.id = this.refs.cluster_id.value;
      current_field.soil_type = this.state.soil_type;

        if (this.state.action == this.props.t('create')) {

            current_field.sensors = sensorsManager.serialNumberCheck(current_field.sensors)
            this.props.create_field(this.props.selected_distribution.id, this.props.selected_farming_unit.id, current_field);

        } else if (this.state.action == this.props.t('update')){
        this.props.update_field(this.props.selected_distribution.id, this.props.selected_farming_unit.id, current_field);

            // check for changes when update field to send the mixpanel
            let changes = this.checkValuesChangesInField();
            if (Object.keys(changes).length > 0) {
                mixpanel.track("Field Update (Event property)", {'Changes': changes});
            }
      }
    }
    form.classList.add('was-validated');

  }

   shouldDisableCropType(installationDate){
      const today = moment();
      const installDay = moment(installationDate);
      const duration = moment.duration(today.diff(installDay)).asDays();
      return duration > 60;
  }

    checkValuesChangesInField() {
        const current_field = this.state.current_field;
        const selected_field = this.props.selected_field;
        let changes = {};
        let keysToCheck = ['irrigation_system', 'settings', 'crop_data'];
        for (let key of keysToCheck) {

            if (key === "settings") {

                if (current_field[key].is_rolling_fit_active !== selected_field[key].is_rolling_fit_active) {
                    changes["is_planner_active"] = current_field[key].is_rolling_fit_active;
                }
            } else if (key === "crop_data") {

                if (current_field[key].sow_date !== selected_field[key].sow_date) {
                    changes["sow_date"] = current_field[key].sow_date;
                }
            } else if (key === "irrigation_system") {

                if (current_field[key].irrigation_rate !== selected_field[key].irrigation_rate) {
                    changes["old_irrigation_rate"] = selected_field[key].irrigation_rate;
                    changes["new_irrigation_rate"] = current_field[key].irrigation_rate;
                }

                if (current_field[key].irrigation_type !== selected_field[key].irrigation_type) {
                    changes["irrigation_type"] = current_field[key].irrigation_type;
                }
            }
        }
        return changes;
    }

  onLayerSelection(layerName) {
    }

  render() {
    const { t } = this.props;
    let is_moderator = this.props.selected_farming_unit?.role === "moderator";

    if ((this.props.field_actions.status == "success") && (["UPDATE", "CREATE"].includes(this.props.field_actions.type))) {

      $('#' + this.props.modalId).modal('hide');
    }

    var installation_date = this.state.current_field && this.state.current_field.contract_data.installation_date ? moment.unix(this.state.current_field.contract_data.installation_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    var expiration_date = this.state.current_field && this.state.current_field.contract_data.expiration_date ? moment.unix(this.state.current_field.contract_data.expiration_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");

    // var contract_duration = this.state.current_field && this.state.current_field.contract_data.installation_date && this.state.current_field.contract_data.expiration_date ? moment.unix(this.state.current_field.contract_data.expiration_date).diff(moment.unix(this.state.current_field.contract_data.installation_date), 'years') : 0;
    // const disableCropType = this.shouldDisableCropType(installation_date);
    const disableCropType = false;
    const measuredIrrigationRateObj = this.state.current_field?.historical_data?.measured_irrigation_rate;
    let measuredIrrigationRate = measuredIrrigationRateObj ?  Object.values(this.state.current_field?.historical_data?.measured_irrigation_rate?.values)[0] : null;
    return (

        <div className="modal fade mb-5 pb-5 text-grey45" id={this.props.modalId} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

            <form className="needs-validation" noValidate onSubmit={this.onSubmitClick} id="distribution-form" style={{height:'100%'}}>
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document" style={{top:10, maxHeight:"calc(100vh - 70px)", height:'100%'}}>
              <div className="modal-content bg-grey98" style={{alignSelf:'stretch'}}>
                <div className="modal-header" style={{background:"white",height:"72px"}}>
                  <h5 className="modal-title ml-2 font-weight-bold text-size-32" style={{lineHeight:"normal"}} id="exampleModalLabel">{this.state.title} {t('field')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{color:"#4D5674",opacity:1,outline:"none",padding:"24px"}}>
                        <img src={require('images/icons/edit_field/close_icon.svg')}/>
                    </button>
                </div>
                <div className="modal-body pt-0 pb-0" style={{overflowY:"auto", flex:1, height:'100%'}}>

                    <div className="form-row justify-content-center">
                      <div className="col">
                        <div className="form-group ml-2 mr-2 mt-3 font-weight-bold text-size-20">
                          <label style={{marginBottom:"24px"}} htmlFor="fieldName">{t('edit_field_field_name')}</label>
                          <input type="text" ref='field_name' className="form-control col-sm-4" placeholder={t('edit_field_field_name')} required/>
                            <div className="invalid-feedback">{t('edit_field_field_name_invalid_data_message')}</div>
                        </div>
                      </div>
                    </div>
                                        {!is_moderator &&
                    <div className="form-row justify-content-center">
                      <div className="col-12">
                        <div className="ml-2 mr-2 mt-1 font-weight-bold text-size-20" style={{pointerEvents:is_moderator ? "none" : 'auto'}}>
                          <label style={{marginBottom:"16px"}} htmlFor="sensors">{t('area')}</label>
                            <CustomMap mapId="edit_field" showEditToolBar={true}
                                       onRef={ref => (this.custom_map = ref)}
                                       map_height='400'
                                       onAreaChanged={this.onAreaChanged}
                                       onLayerSelection={this.onLayerSelection}
                                       message={this.state.invalid_map_coords_message}
                                       shouldPresentStressMenu={false}
                                       shouldPresentFilePicker={true}
                                       shouldPresentPlusButton={false}
                                       />
                                     <div className="d-flex">
                                       <div className="mr-auto" >
                             <small className="form-text text-grey71 text-size-14">
                               {t('edit_field_area_invalid_data_message')}
                             </small>
                             </div>
                             <div>
                               <small className="form-text text-grey71 text-size-14">

                             </small>
                             </div>
                              </div>
                        </div>
                      </div>
                    </div>}

                    <div className="form-row ">
                      <div className="col-12 col-lg-4">
                        <div className="form-group ml-1 mt-3 font-weight-semi-bold text-size-14" style={{marginRight:"4px"}}>
                          <label htmlFor="soilType" style={{marginBottom:"4px"}}>{t('soil_type')}</label>
                          <select defaultValue={t((this.state.soil_type).toLowerCase()) || t((this.state.current_field.soil_type).toLowerCase())} value={t((this.state.soil_type).toLowerCase()) || t((this.state.current_field.soil_type).toLowerCase())} onChange={(e)=>{this.handleSoilTypeChange(e.target.value)}}  className="form-control custom-select" id="exampleFormControlSelect1" style={{background:"none"}}>
                          {this.props.soil_data && this.props.soil_data.soil_data && Object.keys(this.props.soil_data.soil_data).filter(item => this.props.soil_data.soil_data[item].active === true).sort().map((item) => {
                            return <option value={item} key={item} >{t(upper(item))}</option>;
                          })}
                          </select>
                        </div>
                      </div>
                        <div className="col-12 col-lg-4">
                            <div className="form-group ml-1 mt-3 font-weight-semi-bold text-size-14"
                                 style={{marginRight: "4px", marginLeft: "4px"}}>
                                <label htmlFor="farming_type" style={{marginBottom: "4px"}}>{t('farming_type')}</label>
                                <select value={(Object.keys(FARMING_TYPES_DICTIONARY).filter((farming_type) => {
                                    return farming_type === this.state.current_field.farming_type
                                }))[0]} onChange={(e) => {
                                    this.handleFarmingTypeChange(e.target.value)
                                }} className="form-control custom-select" id="exampleFormControlSelect1"
                                        style={{background: "none"}}>
                                    {Object.values(FARMING_TYPES_DICTIONARY).sort().map((item) => {
                                        let key = Object.keys(FARMING_TYPES_DICTIONARY).filter(function (key) {
                                            return FARMING_TYPES_DICTIONARY[key] === item
                                        })[0];
                                        return <option value={key} key={key}>{item}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                           <div className="col-12 col-lg-4">
                        <div className="form-group ml-1 mr-2 mt-3 font-weight-semi-bold text-size-14">
                          <label htmlFor="fieldSize" style={{marginBottom:"4px"}}>{t('field_size') + ` (${unitDisplayName(this.props.userData.area)})`}</label>
                            <input type="number" className="form-control" id="field_size" onChange={(event)=>{this.onFieldSizeChange(event.target.value)}} value={this.state.areaSize} disabled={is_moderator} />
                        </div>
                      </div>
                    </div>

                    <div className="form-row justify-content-center">
                      <div className="col">


                          <KCBox selectedField={this.state.current_field} modalId={this.props.modalId} onKCTableChange={this.handleKCTableChange} onCropDetailsChange={this.handleCropDetailsChange} onIrrigationDateChange={this.handleIrrigationDateChange} onSowDateChange={this.handleSowDateChange} onHarvestDateChange={this.handleHarvestDateChange} disableCropType={disableCropType} isModerator={is_moderator}/>


                      </div>
                    </div>
                    {!is_moderator &&
                    <IrrigationSystem onMeasuredRateStatusChange={this.handleMeasuredRateStatusChange} onTankTypeChange={this.handleTankTypeChange} onIrrigationSystemTypeChange={this.handleIrrigationSystemTypeChange} onIrrigationSystemRateChange={this.handleIrrigationSystemRateChange} onNDripHinChange={(hin) => this.handleIrrigationSystemHinChange(hin)} initialHin={this.state.current_field.irrigation_system.irrigation_hin} initialRate={this.state.current_field.irrigation_system.irrigation_rate} initialType={this.state.current_field.irrigation_system.irrigation_type} initialMeasuredRate={measuredIrrigationRate} initialTankType={this.state.current_field.irrigation_system?.tank_type} initialMeasuredRateStatus={this.state.current_field.settings?.is_measured_rate_active}></IrrigationSystem>}

                    {!is_moderator &&
                    <div className="form-row justify-content-center">
                        <div className="col-12">
                            <div className="form-group ml-2 mr-2 mt-3">
                                <label className="font-weight-bold text-size-20" htmlFor="planner">{t("Planner")}</label>

                                <div className="form-check">
                                    <input className="form-check-input"
                                           checked={this.state.current_field.settings.is_rolling_fit_active}
                                           onChange={(event) => {
                                               this.handleRollingFitChange(event)
                                           }} style={{marginTop: "0.4rem"}} type="checkbox" value=""
                                           id="planner"/>
                                    <label className="text-grey45" htmlFor="planner">
                                        {t('support_planner')}
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>}

                    {this.state.current_field.settings.is_rolling_fit_active ? <IrrigationTimeFrame initialTimeFrames={this.state.current_field.irrigation_system.time_frames} onIrrigationTimeFramesChange={this.handleIrrigationTimeFrameChange} onCheckTimeFramesValidation={this.checkTimeFrameValidity} fieldIrrigationSystem={this.state.current_field.irrigation_system} fieldSoilType={this.state.current_field.soil_type} irrigationStartDate={this.state.current_field?.crop_data?.irrigation_start_date || this.state.current_field?.crop_data?.sow_date}></IrrigationTimeFrame> : null}
                    {!is_moderator &&
                    <SensorsWrapper selectedField={this.state.current_field} onSensorsChange={this.handleSensorsChange} />}

                    <Fertigation initial_concentrations={this.state.current_field.initial_concentrations}
                                                   isModerator={is_moderator}
                                                   onFertigationConcentrationChange={this.handleFertigationConcentrationChange}
                                                   onConcentrationChange={this.handleSoilConcentrationChange}
                                                   initialFertigationConcentrations={this.state.current_field.irrigation_system.fertigation_concentrations}/>
                    
                    {!is_moderator &&
                        <CoupModelBox selectedField={this.state.current_field} modalId={this.props.modalId}
                                      onCoupModelSelection={this.handleCoupModelSelection}
                                      onAutomateIrrigationSelection={this.handleAutomateIrrigation}/>
                    }

                    <div className="form-row justify-content-start">
                        <div className="col-4">
                            <div className="form-group ml-2 mr-2 mt-3 font-weight-bold text-size-20">
                                <label htmlFor="fieldName">{t('cluster')}</label>
                                    <select className="form-control custom-select" ref="cluster_id" id="exampleFormControlSelect1">
                                        <option value={""}  key={0} selected={this.state.current_field.cluster.id == "" ? "selected" : ""}>{t('none_default')} </option>
                                        <option value={"A"} key={1} selected={this.state.current_field.cluster.id == "A" ? "selected" : ""}>A</option>
                                        <option value={"B"} key={2} selected={this.state.current_field.cluster.id == "B" ? "selected" : ""}>B</option>;
                                        <option value={"C"} key={3} selected={this.state.current_field.cluster.id == "C" ? "selected" : ""}>C</option>;
                                        <option value={"D"} key={4} selected={this.state.current_field.cluster.id == "D" ? "selected" : ""}>D</option>;
                                        <option value={"E"} key={5} selected={this.state.current_field.cluster.id == "E" ? "selected" : ""}>E</option>;
                                        <option value={"F"} key={6} selected={this.state.current_field.cluster.id == "F" ? "selected" : ""}>F</option>;
                                        <option value={"G"} key={7} selected={this.state.current_field.cluster.id == "G" ? "selected" : ""}>G</option>;
                                        <option value={"H"} key={8} selected={this.state.current_field.cluster.id == "H" ? "selected" : ""}>H</option>;
                                        <option value={"I"} key={9} selected={this.state.current_field.cluster.id == "I" ? "selected" : ""}>I</option>;
                                        <option value={"J"} key={10} selected={this.state.current_field.cluster.id == "J" ? "selected" : ""}>J</option>;
                                        <option value={"K"} key={11} selected={this.state.current_field.cluster.id == "K" ? "selected" : ""}>K</option>;

                                    </select>
                            </div>
                        </div>
                    </div>

                    {!is_moderator && <div className="form-row justify-content-center">
                      <div className="col-12 col-lg-6">
                        <div className="form-group ml-2 mr-2 mt-3 font-weight-bold text-size-20">
                          <label htmlFor="fieldName">{t('edit_field_installation_date')}</label>
                          <input type="text" ref='installation_date_field' className="form-control" id="installation_date_input" aria-describedby="installationHelp" placeholder="Installation Date" required disabled value={installation_date}/>
                            <div className="invalid-feedback">{t('edit_field_installation_date_invalid_message')}</div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">

                        <div className="form-group ml-2 mr-2 mt-3 font-weight-bold text-size-20">
                          <label className="font-weight-bold" htmlFor="expirationDate">{t('edit_field_contract_duration')}</label>
                            <br/>
                            <DayPickerInput value={expiration_date} onDayChange={this.handleNewExpirationDay} inputProps={{ className: 'form-control', type:"text", ref:"contract_end_date_field", id:"contract_end_date_input",  placeholder:t('choose_date'), style:{width: '100%'}}}/>
                            <div className="invalid-feedback">{t('edit_field_contract_duration_invalid_message')}</div>

                        </div>
                      </div>
                    </div>}

                    <div className="form-row justify-content-center mt-4">
                      <div className="text-red text-center">{this.props.field_actions.status == "fail" && this.props.field_actions.error}</div>
                    </div>

                </div>

                <div className="modal-footer" style={{background:"white"}}>
                  <div className="container text-center">
                    {env.stage == 'local' &&
                      <button type="button" className="btn btn-success" onClick={this.autoFill}>Auto Fill</button>
                    }

                    <button type="button" className="btn btn-outline-secondary m-1 p-0 btn-lg footer-button-cancel" data-dismiss="modal" aria-label="Close" >{t('cancel')}</button>

                       <button type="submit" className="btn m-0 p-0 btn-lg footer-button-save bg-blue-primary text-white" disabled={(['CREATE','UPDATE'].includes(this.props.field_actions.type)) && (this.props.field_actions.status == 'init') }>
                      {this.state.title}

                      {(['CREATE','UPDATE'].includes(this.props.field_actions.type)) && (this.props.field_actions.status == 'init') && <span>&nbsp;&nbsp;<span className="spinner-grow spinner-grow-sm mb-1" role="status" aria-hidden="true"></span></span>}
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </form>

        </div>
    );
  }
};

const mapStateToProps = function(state){
  return {
    userData: state.authentication_data.user_data,
    field_actions: state.distribution_data.field_actions,
    soil_data: state.soil_data.soil_data,
    selected_farming_unit: state.distribution_data.selected_entities.farming_unit,
    selected_distribution: state.distribution_data.selected_entities.distribution,
    selected_field: state.distribution_data.selected_entities.field,
  }
}

const mapDispatchToProps = {

  create_field: distributionActions.create_field,
  update_field: distributionActions.update_field,
  clear_field_action: distributionActions.clear_field_action,
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UpdateFieldModal));
