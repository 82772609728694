import {Link} from "react-router-dom";
var React = require('react');

export const generateDivider = () => {
    return (<div className="dropdown-divider"/>)
}

export const generateCheckBoxMenuItem = ({title, onCheck , func,checkBoxOn}) => {
    return (<div className="dropdown-item p-0 d-flex align-items-start" style={{padding: '8px 16px 8px 8px', wordBreak: 'break-word', minHeight: '32px', borderRadius: '8px',pointerEvents:"none"}} ><input type="checkbox" style={{marginRight: '8px', marginTop: '5px',pointerEvents:"auto"}} className="btn-check menu-checkbox" checked={checkBoxOn} onChange={func} autoComplete="off"/><span style={{wordBreak: 'break-word', width: '232px', whiteSpace: 'initial'}}>{title}</span></div>)
}

export const generateDefaultMenuItem = ({title, href, icon,func,textColor}) => {
    const iconElement = icon && <img src={icon}/>
    let deleteHoverClass = ''
    if (title === "Delete"){
            deleteHoverClass = 'delete-dropdown-item'
    }
    return (<a  onClick={func} className={"dropdown-item d-flex align-items-center " + deleteHoverClass} style={{padding: '8px 8px', borderRadius: '8px',color:textColor}} href={href}><div style={{marginRight: '8px', marginTop: '-3px', width: '12px'}}>{iconElement}</div>{title}</a>)
}

export const generateNavigatorMenuItem = ({title, href, func, textColor}) => {
    return (<Link className="dropdown-item d-flex align-items-center" onClick={func} style={{paddingLeft:"8px",paddingRight:"20px",paddingTop:"4px",paddingBottom:"4px",color:textColor}} to={href}>{title}</Link>)
}
