const {useTranslation, withTranslation} = require("react-i18next");
const React = require("react");
const {connect} = require("react-redux");
var {upper, formatNumberWithoutRounding} = require('helpers');
var {unitDisplayName} = require('unitsManager');
const {useState, useEffect} = require("react");

const InsightsDetailsView = ({distributionData, userData, currentFarmingUnitLoaded , finalFarmingUnitNum}) => {

    let divsStyle = "text-grey45 text-truncate text-size-14"
    let spanStyle = "text-blue-secondary"
    let paddingStyle = "pl-4 pr-4"
    const {t} = useTranslation();
    const [loadingData, setLoadingData] = useState(true);

    let nitrogenUserUnits = (userData && ("weight" in userData)) ? unitDisplayName(userData.weight) : "";
    let fertilizerUserUnits = (userData && ("weight/area" in userData)) ? unitDisplayName(userData.weight + "/" + userData.area) : "";
    let cropTypesCut = [];

    if (distributionData.hasOwnProperty("distribution_crop_types")) {
        if (distributionData.distribution_crop_types.length > 10) {
            distributionData.distribution_crop_types.every((crop, index) => {
                if (index <= 9) {
                    cropTypesCut.push(upper(crop) + ", ")
                    return true;
                } else {
                    cropTypesCut.push(` and ${distributionData.distribution_crop_types.length - 10} more...`);
                    return false;
                }

            })
        } else {
            distributionData.distribution_crop_types.every((crop, index) => {
                if (index === distributionData.distribution_crop_types.length - 1) {
                    cropTypesCut.push(upper(crop))
                    return true;
                }
                cropTypesCut.push(upper(crop) + ", ")
                return true;
            })
        }
    }

    useEffect(() => {
        if (currentFarmingUnitLoaded > 0 & finalFarmingUnitNum > 0 && currentFarmingUnitLoaded === finalFarmingUnitNum) {
            setLoadingData(false);
        } else {
            setLoadingData(true);
        }
    }, [currentFarmingUnitLoaded, finalFarmingUnitNum])

    return (
        <div className="d-flex flex-grow-1"
             style={{
                 flexDirection: "row",
                 marginTop: "60px",
                 marginLeft: "24px",
                 marginRight: "24px",
                 minHeight:"80px",
                 lineHeight: "39px",
                 borderRadius:"15px"
             }}>
            <div className="d-flex flex-row bg-white w-100"
                 style={{
                     borderTopLeftRadius: '15px',
                     borderTopRightRadius: '15px',
                     borderBottomRightRadius: "15px",
                     borderBottomLeftRadius: "15px"
                 }}>
                <div className="d-flex align-items-center text-grey45" style={{
                    borderLeft: "0.5px solid #DCE0E8",
                    borderBottom: "0.5px solid #DCE0E8",
                    borderTop: "0.5px solid #DCE0E8",
                    borderTopLeftRadius: "15px",
                    borderBottomLeftRadius: "15px",
                    fontWeight: 700,
                    fontSize: "24px",
                    paddingRight: "24px",
                    paddingLeft: "24px",
                    width: "340px",
                    whiteSpace:"break-spaces"
                }}>
                    <div className="distname">
                        {loadingData?<span style={{width: "16px", height: "16px", marginRight: "8px", marginBottom:"4px", verticalAlign: "middle"}} className="spinner-border spinner-border-sm text-secondary" role="status"></span> : ""}{distributionData?.distribution_name}</div>
                </div>
                <div className="d-flex justify-content-around flex-wrap w-100"
                     style={{
                         borderRight: "1px solid #DCE0E8",
                         borderTop: "1px solid #DCE0E8",
                         borderTopRightRadius:"15px",
                         borderBottomRightRadius:"15px",
                     }}>
                    <div className={"text-center flex-grow-1 " + paddingStyle}
                         style={{
                             borderLeft: "0.5px solid #DCE0E8",
                             borderBottom: "0.5px solid #DCE0E8",
                             display: "flex",
                             justifyContent: "center",
                             alignItems: "center"
                         }}>
                        <div className={divsStyle}>{t('number_of_fields')}
                            <span
                                className={spanStyle}
                                style={{
                                    borderRadius: "5px",
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    paddingLeft: "8px"
                                }}
                            >{distributionData?.distribution_field_count}</span>
                        </div>
                    </div>
                    <div className={"text-center flex-grow-1 " + paddingStyle}
                         style={{
                             borderLeft: "0.5px solid #DCE0E8",
                             borderBottom: "0.5px solid #DCE0E8",
                             display: "flex",
                             justifyContent: "center",
                             alignItems: "center"
                         }}>
                        <div className={divsStyle}>{t('total_area')} <span
                            className={spanStyle}
                            style={{
                                borderRadius: "5px",
                                fontWeight: "700",
                                fontSize: "14px",
                                paddingLeft: "4px"
                            }}>{(distributionData?.distribution_total_area === undefined ? false : formatNumberWithoutRounding(distributionData?.distribution_total_area, 2) + " " + userData.area)}</span>
                        </div>
                    </div>

                    <div className={"text-center flex-grow-1 " + paddingStyle}
                         style={{
                             borderLeft: "0.5px solid #DCE0E8",
                             borderBottom: "0.5px solid #DCE0E8",
                             display: "flex",
                             justifyContent: "center",
                             alignItems: "center"
                         }}>
                        <div className={divsStyle}>{t('total_water_usage')} <span
                            className={spanStyle}
                            style={{
                                borderRadius: "5px",
                                fontWeight: "700",
                                fontSize: "14px",
                                paddingLeft: "4px"
                            }}>{(distributionData?.distribution_total_water_usage === undefined ? false : formatNumberWithoutRounding(distributionData?.distribution_total_water_usage,2) + " " + unitDisplayName(userData.volume))}</span>
                        </div>
                    </div>


                    <div className={"text-center flex-grow-1 " + paddingStyle}
                         style={{
                             borderLeft: "0.5px solid #DCE0E8",
                             borderBottom: "0.5px solid #DCE0E8",
                             display: "flex",
                             justifyContent: "center",
                             alignItems: "center"
                         }}>
                        <div className={divsStyle}>{t('total_fertilizers_usage')}<span
                            className={spanStyle}
                            style={{
                                borderRadius: "5px",
                                fontWeight: "700",
                                fontSize: "14px",
                                paddingLeft: "4px"
                            }}>{(distributionData?.distribution_total_fertilizers_usage === undefined ? false : formatNumberWithoutRounding(distributionData?.distribution_total_fertilizers_usage, 2) + " " + fertilizerUserUnits)}</span>
                        </div>
                    </div>

                    <div className={"text-center flex-grow-1 " + paddingStyle}
                         style={{
                             borderLeft: "0.5px solid #DCE0E8",
                             borderBottom: "0.5px solid #DCE0E8",
                             display: "flex",
                             justifyContent: "center",
                             alignItems: "center"
                         }}>
                        <div className={divsStyle}>{t('total_nitrogen_leached')} <span
                            className={spanStyle}
                            style={{
                                borderRadius: "5px",
                                fontWeight: "700",
                                fontSize: "14px",
                                paddingLeft: "4px"
                            }}>{(distributionData?.distribution_total_nitrogen_leached === undefined ? false : formatNumberWithoutRounding(distributionData?.distribution_total_nitrogen_leached, 2) + " " + nitrogenUserUnits)}</span>
                        </div>
                    </div>

                    <div className={"text-center flex-grow-1 " + paddingStyle}
                         style={{
                             borderLeft: "0.5px solid #DCE0E8",
                             borderBottom: "0.5px solid #DCE0E8",
                             borderBottomRightRadius:"15px",
                             display: "flex",
                             justifyContent: "center",
                             alignItems: "center",

                         }}>
                        <div className={divsStyle}>{t('crop_types')} <span
                            className={spanStyle}
                            style={{
                                borderRadius: "5px",
                                fontWeight: "700",
                                fontSize: "14px",
                                paddingLeft: "4px",
                                whiteSpace: "normal",
                            }}>{cropTypesCut}</span></div>
                    </div>
                </div>
            </div>
        </div>)

}

module.exports = connect()(withTranslation()(InsightsDetailsView));