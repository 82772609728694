import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ModalContainer, ModalContent, mapType} from "./EventModal";
import moment from "moment";
import $ from "jquery";
import {useTranslation} from "react-i18next";
var {getDensity} = require('nitrogenManager');
var fieldManager = require('fieldManager');
var {get_farming_units_history, get_field_history} = require('distributionActions');
var {upper} = require('helpers');

export const EditPlannedEventModal = (props) => {
    const { t } = useTranslation();
    const [eventType, setEventType] = useState('irrigation');
    const [data, setData] = useState({});

    const selectedFarmingUnit = useSelector((state) => state.distribution_data.selected_entities.farming_unit);
    const selectedDistribution = useSelector((state) => state.distribution_data.selected_entities.distribution);
    const dispatch = useDispatch();

    useEffect(() => {

        $("#" + props.modalId).on('show.bs.modal', function (event) {
            var field_id = "";
            var action = "";
            var type = "";
            var startDate = "";
            var duration = "";
            var fertType = "";
            var fertAmount = "";
            var value = "";
            var disableTypeChange = "";
            if (event.relatedTarget) {
                var button = $(event.relatedTarget) // Button that triggered the modal
                field_id = button[0].dataset.id; // Extract info from data-* attributes
                action = button[0].dataset.action;
                type = button[0].dataset.type;
                startDate = moment.unix(button[0].dataset.start);
                duration = button[0].dataset.duration;
                fertType = button[0].dataset.ftype;
                fertAmount = button[0].dataset.famount;
                value = button[0].dataset.value;
                disableTypeChange = Boolean(button[0].dataset.disabletypechange);
            }
            let data = {
                type: type,
                content: {duration: parseFloat(duration).toFixed(3), value: value, fertType: fertType, fertAmount: fertAmount},
                startDate: startDate,
                field_id: field_id,
                disableTypeChange: disableTypeChange
            }

            if (action == "create") {
                setData({
                    ...data,
                    title: t('create_planner_item'),
                    action: 'create',
                })

            } else {
                setData({
                    ...data,
                    title: t('update_planner_item'),
                    action: 'update',
                    disableTypeChange: true,
                });
            }
        });
    }, [])

    const closeModal = () => {
        $('#' + props.modalId).modal('hide');
    }

    const updateTable = (fieldId = data.field_id) => {
        dispatch(get_farming_units_history(selectedDistribution.id, selectedFarmingUnit.id, data.field_id));
        dispatch(get_field_history(selectedDistribution.id, selectedFarmingUnit.id, data.field_id));
    }

    return (
        <ModalContainer id={'eventModalId'}>
            <EditPlannedEventModalContent eventType={eventType} setEventType={setEventType}
                                   data={data} updateTable={updateTable} closeModal={closeModal}/>
        </ModalContainer>
    )
}

const EditPlannedEventModalContent = ({eventType, setEventType, data, updateTable, closeModal}) => {

  const { t, i18n } = useTranslation();
  let [reportedData, setReportedData] = useState({});
  const [measurement, setMeasurement] = useState('duration');

  const selectedField = useSelector((state) => state.distribution_data.selected_entities.field);
  const userData = useSelector((state) => state.authentication_data.user_data);

    useEffect(() => {
        setReportedData({
            fieldId: data.field_id,
            startTime: data?.startDate,
            duration: data?.content?.duration,
            fertType: data?.content?.fertType || 'no3',
            fertAmount: data?.content?.fertType ? data?.content?.fertAmount : data?.content?.value,
            nitroAmount: data?.content?.value,
            value: data?.content?.value,
        });
        setEventType(data?.type || eventType)

    }, [data, data?.startDate])

    const deleteEvent = (shouldUpdate = true) => {
        let originType = data.type;
        let type = mapType[originType];
        let startDate = moment(data.startDate).format('X');

        fieldManager.deleteFieldRecord(data.field_id, startDate, type).then(function (res) {
            if (shouldUpdate) {
                updateTable();

                mixpanel.track("Update Planning Event", {'Type': i18n.t(originType, {lng: 'en'}), 'Action': 'Delete'});
            }

        }, function (error) {
            console.log(error);
        });

        // Prevent the possibility of the automatic recreation of an irrigation event that has been deleted by the user.
        if (type === 'plan_irr') {
            let extra = data.content.duration ? {
                endDate: moment(reportedData.startTime).add(reportedData.duration, 'hours').format('X'),
                source: 'planner',
                deleted: "yes"
            } : {};
            fieldManager.addFieldRecord(data?.field_id, startDate, type, "0", extra).then(function (res) {
                updateTable();
            }, function (error) {
                console.log(error);
            });
        }

        closeModal();
    }

    const recordNewPlan = (action, fieldId, date, type, value, extraData) => {

        if (action === 'update' && (date !== data.startDate.unix().toString() || fieldId !== data.field_id)) {
            // delete the old item only if its key changed
            deleteEvent(true);
        }

        fieldManager.addFieldRecord(fieldId, Number(date).toString(), type, value, extraData).then(function (res) {
            updateTable(fieldId);
            mixpanel.track("Update Planning Event", {
                'Type': i18n.t(eventType, {lng: 'en'}),
                'Action': data.title
            });
            closeModal();
        }, function (error) {
            console.log(error);
        });

    }

    const onActionButtonClick = () => {
        if (!enableAct()){
            return;
        }
        let type = mapType[eventType];
        let startDate = moment(reportedData.startTime).format('X');
        let value, extraData;
        switch (eventType) {
            case 'irrigation':
                value = reportedData.value;
                extraData = {
                    endDate: moment(reportedData.startTime).add(reportedData.duration, 'hours').format('X'),
                    source: 'planner'
                }
                break;
            case 'fertilization':
                value = parseFloat(reportedData?.fertAmount) * getDensity(reportedData?.fertType);
                extraData = {
                    fertType: reportedData.fertType,
                    source: 'manual'
                };
                break;
            case 'note':
                value = reportedData.value;
                break;
        }
        recordNewPlan(data.action, reportedData.fieldId, startDate, type, value, extraData);
    }

    const enableAct = () => {
      if (eventType === 'irrigation'){
          return reportedData.duration > 0 && reportedData.value > 0 && !!reportedData.startTime;
      }
      if (eventType === 'fertilization'){
          return reportedData.fertAmount > 0 && !!reportedData.startTime && !!reportedData.fertType;
      }
      if (eventType === 'note'){
          return !!reportedData.value && !!reportedData.startTime;
      }
      return false;
    }

    function onDeleteClick() {
      deleteEvent(true);
      closeModal();
    }

    function chipMode(type) {
        if (eventType == type)
            return 'selected';
        return data.disableTypeChange ? 'disabled' : 'notselected';
    }

    function handleTypeChanging(type){
        if (!data.disableTypeChange){
            setEventType(type)
        }
    }

    let eventTypesChips = <div className='ml-1 row'>
                <div className={`chip chip--${chipMode('irrigation')}`}
                    onClick={() => handleTypeChanging('irrigation')}>{upper(t('irrigation'))}</div>
                <div className={`chip chip--${chipMode('fertilization')}`}
                     onClick={() => handleTypeChanging('fertilization')}>{upper(t('fertilization'))}</div>
                <div className={`chip chip--${chipMode('note')}`}
                     onClick={() => handleTypeChanging('note')}>{upper(t('note'))}</div>
            </div>

    return <ModalContent selectedField={selectedField} userData={userData} reportedData={reportedData} setReportedData={setReportedData}
                         measurement={measurement} setMeasurement={setMeasurement} title={data.title} actionButtonText={data.action}
                         eventType={eventType} eventTypesChips={eventTypesChips}
                         onActionButtonClick={onActionButtonClick} enableAct={enableAct}
                         displayFieldSection={true} onDeleteClick={data.action === 'update' && onDeleteClick}/>
}
