var React = require('react');
var { Link } = require ('react-router-dom');
var {connect} = require('react-redux');
var distributionActions = require('distributionActions');
var fieldManager = require('fieldManager');

class FieldsDropdown extends React.Component {

  constructor(props) {
      super(props);
      this.fieldStressColor = this.fieldStressColor.bind(this);
      this.fieldStress = this.fieldStress.bind(this);
  }

  onDropdownSelection(fieldId) {

    var selectedField = this.props.selectedFarmingUnit.fields[fieldId];
    this.props.setSelectedEntities(null, null, selectedField, null);
  }

  fieldStress(currentField) {
    var fieldItems = fieldManager.getFieldItems(currentField);

    return fieldItems.stressDataItem.value;
  }

  fieldStressColor(currentField) {
    var fieldItems = fieldManager.getFieldItems(currentField);
    var fieldStressColor = fieldItems.irrigationDataItem.value == -1.0 ? "rgb(195, 201, 215)" : fieldManager.get_stress_color(fieldItems.stressDataItem.value);

    return fieldStressColor;
  }

  render() {

    let allFields = [];
    let filteredFields=[];

    let that = this;

    if (this.props.selectedFarmingUnit) {
      let fields = Object.values(this.props.selectedFarmingUnit.fields);

      if (this.props.isArchiveMode) {
        filteredFields = fields.filter((field) => {
          return field.settings.field_state === "archive"
        })
      } else {
        filteredFields = fields.filter((field) => {
          return field.settings.field_state === "active"
        })
      }

      allFields = filteredFields.map(function(currentField) {
        return {"id": currentField.id, "name": currentField.name, "color": that.fieldStressColor(currentField), "stress":that.fieldStress(currentField)};
      });

      allFields.sort((a, b) => (a["stress"] > b["stress"]) ? 1 : -1);
      allFields.sort((a, b) => (b["stress"] != -1) ? 1 : -1); // sort out all -1 stress (no recommendation available)
    }

    return (
      <div style={{paddingTop:"5px"}}>
        <div className="dropdown text-grey71">
          <div className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <Link to="" className={"font-weight-bold text-grey45 text-wrap"+this.props.isArchiveMode? "archive-color" : ""}>{this.props.selectedField && this.props.selectedField.name}</Link>
          </div>
          <div className="dropdown-menu  scrollable-menu" aria-labelledby="dropdownMenuButton">
            {allFields && allFields.map((current_field) => {

              return <a className="dropdown-item" style={{color:this.props.isArchiveMode? "#919db4":current_field["color"]}} href="#" key={current_field["id"]} onClick={() => { this.onDropdownSelection(current_field["id"]) }}>{current_field["name"]}</a>
            })}
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = function(state){

  return {
    selectedField: state.distribution_data.selected_entities.field,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    selectedDistribution: state.distribution_data.selected_entities.distribution,
    loadHistoryStatus: state.distribution_data.farming_unit_load_history,
  }
}

const mapDispatchToProps = {
  setSelectedEntities: distributionActions.selected_entities
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(FieldsDropdown);
