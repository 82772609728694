var React = require('react');
const {connect} = require("react-redux");
const {withTranslation, useTranslation} = require("react-i18next");
const {useState, useEffect} = require("react");
var insightsCSS = require("insightsCSS");
var {get_all_farming_units_from_distribution, buildStructureByGroup} = require('@managers/insightsManager')
const BaseComponent = require("../general/BaseComponent");
const InsightsTable = require("./InsightsTable");
const InsightsDetailsView = require("./InsightsDetailsView");
const SelectionMenu = require("../general/SelectionMenu")

const InsightsContainer = (props) => {
    const [insightsData, setInsightsData] = useState({});
    const [openFieldsToggle, setOpenFieldsToggle] = useState([]);
    const [fieldsData, setFieldsData] = useState({});
    const [distributionData, setDistributionData] = useState({});
    const {t} = useTranslation();
    const groupOptions = [
        {
            key: "farming_unit",
            title: t('farming_unit'),
            isSelected: true,
        }
        , {
            key: "crop",
            title: t('crop_type'),
            isSelected: false,
        }, {
            key: "county",
            title: t('Insights_title_county'),
            isSelected: false,
        },
        {
            key: "county_crop",
            title: `${t('Insights_title_county')} / ${t('crop_type')}`,
            isSelected: false,
        }
    ]
    const [menuOptions, setMenuOptions] = useState(groupOptions);
    const [selectedGroup, setSelectedGroup] = useState(groupOptions[0]);
    let farmingUnitsIds = [];
    let data = [];
    let tableHeaders = [];

    if (props.active_distribution !== null && props?.active_distribution.hasOwnProperty("farming_units")) {
        farmingUnitsIds = Object.keys(props?.active_distribution?.farming_units);
    }

    const getHeadersStyle = (headersArray) => {
        let headersStyle = [];
        let mappedHeaders = [];
        if (selectedGroup.key === "farming_unit") {
            headersStyle = ["8px", "40px", "136px", "136px", "136px", "136px", "136px", "136px", "136px", "136px", "8px"];
        } else if (selectedGroup.key === "crop") {
            headersStyle = ["8px", "40px", "136px", "136px", "136px", "136px", "136px", "136px", "136px", "136px", "8px"];
        } else if (selectedGroup.key === "county") {
             headersStyle = ["8px", "40px", "136px", "136px", "136px", "136px", "136px", "136px", "136px", "136px", "8px"];
        } else if (selectedGroup.key === "county_crop") {
            headersStyle = ["8px", "40px", "136px", "136px", "136px", "136px", "136px", "136px", "136px", "136px", "136px", "8px"];
        }
        headersArray[0]?.forEach((header, i) => {
            mappedHeaders.push([header, headersStyle[i]]);
        });
        return mappedHeaders;
    }

    useEffect(() => {
        if (props && props.active_distribution) {
            get_all_farming_units_from_distribution(props.active_distribution, setInsightsData, setOpenFieldsToggle, openFieldsToggle, setDistributionData, setFieldsData);
            mixpanel.track("Insights Page Load");
        }
    }, [props?.active_distribution])

    let structureData= buildStructureByGroup(fieldsData, selectedGroup.key, props.userData)
    data = structureData?.data;
    tableHeaders = structureData?.headers;

    const onChangeGroup = (index) => {
        setSelectedGroup(menuOptions[index]);
        setOpenFieldsToggle(()=>{
            return openFieldsToggle.map(() => {
                return false;
            })
        })
        setMenuOptions(prevState => {
            return prevState.map((item, i) => {
                if (i === index) {
                    return {
                       ...item,
                        isSelected:!item.isSelected,
                    }
                } else {
                    return {
                       ...item,
                        isSelected: false,
                    }
                }
            })
        })
        mixpanel.track("Insights Group By Changed", {
            'Current grouping': menuOptions[index].key,
        });
    }
    return (
        <div>
            <InsightsDetailsView distributionData={distributionData ? distributionData : {}} userData={props.userData} finalFarmingUnitNum={farmingUnitsIds.length} currentFarmingUnitLoaded={openFieldsToggle.length}/>
            <SelectionMenu onItemClick={onChangeGroup} menuOptions={menuOptions} selectedItem={selectedGroup}/>
            <InsightsTable headers={tableHeaders || []} insightsData={data} userData={props.userData} getHeadersStyle={getHeadersStyle}
                           openFieldsToggle={openFieldsToggle} setOpenFieldsToggle={setOpenFieldsToggle}/>
        </div>
    );
}

class InsightsContainerClass extends BaseComponent {
    render() {
        return (<div style={{overflowY:"hidden"}}><InsightsContainer {...this.props}/></div>);
    }
}

const mapStateToProps = function (state) {
    return {
        userData: state.authentication_data.user_data,
        selected_farming_unit: state.distribution_data.selected_entities.farming_unit,
        selected_distribution: state.distribution_data.selected_entities.distribution,
        all_fields: state.distribution_data.selected_entities.farming_unit?.fields,
        loadHistoryStatus: state.distribution_data.farming_unit_load_history,
        active_distribution: state.distribution_data.selected_entities.distribution,
    }
}

module.exports = connect(mapStateToProps)(withTranslation()(InsightsContainerClass));