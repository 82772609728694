var redux = require('redux');
var {authenticationReducer, distributionsReducer,transmitterTestSearchResultsReducer, soildDataReducer, rolesReducer, cropdDataReducer, fertilizerDataReducer, configurationDataReducer} = require('@reducers/index.jsx');;
var thunk = require('redux-thunk').default;
import initSubscriber from 'redux-subscriber';

export var configure = () => {

  var reducer = redux.combineReducers({
    authentication_data: authenticationReducer,
    distribution_data: distributionsReducer,
    soil_data: soildDataReducer,
    crop_data: cropdDataReducer,
    fertilizer_data: fertilizerDataReducer,
    configuration_data: configurationDataReducer,
    roles_data: rolesReducer,
    transmitter_test_search_results: transmitterTestSearchResultsReducer
  });

  var store = redux.createStore(reducer, redux.compose(
    redux.applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  ));

  const subscribe = initSubscriber(store);

  return store;
}
