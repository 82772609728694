var axios = require('axios');

export var get_user_location = () => {

  return new Promise(function (resolve, reject) {
    if ("geolocation" in navigator) {
      // check if geolocation is supported/enabled on current browser
      navigator.geolocation.getCurrentPosition(
         function success(position) {
           // for when getting location is a success
           resolve({longitude:position.coords.longitude,
                    latitude:position.coords.latitude})

         },
        function error(error_message) {
          // for when getting location results in an error
          console.log(error_message);
          reject(error_message);
        }
      );
    } else {
      // geolocation is not supported
      // get your location some other way
      console.log('geolocation is not enabled on this browser');
      reject('geolocation is not enabled on this browser');
    }
  });
};
