var React = require('react');
var {connect} = require('react-redux');
var kmlManager = require('kmlManager');
var MarkersCSS = require("markersCSS")
var L = require("leaflet");
var { GestureHandling } = require("leaflet-gesture-handling");
require('leaflet.pm');
require('leaflet.pm/dist/leaflet.pm.css');
require('leaflet-bing-layer');
require('leaflet.gridlayer.googlemutant');
require("leaflet/dist/leaflet.css");
require("leaflet-gesture-handling/dist/leaflet-gesture-handling.css");
var locationManager = require('locationManager');
var moment = require('moment');
var markerCluster = require('leaflet.markercluster');
require('leaflet.markercluster.placementstrategies');
var {sensorsMap} = require('sensorsManager');
var {getEqualDistanceValues} = require('@managers/ndviManager');
var {createCoordsBoundsFromBbox} = require('@managers/geoManager')


const MAP_LINK_URL = "http://www.google.com/maps/place/";

class CustomMap extends React.Component {

  constructor(props) {
      super(props);

      this.refresh = this.refresh.bind(this);
      this.resetMap = this.resetMap.bind(this);
      this.invalidate = this.invalidate.bind(this);

      this.onLayersChange = this.onLayersChange.bind(this);
      this.paintPolygons = this.paintPolygons.bind(this);
      this.addNDVIMenu = this.addNDVIMenu.bind(this);
      this.paintMarkers = this.paintMarkers.bind(this);
      this.clearLayers = this.clearLayers.bind(this);
      this.getArea = this.getArea.bind(this);
      this.onPolygonClick = this.onPolygonClick.bind(this);
      this.onMarkerClick = this.onMarkerClick.bind(this);
      this.loadedKMLPolygons = this.loadedKMLPolygons.bind(this);
      this.addLayersMenu = this.addLayersMenu.bind(this);
      this.addSearchBarMenu = this.addSearchBarMenu.bind(this);
      this.onLayerSelection = this.onLayerSelection.bind(this);

      this.refreshNDVILayer = this.refreshNDVILayer.bind(this);
      this.refreshLayers = this.refreshLayers.bind(this);

      // this.plusButtonClicked = this.plusButtonClicked.bind(this);
      this.mymap = null;
      this.marker = null;
      this.DEFUALT_ZOOM_LEVEL = 15;
      // this.plus_button = undefined;
      this.filePickerButton = undefined;

      this.state = {polygons:undefined, markers:undefined, selectedFieldOnly:true, shouldPresentFarmingUnitNdvi:true};

      this.mapBounds = [];

      this.customLayers = {};

      this.selectedMarker = null;
  }

  // Call this function when adding the map on a modal
  invalidate(polygons, markers, ndviurls, shouldShowSearchBar, shouldPresentFarmingUnitNdvi) {

    this.mymap.invalidateSize();

    if (polygons != null && markers != null) {
      this.refresh(polygons, markers, ndviurls, shouldShowSearchBar, shouldPresentFarmingUnitNdvi);
    }

  }

  refresh(polygons, markers, ndviurls, shouldShowSearchBar, shouldPresentFarmingUnitNdvi) {

    this.addSearchBarMenu(shouldShowSearchBar);
    this.paintPolygons(polygons);
    this.paintMarkers(markers);
    this.selectedNDVILayer = undefined;
    this.addNDVIMenu(ndviurls);
    this.addLayersMenu(ndviurls && (ndviurls.length > 0));
    this.setState(prevState => ({
                ...prevState,
                shouldPresentFarmingUnitNdvi: shouldPresentFarmingUnitNdvi,
                selectedFieldOnly: !shouldPresentFarmingUnitNdvi
            }))

  }

  resetMap() {

    var that = this;
    this.paintPolygons({});
    this.paintMarkers({});

    locationManager.get_user_location().then(function(res) {

      if (that.DEFUALT_ZOOM_LEVEL == that.mymap.getZoom()) {
        that.mymap.setView(new L.LatLng(res.latitude, res.longitude), this.DEFUALT_ZOOM_LEVEL);
      }

    }).catch(function(err) {
      console.log(err);
    });
  }

  refreshLayers(currentLayer) {

    let newLayer = undefined;

    if (this.NDVIRecords && (currentLayer != "ndvi")) {
      this.mymap.removeControl(this.NDVIRecords);
    }
    if (this.NDVIColorBar) {
        this.mymap.removeControl(this.NDVIColorBar);
      }

    if (("ndvi" in this.customLayers) && (currentLayer != "ndvi")) {
      this.mymap.removeLayer(this.customLayers["ndvi"]);
    }

    if (currentLayer === "Satelite") {
      newLayer = this.customLayers["satelite"];
    } else if (currentLayer === "Topography") {
      newLayer = this.customLayers["topography"];
    } else if (currentLayer === "Streets") {
      newLayer = this.customLayers["streets"];
    } else if (currentLayer === "ndvi") {
      this.refreshNDVILayer();
      this.mymap.addControl(this.NDVIRecords);
      this.mymap.addControl(this.NDVIColorBar);
    }

    if (newLayer) {
      this.mymap.removeLayer(this.customLayers["topography"]);
      this.mymap.removeLayer(this.customLayers["streets"]);
      this.mymap.removeLayer(this.customLayers["satelite"]);

      if (newLayer) {
        this.mymap.addLayer(newLayer);
      }

    }
  }

  onLayerSelection(evnt) {

    if (evnt.target.id != "") {
      this.props.onLayerSelection(evnt.target.id);
    }

    this.refreshLayers(evnt.target.id)

  }

    addSearchBarMenu(shouldDisplaySearchBar) {
        var that = this;

        if (shouldDisplaySearchBar) {
            if (this.searchBar) {
                this.mymap.removeControl(this.searchBar);
            }

            var searchBar = L.Control.extend({
                options: {
                    position: 'bottomleft'
                },

                onAdd: function (map) {

                    var legend = L.DomUtil.create('div', 'map-legend', L.DomUtil.get('map'));
                    legend.innerHTML = "<div class='form-inline'><input class='form-control  text-size-12 mb-3' type='text' id='searchbox' name='searchbox' style='width:250px;height:28px' placeholder='latitude, longitude' value=''>" +
                        "<button type='button' name='button' class='bg-super-light-grey text-grey45 ml-1 pt-1 pb-1 pl-1 pr-1 mb-3 rounded text-size-12'><img name='search_image' src='" + require('images/icons/edit_field/search.svg') + "'></button></div>";

                    legend.onclick = function(e){

                        if (e.target.name === 'search_image') {
                            e.stopPropagation();
                            var searchText = document.getElementById('searchbox').value;
                            if (searchText.includes(",")) {
                                let longLatValues = searchText.split(",");
                                if (longLatValues.length === 2) {
                                    map.panTo(new L.LatLng(longLatValues[0], longLatValues[1]));
                                    var myIcon = L.icon({
                                        iconUrl: require("images/icons/map/selected_pin.svg"),
                                        iconSize: [30, 90],
                                        iconAnchor: [22, 94],
                                        popupAnchor: [-3, -76],
                                        shadowSize: [68, 95],
                                        shadowAnchor: [22, 94]
                                    });
                                    L.marker([longLatValues[0], longLatValues[1]],{icon:myIcon,title:`${longLatValues[0]},${longLatValues[1]}`}).addTo(map);
                                }
                            }
                        }
                    };

                    legend.ondblclick = function(e){
                        e.stopPropagation();
                    };

                    return legend;
                },
            });

            that.searchBar = new searchBar();
            that.mymap.addControl(that.searchBar);
        }

    }

  addLayersMenu(shouldDisplayNDVI){
    var that = this;

    if (this.LayerButton) {
      this.mymap.removeControl(this.LayerButton);
    }

    // Zoom Extent
    var LayerButton = L.Control.extend({
      options: {
        position: 'bottomright'
      },

      onAdd: function (map) {

        var legend = L.DomUtil.create('div', 'map-legend', L.DomUtil.get('map'));

        legend.innerHTML = "<div class='dropdown dropup'>" +
                              "<div class='' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>" +
                                "<img src='" + require('images/icons/dashboard_states/layers_idle.svg') + "' onmouseover=\"this.src='" + require('images/icons/dashboard_states/layers_hover.svg') + "'\"" +
                                " onmouseout=\"this.src='" + require('images/icons/dashboard_states/layers_idle.svg') +"'\"" +
                                " onMouseDown=\"this.src='" + require('images/icons/dashboard_states/layers_press.svg') + "'\" class='img-fluid'/>" +
                              "</div>" +
                              "<div class='dropdown-menu dropdown-menu-right text-grey71  mr-2'>" +
                                "<div class='dropdown-item text-grey71' style={'width':'10px'} id='Satelite' >Satelite</div>" +
                                "<div class='dropdown-item text-grey71' style={'width':'10px'} id='Topography'>Topography</div>" +
                                "<div class='dropdown-item text-grey71' style={'width':'10px'} id='Streets'>Streets</div>" +
                                (shouldDisplayNDVI ? "<div class='dropdown-item text-grey71' style={'width':'10px'} id='ndvi'>NDVI</div>"  : "") +
                              "</div>" +
                            "</div>";


        L.DomEvent.addListener(legend, "click", (e) => { that.onLayerSelection(e)});

        legend.ondblclick = function(e){
          e.stopPropagation();
        };

        return legend;
      },
    });

    that.LayerButton = new LayerButton();
    that.mymap.addControl(that.LayerButton);
  }

  componentDidMount() {

    this.props.onRef(this);

    var that = this;

    this.customLayers["satelite"] = L.gridLayer.googleMutant({
		type: "hybrid", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
	})

    this.customLayers["streets"] = L.gridLayer.googleMutant({
		type: "roadmap", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
	})

    this.customLayers["topography"] = L.gridLayer.googleMutant({
		type: "terrain", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
	})

    var bing = L.tileLayer.bing("Ahl73ADSb8qgq_bir80WfgxM2sHWFmwix12a1wdPWXXJTExoMJ_HNIFwMmYyxtgi");

    this.mymap = L.map(this.props.mapId,
      {drawControl: true,
       center: [32.095998, 34.776344],
       zoom: this.DEFUALT_ZOOM_LEVEL,
       zoomControl: false,
       gestureHandling: L.Browser.mobile,
       layers: [this.customLayers["satelite"]],
       attributionControl:false}
    );
    // this.mymap.zoomControl.setPosition('topright');

    this.mymap.scrollWheelZoom.disable();

    this.resetMap();

    if (this.props.showEditToolBar) {
      // define toolbar options
      var options = {
          position: 'topleft', // toolbar position, options are 'topleft', 'topright', 'bottomleft', 'bottomright'
          useFontAwesome: false, // use fontawesome instead of geomanIcons (you need to include fontawesome yourself)
          drawMarker: false, // adds button to draw markers
          drawPolyline: false, // adds button to draw a polyline
          drawRectangle: false, // adds button to draw a rectangle
          drawPolygon: true, // adds button to draw a polygon
          drawCircle: false, // adds button to draw a cricle
          cutPolygon: false, // adds button to cut a hole in a polygon
          editMode: true, // adds button to toggle edit mode for all layers
          removalMode: true, // adds a button to remove layers
      };

      var optionspoly = {
          snappable: true,
          snapDistance: 10,

          templineStyle: {
              color: '#FFFFFF',
              weight: 4,
          },
          hintlineStyle: {
              color: '#FFFFFF',
              dashArray: [5, 5],
          },
          pathOptions: {
              color: '#FFFFFF',
              fillOpacity:0.5,
          },
      };

      // enable drawing mode for shape - e.g. Poly, Line, etc
      this.mymap.pm.enableDraw('Poly', optionspoly);
      this.mymap.pm.disableDraw('Poly');

      // add leaflet.pm controls to the map
      this.mymap.pm.addControls(options);

      this.mymap.on('pm:drawend', this.onLayersChange);
      this.mymap.on('pm:remove', this.onLayersChange);
      this.mymap.on('pm:globaleditmodetoggled', this.onLayersChange);
    }

    // Stress menu
    var StressMenu = L.Control.extend({
      options: {
        position: 'bottomleft'
      },

      onAdd: function (map) {
        var legend = L.DomUtil.create('div', 'map-legend', L.DomUtil.get('map'));

        legend.innerHTML = "<div >  \
        <div class='container'>\
          <div class='row text-white font-weight-bold'> \
            Stress Level \
          </div> \
          <div class='row'> \
            <div class='col-4 bg-red96 rounded-left'> &nbsp;</div><div class='col-4 bg-yellow100'>&nbsp; </div><div class='col-4 bg-green80 rounded-right text-white text-center'>Optimal</div> \
            </div> \
          </div> \
        </div>";

        legend.style.width = '300px';

        return legend;
      }
    });

    this.stressMenu = new StressMenu();

    // Zoom Out
    var ZoomOutButton = L.Control.extend({
      options: {
        position: 'topright'
      },

      onAdd: function (map) {

        var legend = L.DomUtil.create('div', 'map-legend', L.DomUtil.get('map'));
        legend.innerHTML = "<div class='text-right btn btn-link  m-0 p-0'><img src='" + require('images/icons/dashboard_states/zoom_out_idle.svg') + "'" +
        " onmouseover=\"this.src='" + require('images/icons/dashboard_states/zoom_out_hover.svg') + "'\"" +
        " onmousedown=\"this.src='" + require('images/icons/dashboard_states/zoom_out_press.svg') + "'\"" +
        " onmouseout=\"this.src='" + require('images/icons/dashboard_states/zoom_out_idle.svg') + "'\"" +
        " class='img-fluid'  alt='edit field'/></div>";

        legend.onclick = function(){
          that.mymap.setZoom(that.mymap.getZoom() - 1);
        };

        legend.ondblclick = function(e){
          e.stopPropagation();
        };

        return legend;
      },
    });

    // Zoom in
    var ZoomInButton = L.Control.extend({
      options: {
        position: 'topright'
      },

      onAdd: function (map) {

        var legend = L.DomUtil.create('div', 'map-legend', L.DomUtil.get('map'));
        legend.innerHTML = "<div class='text-right btn btn-link  m-0 p-0'><img src='" + require('images/icons/dashboard_states/zoom_in_idle.svg') + "'" +
        " onmouseover=\"this.src='" + require('images/icons/dashboard_states/zoom_in_hover.svg') + "'\"" +
        " onmousedown=\"this.src='" + require('images/icons/dashboard_states/zoom_in_press.svg') + "'\"" +
        " onmouseout=\"this.src='" + require('images/icons/dashboard_states/zoom_in_idle.svg') + "'\"" +
        " class='img-fluid'  alt='edit field'/></div>";

        legend.onclick = function(){
          that.mymap.setZoom(that.mymap.getZoom() + 1)
        };

        legend.ondblclick = function(e){
          e.stopPropagation();
        };

        return legend;
      },
    });

    // Zoom Extent
    var ZoomExtentButton = L.Control.extend({
      options: {
        position: 'topright'
      },

      onAdd: function (map) {

        var legend = L.DomUtil.create('div', 'map-legend', L.DomUtil.get('map'));
        legend.innerHTML = "<div class='text-right btn btn-link  m-0 p-0'><img src='" + require('images/icons/dashboard_states/zoom_extent_idle.svg') + "'" +
        " onmouseover=\"this.src='" + require('images/icons/dashboard_states/zoom_extent_hover.svg') + "'\"" +
        " onmousedown=\"this.src='" + require('images/icons/dashboard_states/zoom_extent_press.svg') + "'\"" +
        " onmouseout=\"this.src='" + require('images/icons/dashboard_states/zoom_extent_idle.svg') + "'\"" +
        " class='img-fluid'  alt='edit field'/></div>";

        legend.onclick = function(){
          that.mymap.fitBounds(that.mapBounds, {padding: [50, 50], maxZoom:18, animate:false});
        };

        legend.ondblclick = function(e){
          e.stopPropagation();
        };

        return legend;
      },
    });

    that.ZoomExtentButton = new ZoomExtentButton();
    that.mymap.addControl(that.ZoomExtentButton);
    that.ZoomInButton = new ZoomInButton();
    that.mymap.addControl(that.ZoomInButton);
    that.ZoomOutButton = new ZoomOutButton();
    that.mymap.addControl(that.ZoomOutButton);

    // file picker button
    var FilePickerButton = L.Control.extend({
      options: {
        position: 'topleft'
      },

      onAdd: function (map) {

        var legend = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom text-center');

        legend.style.backgroundColor = 'white';
        legend.style.width = '35px';
        legend.style.height = '31px';

        var folderelem = document.createElement("input");
        folderelem.setAttribute("type", 'file');
        folderelem.setAttribute("accept", '.kml, .kmz');
        folderelem.setAttribute("id", 'getFile');
        folderelem.setAttribute("style", 'display:none');
        folderelem.onchange = (e) => {
          kmlManager.onFileSelection(e, that.loadedKMLPolygons);
        };
        legend.appendChild(folderelem);

        var elem = document.createElement("img");
        elem.setAttribute("src", require('images/icons/folder.svg'));
        elem.setAttribute("height", "26");
        elem.setAttribute("width", "23");
        legend.appendChild(elem);

        legend.onclick = function(){
          document.getElementById('getFile').click();
        };

        legend.ondblclick = function(e){
          e.stopPropagation();
        };

        return legend;
      },
    });

    that.filePickerButton = new FilePickerButton();
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  loadedKMLPolygons(polygons) {
    this.paintPolygons(polygons, true);
  }

  getArea() {
    var all_coordinates = [];
    this.mymap.eachLayer(function(layer){
      if (layer instanceof L.Polyline) {
        var coordinates_formatted = layer.toGeoJSON().geometry.coordinates[0].map(x =>
          {
            return {longitude:x[0], latitude:x[1]};
          });

        all_coordinates.push(coordinates_formatted);
      }
    });

    return all_coordinates;
  }

 clearMarkersLayers() {

    var that = this;
    if (this.mymap) {

      this.mymap.eachLayer(function(layer){

        if (layer instanceof L.Marker) {
          that.mymap.removeLayer(layer);
        }

        if (layer instanceof L.FeatureGroup) {
          layer.clearLayers();
          that.mymap.removeLayer(layer);
        }

      });
    }
  }

  clearLayers() {
    var that = this;
    if (this.mymap) {
      this.mymap.eachLayer(function(layer){
        if (layer instanceof L.Polyline || (layer instanceof L.ImageOverlay)) {
          that.mymap.removeLayer(layer);
        }
      });
    }
  }

  onLayersChange(e) {

    if (this.props.onAreaChanged) {
      var all_coordinates = this.getArea();
      this.props.onAreaChanged(all_coordinates);
    }
  }

  onPolygonClick(e) {
    this.selectedMarker = null;

    if (this.props.onPolygonClick) {
      this.props.onPolygonClick(e.sourceTarget.attribution);
    }
      // console.log(e.sourceTarget._latlngs);
  }

  onMarkerClick(e) {

    var ids = e.sourceTarget.attribution;
    this.selectedMarker = ids.split('#')[1];

    if (this.props.onMarkerClick) {
      this.props.onMarkerClick(ids.split('#')[0]);
    }
  }

    paintMarkers(markers) {

        let selectedMarkerObj;
        
        // clear before painting new layers
        this.clearMarkersLayers();

        if (markers && Object.keys(markers).length > 0) {

            var markersGroup = window.L.markerClusterGroup({
                disableClusteringAtZoom: 16,
                elementsPlacementStrategy: 'original-locations',
                showCoverageOnHover: false,
                spiderLegPolylineOptions: {weight: 0, color: '#0000ffff', opacity: 0},
                zoomToBoundsOnClick: true,

                iconCreateFunction: function (cluster) {
                    return L.divIcon({
                        className: "marker-cluster",
                        iconSize: [28, 28],
                        html: '<div>'+cluster.getChildCount()+'</div>'
                    });
                }
            });

            for (var current_container_index in markers) {

                for (var current_marker_index in markers[current_container_index].markers) {

                    var current_marker = markers[current_container_index].markers[current_marker_index];

                    var iconSize = 24 + 4;
                    var iconAnchor = iconSize / 2;
                    var icon;

                    if (markers[current_container_index].selected) {

                        if (current_marker.info === "Flow Meter Sensor") {
                            icon = L.divIcon({

                                // empty className to avoid default design
                                className: "",
                                html: `<div style="background: white;border-radius: 50px;height: 32px;width: 32px;position: relative;display: flex;justify-content: center;align-items: center;border: 2px solid #4D5674"><svg width="10" height="40" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7 7H0V5H7C8.65685 5 10 6.34315 10 8V11H8V8C8 7.44772 7.55228 7 7 7Z" fill="#4D5674"/>
<path d="M10 13C10 13.5523 9.55229 14 9 14C8.44771 14 8 13.5523 8 13C8 12.4477 8.44771 12 9 12C9.55229 12 10 12.4477 10 13Z" fill="#4D5674"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1 0H8V2H5.5V4H3.5V2H1V0Z" fill="#4D5674"/>
</svg></div>`,
                                iconAnchor: [iconAnchor, iconAnchor],
                                popupAnchor: [-1, -8]
                            });
                        } else if (current_marker.info === "Piezometer Sensor") {
                            icon = L.divIcon({

                                // empty className to avoid default design
                                className: "",
                                html: `<div style="background: white;border-radius: 50px;height: 32px;width: 32px;position: relative;display: flex;justify-content: center;align-items: center;border: 2px solid #4D5674">
<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 13.9291C11.3923 13.4439 14 10.5265 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.5265 2.60771 13.4439 6 13.9291V16H8V13.9291ZM12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7Z" fill="#4D5674"/>
<path d="M7.35263 5.13798C7.29654 5.16744 7.23262 5.17762 7.16952 5.1718C6.64489 5.12345 6.10343 5.3001 5.70177 5.70176C4.98477 6.41876 4.98477 7.58124 5.70177 8.29824C6.41877 9.01524 7.58125 9.01524 8.29825 8.29824C8.7 7.8965 8.87664 7.35489 8.82818 6.83015C8.82235 6.76704 8.83252 6.70309 8.86199 6.64698L10.0833 4.3216C10.2202 4.06095 9.9389 3.77961 9.67824 3.91651L7.35263 5.13798Z" fill="#4D5674"/>
</svg>
</div>`,
                                iconAnchor: [iconAnchor, iconAnchor],
                                popupAnchor: [-1, -8]
                            });
                        } else {

                            var myCustomColour = current_marker.color;
                            icon = L.divIcon({
                                className: '',
                                html: `<svg width="24" height="33" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 1C18.0964 1 23 5.82654 23 11.7333C23 12.7889 22.6103 14.2129 21.8954 15.8937C21.1898 17.5526 20.2074 19.3708 19.1118 21.1857C16.9215 24.8143 14.3253 28.3534 12.7209 30.4542C12.3502 30.9396 11.6498 30.9396 11.2791 30.4542C9.67466 28.3534 7.07855 24.8143 4.88816 21.1857C3.79259 19.3708 2.81022 17.5526 2.1046 15.8937C1.38967 14.2129 1 12.7889 1 11.7333C1 5.82654 5.90356 1 12 1Z" fill=${myCustomColour} stroke="white" stroke-width="2"/>
<circle cx="12" cy="12" r="4.5" fill="white"/>
</svg>`,
                                //iconSize: [iconSize, iconSize], // is set via the css
                                iconAnchor: [iconAnchor, iconAnchor],
                                popupAnchor: [-1, -8]
                            });
                        }
                    } else {
                        icon = L.divIcon({
                            className: "",
                            html: `<svg width="24" height="33" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 1C18.0964 1 23 5.82654 23 11.7333C23 12.7889 22.6103 14.2129 21.8954 15.8937C21.1898 17.5526 20.2074 19.3708 19.1118 21.1857C16.9215 24.8143 14.3253 28.3534 12.7209 30.4542C12.3502 30.9396 11.6498 30.9396 11.2791 30.4542C9.67466 28.3534 7.07855 24.8143 4.88816 21.1857C3.79259 19.3708 2.81022 17.5526 2.1046 15.8937C1.38967 14.2129 1 12.7889 1 11.7333C1 5.82654 5.90356 1 12 1Z" fill="grey" stroke="white" stroke-width="2"/>
<circle cx="12" cy="12" r="4.5" fill="white"/>
</svg>`,
                            //iconSize: [iconSize, iconSize], // is set via the css
                            popupAnchor: [-1, -8]
                        });
                    }
                    var coordinateStr = parseFloat(current_marker.coordinates[0]).toFixed(6) + ", " + parseFloat(current_marker.coordinates[1]).toFixed(6);
                    var title = sensorsMap.get(current_marker.type);
                    var popupcontent =
                        "<table>" + "<tr>" +
                        `<td class='map-marker-popup-header text-truncate'">${title}` + "</td><tr/>" +
                        "<tr>" + "<td class='map-marker-popup-title'>Transmitter ID </td>" +
                        `<td class='map-marker-popup-value'>#${current_marker.transmitter_id}` + "</td></tr>" +
                        "<tr>" + "<td class='map-marker-popup-title'>Coordinates </td>" + "<td class='map-marker-popup-value'>" +
                        `<a href="${MAP_LINK_URL}${current_marker.coordinates}"` +
                        " style='color:#0000FF !important'; target='_blank'>" +
                        coordinateStr + "</a></td></tr></table>";

                    var marker = L.marker(current_marker.coordinates, {icon: icon}).bindPopup(popupcontent,
                        {direction: top, closeButton: false});

                    marker.attribution = current_container_index + '#' + current_marker_index;

                    marker.on('click', this.onMarkerClick);

                    markersGroup.addLayer(marker);

                    if (this.selectedMarker && this.selectedMarker == current_marker_index) {
                        selectedMarkerObj = marker;
                    }
                }
            }


            this.mymap.addLayer(markersGroup);

            markersGroup.on('clusterclick', function (a) {
                a.layer.zoomToBounds({padding: [20, 20]});
            });
        }
    }


    refreshColorBar(values, selectedFieldOnly) {

        const that = this;
        var NDVIColorBar = L.Control.extend({
            options: {
                position: 'bottomright'
            },
            onAdd: () => {

                const selectFieldOnlyStyle = that.state.shouldPresentFarmingUnitNdvi ? {
                    display: 'flex',
                    "flex-direction": 'row',
                    "align-items": 'center',
                    height: '50px',
                    width: '88%',
                    background: '#FFFFFF',
                    border: '1px solid #DCE0E8',
                    "border-radius": '8px',
                    gap: '6px',
                    "max-width": '660px',
                    "padding-left": '6px'
                } : {
                    display: 'flex',
                    "flex-direction": 'row',
                    "align-items": 'center',
                    height: '50px',
                    width: '88%',
                    background: '#FFFFFF',
                    border: '1px solid #DCE0E8',
                    "border-radius": '8px',
                    "max-width": '660px',
                    "padding-left": '8px'
                };
                const selectFieldOnlyToggle = that.state.shouldPresentFarmingUnitNdvi ? `<div style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 16px;
        width: 180px;
        gap: 6px;
      ">

        <span style="font-size: 14px; line-height: 14px; color: #4D5674; white-space: nowrap">Selected field only</span>

        <div id="toggle-switch" style="">
          <div id="toggle-handle" style="
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12px;
            height: 12px;
            left: 14px;
            top: calc(50% - 12px/2);
            background: #FFFFFF;
            box-shadow: 0px 0px 3px rgba(64, 69, 79, 0.07), 0px 1px 1px rgba(64, 69, 79, 0.3);
            border-radius: 40px;
            cursor: pointer;
            margin-left: 2px;
            margin-right: 2px;
          "></div>
        </div>

      </div>` : `<div></div>`
                const spectrumValues = values.map(value => `<span>${value}</span>`).join('');
                var legend = L.DomUtil.create('div', 'map-legend w-100', L.DomUtil.get("map"));
                legend.innerHTML = `
<div style="width: 100%; justify-content: center; background: transparent; display: flex; margin-bottom: -40px;padding-left: 220px;">
    <div style="${Object.entries(selectFieldOnlyStyle).map(([key, value]) => `${key}: ${value};`).join(' ')}">
${selectFieldOnlyToggle}
      <div style="
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 34px;
        gap: 4px;
        padding-right: 8px;

      ">
        <div style="
          width: 100%;
          height: 16px;
          background: linear-gradient(to right, #E05223, #E68918, #ECC50C, #F2FF00, #98E600, #4FCB3D, #08B078, #0A6F92, #0B2FAB);
          border-radius: 3px;
        "></div>
        <div style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 14px;
          font-weight: bold;
          font-size: 14px;
          color: #4D5674;
        ">
${spectrumValues}
        </div>
      </div>
    </div></div>
  `;

                // we don't want the double-click event to trigger any other event listeners that might be attached to parent elements.
                legend.ondblclick = (e) => {
                    e.stopPropagation();
                };
                if (that.state.shouldPresentFarmingUnitNdvi) {

                    const toggleHandle = legend.querySelector("#toggle-handle");
                    const toggleSwitch = legend.querySelector("#toggle-switch");
                    var handlePosition = selectedFieldOnly ? "right" : "left"; // set initial handle position
                    toggleSwitch.style.background = handlePosition === "right" ? "#4563C7" : "#EEF1F6"
                    toggleHandle.style.left = handlePosition === "right" ? "auto" : "0"
                    toggleHandle.style.right = handlePosition === "right" ? "0" : "auto"


                    toggleSwitch.addEventListener("click", function (e) {
                        if (that.state.shouldPresentFarmingUnitNdvi) {
                            mixpanel.track("NDVI Selected Field Toggle", {'Selected Field': !that.state.selectedFieldOnly});
                            that.setState(prevState => ({
                                ...prevState,
                                selectedFieldOnly: !prevState.selectedFieldOnly
                            }));
                            that.refreshNDVILayer()
                        }
                    });

                }

                return legend;
            }
        });
        this.NDVIColorBar = new NDVIColorBar();
        that.mymap.addControl(that.NDVIColorBar);
    }



    refreshNDVILayer() {

        const that = this;

        if ((this.ndviUrls) && (this.ndviUrls.length > 0)) {

            if (this.NDVIColorBar) {
                this.mymap.removeControl(this.NDVIColorBar);
            }

            var url = undefined;

            if (!this.selectedNDVILayer) {
                this.selectedNDVILayer = Math.max(...Object.keys(this.ndviUrls[0]));
            }

            let urls = this.ndviUrls[0][this.selectedNDVILayer];

            let tileMin = 0
            let tileMax = 1
            Object.values(urls).forEach(tile => {
                if (that.state.selectedFieldOnly && tile.isSelectedField) {
                    tileMin = parseFloat(tile['min'])
                    tileMax = parseFloat(tile['max'])
                }
                if (!that.state.selectedFieldOnly && tile.isSelectedField) {
                    tileMin = parseFloat(tile["farming_details"]['min'])
                    tileMax = parseFloat(tile["farming_details"]['max'])
                }

            });

            const values = getEqualDistanceValues(tileMin, tileMax);
            that.refreshColorBar(values,that.state.selectedFieldOnly);

            Object.keys(this.customLayers).forEach(function (key) {
                if (key.startsWith("ndvi")) {
                    that.mymap.removeLayer(that.customLayers[key]);
                }
            });

            for (let urlIndex in urls) {
                url = urls[urlIndex]['image_path'];
                let fieldBoundsMap = createCoordsBoundsFromBbox(urls[urlIndex]['bbox'])
                let layerID = "ndvi" + urlIndex.toString();
                if (this.state.selectedFieldOnly && urls[urlIndex]['isSelectedField']) {
                    this.customLayers[layerID] = L.imageOverlay(url, fieldBoundsMap)
                    this.mymap.addLayer(this.customLayers[layerID]);
                } else if (!this.state.selectedFieldOnly && urls[urlIndex]['isSelectedField']) {
                    var farming_url = urls[urlIndex]["farming_details"]['image_path']
                    let farmingUnitBoundsMap = createCoordsBoundsFromBbox(urls[urlIndex]["farming_details"]['bbox'])
                    this.customLayers[layerID] = L.imageOverlay(farming_url, farmingUnitBoundsMap)
                    this.mymap.addLayer(this.customLayers[layerID]);
                }
            }

        }
    }

  addNDVIMenu(ndviURLs) {

    if (ndviURLs) {
      this.ndviUrls = ndviURLs;

    }

    if (this.NDVIRecords) {
      this.mymap.removeControl(this.NDVIRecords);
    }

        if (this.NDVIColorBar) {
      this.mymap.removeControl(this.NDVIColorBar);
    }

      let that = this;

      if ((this.ndviUrls) && (this.ndviUrls.length > 0)) {

          var NDVIRecords = L.Control.extend({
              options: {
                  position: 'topleft'
              },

              onAdd: function (map) {

                  var legend = L.DomUtil.create('div', 'map-legend bg-white h-100', L.DomUtil.get("map"));
                  legend.style.width = '220px';
                  let legendHeight = (that.props.map_height - 20).toString() + "px";
                  let legendHeightImages = (that.props.map_height - 90).toString() + "px";
                  legend.style.height = legendHeight;
                  legend.style.borderRadius = "8px";
                  legend.style.border = "1px solid #DCE0E8"
                  legend.style.borderRadius = "8px";
                  legend.style.boxShadow = "0 0px 20px 0 rgba(0, 0, 0, 0.1)";

            let str = "";
            let reversedUrls = Object.keys(that.ndviUrls[0]).reverse();


            for (let item in reversedUrls) {

              let extraAttribute = "";
              if (reversedUrls[item] == that.selectedNDVILayer) {
                extraAttribute = "bg-grey45 text-white";
              }

                  let cloudiness = "";
                  let source = "&nbsp";

              // calculate cloudiness
              for (let tile in that.ndviUrls[0][reversedUrls[item]]) {

                      let currentTile = that.ndviUrls[0][reversedUrls[item]][tile];
                      if (currentTile["isSelectedField"]) {
                          cloudiness = Math.round(currentTile['cloud_cover'] * 100);
                          source = currentTile['source'];
                }
              }

              str += "<div style='cursor: pointer; border-width: 0; min-height:50px; border-bottom: 1px solid #DCE0E8;' class='list-group-item list-group-item-action rounded-0 p-2 pl-3 pr-3 d-flex justify-content-between align-items-center " + extraAttribute + " ' id='" +  reversedUrls[item] + "'>" +
              "<div class='d-flex flex-column '>" +
                  "<div class='m-0 p-0 '>" + moment.unix(reversedUrls[item]).format('LL') + "</div>" +
                  "<div class='m-0 p-0 text-size-12'>" + "<small class='text-grey71'>" + source +"</small>"+"</div></div>" +
              "<div class='d-flex flex-column '>" +
                  "<div class='mr-0  d-flex justify-content-between '>" + "<small class='font-weight-bold ' " + extraAttribute + ">" + cloudiness + "%&nbsp" + "</small>" + "<img style='width:16px; height:16px' class='img-fluid ' src='" + require('images/icons/graph/cloud.svg') + "'/>" + "</div>" +
                  "<div class='mr-0'>"+ "&nbsp" + "</div></div>" +
              "</div>";
            }

            legend.innerHTML = "<div ><div style='border-bottom: 1px solid #DCE0E8;' class='text-center text-grey45 p-3 text-size-14 font-weight-semi-bold d-flex align-items-center justify-content-center'>NDVI Timeline</div>\
            <ul class='list-group ' style='border-radius:8px; border-top-right-radius:0px; border-top-left-radius:0px; max-height:" + legendHeightImages + ";height:" + legendHeightImages + "; overflow:auto; '> \
            " + str + " \
          </ul></div>";


          L.DomEvent.addListener(legend, "click", (e) => {

            e.stopPropagation();
            e.preventDefault();
            
            let currentTarget = e.target;
            if (currentTarget.id === '') {

              while (currentTarget.id === "") {
                currentTarget = currentTarget.parentNode;
              }
            }

            if (currentTarget.id != '' && currentTarget.id != "dashboard") {
              var selectedElement = document.getElementById(that.selectedNDVILayer);
              selectedElement?.classList.remove("bg-grey45");
              selectedElement?.classList.remove("text-white");

              currentTarget.classList.add("bg-grey45");
              currentTarget.classList.add("text-white");
              that.selectedNDVILayer = currentTarget.id;

              that.props.onNDVILayerSelection(currentTarget.id);

              that.refreshNDVILayer();
            }

          });

          legend.ondblclick = function(e){
            e.stopPropagation();
          };

            return legend;
          },
        });
        this.NDVIRecords = new NDVIRecords();

      }
  }

  paintPolygons(polygons, should_notify=false) {

    // clear before painting new layers
    this.clearLayers();

    if (polygons && Object.keys(polygons).length > 0) {
      var allPoints = [];
      var selectedFieldPoints = [];
      let selectedPolygons = [];

      for (var current_container_index in polygons) {

        var fill_color = polygons[current_container_index]["fill_color"];

        var border_color = fill_color;
        var border_weight = 4;
        if (polygons[current_container_index]["selected"]) {
          border_color = '#1592e6';
          border_weight = 7;
        }

        for (var current_area_index in polygons[current_container_index]["areas"]) {

          var latlngs = [];

          for (var current_coordinates_index in polygons[current_container_index]["areas"][current_area_index]) {

            var current_coordinate = polygons[current_container_index]["areas"][current_area_index][current_coordinates_index];

            latlngs.push([current_coordinate.latitude, current_coordinate.longitude]);
          }

          var polygon = L.polygon(latlngs,
              {color: border_color, weight:border_weight, fillOpacity:0.1, fill:true, fillColor:fill_color}).bindTooltip(polygons[current_container_index]["title"],
                              {className:'map-polygon-tooltip',direction:'bottom'});

          polygon.attribution = current_container_index;

          polygon.on('click', this.onPolygonClick);

          allPoints.push(latlngs);

          if ((latlngs.length > 0) && (polygons[current_container_index]["selected"])) {
              selectedFieldPoints.push(latlngs);
              selectedPolygons.push(polygon)
          } else {
            polygon.addTo(this.mymap);
          }
        }
      }

      // add selected polygons after all polygons so it will appear in the front
      selectedPolygons.map(a => a.addTo(this.mymap));

      if (selectedFieldPoints && selectedFieldPoints.length > 0) {
        this.mapBounds = new L.LatLngBounds(selectedFieldPoints);
      } else {
        this.mapBounds = new L.LatLngBounds(allPoints);
      }
      // Lior change here max was 17
      this.mymap.fitBounds(this.mapBounds, {padding: [50, 50], maxZoom:18, animate:true});

      if (should_notify) {
        this.onLayersChange(undefined);
      }
    }
  }

  render() {

    if (this.filePickerButton) {
      this.props.shouldPresentFilePicker ? this.mymap.addControl(this.filePickerButton) : this.mymap.removeControl(this.filePickerButton);
    }

    if (this.stressMenu) {
      // this.props.shouldPresentStressMenu ? this.mymap.addControl(this.stressMenu) : this.mymap.removeControl(this.stressMenu);
    }

    if (document.getElementById("mapid")) {
      var map_claslist = document.getElementById("mapid").classList;
      if (this.props.message) {
        map_claslist.add("border-red", "border", "rounded");
      } else {
        map_claslist.remove("border-red", "border", "rounded");
      }
    }

    return (
      <div style={{height: "100%" }}>

        <div id={this.props.mapId} style={{height: "100%", minHeight: this.props.map_height.toString() + "px" ,borderRadius:"5px"}}></div>
        <div className="text-red text-center"><small>{this.props.message}</small></div>
      </div>
    );
  }
};

const mapStateToProps = function(state){
  return {

  }
}

const mapDispatchToProps = {

}

module.exports = connect(mapStateToProps,mapDispatchToProps)(CustomMap);
