import omnivore from "leaflet-omnivore";
var JSZip = require('jszip');


export var loadKMLString = (res, callback) => {
    var tmp_polygons = {
        'dummy_id': {
            areas: [],
            fill_color: '#FFFFFF',
            selected: false,
            title: 'My Field'
        }
    }

    try {
        var lay = omnivore.kml.parse(res);
        for (var i in lay._layers) {
            var currentl = lay._layers[i];

            if (currentl.hasOwnProperty("_latlngs")) {
                for (var current_ltlng_index in currentl._latlngs) {
                    var current_ltlng = currentl._latlngs[current_ltlng_index]

                    if (Array.isArray(current_ltlng) == true) {
                        var current_coordinates = current_ltlng.map((current_coordinate) => {
                            return {longitude: current_coordinate.lng, latitude: current_coordinate.lat};
                        });
                        tmp_polygons['dummy_id']['areas'].push(current_coordinates);
                    }
                }
            }
        }
    } catch (err) {
        console.log(" ", err);
    }

    callback(tmp_polygons);
};

export var onFileSelection = (evt, callback) => {

    var that = this;
    var fileReader = new FileReader();
    fileReader.onloadend = (e) => {
        if (Object.prototype.toString.call(e.target.result) == "[object ArrayBuffer]") {
            new JSZip().loadAsync(e.target.result).then(function (unzippedFile) {
                if (Object.keys(unzippedFile.files).length > 0) {
                    unzippedFile.files[Object.keys(unzippedFile.files)[0]].async('text').then(function (unzippedText) {
                        loadKMLString(unzippedText, callback);
                    });
                }
            });
        } else if (typeof e.target.result == "string") {
            loadKMLString(e.target.result, callback);
        }
    };

    var files = evt.target.files;

    // Read KML and KMZ files
    for (var i = 0, f; f = files[i]; i++) {
        if (f.type == "application/vnd.google-earth.kml+xml" || f.name.substring(f.name.length - 3) === "KML" || f.name.substring(f.name.length - 3) === "kml") {
            mixpanel.track("Import Map File", {'File Type': 'KML'});
            fileReader.readAsText(f);
        } else if (f.type == "application/vnd.google-earth.kmz" || f.name.substring(f.name.length - 3) === "KMZ" || f.name.substring(f.name.length - 3) === "kmz") {
            mixpanel.track("Import Map File", {'File Type': 'KMZ'});
            fileReader.readAsArrayBuffer(f);
        }
    }
};
