var React = require('react');

const FieldSelectionRow = ({
                               onMouseLeave,
                               onMouseEnter,
                               onClick,
                               currentField,
                               currentBackground, rowContent, settingsElement, hoveredField
                           }) => {

    return (
        <div onMouseLeave={() => onMouseLeave()}
             onMouseEnter={() => onMouseEnter()}
             onClick={() => onClick()}
             className="list-group-item m-0 p-0 pt-1"
             style={{
                 'borderRadius': '0%',
                 'border': '0px solid white',
                 'cursor': 'pointer',
                 'backgroundColor': currentBackground,
                 'borderLeft': currentField.isActive ? "6px solid #4D5674" : "",
                 borderBottom: "1px solid #EEF1F6"
             }}>

            {rowContent}
            {hoveredField === currentField.id ?
                <div className={"d-flex flex-row-reverse align-items-center position-absolute "} style={{
                    top: 0,
                    right: 0,
                    bottom: 0,
                    width: "30%",
                    background: "linear-gradient(270deg, #F6F8FA 50%, #F6F8FA 50%, rgba(246, 248, 250, 0) 100%)"
                }}>
                    {settingsElement ? <div className="dropdown">
                        <div className="mr-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src={require('images/icons/field_selection/settings-icon.svg')}
                                 alt="irrigation ok"/>
                        </div>
                        {settingsElement}
                    </div> : null}
                </div> : ''}
        </div>
    );
};

module.exports = FieldSelectionRow;