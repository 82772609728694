var cropDataManager = require('@managers/cropDataManager');
var GeneralClasses = require('@model/GeneralClasses');
var {error_message} = require('@managers/helpers');


// Middleware
export var load_crop_data = () => {

  return (dispatch, getState) => {

    var state = getState();

    // Load only once
    if (!state.crop_data.crop_data) {

      dispatch(get_crop_data_init());

      cropDataManager.crop_data().then(function(result) {

          dispatch(get_crop_data_success(result));

        }, function(err) {
          console.log(err);
          dispatch(get_crop_data_fail(err));
        });
      }
    };
};

export var get_crop_data_init = () => {
  return {
    type: "GET_CROP_DATA_INIT"
  }
};

export var get_crop_data_success = (crop_data) => {
  return {
    type: "GET_CROP_DATA_SUCCESS",
    crop_data: crop_data,
  }
};

export var get_crop_data_fail = (err) => {
  return {
    type: "GET_CROP_DATA_FAIL",
    error_message: error_message(err)
  }
};

export var clear_crop_data_actions = () => {

  return {
    type: "CLEAR_CROP_DATA_ACTIONS"
  }
};
