var React = require('react');
var {connect} = require('react-redux');
var $ = require('jquery');
var { withTranslation } = require('react-i18next');

class AlertModal extends React.Component {

  constructor(props) {
      super(props);

      this.onOKButtonClick = this.onOKButtonClick.bind(this);
  }

  componentDidMount() {

    var that = this;

    $("#" + this.props.modalId).on('hide.bs.modal', function (event) {

      if (that.props.onDismiss) {
        that.props.onDismiss();
      }
    });
  }

  onOKButtonClick(e) {

    e.preventDefault();
    e.stopPropagation();

    $('#' + this.props.modalId).modal('hide');
  }

  render() {
    const { t } = this.props;

    return (
        <div className="modal fade" id={this.props.modalId} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Error</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
                <div className="modal-body text-center">
                    <div className="text-red">{this.props.message}</div>
                </div>
                <div className="modal-footer">
                  <div className="container text-center">
                    <button type="button" className="btn btn-dark-green btn-md general-button m-0 p-0 mr-3 " onClick={this.onOKButtonClick}>{t('ok')}</button>

                  </div>
                </div>
              </div>
            </div>

        </div>
    );
  }
};


module.exports = connect()(withTranslation()(AlertModal));
