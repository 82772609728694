import React, {useRef, useEffect, useState, useMemo} from "react";
import moment from "moment";

export const MiniGraph = ({data, timeZone}) => {
    const canvasRef = useRef(null);
    const [samplesData, setSamplesData] = useState(data);
    const [drawingGraph, setDrawingGraph] = useState(false);
    let ctx;
    useEffect(() => {
        setSamplesData(data)
    }, [data]);

    useEffect(() => {
        if (data === undefined) return;
        else {
            if (data.length !== samplesData.length || !drawingGraph) {
                initGraph();
            } else return;
        }
    }, [samplesData]);

    const initGraph = () => {
        if (samplesData === undefined) return;
        setDrawingGraph(true);
        let dataForGraph = Object.values(samplesData).flat(1);
        let samples;
        let lables = [];
        samples = dataForGraph.map((samp, index) => {
            if (samp === undefined) {
                return null
            } else {
                lables.push(moment.unix(samp.date).tz(timeZone));
                return {
                    x: moment.unix(samp.date).tz(timeZone),
                    y: parseFloat(samp.value),
                }
            }
        })
        const canvas = canvasRef.current;
        ctx = canvas.getContext("2d");
        ctx.canvas.width = 50;
        ctx.canvas.height = 40;
        var gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        gradient.addColorStop(0, 'rgba(69, 99, 199, 0.22)');
        gradient.addColorStop(1, 'rgba(69, 99, 199, 0.02)');
        const chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: lables,
                datasets: [
                    {
                        label: "",
                        data: samples,
                        fill: "start",
                        backgroundColor: gradient,
                        borderColor: "#919DB4",
                        borderWidth: 1,
                        pointRadius: 0,
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: false,
                hover: {mode: null},
                plugins: {
                    title: {
                        display: false
                    },
                },
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        display: false,
                        title: {
                            display: false,
                        },
                        gridLines: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            beginAtZero: true,
                            reverse: true,
                        }
                    }],
                    yAxes: [{
                        display: false,
                        title: {
                            display: false,
                        },
                        gridLines: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    return (
        <div id="chart-wrapper">
            <canvas ref={canvasRef} id="chart" style={{width: "60px", height: "30px", marginTop: "10px"}}/>
        </div>
    );
};

