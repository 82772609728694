var React = require('react');
var {connect} = require('react-redux');
var {withTranslation} = require('react-i18next');
var {useState, useEffect} = require('react');
const AccumulatedClickedIcon  = require('images/icons/graph/blue_accumulated.svg');
const AccumulatedIcon  = require('images/icons/graph/grey_accumulated.svg');
const GreyDropIcon = require('images/icons/graph/grey_drop.svg');
const BlueDropIcon = require('images/icons/graph/blue_drop.svg');
const BlueNitrogen = require('images/icons/graph/blue_nitrogen.svg');
const GreyNitrogen = require('images/icons/graph/nitrogen_grey.svg');

function GraphToggle({initialValue, onToggleClick,icon}) {

    const [selectedButton, setSelectedButton] = useState(initialValue);

    DROP = "drop";
    NITROGEN = "nitrogen";
    ACCUMULATED = 'accumulated';

    useEffect(() => {
        if (onToggleClick) {
            onToggleClick(selectedButton);
        }
    }, [selectedButton]);

    selectedStyle = { width:'36px', height:'26px', border:'0px', outline:'none',backgroundColor:'transparent'};
    nitrogenImage = selectedButton === NITROGEN ? BlueNitrogen : GreyNitrogen
    dropImage = selectedButton === DROP ? BlueDropIcon : GreyDropIcon;
    waterMeterImage = selectedButton === ACCUMULATED ? AccumulatedClickedIcon : AccumulatedIcon;

    return (
        <div className="d-flex d-inline align-items-end" style={{height:"32px"}}>
            {icon.includes('drop') && <button className="" style={selectedStyle}
                    onClick={() => {
                        if (selectedButton === NITROGEN) {
                            setSelectedButton(DROP);
                        }
                        else if (selectedButton === ACCUMULATED){
                            setSelectedButton(DROP);
                        }
                    }}>
                <img src={ dropImage } className="mb-1"/>  <div style={{width:"24px",height:"3px",backgroundColor:"#4563C7"  ,alignSelf:"stretch", visibility: selectedButton !==  DROP ? "hidden" : true}}/>
            </button>}
            {icon.includes('nitrogen') && <button className="" style={selectedStyle}
                    onClick={() => {
                        if (selectedButton === DROP) {
                            setSelectedButton(NITROGEN);
                        }
                        else if (selectedButton === ACCUMULATED){
                            setSelectedButton(NITROGEN);
                        }
                    }}>
                <img src={ nitrogenImage } className="mb-1" /> <div style={{width:"24px",height:"3px",backgroundColor:"#4563C7" , alignSelf:"stretch", visibility: selectedButton !==  NITROGEN ?  "hidden" : true}}/>
            </button>}
            {icon.includes('accumulated') && <button className="" style={selectedStyle}
                    onClick={() => {
                        if (selectedButton === DROP) {
                            setSelectedButton(ACCUMULATED);
                        }
                        else if (selectedButton === NITROGEN){
                            setSelectedButton(ACCUMULATED);
                        }
                    }}>
                <img src={ waterMeterImage } className="mb-1" /> <div style={{width:"24px",height:"3px",backgroundColor:"#4563C7" , alignSelf:"stretch", visibility: selectedButton !==  ACCUMULATED ? "hidden" : true}}/>
            </button>}
        </div>
    );
}

module.exports = connect()(withTranslation()(GraphToggle));
