var React = require('react');
var {connect} = require('react-redux');
var {Chart} = require('react-chartjs-2');
var moment = require('moment');
var {Link} = require('react-router-dom');
var $ = require('jquery');
var {upper} = require('helpers');
var {unitDisplayName} = require('unitsManager');
var {withTranslation} = require('react-i18next');
var RightArrow = require('images/icons/right.svg');
var LeftArrow = require('images/icons/left.svg');
var {EventPopUp} = require('./EventPopUp.jsx');
var annotationPlugin =require("chartjs-plugin-annotation");
const {logEntryPolyfills} = require("@babel/preset-env/lib/debug");
var fieldManager = require('fieldManager');
var distributionActions = require("@actions/distributionActions");

class SensorDataStream {
    constructor(id, samples, color, upperRange, lowerRange, displayName, transmitterId) {

        this.id = id;
        this.samples = samples;
        this.color = color;
        this.upperRange = upperRange;
        this.lowerRange = lowerRange;
        this.displayName = displayName;
        this.transmitterId = transmitterId;

        this.curDayOverLay = null;
    }
}

class SensorGraph extends React.Component {

    constructor(props) {
        super(props);
        this.updateChart = this.updateChart.bind(this);
        this.updateOverlaySize = this.updateOverlaySize.bind(this);
        this.manipulateDailyData = this.manipulateDailyData.bind(this);

        this.current_chart = null;
        this.dailyData = null;
        this.timezone = null;
        this.temperatureStatistics = {};
        this.etcStatistics = {};

        this.rainSamples = {};
        this.sensorData = {};
        this.upper_range = 0;
        this.lower_range = 0;

        this.DatesArray = [];
    }

    componentDidMount() {

        this.props.onRef(this);

        window.addEventListener("resize", this.updateOverlaySize);

        var canvas = document.getElementById('chartJSContainer');
        if (canvas) {
            this.setupChart();
        }

    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    isMobile() {
        return (window.innerWidth < 768);
    }


    setupChart() {
    const { t } = this.props;

        Chart.pluginService.register(annotationPlugin);
        var that = this;
        let options = {
            maintainAspectRatio: false,
            responsive: true,
            tension: 0.3,
            layout: {
                padding: {
                    left: 4,
                    right: 8,
                    top: 0,
                    bottom: !that.props.shouldDrawBottom ? 50 : 125
                }
            },
            tooltips: {
                mode: that.props.toolTipMode,
                intersect: false,
                caretPadding: 15,
                position: 'nearest',
                backgroundColor: '#4d5674',
                bodySpacing: 5,
                callbacks: {
                    title: function (tooltipItems) {
                        var date = that.current_chart.data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].x;
                        return that.props.shouldDiplayUTC ? moment(date).tz(that.timezone).format("DD/MM/YYYY HH:mm UTCZ") : moment(date).tz(that.timezone).format("DD/MM/YYYY HH:mm") ;
                    },
                    label: function (tooltipItem, data) {
                        let label = data.datasets[tooltipItem.datasetIndex].transmitterID;
                        let labels = []
                        if (label != data.datasets[tooltipItem.datasetIndex].displayName) {
                            label = data.datasets[tooltipItem.datasetIndex].displayName || '';
                        }
                        if (label) {
                            label += ': ';
                            label += Math.round(tooltipItem.yLabel * 100) / 100;
                            label += ' ' + that.props.units
                            labels.push(label)
                        }
                        if (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].dataToDisplay?.length) {
                            for (let i = 0; i < data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].dataToDisplay.length; i++) {
                                if (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].dataToDisplay[i].length) {
                                    labels.push(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].dataToDisplay[i])
                                }
                            }
                        }
                        return labels;
                    }
                    },
            },
            hover: {
                mode: that.props.toolTipMode,
                intersect: false
            },
            legend: {
                display: false,
            },
            chartArea: {
                backgroundColor: '#FFFFFF'
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        lineWidth: 1,
                        display: false,
                        color: "#eef1f6"
                    },
                    ticks: {
                        min: that.props.shouldSetMinMaxXAxes ? moment(moment().subtract(17,'d')) : null ,
                        display: false,
                        fontStyle: '600',
                        unit:"hour"
                    },
                    type: 'time',
                    distribution: that.props.distributionMode,
                }
                ],
                yAxes: [{
                    gridLines: {
                        lineWidth: 1,
                        display: true,
                        color: "#eef1f6",
                    },
                    ticks: {
                        fontColor: 'rgba(77, 86, 116,0.5)',
                        fontStyle: '400',
                        display: true,
                        beginAtZero: true,
                        sampleSize:3,
                        padding:4,
                        autoSkip:true,
                        autoSkipPadding:6,
                        maxTicksLimit:11,
                        suggestedMax: 1,
                        suggestedMin: -10,
                        callback: function (value, index, values) {
                            if (value >= 10000) {
                                return (value / 1e3).toFixed() + 'K';
                            }
                            else if (value >= 1000){
                                return (value / 1e3).toFixed(1) + 'K';
                            }

                            if ((index != 0) && (index != (values.length - 1))) {
                                return value;
                            } else {
                                return "";
                            }

                        },

                    },
                    scaleLabel: {
                        fontColor: 'rgba(77, 86, 116,0.5)',
                        fontStyle: '400',
                        display: true,
                        labelString: upper(that.props.units)
                    }
                }]
            },
            animation: {
                duration: 400,
                easing: 'easeInCubic',
            },
            annotation: {
                drawTime: "afterDatasetsDraw",
                annotations: [
                    {
                        drawTime: "afterDraw",
                        id: "a-line-1",
                        type: "line",
                        mode: "horizontal",
                        scaleID: "y-axis-0",
                        value: that.props.irrigation_hin ,
                        borderColor: that.props.irrigation_hin > 0 ? "rgba(246, 107, 122, 0.3)" : "transparent",
                        borderWidth: 2,
                        label: {
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            fontSize: 12,
                            fontStyle: "bold",
                            fontColor: "rgba(252, 211, 215, 1)",
                            xPadding: 4,
                            yPadding: 4,
                            cornerRadius: 3,
                            position: "left",
                            xAdjust: 6,
                            yAdjust: 0,
                            enabled: that.props.irrigation_hin > 0 ? true : false,
                            content: `Hin: ${that.props.irrigation_hin}`
                        },

                    },
                    {
                        drawTime: "afterDraw",
                        id: "a-line-2",
                        type: "line",
                        mode: "vertical",
                        scaleID: "x-axis-0",
                        value: moment(),
                        borderColor: that.props.shouldDrawTodayMark ? "rgb(246,107,122,0.30)" : "transparent",
                        borderWidth: 3,


                        label: {
                            backgroundColor: "transparent",
                            fontSize: 12,
                            fontStyle: "normal",
                            fontColor: "#919DB4",
                            position: "bottom",
                            xAdjust: 25,
                            yAdjust: 1,
                            enabled: that.props.shouldDrawTodayMark ? true : false,
                            content: "Today",
                            borderColor:"transparent",
                            align:"right",
                            borderWidth: 0,
                        },
                    }
                ]
            }
        };

        var data_info = {

            datasets: [
                {
                    label_indexes: [],
                    type: 'line',
                    label: 'line graph',
                    fill: 'true',
                    borderColor: '#4251b9',
                    backgroundColor: 'rgba(0,0,0,0.0)',
                    pointBackgroundColor: 'rgba(0,0,0,0.7)',
                    pointRadius: 0,
                    pointHitRadius: 8,
                    borderWidth: 2,
                    data: []
                },
            ],
        };

        var plugins = [{
            beforeDraw: function (chart, easing) {


                var chartArea = chart.chartArea;
                var ctx = chart.ctx;

                // set defaults
                ctx.textBaseline = 'alphabetic';
                ctx.textAlign = 'start';

                // Fill the chartarea with background according to the option parameter
                if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {

                    ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
                    ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
                }

                if (that.props.shouldDrawBottom) {
                    // bottom left side
                    ctx.fillStyle = '#F5F5F5';
                    ctx.fillRect(chartArea.left - 100, chartArea.bottom + 27, 100, 109)
                    // left side
                    ctx.fillStyle = '#eef1f6';
                    ctx.fillRect(chartArea.left - 100, chartArea.top, 100, chartArea.bottom - chartArea.top + 29); // 109
                }

                // bottom
                if (that.props.shouldDrawBottom) {
                    ctx.fillStyle = '#eef1f6';
                    ctx.fillRect(chartArea.left, chartArea.bottom + 27, chartArea.right - chartArea.left, 109);

                }
                else if (!that.props.shouldDrawBottom){
                    ctx.fillStyle = '#eef1f6';
                    ctx.fillRect(chartArea.left, chartArea.bottom, chartArea.right - chartArea.left, 29);
                    // left side
                    ctx.fillRect(chartArea.left - 100, chartArea.top, 100, chartArea.bottom - chartArea.top); // 109
                }



                if (that.props.shouldDrawBottom) {
                    // add titles to left bottom of the graph
                    ctx.fillStyle = 'rgba(145, 157, 180, 1)';

                    ctx.fillText("Min", chartArea.left - 40, chart.chartArea.bottom + 72);
                    ctx.fillText("Max", chartArea.left - 40, chart.chartArea.bottom + 72 + 25);
                    ctx.fillText("ETc", chartArea.left - 40, chart.chartArea.bottom + 72 + 50);
                }

                var xAxis = chart.scales['x-axis-0'];
                var tickGap = xAxis.getPixelForTick(1) - xAxis.getPixelForTick(0);
                let ticksGapInSeconds = xAxis._ticks.length > 0 ? (xAxis._ticks[xAxis._ticks.length - 1]?.value - xAxis._ticks[0]?.value) / 1000 : 0;
                let totalPixelsInChart = chart.chartArea.right - chart.chartArea.left;
                let copyOfStartDatesWFR = (that.dailyData && 'wfr' in that.dailyData) ? JSON.parse(JSON.stringify(that.dailyData.wfr.values)) : {}; // we create copy of the values so we could remove values after we add them
                let copyOfStartDatesRain = JSON.parse(JSON.stringify(that.rainSamples))
                let copyOfStartDatesFertilizers = (that.dailyData && 'fert' in that.dailyData) ? JSON.parse(JSON.stringify(that.dailyData["fert"].values)) : {}
                let five_days = 60 * 60 * 24 * 5;

                let pointsArray = [];
                let additionals = document.getElementById('additionals');
                if (additionals) {

                    while (additionals.firstChild) additionals.removeChild(additionals.firstChild);

                    // paint additional values to the bottom of the graph day by day.
                    Chart.helpers.each(xAxis.ticks, function (tick, index) {

                        let etcValue = "";
                        let minTempValue = "";
                        let maxTempValue = "";
                        let wfrStartDate = undefined;
                        let fertStartDate = undefined;
                        let rainStartDate = undefined;
                        let dayPassed = false;
                        let rainObject = undefined;

                        var last_date = index > 0 ? moment(xAxis._ticks[index - 1]?.value).tz(that.timezone) : null;

                        if (that.dailyData &&
                            that.etcStatistics &&
                            that.temperatureStatistics) {

                            let todayTimeStamp = xAxis._ticks[index]?.value / 1000;

                            let graphStartDate = xAxis._ticks[0]?.value / 1000;
                            let graphEndDate = xAxis._ticks[xAxis._ticks.length - 1]?.value / 1000;

                            // get values as close as possible to the given date
                            let findCloseDateFunc = function (minTimestamp, currentTimeStamp) {
                                if (Math.abs(parseInt(currentTimeStamp) - todayTimeStamp) < Math.abs(parseInt(minTimestamp) - todayTimeStamp)) {
                                    return currentTimeStamp;
                                } else {
                                    return minTimestamp;
                                }
                            };

                            if ("fert" in that.dailyData) {

                                // Check if fertilization values exist, in case they are, we calculate the efr length by considering the proportion of the entire graph width.
                                fertStartDate = Object.keys(copyOfStartDatesFertilizers).reduce(findCloseDateFunc, 9999999999);
                                let fertilizersObject = copyOfStartDatesFertilizers[fertStartDate];

                                if (fertilizersObject && Math.abs(todayTimeStamp - parseInt(fertStartDate)) < five_days &&
                                    (todayTimeStamp > fertStartDate && graphStartDate < (parseInt(fertStartDate) + 60 * 60 * 24) && graphEndDate > fertStartDate)) {
                                    delete copyOfStartDatesFertilizers[fertStartDate];
                                } else {
                                    fertStartDate = undefined;
                                }
                            }

                            if ("wfr" in that.dailyData) {
                                // Check if WFR values exist, in case they are, we calculate the efr length by considering the proportion of the entire graph width.
                                wfrStartDate = Object.keys(copyOfStartDatesWFR).reduce(findCloseDateFunc, 9999999999);

                                let wfrObject = copyOfStartDatesWFR[wfrStartDate];

                                if (wfrObject && Math.abs(todayTimeStamp - parseInt(wfrStartDate)) < five_days &&
                                    (todayTimeStamp > parseInt(wfrStartDate) && graphStartDate < wfrObject.endDate && graphEndDate > parseInt(wfrStartDate))) {

                                    delete copyOfStartDatesWFR[wfrStartDate];
                                    wfrStartDate = wfrStartDate;
                                } else {
                                    wfrStartDate = undefined;
                                }
                            }


                            if ("precipitation_h" in that.dailyData) {
                                // Check if precipitation_h values exist, in case they are, we calculate the efr length by considering the proportion of the entire graph width.
                                rainStartDate = Object.keys(copyOfStartDatesRain).reduce(findCloseDateFunc, 9999999999);
                                rainObject = copyOfStartDatesRain[rainStartDate];

                                if (rainObject && Math.abs(todayTimeStamp - parseInt(rainStartDate)) < five_days &&
                                    (todayTimeStamp > rainStartDate && graphStartDate < rainObject.endDate && graphEndDate > rainStartDate)) {

                                    delete copyOfStartDatesRain[rainStartDate];
                                    rainStartDate = rainStartDate;
                                } else {
                                    rainStartDate = undefined;
                                }
                            }

                            // These values we treat on a daily basis so only when a day passed since last check
                            var current_date = moment(xAxis._ticks[index]?.value).tz(that.timezone);
                            if (!last_date || (last_date.format('YYYYMMDD') != current_date.format('YYYYMMDD'))) {

                                let todayTimeStampMidnight = moment(current_date).startOf('day').unix();
                                let todayTimeStampMidnightETc = moment(current_date?.add(1, 'days')).startOf('day').unix();

                                if (todayTimeStampMidnightETc in that.etcStatistics) {
                                    etcValue = that.etcStatistics[todayTimeStampMidnightETc]["lastValue"] + unitDisplayName(that.dailyData.etc.units);
                                }

                                if (todayTimeStampMidnight in that.temperatureStatistics) {
                                    maxTempValue = that.temperatureStatistics[todayTimeStampMidnight]["maxValue"] + unitDisplayName(that.dailyData.temperature_h.units);
                                    minTempValue = that.temperatureStatistics[todayTimeStampMidnight]["minValue"] + unitDisplayName(that.dailyData.temperature_h.units);
                                }

                                dayPassed = true;
                            }
                        }

                        // Display calculated values from previous check
                        var yPos = chart.chartArea.bottom;
                        var xPos = xAxis.getPixelForTick(index);

                        // Present WFR
                        if (that.props.shouldDrawInputs) {

                            if (wfrStartDate) {
                                let wfrObject = that.dailyData.wfr.values[wfrStartDate];
                                let wfrLengthObject = that.dailyData.wfr_length.values[wfrStartDate];

                                // adjusting irrigation length
                                let irrigationLength = wfrObject.endDate - wfrStartDate;
                                let proportion = irrigationLength / ticksGapInSeconds;
                                let wfrLength = totalPixelsInChart * proportion; // pixels for current irrigation
                                var maxLength = (chart.chartArea.right - chart.chartArea.left + 50) - xPos; // chart width - current x position + 50 (left side)
                                wfrLength = Math.max(3, wfrLength);
                                wfrLength = Math.min(maxLength, wfrLength);

                                // Get values to display
                                let wfrIrrigationValue = wfrObject?.value || 0;
                                let beginning = moment.unix(wfrStartDate).tz(that.timezone);
                                let end = moment.unix(wfrObject.endDate).tz(that.timezone);


                                var duration = moment.duration(end.diff(beginning));
                                var hours = duration.asHours();
                                let rate = wfrIrrigationValue / hours;

                                let rightMenuClass = (chart.chartArea.right - xPos) > 150 ? "" : "dropdown-menu-right";

                                let valves = wfrObject.valves ? wfrObject.valves.join(', ') : '';
                                let unitText = that.dailyData.wfr_length.units;
                                let value =  wfrLengthObject?.value?.toFixed(2).toString();
                                let enableDelete = wfrObject.sensor_id === "manual" || that.props?.hasPermission;
                                let modal = EventPopUp(beginning,end,"irrigation",unitText,value,wfrObject,enableDelete,"sensorGraph", that.timezone);
                                var currentChild = document.createElement('div');
                                currentChild.innerHTML = "<div class='dropdown-hover bg-blue-secondary position-absolute' style='height:9px; width:" + wfrLength.toString() + "px; left:" + xPos.toString() + "px; top:" + yPos.toString() + "px'>" +
                                    "<a href='#' class=''  data-toggle='dropdown' role='button' aria-haspopup='true' aria-expanded='false'> </a>" +
                                    "<div style='border-radius: 5px;box-shadow: 0px 4px 10px rgba(145, 157, 180, 0.19)' class='dropdown-menu pr-2 pl-2 mt-0 " + rightMenuClass + "'>" +
                                    modal+
                                    "</div>" +
                                    "</div>";

                                currentChild.onclick = function (evt) {

                                    if (evt) {
                                        evt.preventDefault();
                                        evt.stopPropagation();
                                    }
                                    if (evt.srcElement.id == "deleteIrrigationButton") {
                                        let date = evt.srcElement.attributes.date?.value / 1000.0;
                                        let disableEventRecovery = (/true/).test(evt.srcElement.attributes.disableeventrecovery.value);
                                        let sensorId = evt.srcElement.attributes.sensorid.value;
                                        fieldManager.deleteIrrigationEvent({startDate: date, source: sensorId}, disableEventRecovery, that.props.selectedField);
                                        that.props.getFieldHistory(that.props.selectedDistribution.id, that.props.selectedFarmingUnit.id, that.props.selectedField.id);
                                    }
                                };

                                document.getElementById('additionals').appendChild(currentChild);
                            }

                            // Present Rain
                            if (rainStartDate) {

                                // adjusting precipitation length
                                let rainLength = rainObject.endDate - rainStartDate;
                                let proportion = rainLength / ticksGapInSeconds;
                                let precipitationLength = totalPixelsInChart * proportion; // pixels for current irrigation
                                var maxLength = (chart.chartArea.right - chart.chartArea.left + 50) - xPos; // chart width - current x position + 50 (left side)
                                precipitationLength = Math.max(3, precipitationLength);
                                precipitationLength = Math.min(maxLength, precipitationLength);

                                // Get values to display
                                let rainPrecipitationValue = rainObject?.value;
                                let beginning = moment.unix(rainStartDate).tz(that.timezone);
                                let end = moment.unix(rainObject.endDate).tz(that.timezone);

                                let unitText = unitDisplayName(that.dailyData.precipitation_h.units);
                                let value = rainPrecipitationValue.toFixed(2).toString();
                                let enableDelete = rainObject.source === "manual" || that.props?.hasPermission;
                                let modal = EventPopUp(beginning, end, "rain", unitText, value, rainObject, enableDelete, "sensorGraph", that.timezone);

                                let rightMenuClass = (chart.chartArea.right - xPos) > 150 ? "" : "dropdown-menu-right";
                                var currentChild = document.createElement('div');
                                currentChild.innerHTML = "<div class='dropdown dropdown-hover bg-blue-third position-absolute' style='height:9px; width:" + precipitationLength.toString() + "px; left:" + xPos.toString() + "px; top:" + (yPos + 9).toString() + "px'>" +
                                    "<a href='#' class=''  data-toggle='dropdown' role='button' aria-haspopup='true' aria-expanded='false'> </a>" +
                                    "<div style='border-radius: 5px;box-shadow: 0px 4px 10px rgba(145, 157, 180, 0.19)' class='dropdown-menu pr-2 pl-2 mt-0 " + rightMenuClass + " bg-white'>" +
                                    modal +
                                    "</div>" +
                                    "</div>";
                                currentChild.onclick = function (evt) {

                                    if (evt) {
                                        evt.preventDefault();
                                        evt.stopPropagation();
                                    }
                                    if (evt.srcElement.id == "deletePrecipitationButton") {
                                        let date = evt.srcElement.attributes.date?.value / 1000.0;
                                        let source = evt.srcElement.attributes.source.value;
                                        that.props.onPrecipitationDeletion(date, source);
                                    }
                                };
                                document.getElementById('additionals').appendChild(currentChild);
                            }

                            // present fertilizers
                            if (fertStartDate) {

                                let fertObj = "";
                                let unitsDisplayNameLabel = "";
                                if (("fert" in that.dailyData) && (fertStartDate in that.dailyData["fert"].values)) {
                                    fertObj = that.dailyData["fert"].values[fertStartDate];
                                    unitsDisplayNameLabel = unitDisplayName(that.dailyData["fert"].units);
                                }

                                // adjusting irrigation length
                                let fertLength = fertObj.endDate ? parseInt(fertObj.endDate) - fertStartDate : 60*60*4;
                                let proportion = fertLength / ticksGapInSeconds;
                                fertLength = totalPixelsInChart * proportion; // pixels for current irrigation
                                var maxLength = (chart.chartArea.right - chart.chartArea.left + 50) - xPos; // chart width - current x position + 50 (left side)
                                fertLength = Math.max(3, fertLength);
                                fertLength = Math.min(maxLength, fertLength);

                                // Get values to display
                                let beginning = moment.unix(fertStartDate).tz(that.timezone);
                                let end = moment.unix(fertStartDate +( 60 * 60 * 24)).tz(that.timezone);

                                let rightMenuClass = (chart.chartArea.right - xPos) > 150 ? "" : "dropdown-menu-right";
                                let unitText = unitsDisplayNameLabel;
                                let value = fertObj.nitroAmount.toFixed(2).toString();
                                fertObj.fertAmount = fertObj.value

                                let modal =  EventPopUp(beginning, end, "fertilization", unitText, value, fertObj, true, "sensorGraph", that.timezone, t);

                                var currentChild = document.createElement('div');
                                currentChild.innerHTML = "<div class='dropdown dropdown-hover bg-green2 position-absolute' style='height:9px; width:" + fertLength.toString() + "px; left:" + xPos.toString() + "px; top:" + (yPos + 18).toString() + "px'>" +
                                    "<a href='#' class='' data-toggle='dropdown' role='button' aria-haspopup='true' aria-expanded='false'> </a>" +
                                     "<div style='border-radius: 5px;box-shadow: 0px 4px 10px rgba(145, 157, 180, 0.19)' class='dropdown-menu pr-2 pl-2 mt-0 " + rightMenuClass + " bg-white'>" +
                                    modal+
                                    "</div>" +
                                    "</div>";

                                currentChild.onclick = function (evt) {

                                    if (evt) {
                                        evt.preventDefault();
                                        evt.stopPropagation();
                                    }

                                    if (evt.srcElement.id == "deleteFertilizationButton") {
                                        fieldManager.deleteFertilizationEvent({startDate:(evt.srcElement.attributes.date?.value / 1000.0)}, that.props.selectedField);
                                        that.props.getFieldHistory(that.props.selectedDistribution.id, that.props.selectedFarmingUnit.id, that.props.selectedField.id);
                                    }
                                };

                                document.getElementById('additionals').appendChild(currentChild);
                            }
                        }

                        // eliminate too close tick neighbors
                        let filteredArray = pointsArray.filter(function (currentPoint) {

                            if ((xPos - currentPoint.x) < 75) {
                                return true;
                            } else {
                                return false;
                            }
                        });

                        ctx.font = "600 12px Arial";
                        if (!that.props.shouldDrawBottom){
                            ctx.font = "400 12px Source Sans Pro";
                            yPos -= 27;
                        }
                        ctx.rect(xPos, yPos + 30, tickGap, 1000);

                        // place text labels according to current tick position
                        ctx.textBaseline = 'middle';
                        ctx.textAlign = 'center';

                        // handle left edge of the chart
                        if ((xPos - chartArea.left) < 25) {
                            ctx.textAlign = 'left';
                            ctx.textBaseline = 'start';
                            xPos = chartArea.left + 3;

                            if (tick.length > 10) {
                                tick = "";
                            }
                        }

                        // handle right edge of the chart
                        if ((chartArea.right - xPos) < 25) {
                            ctx.textBaseline = 'end';
                            ctx.textAlign = 'right';
                            xPos = chartArea.right - 3;

                            if (tick.length > 10) {
                                tick = "";
                            }
                        }

                        if ((filteredArray.length == 0) && dayPassed && (tick != "")) {
                            // dates on x axis
                            pointsArray.push({x: xPos, y: yPos});
                            ctx.fillStyle = 'rgba(77, 86, 116,1)';
                            ctx.fillText(tick, xPos, yPos + 30 + 4 + 9);

                            if ((minTempValue != "") || (maxTempValue != "") || (etcValue != "")) {
                                ctx.fillStyle = 'rgba(77, 86, 116,0.5)';

                                ctx.fillText(minTempValue, xPos, yPos + 20 + 40 + 9);
                                ctx.fillText(maxTempValue, xPos, yPos + 20 + 40 + 25 + 9);
                                ctx.fillText(etcValue, xPos, yPos + 20 + 40 + 50 + 9);
                            }
                        }
                    });
                }
            },
        }];


        var all = {
            type: 'line',
            data: data_info,
            options: options,
            plugins: plugins,

        }

        var canvas = document.getElementById('chartJSContainer');
        var ctx = canvas.getContext('2d');

        this.current_chart = new Chart(ctx, all);

        var overlay = document.getElementById('overlay');

        var startIndex = [];

        this.updateOverlaySize();

        var selectionContext = overlay.getContext('2d');
        selectionContext.fillStyle = "#919DB4";


        var selectionRect = {
            w: 0,
            startX: 0,
            startY: 0
        };
        var drag = false;

        // get the proportion
        var getProportion = (evt) => {
            var width = this.current_chart.chartArea.right - this.current_chart.chartArea.left - (this.isMobile() ? 0 : 50);
            var currentPos = evt.layerX - this.current_chart.chartArea.left;
            let prop = currentPos / width;

            if (prop > 1) {
                prop = 1;
            } else if (prop < 0) {
                prop = 0;
            }

            return prop;
        }

        canvas.addEventListener('mousedown', evt => {
            if (that.props.shouldDisplayZoom) {
                if (!drag) {
                    startIndex = [];
                    let prop = getProportion(evt);
                    let activeTooltipIndex = this.current_chart.tooltip._active[0]?._index;

                    for (let currentSensorKey in this.sensorData) {

                        startIndex.push(activeTooltipIndex);
                    }
                    const rect = canvas.getBoundingClientRect();
                    selectionRect.startX = evt.clientX - rect.left;
                    selectionRect.startY = this.current_chart.chartArea.top;
                    drag = true;
                }
            }
        });

        var stopDragging = (evt) => {
            selectionContext.clearRect(0, 0, overlay.width, overlay.height);
            drag = false;
        }

        var endSelection = (evt) => {
            if (drag) {
                let shouldUpdateChart = true;
                drag = false;
                let activeTooltipIndex = this.current_chart.tooltip._active[0]?._index;
                selectionContext.clearRect(0, 0, overlay.width, overlay.height);

                for (let currentSensorKey in this.sensorData) {
                    var index = Object.keys(this.sensorData).indexOf(currentSensorKey);
                    let currentSensor = this.sensorData[currentSensorKey];
                    let currentDataSet = this.current_chart.data.datasets.filter(x => x.label == currentSensorKey)
                    if (currentDataSet.length > 0) {
                        var upperRange = Math.max(currentDataSet[0].label_indexes[activeTooltipIndex], currentDataSet[0].label_indexes[startIndex[index]]);
                        var lowerRange = Math.min(currentDataSet[0].label_indexes[activeTooltipIndex], currentDataSet[0].label_indexes[startIndex[index]]);
                        if (upperRange == lowerRange) {
                            shouldUpdateChart = false;
                        } else {
                            shouldUpdateChart = true;
                            this.validateRange(currentSensor, lowerRange, upperRange);
                        }

                    }
                }
                if (shouldUpdateChart) {
                    this.updateChart(this.sensorData, undefined);
                }

            }
        }
        canvas.addEventListener('mouseout', evt => {

            // stopDragging(evt);
        });

        canvas.addEventListener('mousemove', evt => {

            const rect = canvas.getBoundingClientRect();
            if (drag) {
                selectionRect.w = (evt.clientX - rect.left) - selectionRect.startX;

                let prop = getProportion(evt);

                if (prop == 0) {

                    // stopDragging(evt);
                } else {

                    selectionContext.globalAlpha = 0.5;

                    selectionContext.clearRect(0, 0, overlay.width, overlay.height);
                    selectionContext.fillRect(selectionRect.startX,
                        selectionRect.startY,
                        selectionRect.w,
                        this.current_chart.chartArea.bottom - this.current_chart.chartArea.top);
                }

            } else {
                selectionContext.clearRect(0, 0, overlay.width, overlay.height);
                var x = evt.clientX - rect.left;
                if (x > this.current_chart.chartArea.left) {

                    selectionContext.fillRect(x,
                        this.current_chart.chartArea.top,
                        1,
                        this.current_chart.chartArea.bottom - this.current_chart.chartArea.top);
                }
            }
        });

        canvas.addEventListener('mouseup', evt => {
            endSelection(evt);
        });
    }

    updateOverlaySize() {

        var overlay = document.getElementById('overlay');

        // overlay.style.backgroundColor = "red";

        if (overlay && this.current_chart && this.current_chart.chartArea) {

            overlay.height = this.current_chart.chartArea.bottom - this.current_chart.chartArea.top + 50;
            overlay.width = this.current_chart.chartArea.right - this.current_chart.chartArea.left + 50;
            overlay.color = "#919DB4"

        }

        this.setState({});
    }


    manipulateDailyData(dailyData) {
        var that = this;

        this.rainSamples = {};
        let rainObjects = (dailyData && 'precipitation_h' in dailyData) ? JSON.parse(JSON.stringify(dailyData.precipitation_h.values)) : undefined; // we create copy of the values so we could remove values after we add them
        that.rainSamples = rainObjects || {};

    }

    loadData(graphSamples, dailyData, suggestedMinimum, timezone, temperatureStatistics, etcStatistics) {

        if (!graphSamples || !dailyData || !timezone) {
            this.dailyData = {};
            this.sensorData = {};
            this.timezone = undefined;
            this.temperatureStatistics = {};
            this.etcStatistics = {};
        } else {
            this.dailyData = dailyData;
            this.sensorData = {};
            this.timezone = timezone;
            this.temperatureStatistics = temperatureStatistics;
            this.etcStatistics = etcStatistics;
            this.manipulateDailyData(dailyData);

            for (var sensorKey in graphSamples) {
                let currentSensorSample = graphSamples[sensorKey];
                if (currentSensorSample && currentSensorSample.data && (currentSensorSample.data.length > 0)) {

                    let newInstance = new SensorDataStream(currentSensorSample.id, currentSensorSample.data, currentSensorSample.color, 0, 0, currentSensorSample.displayName, currentSensorSample.transmitter_id);
                    this.sensorData[currentSensorSample.id] = newInstance;

                    this.validateRange(newInstance, 0, newInstance.samples.length - 1);
                }
            }
        }
        this.updateChart(this.sensorData, suggestedMinimum);
    }

    validateRange(sensorDataStream, lower_range, upper_range, must_move_together) {

        if (sensorDataStream && sensorDataStream.samples) {

            if (sensorDataStream.samples.length == 0) {
                sensorDataStream.lowerRange = 0;
                sensorDataStream.upperRange = 0;

            } else {
                let global_upper = sensorDataStream.samples.length - 1;
                let global_lower = 0;

                if (upper_range <= lower_range) {
                    return;
                }

                if ((must_move_together) && ((upper_range > global_upper) || (lower_range < global_lower))) {
                    // Move one point to the edge but keep the other stand stil.
                    if (global_upper < upper_range) {
                        sensorDataStream.upperRange = global_upper;
                    }

                    if (global_lower > lower_range) {
                        sensorDataStream.lowerRange = global_lower;
                    }
                } else {
                    sensorDataStream.upperRange = Math.min(upper_range, global_upper);
                    sensorDataStream.lowerRange = Math.max(lower_range, global_lower);
                }
            }
        }
    }

    updateChart(sensorsData, suggestedMinimum) {

        var that = this;

        if (sensorsData) {

            if (this.current_chart) {
                this.current_chart.data.datasets = [];
                // let index = 0;
                for (var sensorKey in sensorsData) {
                    let currentSensorSample = sensorsData[sensorKey];
                    let filteredData = currentSensorSample.samples.slice(currentSensorSample.lowerRange, currentSensorSample.upperRange + 1);
                    var time_unit = this.calculateTimeUnit(filteredData);
                    var [spacedData, dataMaxValue] = this.spacedData(filteredData); // spacing the data since we don't need an high resolutino of items
                    var labelIndexes = spacedData ? spacedData.map((sample) => {
                        return sample.index
                    }) : [];

                    let newDataset = {
                        lineTension: 0,
                        type: 'line',
                        label: currentSensorSample.id,
                        transmitterID: currentSensorSample.transmitterId,
                        displayName: currentSensorSample.displayName,
                        fill: 'false',
                        label_indexes: labelIndexes,
                        borderColor: currentSensorSample.color,
                        backgroundColor: 'rgba(0,0,0,0.0)',
                        pointBackgroundColor: currentSensorSample.color,
                        pointRadius: 0,
                        pointHitRadius: 8,
                        borderWidth: 2,
                        data: spacedData
                    };
                    this.current_chart.data.datasets.push(newDataset);
                }

                if (this.props.irrigation_hin) {
                    this.current_chart.annotation.elements["a-line-1"].options.value = this.props.irrigation_hin;
                    this.current_chart.annotation.elements["a-line-1"].options.label.content = "Hin: " + this.props.irrigation_hin;
                    this.current_chart.annotation.elements["a-line-1"].options.borderColor = "rgba(246, 107, 122, 0.3)";
                    this.current_chart.annotation.elements["a-line-1"].options.label.enabled = true;
                } else {
                    this.current_chart.annotation.elements["a-line-1"].options.value = 0;
                    this.current_chart.annotation.elements["a-line-1"].options.borderColor = "transparent";
                    this.current_chart.annotation.elements["a-line-1"].options.label.enabled = false;
                }
                this.current_chart.options.scales.xAxes[0].time.unit = time_unit;
                this.current_chart.options.scales.xAxes[0].ticks = {...this.current_chart.options.scales.xAxes[0].ticks,
                    display: false,
                    fontStyle: '600',
                    callback: function (value, index, values) {

                        // Add the day label every time a date is changing
                        if ((["hour", "minute",'day','week'].includes(time_unit)) && (values.length > 0)) {
                            var current_date = moment(values[index].value).tz(that.timezone);

                            var last_date = index > 0 ? moment(values[index - 1].value).tz(that.timezone) : null;

                            if (!last_date || (last_date.format('YYYYMMDD') != current_date.format('YYYYMMDD'))) {
                                return current_date.format("MMM DD");
                            }
                        }


                        return value;
                    },
                };

                let etcIndex, irrPrecpIndex = undefined;
                if (suggestedMinimum != undefined) {
                    this.current_chart.options.scales.yAxes[0].ticks.suggestedMin = suggestedMinimum;
                }
                
                if (dataMaxValue < this.props.irrigation_hin) {
                    this.current_chart.options.scales.yAxes[0].ticks.max = parseInt(this.props.irrigation_hin) + 50;
                }
                for (let index in this.current_chart.config.data.datasets) {
                    if (this.current_chart.config.data.datasets[index]?.label === "Irrigation Precipitation") {
                        this.current_chart.config.data.datasets[index].borderWidth = 3;
                        irrPrecpIndex = index;
                    } else if (this.current_chart.config.data.datasets[index]?.label === "ETc") {
                        this.current_chart.config.data.datasets[index].borderWidth = 3;
                        etcIndex = index
                    } else if (that.props.shouldDrawTodayMark){
                        this.current_chart.config.data.datasets[index].borderWidth = 1;
                    }
                    if (this.current_chart.config.data.datasets[index]?.label === "Future Planned irrigation") {
                        this.current_chart.config.data.datasets[index].borderDash = [2,3];
                        // don't display the 'past' of the Future Planned line
                        for (let sample in this.current_chart.config.data.datasets[index].data){
                            if (this.current_chart.config.data.datasets[index].data[sample]['y'] == 0){
                                this.current_chart.config.data.datasets[index].data[sample]['y'] = null;
                            }
                        }
                    }
                }
                if (irrPrecpIndex && etcIndex) {
                    this.current_chart.config.data.datasets[etcIndex].fill = irrPrecpIndex
                    this.current_chart.config.data.datasets[etcIndex].backgroundColor = "rgb(238,180,141,0.2)" // == #EEB48D with 0.2 opacity
                }
                this.current_chart.update();
            }
        }
    }

    calculateTimeUnit(filtered_samples) {

        var time_unit = 'day';
        if (filtered_samples.length > 0) {
            var first_date = filtered_samples[0].date;
            var last_date = filtered_samples[filtered_samples.length - 1].date;
            var hour_diff = Math.abs(last_date.diff(first_date, 'hour'));

            if (hour_diff < 6) {
                time_unit = 'minute';
            } else if (hour_diff < 72) {
                time_unit = 'hour';
            } else if (hour_diff < 380) {
                time_unit = 'day';
            } else {
                time_unit = 'week';
            }
        }

        return time_unit;
    }

    spacedData(samples) {
        let shrinker = 1;
        if (this.props.shouldSpaceData){
            shrinker = samples.length > 2000 ? 6 : samples.length > 1000 ? 5 : samples.length > 500 ? 3 : samples.length > 50 ? 2 : 1;
        }
        let maxValue=Math.max(...samples.map(o => o.value));
        var data = samples.filter(function (value, index) {
            return (index % shrinker === 0)
        }).map((sample, index) => {
            return {y: sample.value, x: sample.date, index: sample.index, dataToDisplay: sample.dataToDisplay};
        });
        return [data,maxValue];
    }

    xAxesChange(type) {

        if (type === 'back') {
            let currentMin =  this.current_chart.options.scales.xAxes[0].ticks.min.clone();
            let currentMax =  this.current_chart.options.scales.xAxes[0].ticks.max ? this.current_chart.options.scales.xAxes[0].ticks.max : this.current_chart?.config?.data?.datasets[0]?.data[0]?.x;
            // check if possible go back
            if (this.props.changeXAxes(moment(currentMin).unix() , moment(currentMax).unix())){
            this.current_chart.options.scales.xAxes[0].ticks.max ? this.current_chart.options.scales.xAxes[0].ticks.max.subtract(7, "d") : this.current_chart.options.scales.xAxes[0].ticks.max = this.current_chart?.config?.data?.datasets[0]?.data[0]?.x.subtract(7, "d");
            this.current_chart.options.scales.xAxes[0].ticks.min.subtract(7, "d");
            this.props.scaleGraph(moment(this.current_chart.options.scales.xAxes[0].ticks.min).unix(), moment(this.current_chart.options.scales.xAxes[0].ticks.max).unix());
            }
        } else if (type === 'forward' && this.current_chart.options.scales.xAxes[0].ticks.max) {
            let currentMax = this.current_chart.options.scales.xAxes[0].ticks.max.clone()
            let dateAfterForwardClick = currentMax.add(7, "d")
            let day_in_unx = 60 * 60 * 24
            let daysDiff = (dateAfterForwardClick.unix() - currentMax.unix()) / day_in_unx

            // check if possible go forward
            if (daysDiff < 7 && dateAfterForwardClick.unix() < moment().unix()) {
                this.current_chart.options.scales.xAxes[0].ticks.min.add(7, "d");
                this.current_chart.options.scales.xAxes[0].ticks.max.add(7, "d");
                this.props.scaleGraph(moment(this.current_chart.options.scales.xAxes[0].ticks.min).unix(), moment(this.current_chart.options.scales.xAxes[0].ticks.max).unix());
            }
        }
        let filteredDataSet = this.current_chart?.config?.data?.datasets[0]?.data?.filter((dataObj) => {
            if (dataObj.x.unix() >= moment(this.current_chart.options.scales.xAxes[0].ticks.min).unix() && dataObj.x.unix() <= moment(this.current_chart.options.scales.xAxes[0].ticks.max).unix()) {
                return dataObj;
            }
        })
        let maxYValue;
        if(filteredDataSet === undefined){
            maxYValue = 0;
        } else {
             maxYValue = Math.max(...filteredDataSet?.map((dataObj) => {
            return dataObj.y
        }))}

        if (parseInt(this.props.irrigation_hin) > maxYValue) {
            this.current_chart.options.scales.yAxes[0].ticks.max = parseInt(this.props.irrigation_hin) + 50;
        } else {
            this.current_chart.options.scales.yAxes[0].ticks.max = maxYValue + 50;
        }
        this.current_chart.update();
    }

    zoom(type) {

        for (let currentSensorKey in this.sensorData) {
            let currentSensor = this.sensorData[currentSensorKey];

            let upper_range_zoom_factor = type == "in" ? -1 : 1;
            let lower_range_zoom_factor = type == "out" ? -1 : 1;

            let current_upper_range = currentSensor.upperRange;
            let current_lower_range = currentSensor.lowerRange;

            var index_diff = current_upper_range - current_lower_range;
            var zoom_value = parseInt(Math.max(index_diff / 10, 1));

            current_upper_range = current_upper_range + (upper_range_zoom_factor * zoom_value);
            current_lower_range = current_lower_range + (lower_range_zoom_factor * zoom_value);

            this.validateRange(currentSensor, current_lower_range, current_upper_range);
        }
        this.updateChart(this.sensorData, undefined);

    }

    move(type) {

        for (let currentSensorKey in this.sensorData) {
            let currentSensor = this.sensorData[currentSensorKey];
            let move_factor = type == "right" ? 1 : -1;

            let current_upper_range = currentSensor.upperRange;
            let current_lower_range = currentSensor.lowerRange;

            var index_diff = current_upper_range - current_lower_range;
            var move_value = parseInt(Math.max((index_diff / 3), 1));

            current_upper_range = current_upper_range + (move_factor * move_value);
            current_lower_range = current_lower_range + (move_factor * move_value);

            this.validateRange(currentSensor, current_lower_range, current_upper_range, true);
        }
        this.updateChart(this.sensorData, undefined);
    }

    // Reset graph to original zoom levels
    resetGraph() {
        for (let currentSensorKey in this.sensorData) {
            let currentSensor = this.sensorData[currentSensorKey];

            this.validateRange(currentSensor, 0, currentSensor.samples.length - 1);
        }
        this.updateChart(this.sensorData, undefined);
    }

    render() {

        if (this.current_chart) {
            this.current_chart.config.options.scales.yAxes[0].scaleLabel.labelString = this.props.units;
            this.current_chart.config.options.plugins.filler.propagate = false;
        }

    const pStyle = {
      'pointerEvents':'none'
    };

        let homeButton = <a className="btn btn-link  pr-0 pl-0" onClick={() => this.resetGraph()}><img
                            className="image-buttons" src={require('images/icons/dashboard_states/zoom_extent_idle.svg')}
                            onMouseOver={(obj) => obj.target.src = require('images/icons/dashboard_states/zoom_extent_hover.svg')}
                            onMouseDown={(obj) => obj.target.src = require('images/icons/dashboard_states/zoom_extent_press.svg')}
                            onMouseOut={(obj) => obj.target.src = require('images/icons/dashboard_states/zoom_extent_idle.svg')}
                            alt="home"/></a>

        let secondButton = <a className="btn btn-link  pr-0 pl-0" onClick={!this.props.shouldDisplayZoom ? () => this.xAxesChange("back") : () => this.zoom("in")}><img
                            className="image-buttons" src={!this.props.shouldDisplayZoom ? LeftArrow : require('images/icons/dashboard_states/zoom_in_idle.svg')}
                            onMouseOver={this.props.shouldDisplayZoom ? (obj) => obj.target.src = require('images/icons/dashboard_states/zoom_in_hover.svg') : null}
                            onMouseDown={this.props.shouldDisplayZoom ? (obj) => obj.target.src = require('images/icons/dashboard_states/zoom_in_press.svg') : null}
                            onMouseOut={this.props.shouldDisplayZoom ? (obj) => obj.target.src = require('images/icons/dashboard_states/zoom_in_idle.svg') : null}
                            alt="zoom in"/></a>
        let thirdButton = <a className="btn btn-link  pr-0 pl-0" onClick={!this.props.shouldDisplayZoom ? () => this.xAxesChange("forward") : () => this.zoom("out")}><img
                            className="image-buttons" src={!this.props.shouldDisplayZoom ? RightArrow : require('images/icons/dashboard_states/zoom_out_idle.svg')}
                            onMouseOver={this.props.shouldDisplayZoom ? (obj) => obj.target.src = require('images/icons/dashboard_states/zoom_out_hover.svg') : null}
                            onMouseDown={this.props.shouldDisplayZoom ? (obj) => obj.target.src = require('images/icons/dashboard_states/zoom_out_press.svg') : null}
                            onMouseOut={this.props.shouldDisplayZoom ? (obj) => obj.target.src = require('images/icons/dashboard_states/zoom_out_idle.svg') : null}
                            alt="zoom out"/></a>

        return (
            <div className="d-flex flex-row h-100">

                <div className="flex-fill">

                    <div className="" style={{position: "relative", width: "99%", height: "100%", margin: "auto"}}>
                        <canvas id="overlay" className="position-absolute" style={pStyle}/>
                        <div className="" id="additionals"></div>
                        <canvas className="" id="chartJSContainer"></canvas>
                    </div>
                </div>
                {!this.props.hideNavigationButtons &&
                <div className="d-none d-sm-block" style={{'width': '50px'}}>
                    <div className="d-flex justify-content-end  flex-column ">
                        {this.props.shouldDrawResetButton && homeButton}
                        {secondButton}
                        {thirdButton}
                    </div>
                </div>}
            </div>
        );
    }
};

const mapStateToProps = function (state) {
    const field = state.distribution_data.selected_entities.field;
    const role = state.distribution_data.selected_entities?.farming_unit?.role;

    return {
        plan_irr: field && field.historical_data && field.historical_data.plan_irr,
        fert: field && field.historical_data && field.historical_data.fert,
        hasPermission: ["moderator", "admin"].includes(role)
    }
}

const mapDispatchToProps = {
    getFieldHistory: distributionActions.get_field_history,
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SensorGraph));


/*

<div className="d-flex " style={{'height':'400px'}}>

  <div className=" bg-blue" >
    <canvas id="overlay" className="position-absolute" style={pStyle}/>
    <div className="" id="wfr"></div>
    <canvas className="" id="chartJSContainer"></canvas>

  </div>
  <div className="" style={{'width':'50px'}}>
    <div className="d-flex  flex-column">
      <a className="btn btn-link  pr-0 pl-0" onClick={() => this.resetGraph()}><img className="image-buttons" src={ require('images/icons/graph/home_view.svg') } onMouseOver={(obj) =>  obj.target.src = require('images/icons/graph/home_view_hover.svg')} onMouseOut={(obj) =>  obj.target.src = require('images/icons/graph/home_view.svg')}  alt="home"/></a>
      <a className="btn btn-link  pr-0 pl-0" onClick={() => this.zoom("in")}><img className="image-buttons" src={ require('images/icons/graph/zoom_in.svg') }  onMouseOver={(obj) =>  obj.target.src = require('images/icons/graph/zoom_in_hover.svg')} onMouseOut={(obj) =>  obj.target.src = require('images/icons/graph/zoom_in.svg')} alt="zoom in"/></a>
      <a className="btn btn-link  pr-0 pl-0" onClick={() => this.zoom("out")}><img className="image-buttons" src={ require('images/icons/graph/zoom_out.svg') }  onMouseOver={(obj) =>  obj.target.src = require('images/icons/graph/zoom_out_hover.svg')} onMouseOut={(obj) =>  obj.target.src = require('images/icons/graph/zoom_out.svg')} alt="zoom out"/></a>
    </div>

  </div>

</div>




*/
