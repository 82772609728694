// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storybook-checkbox {
    /* Auto layout */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    width: var(--checkbox-size, 24px);
    height: 24px;
    height: var(--checkbox-size, 24px);
    background-color: #4563C7;
    background-color: var(--checkbox-bg-color, #4563C7);
    border: 0px solid #919DB4;
    border: var(--checkbox-border, 0px) solid var(--checkbox-border-color, #919DB4);
    border-radius: 4px;
    border-radius: var(--checkbox-border-radius, 4px);

    -webkit-box-sizing: border-box;
}


.checkbox--large {
    --checkbox-size: 24px;
    --checkbox-border-width: 2px;
}

.checkbox--small {
    --checkbox-size: 16px;
    --checkbox-border-radius: 3px
}

.checkbox--small.checkbox--unchecked {
    --checkbox-border-width: 1px;
}

.checkbox--unchecked {
    --checkbox-border: 1px;
}

.checkbox--idle.checkbox--unchecked {
    --checkbox-bg-color: white;
    --checkbox-border-color: #919DB4;
}

.checkbox--hover.checkbox--unchecked {
    --checkbox-bg-color: #F6F8FA;
}

.checkbox--disable.checkbox--unchecked {
    --checkbox-bg-color: #F6F8FA;
    --checkbox-border-color: #D0D3D9;
}

.checkbox--disable {
    --checkbox-bg-color: #D0D3D9;
}
`, "",{"version":3,"sources":["webpack://./stories/NDcheckBox.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAiC;IAAjC,iCAAiC;IACjC,YAAkC;IAAlC,kCAAkC;IAClC,yBAAmD;IAAnD,mDAAmD;IACnD,yBAA+E;IAA/E,+EAA+E;IAC/E,kBAAiD;IAAjD,iDAAiD;;IAEjD,8BAA8B;AAClC;;;AAGA;IACI,qBAAqB;IACrB,4BAA4B;AAChC;;AAEA;IACI,qBAAqB;IACrB;AACJ;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,0BAA0B;IAC1B,gCAAgC;AACpC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,4BAA4B;IAC5B,gCAAgC;AACpC;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".storybook-checkbox {\n    /* Auto layout */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: var(--checkbox-size, 24px);\n    height: var(--checkbox-size, 24px);\n    background-color: var(--checkbox-bg-color, #4563C7);\n    border: var(--checkbox-border, 0px) solid var(--checkbox-border-color, #919DB4);\n    border-radius: var(--checkbox-border-radius, 4px);\n\n    -webkit-box-sizing: border-box;\n}\n\n\n.checkbox--large {\n    --checkbox-size: 24px;\n    --checkbox-border-width: 2px;\n}\n\n.checkbox--small {\n    --checkbox-size: 16px;\n    --checkbox-border-radius: 3px\n}\n\n.checkbox--small.checkbox--unchecked {\n    --checkbox-border-width: 1px;\n}\n\n.checkbox--unchecked {\n    --checkbox-border: 1px;\n}\n\n.checkbox--idle.checkbox--unchecked {\n    --checkbox-bg-color: white;\n    --checkbox-border-color: #919DB4;\n}\n\n.checkbox--hover.checkbox--unchecked {\n    --checkbox-bg-color: #F6F8FA;\n}\n\n.checkbox--disable.checkbox--unchecked {\n    --checkbox-bg-color: #F6F8FA;\n    --checkbox-border-color: #D0D3D9;\n}\n\n.checkbox--disable {\n    --checkbox-bg-color: #D0D3D9;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
