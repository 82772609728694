var moment = require('moment');
var axios = require('axios');
var {error_message} = require('@managers/helpers');

const SCIROOT_WEATHER_RECORDS_PATH = 'weather_records';

export var weatherDataStatistics = (weatherData, timezone) => {

  if (weatherData) {
    var keys = Object.keys(weatherData);
    keys.sort();
    // keys.reverse();

    let informationDic = {}
    let currentCounter = 0;
    let currentDate = undefined;
    let currentSum = 0;

    for (var currentKey in keys) {

      let currentValue = weatherData[keys[currentKey]];
      let tmpDateObject = moment.unix(parseInt(keys[currentKey])).tz(timezone);

      // Take mid-night value only
      if (tmpDateObject.hour() == 0) {

        // Completing the average calculation
        if (currentDate && (!informationDic[currentDate]["averageValue"])) {
          informationDic[currentDate]["averageValue"] = (currentSum / currentCounter).toFixed(2);
        }

        currentDate = tmpDateObject.set({minute:0,second:0,millisecond:0}).unix();

        // Skipping to the next day
        informationDic[currentDate] = {
          firstValue:currentValue,
          lastValue:currentValue,
          minValue:currentValue,
          maxValue:currentValue,
          averageValue:undefined
        }
        currentCounter = 1;
        currentSum = currentValue;
      } else if (currentDate) {

        // Filling the weather data statistics
        if (currentValue < informationDic[currentDate]["minValue"]) {
          informationDic[currentDate]["minValue"] = currentValue;
        }

        if (currentValue > informationDic[currentDate]["maxValue"]) {
          informationDic[currentDate]["maxValue"] = currentValue;
        }

        informationDic[currentDate]["lastValue"] = currentValue;
        currentSum += currentValue;
        currentCounter += 1;
      }
    }

    // Completing the average calculation
    if (currentDate && (!informationDic[currentDate]["averageValue"])) {

      informationDic[currentDate]["averageValue"] = (currentSum / currentCounter).toFixed(2);
    }

    return informationDic;
  }

  return {}
}

export var deleteWeatherRecord = (source, startDate, recordType) => {

  return new Promise(function (resolve, reject) {

    axios.delete(SCIROOT_WEATHER_RECORDS_PATH + "/" + source + "?startDate=" + startDate + "&recordType=" + recordType , {
    }).then(function (res) {

      resolve(res.data);

    }).catch(function(err) {
      console.log(error_message(err));
      reject(err);
    });
  });
};