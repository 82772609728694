var axios = require('axios');
var timezones = require('timezones');
var moment_timezone = require('moment-timezone');

export var get_timezones = (opposite_keys = true) => {

  var all_timezones = {};
  for (var index in timezones) {
    var current_time_zone_object = timezones[index];

    var country = current_time_zone_object["name"];

    for (var time_zone_index in current_time_zone_object.timezones) {

      var current_time_zone_string = current_time_zone_object.timezones[time_zone_index];
      var time_zone_string_splitted = current_time_zone_string.split("/");
      var time_zone_last_element = time_zone_string_splitted[time_zone_string_splitted.length - 1].replace("_"," ");

      var time_zone_diff = moment_timezone().tz(current_time_zone_string).format('Z');

      var key = current_time_zone_string;
      all_timezones[key] = country + " - " + time_zone_last_element + " (GMT " + time_zone_diff + ")";
    }
  }

  return all_timezones;
};
