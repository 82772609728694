var React = require('react');
var {connect} = require('react-redux');
var fieldManager = require('fieldManager');
var distributionActions = require('distributionActions');
var { withTranslation } = require('react-i18next');
var {copyTextToClipboard} = require('Utils');
const {unitDisplayName,convert} = require('unitsManager');
const {MiniGraph} =require("../general/MiniGraph");
var MainCSS = require("../../styles/main.css");
var {sortByAlphabetic,sortArrayByValue} = require('helpers');
var FieldSelectionRow = require('FieldSelectionRow')


class FieldsSelector extends React.Component {


  constructor(props) {
    super(props);

    this.handleFieldSelection = this.handleFieldSelection.bind(this);
    this.hanelMouseLeave = this.hanelMouseLeave.bind(this);
    this.hanelMouseEnter = this.hanelMouseEnter.bind(this);
    this.handleDuplicateField = this.handleDuplicateField.bind(this);
    this.setClusterDisplay = this.setClusterDisplay.bind(this);

  }

  componentDidMount() {
    this.setState({'hoveredField':undefined});
  }


  componentWillUnmount() {
  }

    handleFieldSelection(fieldId) {
        // change just if we choose different field
        if (this.props.selectedField && this.props.selectedField.id !== fieldId) {
            var selectedField = this.props.selectedFarmingUnit.fields[fieldId];
            mixpanel.track("Field Change", {
                'Field ID': selectedField.id,
                'Field Name': selectedField.name
            });
            this.props.setSelectedEntities(null, null, selectedField, null);
        }
    }

  hanelMouseEnter(fieldId){
    this.setState({'hoveredField':fieldId});
  }

  hanelMouseLeave(fieldId){

    if (this.state && this.state.hoveredField === fieldId) {
      this.setState({'hoveredField':""});
    }
  }

  handleDuplicateField(){
             mixpanel.track("Field has been duplicated", {
                'Field ID': this.props.selectedField.id,
                'Field Name': this.props.selectedField.name
            });
  }

    setClusterDisplay(currentFieldCluster, fieldIndex, lastFieldCluster) {
        const {t} = this.props;
        let showCluster = true;
        let clusterString = "";

        if (currentFieldCluster === '') {
            clusterString = t("no_cluster").toUpperCase();
        } else {
            clusterString = (t("cluster") + " " + currentFieldCluster).toUpperCase();
        }

        if (fieldIndex !== 0 && currentFieldCluster === lastFieldCluster) {
            clusterString = undefined;
        }
        return clusterString;
    }


  render() {
    const { t } = this.props;
    let userLengthUnits = (this.props.userData && ("length" in this.props.userData)) ? unitDisplayName(this.props.userData.length) : "";
    let userPressureUnits = (this.props.userData && ("pressure" in this.props.userData)) ? unitDisplayName(this.props.userData.pressure) : "";
    let formattedFields = undefined;
    let is_admin = false;
    let is_moderator = false;
    if (this.props.selectedFarmingUnit) {

      let allFields = Object.values(this.props.selectedFarmingUnit.fields);
      formattedFields = allFields.filter(currentField => currentField.settings.field_state === "active").map((currentField) => {
        var fieldItems = fieldManager.getFieldItems(currentField);
        var fieldStressColor = fieldItems.irrigationDataItem.value == -1.0 ? "rgb(195, 201, 215)" : fieldManager.get_stress_color(fieldItems.stressDataItem.value);
        var fieldStressBackgroundColor = fieldItems.irrigationDataItem.value == -1.0 ? "rgb(195, 201, 215)" : fieldManager.get_stress_background_color(fieldItems.stressDataItem.value);

        return {name: currentField.name,
                ir: fieldItems.irrigationDataItem.value,
                isActive: currentField.id === this.props.selectedField?.id,
                id: currentField.id,
                stressLevel: fieldItems.stressDataItem.value,
                stressColor: fieldStressColor,
                stressBackgroundColor: fieldStressBackgroundColor,
                accumulatedETc: fieldItems.accumulatedETc.value,
                userLengthUnits: userLengthUnits,
                userPressureUnits: userPressureUnits,
                wpSamples : currentField.historical_data?.wp_samples,
                timeZone : currentField.geo_data.time_zone,
                cluster:currentField.cluster.id}
      });

      if (this.props.sortBy === "deficit") {
        formattedFields.sort(fieldManager.compareFields);
      }
      else if (this.props.sortBy === "alphabetic") {
        sortByAlphabetic(formattedFields, "name")
      }
       else if (this.props.sortBy === "cluster") {
        sortArrayByValue(formattedFields, "cluster")
      }

      is_admin = this.props.selectedFarmingUnit.role === "admin";
      is_moderator = this.props.selectedFarmingUnit.role === "moderator";

    }

    return (

        <div className="d-flex flex-column  h-100" style={{boxSizing:"border-box" , borderRight:"1px solid #DCE0E8"}}>
        <div>
          <div className="container-fluid p-0 bg-white">
          <div className="row justify-content-between align-items-center m-0 pt-2 pb-2" style={{borderBottom:"1px solid #dee2e6", borderTop:"1px solid #dee2e6", flexWrap:"nowrap"}}>
              <div className="text-grey71 text-size-12" style={{width:"123px", marginLeft:"16px", marginRight:"16px", lineHeight:"120%"}}>{t('field').toUpperCase()}<br/>{t('name').toUpperCase()}</div>
              <div className="text-grey71 text-size-12" style={{width:"78px", marginRight:"16px", lineHeight:"120%"}}>{(t('soil_water_deficit').toUpperCase())}{` (${userLengthUnits})`}</div>
              <div className="text-grey71 text-size-12" style={{width:"100px", marginRight:"6px", lineHeight:"120%"}}>{(t('water_potential').toUpperCase())}{` (${userPressureUnits})`}</div>
          </div>
        </div>
        </div>
          <div className="bg-white flex-fill" style={{ "overflow":"auto", "borderBottom":"1px solid #dee2e6", overflowX:"hidden"}}>

            {formattedFields && formattedFields.map((currentField,index) => {
              let clusterString = this.setClusterDisplay(currentField?.cluster, index, formattedFields[index - 1]?.cluster);
              let samples;
              if (currentField.wpSamples !== undefined) {
                samples = currentField.wpSamples.map((sample, index) => {
                  return {
                    value: parseFloat(sample.value),
                    date: sample.date,
                    index: index,
                  };
                });
              }
              let nameElement = "";
              let settingsElement = "";
              let wdElement = "";
              let currentBackground = "white";
              if (currentField.isActive) {
                currentBackground = "#F6F8FA";
              } else if (this.state && this.state.hoveredField == currentField.id) {
                currentBackground = "#F6F8FA";
              }

              if (currentField.ir == -1.0) {
                  nameElement = (<div className="text-grey45 pr-0 line-clamp justify-content-center mb-1" style={{ maxHeight:"2.250em", lineHeight:"110%"}}>
                    <p className="m-0">{currentField.name}</p></div>);
              } else {
                if (currentField.ir !== 0) {
                  wdElement = (<div className="pl-0 pr-3 font-weight-semi-bold text-size-16">
                    <div style={{
                      'backgroundColor': currentField.stressBackgroundColor,
                      'color': currentField.stressColor,
                      'width': 72,
                      'height': 32,
                      'textAlign': 'center',
                      'borderRadius': '3px',
                      paddingTop: "2px",
                      'border': `1px solid ${currentField.stressColor}`
                    }}>{currentField.ir}</div>
                  </div>);
                } else {

                  wdElement = (<div className="pl-0 pr-3 font-weight-semi-bold text-size-16">
                    <div style={{
                      'backgroundColor': currentField.stressBackgroundColor,
                      'color': currentField.stressColor,
                      'width': 72,
                      'height': 32,
                      'textAlign': 'center',
                      'borderRadius': '3px',
                      paddingTop: "2px",
                      'border': `1px solid ${currentField.stressColor}`
                    }}>{currentField.accumulatedETc}</div>
                  </div>);
                }
                  nameElement = (<div className="text-grey45 pr-0 line-clamp justify-content-center mb-1" style={{
                    maxHeight: '2.125em',
                    lineHeight: "110%"
                  }}>
                    <p className="m-0">{currentField.name}</p></div>);
              }

              if (this.state && (this.state.hoveredField === currentField.id)) {

                if (is_admin || is_moderator) {
                  settingsElement = (
                          <div className="dropdown-menu text-grey71">
                            {is_admin &&
                            <div className="dropdown-item text-grey71"  href="#" onClick={() => copyTextToClipboard(currentField.id)}>{t('dashboard_copy_field_id')}</div>}
                            <div className="dropdown-item text-grey71" data-toggle="modal" data-target={"#" + "fieldModalId"} data-id={currentField.id} href="#">{t('dashboard_edit_field')}</div>

                            {is_admin &&
                            <div className="dropdown-item text-grey71"  data-toggle="modal" data-target={"#" + "approvalModalId"} data-id={currentField.id} data-values={[ this.props.selectedDistribution.id, this.props.selectedFarmingUnit.id,currentField.id]} href="#">{t('dashboard_move_to_archive')}</div>}

                            {is_admin &&
                            <div className="dropdown-item text-grey71" data-toggle="modal" data-target={"#" + "fieldModalId"} data-id={currentField.id} data-data="duplicate" href="#" onClick={()=>{
                              this.handleDuplicateField()
                            }}>{t('duplicate')}</div>}

                            {is_admin &&
                            <div className="dropdown-item text-grey71" data-toggle="modal" data-target={"#" + "deletionApprovalModalId"}
                              data-values={[ this.props.selectedDistribution.id, this.props.selectedFarmingUnit.id,currentField.id]} href="#">{t('dashboard_delete_field')}</div>}
                          </div>)
                }
              }
              let rowContent = <div className="d-flex align-items-center row" style={{
                paddingLeft: currentField.isActive ? "11px" : "16px",
                height: "64px",
                flexWrap: "nowrap",
                marginRight: "15px"
              }}>
                <div className="text-size-16 font-weight-bold mr-auto flex-fill"
                     style={{marginLeft: "16px", marginRight: "14px"}}>
                  {nameElement}
                </div>
                  {this.props?.loadHistoryStatus.status !== "init" && <>
                      <div style={{marginRight: "11px", marginLeft: "8px"}}>
                  {wdElement}
                </div>
                <div className="pl-0 pr-5 text-right text-size-12">

                  <div style={{display: "flex", width: "40px"}}>
                    <span style={{display: "inline-block", float: "left", height: "50px"}}><MiniGraph
                        timeZone={currentField.timeZone} data={samples}/></span>
                    <span className="text-size-14 text-grey45" style={{
                      marginLeft: "4px",
                      marginTop: "12px",
                      float: "right"
                    }}>{samples && samples[0] && samples[0].value.toFixed(0)}</span>
                  </div>
                </div>
                  </>}
              </div>

                return (<div key={index}>
                        {clusterString !== undefined && this.props.sortBy === 'cluster' && <ClusterRow clusterString={clusterString}/>}
                        <FieldSelectionRow onMouseLeave={() => this.hanelMouseLeave(currentField.id)}
                                           onMouseEnter={() => this.hanelMouseEnter(currentField.id)}
                                           onClick={() => this.handleFieldSelection(currentField.id)}
                                           key={currentField.id}
                                           currentField={currentField}
                                           currentBackground={currentBackground}
                                           rowContent={rowContent} settingsElement={settingsElement}
                                           hoveredField={this.state?.hoveredField}/>
                    </div>
              );
            })}

            </div>
          {is_admin &&
              <div className="bg-white" style={{minHeight: "115px"}}>
                <div className="p-0 pt-2" style={{marginTop: "8px", marginRight: "16px"}}>
                  <div className="col-12 text-right pr-0">
                    <button className="btn addBtn font-weight-bold text-grey45 pt-1 pb-1 pl-3 pr-3 text-size-16"
                            style={{'borderRadius': '3px', 'border': "1px solid #919DB4", "width": "100%"}}
                            data-toggle="modal" data-target={"#" + "fieldModalId"} data-id={""}>
                      <img src={require('images/icons/plus_icon.svg')} className="img-fluid" alt="irrigation ok"/>
                      <span className="ml-2">{t('dashboard_add_field')}</span>

                    </button>
                  </div>
                </div>
              </div>
          }
        </div>

    );
  }
};

class ClusterRow extends React.Component {
    render() {
        const {clusterString} = this.props
        return (
            <div className="list-group-item m-0 p-0 pt-1"
                 style={{
                     'borderRadius': '0%',
                     'border': '0px solid white',
                     "height": "39px",
                     'backgroundColor': "#FAFBFC",
                     borderBottom: "1px solid #EEF1F6",
                     display: "flex"
                 }}>
                            <span style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "#919DB4",
                                paddingBottom: "8px",
                                paddingLeft: "16px",
                                paddingTop: "10px"
                            }}>
                                 {clusterString}
                            </span>
            </div>
        );
    }
}

const mapStateToProps = function(state){
  return {
    selectedField: state.distribution_data.selected_entities.field,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    selectedDistribution: state.distribution_data.selected_entities.distribution,
    userData: state.authentication_data.user_data,
    loadHistoryStatus: state.distribution_data.farming_unit_load_history,
    fieldState: state.distribution_data?.selected_entities?.field?.settings?.field_state
  }
}

const mapDispatchToProps = {
  setSelectedEntities: distributionActions.selected_entities,
  getFarmingUnitHistory: distributionActions.get_farming_units_history,

}

module.exports = connect(mapStateToProps,mapDispatchToProps)(withTranslation()(FieldsSelector));
