var React = require('react');


export const DefaultCoup = {
    "C Leaf to Grain": "",
    "C Seed": "",
    "C Stem to Grain": "",
    "Conduct Max": "",
    "Depths of Sonds": "",
    "Dorm_FinalDayNo": "",
    "Drip": "",
    "Emergence": "",
    "FHarvest CRoots": "",
    "FHarvest Grain": "",
    "FHarvest Leaf": "",
    "FHarvest Roots": "",
    "FHarvest Stem": "",
    "FLitter CRoots": "",
    "FLitter Grain": "",
    "FLitter Leaf": "",
    "FLitter Roots": "",
    "FLitter Stem": "",
    "Grain Step": "",
    "Grain StepDay": "",
    "Grain StepTemp": "",
    "Grain ThDay": "",
    "Grain ThTemp": "",
    "GrainRate1": "",
    "GrainRate2": "",
    "GrainTsum1": "",
    "GrainTsum2": "",
    "GSI Post Harvest": "",
    "I C CoarseRoot": "",
    "I C Grain": "",
    "I C Leaf": "",
    "I C OldCoarseRoot": "",
    "I C OldLeaf": "",
    "I C OldRoot": "",
    "I C OldStem": "",
    "I C Root": "",
    "I C Stem": "",
    "I N CoarseRoot": "",
    "I N Grain": "",
    "I N Leaf": "",
    "I N OldCoarseRoot": "",
    "I N OldLeaf": "",
    "I N OldRoot": "",
    "I N OldStem": "",
    "I N Root": "",
    "I N Stem": "",
    "I Plant Age": "",
    "Initial GSI": "",
    "Initial Mineral N": "",
    "Leaf c1": "",
    "LeafRate1": "",
    "LeafRate2": "",
    "LeafTsum1": "",
    "LeafTsum2": "",
    "Litter fall dynamics": "",
    "Manure Input": "",
    "Mature Tsum": "",
    "Mature Tth": "",
    "Max GSI": "",
    "Max Height": "",
    "Max Leaf Lifetime": "",
    "Max Plant Lifetime": "",
    "MCoefCoarseRoot": "",
    "MCoefGrain": "",
    "MCoefLeaf": "",
    "MCoefRoot": "",
    "MCoefStem": "",
    "Min GSI": "",
    "Mycorrhiza Fungal Growth": "",
    "N fixation": "",
    "N Leaf to Grain": "",
    "N Roots to Grain": "",
    "N Stem to Grain": "",
    "RadEfficiency": "",
    "RaIncreaseWithLAI": "",
    "Ripening Tsum": "",
    "Ripening Tth": "",
    "Root LowestDepth": "",
    "Root Water c1": "",
    "RoughLBareSoilMom": "",
    "Sowing": "",
    "Specific LeafArea": "",
    "StemRate1": "",
    "StemRate2": "",
    "StemTsum1": "",
    "StemTsum2": "",
    "Terminating Harvest": "",
    "Winter regulation": "",
    "TL Min": "",
    "TL Max": "",
}

export const DefaultGDD = {
    "t_base": "",
    "t_max": "",
    "t_min": ""
}

export const DefaultYield = {
    "grain": "",
    "leaf": ""
}

export const getCropTypePrefix = (crops, cropType, cropVariety, cropFeature = "general") => {
    let crop_type = ""

    if (crops[cropType]) {

        if (cropVariety in crops[cropType]) {

            crop_type = crops[cropType][cropVariety]["general"] || crops[cropType][cropVariety][cropFeature]
            if (crop_type) {
                crop_type = crop_type["crop_type"]
                const endIndex = crop_type.indexOf(cropVariety) + cropVariety.length;
                if (endIndex !== -1) {
                    return crop_type.substring(0, endIndex);
                }
            }
        }
    }
    
    return crop_type;
};