var React = require('react');
var {connect} = require('react-redux');
var {withTranslation} = require('react-i18next');
const chevronIcon = require('images/icons/header/down_arrow.svg');

class LanguageMenu extends React.Component {

    constructor(props) {
        super(props);
        this.onLanguageChange = this.onLanguageChange.bind(this);
    }

    onLanguageChange(e) {
        const {t, i18n} = this.props;
        let langCode = e.target.value
        i18n.changeLanguage(langCode);
    }

    render() {
        const {t, i18n} = this.props;

        let languageMenu = [
            {title: "English (US)", code: 'en'},
            {title: "Português", code: 'pt'},
            {title: "中文", code: 'zh'},
            {title: "Espanõl", code: 'es'},

        ]
        const optionsElements = languageMenu.map(({code, title}) => {
            return <option key={code} value={code}>{title}</option>
        })

        return (
            <div className={"selectMenuWrapper"}>
                <select className='form-control w-100 selectMenu border-grey91 sortBox'
                        style={{height: 40, borderWidth: 1, borderColor: 'grey91', fontSize: 14}}
                        value={i18n.language} onChange={this.onLanguageChange}>
                    {optionsElements}
                </select>
                <img src={chevronIcon} className='chevronIconsortBox'/>
            </div>
        );
    }
};

const mapStateToProps = function () {
    return {}
}

module.exports = connect(mapStateToProps)(withTranslation()(LanguageMenu));
