const {useTranslation, withTranslation} = require("react-i18next");
const {useState, useEffect, useMemo} = require("react");
const {connect} = require("react-redux");
const React = require("react");
const searchIcon = require('images/icons/search_icon.svg');
const {SelectMenu} = require('SelectMenu');
var sensorsManager = require('sensorsManager');


const ByFieldSearch = ({all_distributions, onTestClick, loading}) => {
    const {t} = useTranslation();
    const [selectedDistribution, setSelectedDistribution] = useState(undefined);
    const [selectedFarmingUnit, setSelectedFarmingUnit] = useState(undefined);
    const [selectedField, setSelectedField] = useState(undefined);
    const [selectedSensor, setSelectedSensor] = useState(undefined);
    const [distributionsMenu, setDistributionsMenu] = useState([]);
    const [farmingUnitsMenu, setFarmingUnitsMenu] = useState([]);
    const [fieldsMenu, setFieldsMenu] = useState([]);
    let showSensorsLabel = false;
    if (selectedField?.sensors !== undefined && Object.keys(selectedField?.sensors).length > 0) {
        showSensorsLabel = true;
    }

    useEffect(() => {
        if (selectedDistribution === undefined) {
            let sortedDistributions = undefined;
            let sortedFarmingUnits = undefined;
            let sortedFields = undefined;
            let sortedDistributionsMenu = undefined;
            let sortedFarmingUnitsMenu = undefined;
            let sortedFieldsMenu = undefined;
            if (all_distributions) {
                sortedDistributions = Object.values(all_distributions.distributions);
                sortedDistributions.sort((a, b) => a.name.localeCompare(b.name));
                sortedDistributionsMenu = sortedDistributions.map((dist) => {
                    return {title: dist.name, value: dist.name};
                })
                setDistributionsMenu(sortedDistributionsMenu);
                setSelectedDistribution(sortedDistributions[0]);

                sortedFarmingUnits = Object.values(sortedDistributions[0].farming_units);
                sortedFarmingUnits.sort((a, b) => a.name.localeCompare(b.name));
                sortedFarmingUnitsMenu = sortedFarmingUnits.map((farmingUnit) => {
                    return {title: farmingUnit.name, value: farmingUnit.name};
                })
                setFarmingUnitsMenu(sortedFarmingUnitsMenu);
                setSelectedFarmingUnit(sortedFarmingUnits[0]);

                if (sortedFarmingUnits.length >0) {
                     sortedFields = Object.values(sortedFarmingUnits[0].fields);
                sortedFields.sort((a, b) => a.name.localeCompare(b.name));
                let updatedFields = sortedFields.filter((field) => {
                    if (field.settings.field_state === "active") {
                        return field;
                    }
                })
                sortedFieldsMenu = updatedFields.map((field) => {
                    return {title: field.name, value: field.id};
                })
                } else {
                    sortedFieldsMenu = [];
                    sortedFields = [];
                }
                setFieldsMenu(sortedFieldsMenu);
                setSelectedField(sortedFields[0]);
            }
        }

    }, [all_distributions]);


    const onChangeDistribution = (distributionName) => {

        let distributions = Object.values(all_distributions.distributions);
        let distributionObj = distributions.filter((distribution) => {
            if (distribution.name === distributionName) return distribution;
        })
        setSelectedDistribution(distributionObj[0]);
        if (Object.keys(distributionObj[0]?.farming_units).length === 0) {
            setFarmingUnitsMenu([]);
            setSelectedFarmingUnit(undefined);
            setFieldsMenu([]);
            setSelectedField(undefined);
            return;
        } else {
              let sortedFarmingUnits = Object.values(distributionObj[0].farming_units);
        sortedFarmingUnits.sort((a, b) => a.name.localeCompare(b.name));
        let sortedFarmingUnitsMenu = sortedFarmingUnits.map((farmingUnit) => {
            return {title: farmingUnit.name, value: farmingUnit.name};
        })
        setFarmingUnitsMenu(sortedFarmingUnitsMenu);
        setSelectedFarmingUnit(sortedFarmingUnits[0]);
        onChangeFarmingUnit(distributionObj[0], sortedFarmingUnits[0].name);
        }
    }

    const onChangeFarmingUnit = (distribution, farmingUnitName) => {

        let distributions = Object.values(all_distributions.distributions);
        let distributionObj = distributions.filter((dist) => {
            if (dist.name === distribution.name) return distribution;
        })
        let farmingUnits = Object.values(distributionObj[0]?.farming_units);
        let farmingUnitObj = farmingUnits.filter((farming_unit) => {
            if (farming_unit.name === farmingUnitName) return farming_unit;
        });
        setSelectedFarmingUnit(farmingUnitObj[0]);
        let sortedFields = Object.values(farmingUnitObj[0].fields);
        sortedFields.sort((a, b) => a.name.localeCompare(b.name));
        let updatedFields=sortedFields.filter((field)=>{
                    if (field.settings.field_state === "active"){
                        return field;
                    }
                })
        let sortedFieldsMenu = updatedFields.map((field) => {
            return {title: field.name, value: field.id};
        })
        setFieldsMenu(sortedFieldsMenu);
        setSelectedField(updatedFields[0]);
    }

    const onChangeField = (fieldId) => {

        let fields = Object.values(selectedFarmingUnit.fields);
        let newFieldObj = fields.filter((field) => {
            return field.id === fieldId
        });
        setSelectedField(newFieldObj[0]);
    }

    const onByFieldSensorPressed = (transmitter_id) => {
        onTestClick(transmitter_id.toLowerCase());
    }

    const SensorElements = () => {

        const {t} = useTranslation();
        if (selectedField) {
            let sensorsArr = Object.values(selectedField.sensors).filter((sensor)=>{
                if (sensor?.transmitter_id?.toLowerCase().startsWith("gt") || sensor?.transmitter_id?.toLowerCase().startsWith("mt") ) return sensor;
            });

            let index = 0;
            return sensorsArr.map((sensor) => {
                let sensorColor="";
                let sensorType = sensor.type.includes("piezometer") ? " (e-piezo)" : (sensor.type.includes("wfr") && !sensor.type.includes("tal-gil")  ) ? " (Flow Rate)" : "";

                if (sensorType === ""){
                    sensorColor = sensorsManager.sensorColorByIndex(index, selectedField.field_state);
                    index++;
                }
                else if (sensorType === " (e-piezo)") {
                    sensorColor = "#662F6F";

                } else if ( sensorType === " (Flow Rate)") {
                    sensorColor = "#3A8500";
                }
                return (<div
                    onClick={() => {
                        if (!loading) {
                            setSelectedSensor(sensor)
                            onByFieldSensorPressed(sensor.transmitter_id);
                        }
                    }}
                    key={sensor.transmitter_id} id={sensor.transmitter_id} className="mr-2 d-flex" style={{
                    border: `1px solid ${sensorColor}`,
                    borderRadius: "30px",
                    height: "32px",
                    padding: "6px 16px 7px",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: loading ? "auto" : "pointer",
                    backgroundColor: selectedSensor?.transmitter_id === sensor.transmitter_id ? sensorColor : "transparent",
                    whiteSpace: "nowrap",
                    marginBottom: "8px"
                }}>
                    <a
                        style={{
                            fontSize: 16,
                            fontWeight: "600",
                            cursor: loading ? "auto" : "pointer",
                            color: selectedSensor?.transmitter_id === sensor.transmitter_id ? "white" : sensorColor,
                        }}>{sensor.transmitter_id}{sensorType}</a>
                </div>)
            })
        } else {
            return null;
        }
    }


    return (
        <div className={"tab-pane fade show active row"}
             style={{display:"flex", flexDirection: "column", backgroundColor: "white", paddingTop: 8, width:"100%"}}>
            <div style={{display:"flex", flexDirection: "row", marginBottom: 16}}>
                <div className="text-grey45 col-4 col-sm-4 col-xs-4 col-md-4 col-lg-3" style={{flexDirection: "column", marginTop:16, width: "23%",marginLeft:0}}>
                    <span style={{fontSize: 14}}>{t("Distribution")}</span>
                    <div style={{marginTop: 8}}><SelectMenu options={distributionsMenu}
                                                            curValue={selectedDistribution?.name}
                                                            sortBox={""} onChange={(e) => {
                        onChangeDistribution(e)
                    }}/></div>
                </div>
                <div className="text-grey45 col-4 col-sm-4 col-xs-4 col-md-4 col-lg-3" style={{flexDirection: "column", marginTop:16, width: "23%"}}>
                    <span style={{fontSize: 14}}>{t("farming_unit")}</span>
                    <div style={{marginTop: 8}}><SelectMenu options={farmingUnitsMenu}
                                                            curValue={selectedFarmingUnit?.name}
                                                            onChange={(e) => {
                                                                onChangeFarmingUnit(selectedDistribution, e)
                                                            }} sortBox={""}/></div>
                </div>
                <div className="text-grey45 col-4 col-sm-4 col-xs-4 col-md-4 col-lg-3" style={{flexDirection: "column", marginTop:16, width: "23%"}}>
                    <span style={{fontSize: 14}}>{t("field")}</span>
                    <div style={{marginTop: 8}}><SelectMenu options={fieldsMenu}
                                                            curValue={selectedField?.id || fieldsMenu[0]}
                                                            onChange={(e) => {
                                                                onChangeField(e)
                                                            }} sortBox={""}/></div>
                </div>
            </div>
            <div style={{marginLeft:16}}>
                {showSensorsLabel ?
                    <span className="text-grey45"
                          style={{fontSize: 14}}>{t("transmitters")}</span> : null}
                <div style={{display:"flex", marginTop: 10, flexWrap: "wrap"}}>
                    <SensorElements/>
                </div>
            </div>
        </div>)
}


const FreeSearch = ({onTestClick}) => {
    const {t} = useTranslation();
    const [transmitterID, setTransmitterID] = useState("");


    const onFreeSearchTestPressed = (event) => {
        event.preventDefault();
        event.stopPropagation();

        // Local check (given by bootstrap)
        if (event.target.checkValidity() == true) {
            let newTransmitterID = transmitterID.toLowerCase();
            if (!newTransmitterID.startsWith("gt_") && !newTransmitterID.startsWith("aq_") && !newTransmitterID.startsWith("mt_")) {
                if ((newTransmitterID.length === 3) || (newTransmitterID.length === 4)) {
                    newTransmitterID = "gt_" + newTransmitterID;
                } else if (newTransmitterID.length === 6) {
                    newTransmitterID = "gt_9180089" + newTransmitterID;
                } else if (newTransmitterID.length === 7) {
                    newTransmitterID = "gt_918008" + newTransmitterID;
                }
            } else if (newTransmitterID.startsWith("mt_") && newTransmitterID.length < 7) {
                newTransmitterID = "mt_" + ('0000' + newTransmitterID.substring(3)).slice(-4);
            }
            if (transmitterID !== newTransmitterID) {
                setTransmitterID(newTransmitterID);
            }
            onTestClick(newTransmitterID);
        }
    }

    return (
        <form key={"free"} className="needs-validation" noValidate id="transmitter-form" onSubmit={(e) => {onFreeSearchTestPressed(e)}}>
            <div style={{backgroundColor: "white"}}>
                <div className={"tab-pane fade show active"}>
                    <div className="form-row" style={{paddingBottom: 8}}>
                        <div className="col-8 col-md-4 col-lg-4 col-sm-8 col-xs-8">

                            <div className="form-group mt-4 text-grey45">
                                <label style={{fontSize: 14,fontWeight:400}} htmlFor="transmitter_id">{t('transmitter_id')} </label>
                                <div style={{margin: "auto", width: "100%", position: "relative"}}>
                                    <img style={{position: "absolute", right: "12px", top: "27%"}} src={searchIcon}/>
                                    <input className="form-control" style={{width: "100%"}} type="text"
                                           name="transmitter_id" value={transmitterID} onChange={(event) => {
                                        setTransmitterID(event.target.value)
                                    }} placeholder={"Search"} required/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-center">
                            <div className="form-group mt-3 text-light-blue">
                                <button type="submit" className="btn text-white"
                                        disabled={!transmitterID}
                                        style={{width: '120px', borderRadius: "3px", marginLeft: 8,backgroundColor:"#4563C7"}}>
                                    {t('test')}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    )
}


const SearchContainer = ({onTestClick,all_distributions,loading}) => {
    const {t} = useTranslation();
    const [selectedSearchType,setSelectedSearchType] = useState("free");

    return (
        <div style={{backgroundColor: "white", padding: 16, borderRadius: 5}}>
            <ul className="nav" id="kcTab" role="tablist"
                style={{"borderBottom": "1px solid #DCE0E8", "paddingRight": "15px"}}>
                <li className="nav-item">
                    <a className={"nav-link font-weight-semi-bold text-size-13 pl-0 " + (selectedSearchType==="free" ? "text-blue-primary underline " : "text-grey71 ")}
                       id="freeSearch" style={{letterSpacing: "0.07em"}} onClick={() => {
                        setSelectedSearchType("free")
                    }} data-toggle="tab" href="#home" role="tab"
                       aria-controls="home">{t("free_search").toUpperCase()}</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link font-weight-semi-bold text-size-13 " + (selectedSearchType==="byField" ? "text-blue-primary underline " : "text-grey71 ")}
                       id="byField" style={{letterSpacing: "0.07em"}} onClick={() => {
                        setSelectedSearchType("byField")
                    }} data-toggle="tab" href="#byField" role="tab"
                       aria-controls="profile">{t('search_by_field').toUpperCase()}</a>
                </li>
            </ul>
            <div className={selectedSearchType === "free" ? "d-block" : "d-none"}><FreeSearch onTestClick={onTestClick}/></div>
            <div className={selectedSearchType === "byField" ? "d-block" : "d-none"}><ByFieldSearch loading={loading} onTestClick={onTestClick} all_distributions={all_distributions}/></div>
        </div>
    )
}


const mapStateToProps = function (state) {
    return {
        user_details: state.authentication_data.user_details,
        all_distributions: state.distribution_data.distributions,
    }
}
const mapDispatchToProps = {}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchContainer));