var React = require('react');
var {connect} = require('react-redux');
var cropDataManager = require('cropDataManager');
var {withTranslation} = require('react-i18next');

class CoupModelBox extends React.Component {

    constructor(props) {
        super(props);

        this.handleCoupModelSupportChange = this.handleCoupModelSupportChange.bind(this);
        this.isCoupModelHasCropInDB = this.isCoupModelHasCropInDB.bind(this);
        this.handleAutomateIrrigation = this.handleAutomateIrrigation.bind(this);
    }

    componentDidUpdate(nextProps, nextState) {

    }

    componentDidMount() {

        var that = this;

    }

    componentWillUnmount() {

    }


    handleAutomateIrrigation(event) {
        const shouldAutomateIrrigation = event.target.checked;
        this.props.onAutomateIrrigationSelection(shouldAutomateIrrigation);

    }

    handleCoupModelSupportChange(event) {

        const coupModelSupported = event.target.checked;

        this.props.onCoupModelSelection(coupModelSupported);
    }

    isCoupModelHasCropInDB() {
        if (this.props.selectedField && this.props.crop_data && this.props.crop_data.crop_data && this.props.selectedField.crop_data.crop_type !== '') {
            let currentCropData = cropDataManager.generateCoupParameters(this.props.crop_data.crop_data, this.props.selectedField.crop_data.crop_type.toLowerCase(), this.props.selectedField.crop_data.crop_variety.toLowerCase(), this.props.selectedField.crop_data.crop_feature.toLowerCase())
            if (!currentCropData) {
                return false;
            } else {
                return currentCropData;
            }
        } else {
            return false;
        }
    }


    render() {
        const {t} = this.props;
        let isCurrentCropSupported = this.isCoupModelHasCropInDB();
        let is_coup_model_supported = this.props.selectedField && this.props.selectedField.settings.is_coupmodel_active;
        let shouldAutomateIrrigations = this.props.selectedField && this.props.selectedField.settings.coup_settings.should_automate_irrigations;

        let shouldPresentCoupAdditionalData = isCurrentCropSupported && is_coup_model_supported;


        let current_form = <div>

            <div className="form-row mt-0">
                <div className="col-12">
                    <label
                        className="ml-2 text-grey45 text-size-16 mb-0 d-block font-weight-bold">{t("general_coup_settings")}</label>
                </div>
            </div>
            <div className="form-row mt-2">
                <div className="col-12">
                    <div className="form-check ml-2">
                        <input className="form-check-input" checked={shouldAutomateIrrigations}
                               onChange={this.handleAutomateIrrigation} type="checkbox" value=""
                               id="defaultCheck3"/>
                        <label className="text-grey45" htmlFor="defaultCheck3">
                            {t('should_automate_irrigation')}
                        </label>
                    </div>
                </div>
            </div>

        </div>


        return (
            <div className="text-grey45">
                <div className="form-row justify-content-center">
                    <div className="col-12">
                        <div className="form-group ml-2 mr-2 mt-3 ">
                            <label className="font-weight-bold text-size-20" htmlFor="fieldName">Coup Model</label>

                            <div className="form-check">
                                <input className="form-check-input" checked={is_coup_model_supported} style={{marginTop:"0.4rem"}}
                                       onChange={this.handleCoupModelSupportChange} type="checkbox" value=""
                                       id="defaultCheck2"/>
                                <label className="text-grey45" htmlFor="defaultCheck2">
                                    {t('support_coup_model')}
                                </label>
                            </div>

                        </div>
                    </div>
                </div>

                {shouldPresentCoupAdditionalData ? current_form : <div></div>}

            </div>
        );
    }
};

const mapStateToProps = function (state) {
    return {
        crop_data: state.crop_data.crop_data,
    }
}

const mapDispatchToProps = {}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CoupModelBox));