var axios = require('axios');
var GeneralClasses = require('@model/GeneralClasses');
var {error_message} = require('@managers/helpers');

const SCIROOT_CROP_DATA_PATH = 'crop';


export var crop_data = () => {

  return new Promise(function (resolve, reject) {

    axios.get(SCIROOT_CROP_DATA_PATH, {
    }).then(function (res) {

      var crop_data = new GeneralClasses.CropRecords(res.data);
      resolve(crop_data);

    }).catch(function(err) {
      console.log(error_message(err));
      reject(err);
    });
  });
};


export const updateCropItem = ({id, body}) => {
  return new Promise((resolve, reject) => {
    axios.put(`${SCIROOT_CROP_DATA_PATH}/${id}`, body)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(error_message(err));
        reject(err);
      });
  });
};

export const addNewCropItem = (newData) => {
  return new Promise((resolve, reject) => {
    axios.post(SCIROOT_CROP_DATA_PATH, newData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(error_message(err));
        reject(err);
      });
  });
};


export var generateKCTable = (cropRecords, cropType, cropFeature, cropVariety) => {

  return new Promise(function (resolve, reject) {

    // Initialize an empty array to hold the crop KCs
    let currentCropKCs = []

    if ((cropType in cropRecords) && (cropVariety.toLowerCase() in cropRecords[cropType]) && (cropFeature.toLowerCase() in cropRecords[cropType][cropVariety])) {

      if (cropRecords[cropType][cropVariety.toLowerCase()][cropFeature.toLowerCase()]["crop_type"].includes(cropFeature.toLowerCase())) {

        // If the crop feature is found in the crop record, retrieve the corresponding crop KCs
        currentCropKCs = cropRecords[cropType][cropVariety.toLowerCase()][cropFeature.toLowerCase()]['phenological_phases']
      }

      if (currentCropKCs.length === 0) {

        // If no crop KCs are found for the specific crop feature, retrieve the crop KCs for the general crop variety or crop type
        currentCropKCs = cropRecords[cropType][cropVariety.toLowerCase()]["general"]['phenological_phases'].length ? cropRecords[cropType][cropVariety.toLowerCase()]["general"]['phenological_phases'] : cropRecords[cropType]["general"]["general"]['phenological_phases'].length ? cropRecords[cropType]["general"]["general"]['phenological_phases'] : [];
      }

      if (currentCropKCs.length > 0) {

        let cropDataObject = new GeneralClasses.CropData();

        // GDD as default if gdd duration exist..
        let type = "byGrowthPhase"

        // Check if crop KCs have GDD duration and set the appropriate type
        if (currentCropKCs[0]["duration_gdd"] !== "") {
          type = "byGrowthPhaseGDD"
        }

        cropDataObject['kc_table']['type'] = type;
        cropDataObject['kc_table']['data']['byGrowthPhase'] = currentCropKCs
        cropDataObject['kc_table']['data']['byGrowthPhaseGDD'] = currentCropKCs

        resolve(cropDataObject['kc_table']);

      } else {
        reject("No KCs were found for current parameters");
      }

    } else {
      reject("Crop Type or Crop Feature weren't found in Crop Records");
    }
  })

};


export var generateCoupParameters = (cropRecords, fieldCrop, fieldVariety, fieldFeature) => {

  if ((fieldCrop !== "") && (fieldVariety in cropRecords[fieldCrop]) && (fieldFeature in cropRecords[fieldCrop][fieldVariety])) {
    return cropRecords[fieldCrop][fieldVariety][fieldFeature]['coup_parameters']
  } else if ((fieldCrop !== "") && !(fieldVariety in cropRecords[fieldCrop]) && (fieldFeature in cropRecords[fieldCrop]["general"])) {
    return cropRecords[fieldCrop]["general"][fieldFeature]['coup_parameters']
  } else if ((fieldCrop !== "") && (fieldVariety in cropRecords[fieldCrop]) && !(fieldFeature in cropRecords[fieldCrop][fieldVariety])) {
    let firstFeature = Object.keys(cropRecords[fieldCrop][fieldVariety]).includes("general") ? "general" : Object.keys(cropRecords[fieldCrop][fieldVariety])[0]
    return cropRecords[fieldCrop][fieldVariety][firstFeature]['coup_parameters']
  } else return cropRecords[fieldCrop]["general"]["general"]['coup_parameters']


}
