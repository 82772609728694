import React, {useEffect, useState} from "react";
import $ from "jquery";
import {MultiSelect} from "react-multi-select-component";
import {useDispatch, useSelector} from "react-redux";
var distributionActions = require('distributionActions');

let configurationValueOptions = [
    {value: "true", label: "True"},
    {value: "false", label: "False"},
];
let configurationEnvsOptions = [
    {value: "stg :True", label: "Staging :True"},
    {value: "stg :False", label: "Staging :False"},
    {value: "dev :True", label: "Development :True"},
    {value: "dev :False", label: "Development :False"},
    {value: "prod :True", label: "Production :True"},
    {value: "prod :False", label: "Production :False"},
];

export const ConfigurationModal = (props) => {
    const dispatch = useDispatch();
    const [configurationData, setConfigurationData] = useState({});
    const [isEditModal, setIsEditModal] = useState(false);
    const all_distributions = useSelector((state) => state.distribution_data.distributions);
 let configurationDistributionsOptions = Object.values(all_distributions?.distributions || {})
    ?.flatMap((distribution) => {
        return [
            { value: distribution.name + " :True", label: distribution.name + " :True"},
            { value: distribution.name + " :False", label: distribution.name + " :False" }
        ];
    }) || [];
 let configurationFarmingUnitsOptions = Object.values(all_distributions?.distributions || {})
    .flatMap((distribution) => {
        return Object.values(distribution?.farming_units).flatMap((farming_unit) => {
            return [
                { value: farming_unit.name + " :True", label: farming_unit.name + " :True"},
                { value: farming_unit.name + " :False", label: farming_unit.name + " :False" }
            ];
        });
    }) || [];

    useEffect(() => {
        dispatch(distributionActions.load_distributions(true));
    }, []);

    $("#" + props.modalId).on('show.bs.modal', function (event) {

        // Clear all previous state for famring units
        var form = document.getElementById("configuration_form");
        form.classList.remove('was-validated');
        var button = $(event.relatedTarget) // Button that triggered the modal
        var configuration_name = button[0].dataset.configuration_name
        if (configuration_name === "") {
            setConfigurationData({
                configuration_name: "",
                default_value: false,
                description: "",
                environments: [],
                users_email: [],
                distributions: [],
                farming_units: []
            });
            setIsEditModal(false);
        } else {
            // Extract info from data-* attributes
            var configuration_id = button[0].dataset.configuration_id
            var configuration_value = button[0].dataset.configuration_value
            var configuration_description = button[0].dataset.configuration_description
            var configuration_envs = button[0].dataset.configuration_envs
            var configuration_users = button[0].dataset.configuration_users
            var configuration_distributions = button[0].dataset.configuration_distributions
            var configuration_farming_units = button[0].dataset.configuration_farming_units

            setConfigurationData({
                id: configuration_id,
                configuration_name: configuration_name,
                default_value: configuration_value,
                description: configuration_description,
                environments: configuration_envs?.split(","),
                users_email: configuration_users?.toString(),
                distributions: configuration_distributions?.split(","),
                farming_units: configuration_farming_units?.split(",")
            });
            setIsEditModal(true);
        }
    });

    return (
        <div className="modal fade" id={props.modalId} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">

            <form className="needs-validation" noValidate id="configuration_form">

                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-grey45 font-weight-semi-bold"
                                id="exampleModalLabel">{configurationData?.configuration_name === "" ? "Add New Configuration" : "Edit Configuration"}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group  ml-2 mr-2 mt-3">
                                <label htmlFor="distributionName">{"Feature name"}</label>
                                <input type="text" className="form-control text-grey45" id="configuration_feature_name"
                                       aria-describedby="emailHelp" placeholder={"Please enter feature name"} required
                                       value={configurationData.configuration_name}
                                       onChange={(e) => setConfigurationData({
                                           ...configurationData,
                                           configuration_name: e.target.value
                                       })}
                                />
                            </div>
                            <div className="form-group  ml-2 mr-2 mt-3">
                                <label htmlFor="distributionName">{"Description"}</label>
                                <input type="text" className="form-control text-grey45" id="configuration_description"
                                       aria-describedby="emailHelp" placeholder={"Please enter configuration description"} required
                                       value={configurationData.description}
                                       onChange={(e) => setConfigurationData({
                                           ...configurationData,
                                           description: e.target.value
                                       })}
                                />
                            </div>
                            <div className="form-group ml-2 mr-2 mt-3">
                                <label htmlFor="distributionName">{"Feature default value"}</label>
                                <select value={configurationData.default_value}
                                        className="form-control text-grey45 custom-select"
                                        id="configuration_feature_value"
                                        aria-describedby="emailHelp"
                                        onChange={(e) =>
                                            setConfigurationData({
                                                ...configurationData,
                                                default_value: e.target.value
                                            })}
                                        required>
                                    {configurationValueOptions.map((optionObj, index) => {
                                        return <option key={index}
                                                       value={optionObj.value}>{optionObj.label}</option>
                                    })}
                                </select>
                            </div>
                            <div className="form-group ml-2 mr-2 mt-3">
                                <label htmlFor="distributionName">{"Affected environments"}</label>
                                <MultiSelect className="text-grey45"
                                             options={configurationEnvsOptions}
                                             value={configurationData?.environments?.map((env) => {
                                                 return {value: env, label: env}
                                             }) || []}
                                             onChange={(e) => {
                                                 setConfigurationData({
                                                     ...configurationData,
                                                     environments: e.map((env) => env.value)
                                                 })
                                             }}
                                             labelledBy="Select"
                                />
                            </div>
                            <div className="form-group ml-2 mr-2 mt-3">
                                <label htmlFor="distributionName">{"Affected distributions"}</label>
                                <MultiSelect className="text-grey45"
                                             options={configurationDistributionsOptions}
                                             value={configurationData?.distributions?.map((dist) => {
                                                 return {value: dist, label: dist}
                                             }) || []}
                                             onChange={(e) => {
                                                 setConfigurationData({
                                                     ...configurationData,
                                                     distributions: e.map((dist) => dist.value)
                                                 })
                                             }}
                                             labelledBy="Select"
                                />
                            </div>
                            <div className="form-group ml-2 mr-2 mt-3">
                                <label htmlFor="farmingUnits">{"Affected farming units"}</label>
                                <MultiSelect className="text-grey45"
                                             options={configurationFarmingUnitsOptions}
                                             value={configurationData?.farming_units?.map((farmingUnit) => {
                                                 return {value: farmingUnit, label: farmingUnit}
                                             }) || []}
                                             onChange={(e) => {
                                                 setConfigurationData({
                                                     ...configurationData,
                                                     farming_units: e.map((farmingUnit) => farmingUnit.value)
                                                 })
                                             }}
                                             labelledBy="Select"
                                />
                            </div>
                            <div className="form-group  ml-2 mr-2 mt-3">
                                <label htmlFor="distributionName">{"Affected users"}</label>
                                <input type="text" className="form-control text-grey45"
                                       id="configuration_affected_users" aria-describedby="emailHelp"
                                       placeholder={"Please enter users email separated by comma(,)"}
                                       value={configurationData.users_email}
                                       onChange={(e) => setConfigurationData({
                                           ...configurationData,
                                           users_email: [e.target.value]
                                       })}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="container text-center">
                                <button className="btn btn-dark-green btn-lg general-button" onClick={(event)=>{
                                    event.preventDefault();
                                    isEditModal ? props.onEditConfiguration(configurationData) : props.onAddNewConfiguration(configurationData);
                                    $("#" + props.modalId).modal('hide');
                                }}>
                                    {isEditModal ? "Update" : "Submit"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}