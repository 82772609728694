import React, {useEffect, useState} from "react";
import ReactCardFlip from 'react-card-flip';
import {ConfigurationModal} from "./ConfigurationModal";

var remoteConfigurationsManager = require('@managers/remoteConfigurationsManager');


export const RemoteConfigurationsContainer = (props) => {


    const [configurationsData, setConfigurationsData] = useState([]);
    const [flipCard, setFlipCard] = useState(new Array(configurationsData.length).fill(false));
    let configurationModalId = "configurationModalId";

    useEffect( ()=>{
        let fetchData = async () => {
            let res = await remoteConfigurationsManager.get_configurations();
            setConfigurationsData(res.data.configurations);
            setFlipCard(new Array(res.data.configurations.length).fill(false));
        }
        fetchData().then(r => console.log("Configurations fetched"));
    },[])

    const handleAddNewConfiguration = async (configuration) => {
        configuration.default_value = configuration.default_value.toString();
        configuration.users_email = configuration.users_email.length > 0 ?  configuration.users_email?.toString().split(",") : configuration.users_email;
        configuration.default_value = configuration.default_value.toLowerCase() === 'true';
        let res = await remoteConfigurationsManager.create_new_configuration(configuration);
        configuration.id = res.configuration_id;
        configuration.distributions = remoteConfigurationsManager.convertObjectToArray(configuration.distributions);
        configuration.farming_units = remoteConfigurationsManager.convertObjectToArray(configuration.farming_units);
        configuration.environments = remoteConfigurationsManager.convertObjectToArray(configuration.environments);
        configuration.users_email = remoteConfigurationsManager.convertObjectToArray(configuration.users_email);
        setConfigurationsData([...configurationsData, configuration]);
        setFlipCard([...flipCard, false]);
    }

    const handleEditConfiguration = async (configuration) => {
        configuration.users_email = configuration.users_email?.toString().split(",");
        configuration.default_value = configuration.default_value.toLowerCase() === 'true';
        await remoteConfigurationsManager.update_configuration(configuration);
        let configurationsDataCopy = [...configurationsData];
        let configurationIndex = configurationsDataCopy.findIndex((config) => config.id === configuration.id);
        configuration.distributions = remoteConfigurationsManager.convertObjectToArray(configuration.distributions);
        configuration.farming_units = remoteConfigurationsManager.convertObjectToArray(configuration.farming_units);
        configuration.environments = remoteConfigurationsManager.convertObjectToArray(configuration.environments);
        configuration.users_email = remoteConfigurationsManager.convertObjectToArray(configuration.users_email);
        configurationsDataCopy[configurationIndex] = configuration;
        setConfigurationsData(configurationsDataCopy);
        handleFlipCard(configurationIndex);
    }

    const handleDeleteConfiguration = (configuration) => {
        let configurationsDataCopy = [...configurationsData];
        let configurationIndex = configurationsDataCopy.findIndex((config) => config.id === configuration.id);
        let configurationID = configurationsDataCopy[configurationIndex].id;
        let res = remoteConfigurationsManager.delete_configuration(configurationID);
        configurationsDataCopy.splice(configurationIndex, 1);
        setConfigurationsData(configurationsDataCopy);
        handleFlipCard(configurationIndex);
    }

    const handleFlipCard = (index) => {
        let cardsStatus = [...flipCard];
        let newFlippedCardsStatus = flipCard[index];
        newFlippedCardsStatus = !newFlippedCardsStatus;
        cardsStatus[index] = newFlippedCardsStatus;
        setFlipCard(cardsStatus);
    }

    return (
        <div style={{margin: "16px"}}>
            <ConfigurationModal modalId={configurationModalId} onAddNewConfiguration={handleAddNewConfiguration}
                                onEditConfiguration={handleEditConfiguration}/>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                <div style={{width: "90%", paddingLeft: "100px"}}>
                    <div style={{textAlign: "center"}} className="text-size-42 text-grey45 font-weight-bold">Remote
                        Configurations
                    </div>
                </div>
                <div>
                    <button className="btn btn-link" data-toggle="modal" data-target={"#" + configurationModalId}
                       data-configuration_name={""}>
                        <button className="btn btn-secondary">Add new +</button>
                    </button>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", flexWrap:"wrap", justifyContent:"center",gap:"12px", width:"100%"}}>
                {configurationsData?.map((configuration, index) => {

                    return (
                        <div style={{width: "12%", minWidth: "200px", boxSizing: "border-box", padding: "10px"}}>
                            <ReactCardFlip flipSpeedFrontToBack={0.5} flipSpeedBackToFront={0.5}
                                           isFlipped={flipCard[index]} flipDirection="horizontal">
                                <div className="bg-grey91 text-grey45 d-flex " style={{
                                    width: "fit-content",
                                    height: "fit-content",
                                    wordBreak: "break-word",
                                    border: "1px solid #4D5674",
                                    borderRadius: "8px",
                                    flexDirection: "column",
                                    padding: "10px"
                                }}>
                                    <div
                                        className="font-weight-bold d-flex align-items-center justify-content-center text-size-18"
                                        style={{marginBottom: "8px"}}>{configuration.configuration_name}</div>
                                    <div className="d-flex" style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderBottom: "1px solid #A8ABB7"
                                    }}>
                                        <div
                                            className="font-weight-bold text-size-16 d-flex justify-content-center align-items-center"
                                            style={{marginRight: "8px"}}>Default value:
                                        </div>
                                        <div className="text-size-16" style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "4px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginBottom: "4px"
                                        }}>
                                            <div className="text-white" style={{
                                                backgroundColor: "#919DB4",
                                                border: "1px solid #AFB9CC",
                                                borderRadius: "3px",
                                                padding: "2px"
                                            }}>{configuration?.default_value?.toString()}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex" style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderBottom: "1px solid #A8ABB7"
                                    }}>
                                        <div
                                            className="font-weight-bold text-size-16 d-flex justify-content-center align-items-center"
                                            style={{marginRight: "8px"}}>Affected environments:
                                        </div>
                                        <div className="text-size-16" style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "4px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginBottom: "4px"
                                        }}>
                                            {configuration?.environments?.map((environment) => {
                                                return (
                                                    <div className="text-white" style={{
                                                        backgroundColor: "#919DB4",
                                                        border: "1px solid #AFB9CC",
                                                        borderRadius: "3px",
                                                        padding: "2px"
                                                    }}>{environment}</div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex" style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderBottom: "1px solid #919DB4"
                                    }}>
                                        <div
                                            className="font-weight-bold text-size-16 d-flex justify-content-center align-items-center"
                                            style={{marginRight: "8px"}}>Affected distributions:
                                        </div>
                                        <div className="text-size-16" style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "4px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginBottom: "4px"
                                        }}>
                                            {configuration?.distributions?.map((distribution) => {
                                                return (
                                                    <div className="text-white" style={{
                                                        backgroundColor: "#919DB4",
                                                        border: "1px solid #AFB9CC",
                                                        borderRadius: "3px",
                                                        padding: "2px"
                                                    }}>{distribution}</div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex" style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderBottom: "1px solid #A8ABB7"
                                    }}>
                                        <div
                                            className="font-weight-bold text-size-16 d-flex justify-content-center align-items-center"
                                            style={{marginRight: "8px"}}>Affected farming units:
                                        </div>
                                        <div className="text-size-16" style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "4px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginBottom: "4px"
                                        }}>
                                            {configuration?.farming_units?.map((farmingUnit) => {
                                                return (
                                                    <div className="text-white" style={{
                                                        backgroundColor: "#919DB4",
                                                        border: "1px solid #AFB9CC",
                                                        borderRadius: "3px",
                                                        padding: "2px"
                                                    }}>{farmingUnit}</div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex" style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderBottom: "1px solid #A8ABB7",
                                        marginBottom: "15px"
                                    }}>
                                        <div
                                            className="font-weight-bold text-size-16 d-flex justify-content-center align-items-center"
                                            style={{marginRight: "8px"}}>Affected users:
                                        </div>
                                        <div className="text-size-16" style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "4px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginBottom: "4px"
                                        }}>
                                            {configuration?.users_email?.map((user) => {
                                                return (
                                                    <div className="text-white" style={{
                                                        backgroundColor: "#919DB4",
                                                        border: "1px solid #AFB9CC",
                                                        borderRadius: "3px",
                                                        padding: "2px"
                                                    }}>{user}</div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <button className="btn btn-secondary" onClick={() => {
                                        handleFlipCard(index)
                                    }}>Description & Actions
                                    </button>
                                </div>
                                <div className="bg-grey91 text-grey45 d-flex " style={{
                                    width: "fit-content",
                                    height: "fit-content",
                                    wordBreak: "break-word",
                                    border: "1px solid #4D5674",
                                    borderRadius: "8px",
                                    flexDirection: "column",
                                    padding: "10px"
                                }}>
                                    <div
                                        className="font-weight-bold d-flex align-items-center justify-content-start text-size-18"
                                        style={{marginBottom: "4px"}}>Description
                                    </div>
                                    <div className="text-size-16"
                                         style={{marginBottom: "4px"}}>{configuration.description}</div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "end"
                                    }}>
                                        <button className="btn btn-secondary" style={{
                                            width: "50px",
                                            height: "50px",
                                            padding: "4px",
                                            marginRight: "2px"
                                        }} onClick={() => {
                                            handleFlipCard(index)
                                        }}>&#128281;</button>
                                        <button
                                            style={{width: "50px", height: "50px", padding: "4px", marginRight: "2px"}}
                                            className="btn btn-secondary" data-toggle="modal"
                                            data-target={"#" + configurationModalId}
                                            data-configuration_id={configurationsData[index].id}
                                            data-configuration_name={configurationsData[index].configuration_name}
                                            data-configuration_value={configurationsData[index].default_value}
                                            data-configuration_description={configurationsData[index].description}
                                            data-configuration_envs={configurationsData[index].environments}
                                            data-configuration_users={configurationsData[index].users_email}
                                            data-configuration_distributions={configurationsData[index].distributions}
                                            data-configuration_farming_units={configurationsData[index].farming_units}>
                                            &#128394;
                                        </button>
                                        <button aria-label={"delete"} onClick={() => {
                                            handleDeleteConfiguration(configurationsData[index])}
                                        }
                                                style={{width: "50px", height: "50px", padding: "4px"}}
                                                className="btn btn-secondary">
                                            &#129512;
                                        </button>
                                    </div>
                                </div>
                            </ReactCardFlip>
                        </div>
                    )
                })
                }
            </div>
        </div>

    )
}

