var React = require('react');
const {useEffect, useState} = require("react");
const {useTranslation, withTranslation} = require("react-i18next");
const NotificationsList = require("./NotificationsList")
const {connect} = require("react-redux");
var notificationsManager = require('notificationsManager');
var userActions = require('userActions');

const SELECT_OPTION_ALL = 'all';
const SELECT_OPTION_CURRENT = 'current';

const NotificationsCenterContainer = (props) => {

    const {t} = useTranslation();
    const [selectedOption, setSelectedOption] = useState(SELECT_OPTION_ALL);
    const [allNotificationsData, setAllNotificationsData] = useState([]);
    const [selectedFieldNotificationsData, setSelectedFieldNotificationsData] = useState([])
    const [shouldDisplayWelcomeNotification, setShouldDisplayWelcomeNotification] = useState(false);

    useEffect(() => {

        if (props?.selectedField !== null && Object.keys(props.notificationsData).length > 0) {
            let NotificationsDataRes = notificationsManager.notificationsDataMap(props.notificationsData, props?.selectedField?.id);
            setAllNotificationsData(NotificationsDataRes?.allNotifications);
            setSelectedFieldNotificationsData(NotificationsDataRes?.currentFieldNotifications)

            if (Object.keys(props?.userData)?.length !== 0 && props.userData.hasOwnProperty("meta_data") && props.userData?.meta_data?.notifications?.welcome_notification) {
                setShouldDisplayWelcomeNotification(true)
            } else {
                setShouldDisplayWelcomeNotification(false)
            }
        }
    }, [props?.selectedField, props.notificationsData])

    const updateUserWelcomeNotification = () => {

        setShouldDisplayWelcomeNotification(false);
        let copyOfUserData = {};
        Object.assign(copyOfUserData, props.userData);

        copyOfUserData["meta_data"] = {
            "notifications": {
                "welcome_notification": false
            }
        }
        props.setUserMetaData({"meta_data_notifications_welcome": copyOfUserData["meta_data"]["notifications"]["welcome_notification"]});
        mixpanel.track("Notification Welcome Message Dismissed");
    }

        const changeSelection = (selectedOption) => {
        setSelectedOption(selectedOption);
        mixpanel.track("Notification Menu Tab Selection",  {
               "Tab selected": t(selectedOption)
            });
    }

    return (
        <div>
            <div style={{
                borderBottom: "1px solid #DCE0E8",
                height: "32px",
                display: "flex",
                flexDirection: "row",
                paddingLeft: "16px",
                paddingRight: "24px",
                textAlign: "center",
                alignItems: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white"
            }}>
                <div className="d-flex flex-column" style={{width: "50%"}}>
                    <div id="link_div"
                         className={"text-size-13 font-weight-bold text-grey71 " + (selectedOption === SELECT_OPTION_ALL ? 'text-blue-primary' : '')}
                         onClick={() => changeSelection(SELECT_OPTION_ALL)} style={{
                        textTransform: "uppercase",
                        letterSpacing: "0.91px",
                        lineHeight: "195%",
                        marginBottom: "5px",
                        cursor: "pointer"
                    }}>{t("all_fields")}</div>
                    <div className={(selectedOption === SELECT_OPTION_ALL ? 'bg-blue-primary' : '')}
                         style={{width: "100%", height: "3px"}}/>
                </div>
                <div className="d-flex flex-column" style={{width: "50%"}}>
                    <div id="link_div"
                         className={"text-size-13 font-weight-bold text-grey71 " + (selectedOption === SELECT_OPTION_CURRENT ? 'text-blue-primary' : '')}
                         onClick={() => changeSelection(SELECT_OPTION_CURRENT)} style={{
                        textTransform: "uppercase",
                        letterSpacing: "0.91px",
                        lineHeight: "195%",
                        marginBottom: "5px",
                        cursor: "pointer"
                    }}>{props.openFromMainDashboard ? t("current_field") : t("recent_field")}</div>
                    <div className={(selectedOption === SELECT_OPTION_CURRENT ? 'bg-blue-primary' : '')}
                         style={{width: "100%", height: "3px"}}/>
                </div>
            </div>
            <NotificationsList onCloseClicked={props.onCloseClicked} shouldDisplayWelcomeNotification={shouldDisplayWelcomeNotification}
                               updateUserWelcomeNotification={updateUserWelcomeNotification}
                               notificationsData={selectedOption === SELECT_OPTION_ALL ? allNotificationsData : selectedFieldNotificationsData}/>
        </div>
    );
}

const mapStateToProps = function (state) {

    return {
        selectedField: state.distribution_data.selected_entities.field,
        active_distribution: state.distribution_data.selected_entities.distribution,
        userData: state.authentication_data.user_data,

    }
}

const mapDispatchToProps = {
    setUserMetaData: userActions.set_user_meta_data,
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NotificationsCenterContainer));