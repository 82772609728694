const React = require('react');
const {SelectMenu} = require('SelectMenu');
const {useTranslation, withTranslation} = require("react-i18next");
const {useState, useEffect} = require("react");
const ArrowIcon = require('images/icons/edit_field/ArrowIcon.svg');
const moment = require('moment');
const {connect} = require("react-redux");
const {unitDisplayName} = require('unitsManager');
const FertigationConcentrationsInputLine = require("FertigationConcentrationsInputLine");
var {upper} = require('@managers/helpers');


const EditFertigationConcentration = ({onDone, onCancel}) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {t} = useTranslation();

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState("");
    const [fertType, setFertType] = useState(0);
    const [concentration, setConcentration] = useState(0);


    const onStartDateChanged = (id, newStartDate) => {
        setStartDate(newStartDate)
    }
    const onFTypeChanged = (id, newType) => {
        setFertType(newType);
    }
    const onConcentrationChanged = (id, newConcentration) => {
        setConcentration(newConcentration);
    }

    const handleDonePressed = () => {

        if ((fertType === "") || (concentration === "") || (startDate == "") || (!startDate)) {
            setError(true);
            setErrorMessage(t("missing_values"));
        } else {
            setError(false);
            setErrorMessage(t("missing_values"));
            onDone(startDate, fertType, concentration);
        }
    }

    return (
        <div className="d-flex mb-4 card bg-grey96">

            <div className="mx-3 my-2">
                <div className="d-flex flex-row">
                    <div className="mr-2" style={{flex: 1}}>
                        <div className='text-size-14 lh-1 font-weight-semi-bold'>{t("start_date")}</div>
                    </div>
                    <div className="mr-2" style={{flex: 1}}>
                        <div className='text-size-14 lh-1 font-weight-semi-bold'>{t("end_date")}</div>
                    </div>
                    <div className="mr-2" style={{flex: 1}}>
                        <div className='text-size-14 lh-1 font-weight-semi-bold'>{upper(t('type'))}</div>
                    </div>
                    <div className="" style={{flex: 1}}>
                        <div className='text-size-14 lh-1 font-weight-semi-bold'>{upper(t('concentration'))} (mg/l)</div>
                    </div>
                </div>

                <FertigationConcentrationsInputLine id={undefined} startDate={startDate} endDate={t('not_yet_set')}
                                                    fertType={fertType} concentration={concentration}
                                                    onStartDateChange={onStartDateChanged}
                                                    onFTypeChanged={onFTypeChanged}
                                                    onConcentrationChanged={onConcentrationChanged}
                                                    onDelete={undefined}/>
            </div>

            <div className='d-flex flex-row align-items-center w-100 my-2 pr-3'>
                <label hidden={!error} className='mb-0 ml-3 text-size-14 lh-1 text-red'>{errorMessage}</label>
                <button style={{outline: 'none', width: 80, height: 24}} type='button'
                        className='btn btn-outline-secondary d-flex mr-2 text-size-12 text-grey45 font-weight-semi-bold ml-auto justify-content-center align-items-center'
                        onClick={onCancel}>
                    Cancel
                </button>
                <button style={{outline: 'none', width: 80, height: 24}} type='button'
                        className={'btn d-flex mr-2 justify-content-center align-items-center bg-blue-primary text-size-12 text-white font-weight-semi-bold '}
                        onClick={() => handleDonePressed()}>
                    Done
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        userData: state.authentication_data.user_data
    }
}
const mapDispatchToProps = {}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditFertigationConcentration));