var React = require('react');
var {connect} = require('react-redux');
var fieldManager = require('fieldManager');
var {upper} = require('helpers');
var moment = require('moment');
var distributionActions = require('distributionActions');
var IrrigationMeterContainer = require('IrrigationMeterContainer');
var FertilizationStatusContainer = require('FertilizationStatusContainer');
var NextActionsContainer = require('NextActionsContainer');
var FieldDetails = require('FieldDetails');
var ExtendedFieldDetails = require('ExtendedFieldDetails');
var WaterPotentialGraphContainer = require('WaterPotentialGraphContainer');
var NitrogenGraphContainer = require('NitrogenGraphContainer');
var farmingUnitManager = require('farmingUnitManager');
var WaterMeterGraphContainer = require("WaterMeterGraphContainer");
var MapContainer = require('MapContainer');
var FieldsDropdown = require('FieldsDropdown');
var authenticateActions = require('authenticateActions');
var { withTranslation } = require('react-i18next');
var { Link } = require ('react-router-dom');
var MainCSS = require('mainCSS');
var ArchiveFieldIcon = require('images/icons/dashboard_states/archive_field_icon.svg')



class DashboardContent extends React.Component {

  constructor(props) {
    super(props);

    this.onLogoutButtonClick = this.onLogoutButtonClick.bind(this);
    this.paintArrow = this.paintArrow.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
    this.state = {
      selectedSection: 'drop',
      firstTimeInit: true
    };
  }

  componentDidMount() {
  }

  onToggleClick(toggle) {
    if (toggle == "drop") {
      mixpanel.track("Water Section Load");
    } else if (toggle === 'nitrogen') {
      mixpanel.track("Nitrogen Section Load");
    }
    else{
      mixpanel.track("Accumulated Water Inputs Section Load");
    }
    this.setState({selectedSection:toggle})
  }

  paintArrow(canvasElement) {
    if(canvasElement) {
      var ctx = canvasElement.getContext("2d");
      ctx.lineWidth = 13;
      ctx.lineJoin = "miter";
      ctx.miterLimit = 1;
      ctx.strokeStyle = "#919DB4";
      ctx.moveTo(0, ctx.canvas.height);
      ctx.lineTo(ctx.canvas.width / 2.0, 0);
      ctx.lineTo(ctx.canvas.width, ctx.canvas.height);
      ctx.stroke();
    }
  }

  graphSwitch(toggleIcons){
    if(this.state.selectedSection == "drop"){
      return <WaterPotentialGraphContainer onToggleClick={this.onToggleClick} icon={toggleIcons}/>;
    }
    if (this.state.selectedSection == "nitrogen"){
      return <NitrogenGraphContainer onToggleClick={this.onToggleClick} icon={toggleIcons}/>;
    }
    if (this.state.selectedSection == "accumulated"){
      return <WaterMeterGraphContainer onToggleClick={this.onToggleClick} icon={toggleIcons}/>;

    }
  }

  componentDidUpdate(previousProps, previousState) {
    var arrowNitrogen = document.getElementById("arrowNitrogen");
    var arrowWater = document.getElementById("arrowWater");
    this.paintArrow(arrowNitrogen);
    this.paintArrow(arrowWater);
    var graphToDisplay = fieldManager.dashboardGraphToDisplay(this.props.selectedField)

    if (((previousProps.selectedField?.id !== this.props.selectedField?.id) && !this.state.firstTimeInit)) {
      this.setState({selectedSection: graphToDisplay, firstTimeInit: true})
    }
    else if ((previousProps.selectedField?.id === this.props.selectedField?.id) && this.state.firstTimeInit) {
      this.setState({
        selectedSection: graphToDisplay,
        firstTimeInit: false
      })
    }
  }

  componentWillUnmount() {
  }

  onLogoutButtonClick() {
    this.props.logout();
  }

  render() {
    const { t, i18n } = this.props;
    let shouldDisplayNitrogen = fieldManager.isSupportingNitrogen(this.props.selectedField);
    let fieldHasPlan = fieldManager.isSupportingRollingFit(this.props.selectedField);
    var shouldDisplayAccumulatedIrr = fieldManager.shouldDisplayAccumulatedIrrigation(this.props.selectedField)
    var toggleIcons = ['drop',shouldDisplayNitrogen && 'nitrogen',shouldDisplayAccumulatedIrr ? 'accumulated' : null ]
    let isArchive = this?.props?.field_state === 'archive' ? true : false;
    var dashboardcolor = isArchive ? " text-grey71" : " text-grey45";

    return (

      <div className="ml-0 ml-lg-3 mr-2">
          <div className="container-fluid p-0 ">

          <div className="row pb-3 ">
            <div className={"col-xl-12 p-0" + dashboardcolor}>
                <div className="font-weight-bold text-size-24 ">
                  <div className="d-none d-xl-flex align-items-center ">{isArchive && <div className="d-inline" style={{paddingRight:"8px"}}><img src={ArchiveFieldIcon}/></div>}{this.props.selectedField && <div className="d-inline" style={{paddingTop: isArchive ? "8px" : "0px"}}>{this.props.selectedField.name}</div>}</div>
                    <div className="d-xl-none d-flex">
                      {isArchive && <div style={{paddingRight:"8px"}}><img src={ArchiveFieldIcon}/></div>}
                      <FieldsDropdown isArchiveMode={isArchive}/>
                    </div>
                </div>

                <div className="text-right">
                  <small>
                    {this.props.selectedFarmingUnit && this.props.selectedFarmingUnit.role == "admin" && !isArchive &&
                      <a data-toggle="modal" data-target={"#" + "fieldModalId"} className="text-grey45 d-xl-none mr-2" data-id={this.props.selectedField ? this.props.selectedField.id : '0'} href="#">{t('dashboard_edit_field')}</a>

                    }
                    {this.props.selectedFarmingUnit && this.props.selectedFarmingUnit.role == "admin" &&
                      <Link className="mr-1 text-grey45 d-xl-none" to="Admin">{t("transmitter_admin_page")}</Link>
                    }

                    <a href="#" className="text-grey45 d-xl-none mr-4" onClick={this.onLogoutButtonClick}>{t('logout')}</a>
                  </small>

                </div>

            </div>

            <div className="col-lg-2">

            </div>
          </div>


          <div className="row" style={{"lineHeight":"40px"}}>
            <div className="col p-0 ">
              <ExtendedFieldDetails/>
            </div>
          </div>

            {this.props.field_state === "archive" && <div className="row d-flex bd-highlight flex-wrap" style={{
              "backgroundColor": "#F6F8FA",
              "borderRight": "1px solid #DCE0E8",
              "borderLeft": "1px solid #DCE0E8"
            }}>
              <div className="pt-3 p-3 col-12 " style={{"height": "500px", "backgroundColor": "#F6F8FA"}}>
                {this.state.selectedSection == "drop" ?
                    <WaterPotentialGraphContainer onToggleClick={this.onToggleClick}
                                                  icon={toggleIcons}/> : this.state.selectedSection === "nitrogen" ?
                        <NitrogenGraphContainer onToggleClick={this.onToggleClick} icon={toggleIcons}/> :
                        <WaterMeterGraphContainer onToggleClick={this.onToggleClick} icon={toggleIcons}/>}
              </div>
            </div>}

          {this.props.field_state === "active" && !shouldDisplayNitrogen &&
            <div className="d-flex bd-highlight dashboardNextActionsContainerGraphOnly" style={{"backgroundColor":"#F6F8FA", "borderRight":"1px solid #DCE0E8", "borderLeft":"1px solid #DCE0E8",marginRight:"-15px",marginLeft:"-15px"}}>
            <div className="flex-xl-column flex-sm-row flex-column p-xl-0 p-3 d-flex justify-content-center col-12 flex-grow-1 nextActionsContainerGraphOnly" style={{minWidth:"430px",maxWidth:"430px"}}>
                <div className="d-flex flex-column w-100 h-100 pl-3 pt-4 pr-3">
                    <NextActionsContainer selectedField={this.props.selectedField}
                                          timezone={this.props.selectedField && this.props.selectedField.geo_data.time_zone}
                                          parentHeight={"584px"} heigtAuto={true} fieldHasPlan={fieldHasPlan}/>
              </div>
            </div>
            <div className="pt-4 p-3 flex-grow-1 " style={{"height":"650px", "backgroundColor":"#F6F8FA"}}>

          {this.state.selectedSection === "drop" ?
                  <WaterPotentialGraphContainer onToggleClick={this.onToggleClick} icon={toggleIcons}/> : <WaterMeterGraphContainer onToggleClick={this.onToggleClick} icon={toggleIcons}/>}
            </div>
          </div>
          }

            {this.props.field_state === "active" && shouldDisplayNitrogen &&
            <div >
              <div className="row p-3 bd-highlight" style={{"backgroundColor":"#F6F8FA", "borderRight":"1px solid #DCE0E8","borderLeft":"1px solid #DCE0E8"}} >
                <div className="d-flex align-items-center flex-column flex-grow-1 dashboardNextActionsContainer" style={{"height":"350px",minWidth:"430px",maxWidth:"430px"}}>
                  <div className="d-flex flex-column w-100 h-100">
                    <div className="">
                      <NextActionsContainer selectedField={this.props.selectedField} timezone={this.props.selectedField.geo_data.time_zone}
                                            parentHeight={"316px"} heightAuto={true} fieldHasPlan={fieldHasPlan} displayNitrogen={shouldDisplayNitrogen}/>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 pl-lg-3 pt-2 pt-lg-0" style={{"height":"350px"}}>
                  <div className="d-flex flex-column h-100 w-100">
                    <div className={"text-size-16 mb-2 font-weight-bold" + dashboardcolor}>{t('fertilization_gauge_title')}</div>
                    <div className="flex-grow-1 ">
                      <FertilizationStatusContainer/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 row" style={{"height":"500px", "backgroundColor":"#F6F8FA", "borderRight":"1px solid #DCE0E8", "borderLeft":"1px solid #DCE0E8"}}>
                {this.state.selectedSection === "drop" ?
                <WaterPotentialGraphContainer onToggleClick={this.onToggleClick} icon={toggleIcons}/> : this.state.selectedSection === "nitrogen" ?  <NitrogenGraphContainer onToggleClick={this.onToggleClick} icon={toggleIcons}/> : <WaterMeterGraphContainer onToggleClick={this.onToggleClick} icon={toggleIcons}/> }
              </div>
            </div>
            }
          <div className="row bg-grey96-three pt-3 pt-md-0" >
            <MapContainer archiveMode={this.props.archiveMode}/>
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = function(state){
  const field = state.distribution_data.selected_entities.field;
  return {
    selectedField: state.distribution_data.selected_entities.field,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    plan_irr: field && field.historical_data && field.historical_data.plan_irr,
    precipitation: field && field.historical_data && field.historical_data.precipitation_h,
    loadHistoryStatus: state.distribution_data.field_load_history,
    field_state: state.distribution_data.field_state
  }
}

const mapDispatchToProps = {
  setSelectedEntities: distributionActions.selected_entities,
  logout: authenticateActions.logout,
}


module.exports = connect(mapStateToProps,mapDispatchToProps)(withTranslation()(DashboardContent));
