var React = require('react');
var NavigationLine = require('NavigationLine');
var {connect} = require('react-redux');
var { Link } = require ('react-router-dom');
var { withTranslation } = require('react-i18next');

class Settings extends React.Component {

  constructor(props) {
      super(props);

      // this.onSubmitClick = this.onSubmitClick.bind(this);
  }

  // onSubmitClick(e) {
  //   debugger;
  // }


  render() {
    const { t } = this.props;

    return (
      <div className='container pt-5'>
        <div className="row justify-content-center">
        <div className="col-12">
          <h1>{t('settings')}</h1>
        </div>

        </div>
        <div className="row justify-content-center">
          <div className="col-12 align-self-center mt-3">
            <NavigationLine history={this.props.history}/>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 align-self-center mt-3 shadow-lg rounded">
            <Link to="DistributionUnitsManagement">{t('management_distribution_units_management')} <small>({t('management_admins_only')})</small></Link>

          </div>
          <div className="col-12 align-self-center shadow-lg rounded">
            <Link to="Admin">{t('transmitter_admin_page')} </Link>
          </div>
        </div>

      </div>
    );
  }
};

module.exports = connect()(withTranslation()(Settings));
